import { commonUtils } from '@/helpers/commonUtils'
import { envHelper } from '@/helpers/envHelper'
import { getOemHeaders } from '../oemUtils'

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
interface ReqInfo {
  url: string
  method: MethodType
  headers: Record<string, string>
  bodyArgs: APIBodyArgCollection
  controller?: AbortController
}

type APIQueryArgCollection = ApiTypes.APIQueryArgCollection
type APIBodyArgCollection = ApiTypes.APIBodyArgCollection

export class ApiProxy {
  public async sendRequest(
    method: MethodType,
    pathPrefix: string,
    urlArgs: APIQueryArgCollection = {},
    bodyArgs: APIBodyArgCollection = {},
    /** 需要在 /hy_api 加上 /foreign 的前缀时使用 */
    hasForeign = false,
    controller?: AbortController
  ): Promise<any> {
    let path = ''
    if (path.indexOf('http') === 0) {
      path = commonUtils.underlizeKey(path)
    } else {
      const apiBasePath = hasForeign ? `/foreign${envHelper.apiBasePath}` : envHelper.apiBasePath
      path = `${apiBasePath}${commonUtils.underlizeKey(pathPrefix)}`
    }
    const url = commonUtils.genApiUrl(path, commonUtils.underlize(urlArgs || {}))
    const headers = await this.getAuthHeaders()
    return this.handleSendRequest({
      url,
      headers,
      method,
      bodyArgs,
      controller,
    })
  }

  private handleSendRequest(reqInfo: ReqInfo): Promise<any> {
    const body: RequestInit = {
      method: reqInfo.method,
      headers: reqInfo.headers,
      signal: reqInfo.controller?.signal,
    }
    if (reqInfo.method === 'POST') {
      body.body = JSON.stringify(commonUtils.underlize(reqInfo.bodyArgs || {}))
    }
    const req = fetch(reqInfo.url, body)
    return req
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json()
        }
        throw commonUtils.camelize(res)
      })
      .then((res) => {
        if (res.success) {
          return commonUtils.camelize(res)
        }
        throw commonUtils.camelize(res)
      })
  }

  private async getAuthHeaders(): Promise<{ [key: string]: string }> {
    const oemHeaders = getOemHeaders()
    return {
      ...oemHeaders,
    }
  }
}
