const TRANSLATION = {
  buyModal: {
    afterFree: '무료 평가판 후',
    btn: '무료로 시도하십시오',
    freeTrial: '3 일 무료 평가판',
    monthLabel: '월',
    selectLabel: '계획을 선택하십시오',
    title: '구독 계획',
    yearLabel: '년도',
  },
  contact: {
    block1:
      '사용자 정의, 질문 또는 제안은 contact@noam.tools로 문의하십시오. 우리는 즉시 응답 할 것입니다.',
    block2: '★★★★★ (Google Apps Extension Store에서 우리를 평가하여 우리를 지원하십시오.',
    block3: '이 확장을 공유하여 다른 사람을 도와주세요.',
    title: '저희에게 연락하십시오',
  },
  help: {
    AquickOverviewOfNoam: 'NOAM의 빠른 개요',
    ChatPDF: '채팅 PDF',
    ChatPDF2: 'chatpdf',
    FavoritesManagement: '즐겨 찾기 관리',
    Fixedplugin: '고정 플러그인',
    Focusing: '초점',
    Free: '무료',
    FreeTrialBenefits: '무료 평가판 혜택',
    GoWithWikipedia: 'Wikipedia와 함께 가십시오',
    GoWithYouTube: 'YouTube와 함께 가십시오',
    HoverTranslation: '호버 번역',
    Howtouse: '사용 방법',
    ImageTranslation: '이미지 번역',
    InstantTranslation: '즉각적인 번역',
    Membership: '멤버십',
    NoamHelp: 'NOAM은 번역하고 요약하는 데 도움이됩니다',
    PDFparallelTranslation: 'PDF 병렬 번역',
    ParallelTranslation: '병렬 번역',
    SkipAllSteps: '모든 단계를 건너 뜁니다',
    Summary: '요약',
    Translation: '번역',
    TryItForFree: '무료로 시도하십시오',
    Video: '동영상',
    VideoSummary: '비디오 요약',
    VideoTranslation: '비디오 번역',
    WebSummary: '웹 요약',
    WordSelectionTranslation: '단어 선택 번역',
    benefits: '우리는 당신을 기다리는 혜택이 있습니다!',
    dayFree: '3 일 무료 평가판',
    done: '완료',
    next: '다음',
    pin: '더 쉽게 액세스 할 수 있도록 Noam을 핀',
    planIsNotExist: '계획을 사용할 수 없습니다',
    startWiki: 'Wikimedia부터 시작합시다!',
    startYoutube: 'YouTube부터 시작하겠습니다!',
    try: '시도해보십시오',
    tryNow: '지금 시도해보십시오!',
  },
  home: {
    PDFTranslate: {
      desc: '이중 언어 번역 + 채팅 PDF',
      sub1: {
        desc: '이중 언어 읽기를 통해 원본 텍스트와 번역을 나란히 비교하여 읽기 효율성을 향상시킬 수 있습니다.',
        title: '이중 언어 읽기',
      },
      sub2: {
        desc: 'AI는 PDF 컨텐츠를 기반으로 귀하의 질문을 분석하고 요약하고 답변 할 수 있습니다.',
        title: '채팅 PDF',
      },
      sub3: { desc: '더 큰 정확도와 효율성을위한 AI 기반 PDF 변환.', title: '정확성과 효율성' },
      title: 'PDF 번역',
    },
    aiAssistant: {
      desc: '웹 번역을 더 똑똑하고 편리하게 만듭니다',
      panel1: {
        desc: '이중 언어 상호 참조 번역을 통해 원본 텍스트를 번역과 비교하여 더 나은 이해와 읽기 효율성을 향상시킬 수 있습니다.',
        title: '이중 언어 번역',
      },
      panel2: {
        left: {
          desc: '웹 페이지를 탐색 할 때는 번역을 볼 수 있도록 번역 해야하는 컨텐츠를 호버링하십시오.',
          title: '호버 번역',
        },
        right: {
          desc: '웹 페이지를 탐색 할 때 번역 할 텍스트를 선택하여 번역을 볼 수 있습니다.',
          title: '텍스트 선택 번역',
        },
      },
      panel3: {
        one: {
          desc: '더 부드러운 경험을 위해 언제 어디서나 웹 페이지와 비디오 컨텐츠를 번역하고 요약하십시오!',
          title: '포인트 및 클릭',
        },
        three: {
          desc: 'AI 중심의 대형 언어 모델은 강화 된 읽기 경험을위한 번역 정확도와 일관성을 보장합니다.',
          title: 'AI 중심',
        },
        two: {
          desc: '다국어 전체 커버리지, 이중 언어 장벽이없는 독서, 원 클릭 번역-차이를 경험하십시오!',
          title: '더 포괄적이고 편리합니다',
        },
      },
      panel4: {
        tab1: {
          desc: '효율성 전문가 - Chatgpt를 사용하여 Noam은 YouTube 비디오 컨텐츠를 번역하고 요약하여 시간을 절약합니다!',
          key: '동영상',
          title: '비디오 번역 및 요약',
        },
        tab2: {
          desc: '웹을 탐색하는 동안 이미지를 번역하려면 클릭하십시오.',
          key: '영상',
          title: '이미지 번역',
        },
        tab3: {
          desc: '웹 페이지에서 주요 정보를 즉시 추출하고 요약을 제공하여 읽기 효율성을 향상시킵니다.',
          key: '편물',
          title: '웹 번역 및 요약',
        },
        tag1: '단순한',
        tag2: '효율적인',
        tag3: '이해하기 쉽습니다',
      },
      title: 'AI 보조',
    },
    getStart: '시작하세요',
    getStartedForFree: '무료로 시작하십시오',
    howItWork: {
      desc: 'NOAM은 AI 모델을 통합하여 사용자 간 웹 페이지 번역 및 요약, PDF 및 이미지 번역, 비디오 번역 및 요약에 대한 지능적인 경험을 사용자에게 제공합니다.',
      title: '작동 방식',
    },
    lastBlock: { desc: '더 부드러운 독서 경험을위한 더 나은 AI 번역' },
    top: {
      desc: 'NOAM은 웹 페이지, 비디오 및 PDF 컨텐츠를 번역하고 요약하는 데 도움이되는 무료 AI 브라우저 확장자입니다. \\ n NOAM에 가입하여 마술을 경험하십시오!',
      title1: '하나의 마법',
      title2: '한 번의 클릭',
    },
    userReviews: { desc: 'Chrome 웹 스토어에서 5 성급 리뷰', title: '사용자 리뷰' },
  },
  installTips: {
    btn: '지금 시도하십시오',
    text: '더 많은 기능을 잠금 해제하려면 "Noam Browser 플러그인"을 설치하십시오! 웹 교차 참조, \\ n 웹 비디오 요약 등!',
  },
  locale: {
    af: '아프리카 어',
    am: '암하라',
    ar: '아라비아 말',
    as: '아사 메스',
    auto: '자동 감지',
    az: '아제르바이잔',
    ba: '바쉬 키르',
    bg: '불가리아 사람',
    bn: '방글라',
    bo: '티베트어',
    bs: '보스니아 인',
    ca: '카탈로니아 사람',
    cs: '체코 사람',
    cy: '웨일스 말',
    da: '덴마크 말',
    de: '독일 사람',
    dsb: '더 낮은 소르 비안',
    dv: '디브 히',
    el: '그리스 사람',
    en: '영어',
    es: '스페인 사람',
    et: '에스토니아 사람',
    eu: '바스크 사람',
    fa: '페르시아 인',
    fi: '핀란드',
    fil: '필리핀',
    fj: '피지안',
    fr: '프랑스 국민',
    frCA: '프랑스어 (캐나다)',
    ga: '아일랜드',
    gl: '갈리시아어',
    gom: 'Konkani',
    gu: '구자라트',
    ha: '하우사',
    he: '헤브라이 사람',
    hi: '힌디 어',
    hr: '크로아티아',
    hsb: '상부 소르 비안',
    ht: '아이티 크리올',
    hu: '헝가리 인',
    hy: '아르메니아 사람',
    id: '인도네시아 인',
    ig: 'Igbo',
    ikt: '이누 인나 Qtun',
    is: '아이슬란드',
    it: '이탈리아 사람',
    iu: 'inuktitut',
    iuLatn: 'inuktitut (라틴어)',
    ja: '일본어',
    ka: '그루지야 사람',
    kk: '카자흐',
    km: '크메르',
    kmr: '쿠르드 (Kurdish) (북부)',
    kn: '칸나다어',
    ko: '한국인',
    ku: '쿠르드어 (중앙)',
    ky: '키르기즈',
    ln: '링 갈라',
    lo: '라오',
    lt: '리투아니아 사람',
    lug: '간다',
    lv: '라트비아 사람',
    lzh: '중국어 (문학)',
    mai: 'Maithili',
    mg: '마다가스카르 사람',
    mi: '마오리족',
    mk: '마케도니아 어',
    ml: '말라 얄 람어',
    mnCyrl: '몽골어 (키릴 릭)',
    mnMong: '몽골 (전통)',
    mr: '마라 티',
    ms: '말레이 사람',
    mt: '몰티즈',
    mww: '몽족',
    my: '미얀마 (버마어)',
    nb: '노르웨이 인',
    ne: '네팔',
    nl: '네덜란드 사람',
    noSearchLang: '지원되지 않는 언어',
    nso: 'Sesotho sa leboa',
    nya: 'Nyanja',
    or: '오디아',
    otq: 'Querétaro Otomi',
    pa: '펀 자브',
    pl: '광택',
    placeholder: '찾다',
    prs: '다리',
    ps: '파슈토',
    pt: '포르투갈어 (브라질)',
    ptPT: '포르투갈어 (포르투갈)',
    ro: '루마니아 사람',
    ru: '러시아인',
    run: '런디',
    rw: 'Kinyarwanda',
    sd: '신디',
    si: '신 할라',
    sk: '슬로바키아 사람',
    sl: '슬로베니아',
    sm: '사모아',
    sn: '쇼나',
    so: '소말리아',
    sq: '알바니아',
    srCyrl: '세르비아어 (키릴 릭)',
    srLatn: '세르비아어 (라틴어)',
    st: '세소 토',
    sv: '스웨덴어',
    sw: '스와 할리',
    ta: '타밀 사람',
    te: '텔루구 어',
    th: '태국',
    ti: '티 그린',
    tk: '투르크멘 말',
    tlhLatn: '클링 온 (라틴어)',
    tlhPiqd: '클링 온 (Piqad)',
    tn: 'Setswana',
    to: '통가',
    tr: '터키',
    tt: '타타르',
    ty: '타히티안',
    ug: 'uyghur',
    uk: '우크라이나 말',
    ur: '우르두어',
    uz: '우즈벡 (라틴어)',
    vi: '베트남 사람',
    xh: 'Xhosa',
    yo: '요 루바',
    yua: 'Yucatec Maya',
    yue: '광동어 (전통)',
    zh: '중국인',
    zhHans: '중국어가 단순화되었습니다',
    zhHant: '중국 전통',
    zu: '줄루 족',
  },
  login: {
    and: '그리고',
    btn: 'Google에 로그인하십시오',
    btn1: '지금 로그인하십시오',
    desc: '더 많은 경험을 잠금 해제하려면 로그인하십시오',
    policy: '개인 정보 보호 정책',
    terms: '이용 약관',
    tips: '가입함으로써 나는 동의합니다',
    title: 'Noam에 오신 것을 환영합니다',
  },
  pageFooter: {
    follow: { facebook: '페이스 북', title: '따르다', twitter: '지저귀다' },
    legal: { policy: '개인 정보 보호 정책', terms: '서비스 약관', title: '합법적인' },
    product: {
      image: '이미지 번역',
      pdf: 'PDF 번역',
      title: '제품',
      video: '비디오 번역 및 요약',
      web: '웹 번역 및 요약',
    },
    resources: { title: '자원' },
  },
  pageHeader: {
    contact: '연락하다',
    help: '돕다',
    logout: '로그 아웃하십시오',
    logoutSucc: '로그 아웃.',
    pricing: '가격',
    settings: '설정',
    signUp: '로그인하십시오',
  },
  pdf: {
    autoDetect: '자동 감지',
    cancelBtn: '취소',
    cancelUpload: '취소',
    chatPdf: {
      summaryError: '요약 실패, 나중에 다시 시도하십시오',
      chatError: '채팅이 실패했습니다. 나중에 다시 시도하십시오',
      Search: '찾다',
      cancel: '취소',
      chatTitle: 'NOAM에 오신 것을 환영합니다! PDF의 요약은 다음과 같습니다.',
      clear: '분명한',
      clearContent:
        '이것은 새로운 대화를 시작할 것입니다. 현재 대화는 저장되지 않습니다. 확실합니까?',
      clearDialogTitle: '확인하십시오',
      clearSucc: '지우기',
      copy: '복사',
      copySucc: '복사',
      delete: '삭제',
      deleteCancel: '취소',
      deleteContent: '이 PDF는 일단 삭제되면 복구 할 수 없습니다. 확실합니까?',
      deleteOk: '삭제',
      deleteSuccess: '삭제',
      deleteTitle: '삭제를 확인하십시오',
      expand: '무너지다',
      historyTitle: '역사',
      listError: '읽기 목록을로드하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
      loading: '로딩 ...',
      noDataDesc: '아직 역사가 없습니다.',
      noMore: '더 이상 내용이 없습니다.',
      placeholder: '이 PDF에 대해 물어보십시오',
      summaryTitle: '이 질문에 관심이있을 수 있습니다.',
    },
    collectionEmpty: '컬렉션이 비어 있습니다.',
    collectionTab: '메모',
    dataLoading: '로딩 ...',
    dialogContent: '이것을 삭제 하시겠습니까?',
    dialogTitle: '확인하십시오',
    download: {
      both: '이중 언어',
      btn: '구하다',
      cancel: '이 창을 닫으면 PDF 다운로드가 취소됩니다. 취소 하시겠습니까?',
      desc: '장치에 저장 : 번역을 이미지로 다운로드합니다. 저장 및 인쇄 : 사본 지원과 함께 원래 형식의 번역을 다운로드합니다.',
      download: '다운로드',
      downloadMsg1: 'PDF 번역 진행 :',
      downloadMsg2: '나머지 시간 :',
      downloadSucc: 'PDF가 준비되었습니다! 저장하려면 클릭하십시오.',
      downloadTip: 'PDF 다운로드. 다운로드 오류를 피하기 위해이 창을 닫지 마십시오.',
      error: '저장하지 못했습니다. 대신 인쇄를 시도하십시오.',
      fileName: '파일 이름 :',
      fileType: '파일 유형 :',
      loading: '절약...',
      loading2: '로딩 ...',
      minute: '분...',
      msg1: '이 문서의 일부 번역은 원래 텍스트보다 길고 다운로드 된 파일에서 겹칠 수 있습니다. 웹 페이지에서 읽는 것이 좋습니다.',
      msg2: '1. 번역은 아직 완료되지 않았습니다. 번역이 완료된 후 다운로드하십시오.',
      msg3: '2. 일부 번역은 너무 길다. 텍스트 중첩을 피하려면 웹 페이지를 읽으십시오.',
      msg4: '번역 작업이 진행 중입니다. 완료된 후 다시 시도하십시오.',
      progress: 'PDF 번역. Progress : {{progress}}, 남은 시간 추정 시간 : {{minter}} mings ...',
      save: '장치에 저장하십시오',
      savePrint: '저장 및 인쇄',
      success: '다운로드',
      trans: '번역 전용',
      transContinue: '계속 번역',
    },
    downloadBtn: '다운로드',
    dragTipModal: {
      black: '공간',
      leftKey: '왼쪽 버튼',
      ok: '알았어요',
      press: '누르다',
      tips: '드래그 앤 뷰.',
    },
    entry: 'PDF',
    fileErrMsg: '파일 읽기가 실패했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    fileMsg: '현재 파일 크기 :',
    fileMsg2: '300MB 미만의 파일을 업로드하십시오.',
    freeBannerBtn: '치받이',
    freeBannerTips: '무료 PDF 번역 제한에 도달했습니다',
    guide: {
      placeholder: 'PDF 링크를 여기에 붙여 넣으십시오',
      start: '번역을 시작하십시오',
      text1: '이중 언어 읽기',
      text2: '이중 언어로 읽어 원본 텍스트와 번역을 나란히 비교하여 효율성을 향상시킵니다.',
      text3: 'PDF와 채팅하십시오',
      text4: 'AI 기반 분석 및 요약. PDF에 대해 질문하고 즉각적인 답변을 얻으십시오.',
      text5: '정확하고 효율적입니다',
      text6: '더 큰 정확도와 효율성을위한 AI 기반 PDF 변환.',
      title1: '이중 언어 번역',
      title2: 'PDF와 채팅하십시오',
      toast: '.pdf로 끝나는 유효한 온라인 PDF 링크를 입력하십시오.',
    },
    hasTransTask: '번역 작업이 진행 중입니다. 기다리세요.',
    hoverTrans: '호버로 변환 된 단락',
    initial: 'PDF 번역',
    installText: '일상적인 번역을위한 원 스톱 솔루션.',
    installText2: '먼저 확장자를 설치하십시오',
    installTips: '더 많은 PDF 번역 및 더 부드러운 웹 사이트 번역을 위해 확장자를 설치하십시오.',
    kw: '스테이플',
    link: '링크',
    listError: 'PDF를로드하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    memoTotalCount: '1 항목 총',
    new: '새 파일',
    noId: '파일 ID를 찾을 수 없습니다.',
    notPdf: 'PDF 파일 만.',
    okBtn: '삭제',
    okBtn2: '알았어요',
    original: '원래의',
    parallel: '평행한',
    parseErrDialogContent:
      '이 PDF 링크는 직접 번역 할 수 없습니다. PDF를 다운로드하여 번역을 위해 업로드하십시오.',
    parseErrTips: '파일을 구문 분석하지 못했습니다',
    parseFileToast: '파일을 구문 분석하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    pdfLoading: '구문 분석 ...',
    searchPlaceholder: '찾다',
    selectTrans: '번역 할 텍스트를 선택하십시오',
    shareBtn: '공유하다',
    shareBtnText1: '공유 링크를 생성합니다',
    shareBtnText2: '공유 콘텐츠를 업데이트합니다',
    shareModal: {
      cancel: '취소',
      copy: '복사',
      copySucc: '링크 복사',
      custom: '사용자 정의 :',
      errMsg: '선택한 컨텐츠는 아직 완전히 번역되지 않았습니다. 공유하기 전에 번역하십시오.',
      gen: '공유하다',
      modify: '편집하다',
      notSupport: '이 언어는 현재 지원되지 않습니다.',
      page: '페이지',
      pageNuErr: '유효한 페이지 번호를 입력하십시오.',
      range: '범위:',
      remain: '남아 있습니다',
      remain1: '총 예상 시간 :',
      search: '찾다',
      settings: '공유 설정 :',
      shareSetting: '설정을 공유합니다',
      shareText: '공유하다',
      shareTips: '번역 된 PDF 링크를 친구들과 공유하십시오.',
      sourceLang: '에서:',
      target: '에게:',
      text1: '설정을 공유합니다',
      text2: '공유 링크를 생성합니다',
      totalPage: '1 페이지 총',
      trans: '번역하다',
      translateTips: '팁 : 번역에 시간이 걸리면이 창을 닫고 나중에 공유 할 수 있습니다.',
      translating: 'PDF 번역 ...',
      update: '공유 링크 업데이트',
    },
    shareMsg1: '생성 된 링크를 공유합니다.',
    shareMsg2: '공유 콘텐츠가 업데이트되었습니다.',
    shareMsg3: '공유 링크를 생성하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    shareMsg4: '공유 콘텐츠를 업데이트하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    shareTips: 'PDF 번역이 준비되었습니다! 친구들과 공유하십시오.',
    start: '무료 평가판을 시작하십시오',
    thumbnail: '목차',
    toast1: 'PDF 로딩. 나중에 다시 시도하십시오.',
    toast2: 'PDF 콘텐츠가 너무 큽니다. 대화 상자는 현재 지원되지 않습니다.',
    toast3: '다운로드. 다운로드가 완료된 후 다시 시도하십시오.',
    toolbar: { Adjust: '조정하다', autoFit: '자동 적합' },
    trans: '번역',
    transSucc: '번역 성공!',
    unLoginToast: '이 기능을 사용하려면 로그인하십시오.',
    uploadBtn: '업로드',
    uploadErr: '파일 업로드가 실패했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    uploadErrSignUrl: 'URL을 업로드하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    uploadMsg1: '여기서 파일을 드래그 앤 드롭합니다.',
    uploadMsg2: '지원되는 파일 유형 : PDF |  최대 파일 크기 : 100MB',
    uploadMsg3: '여기에서 파일을 선택하거나 드래그 앤 드롭하려면 클릭하십시오.',
    uploading: '업로드',
    uploadingMsg: '업로드. 기다리세요.',
    uploadingMsg1: '파일이 업로드 중입니다. 나중에 다시 시도하십시오.',
    uploadingMsg2: '현재 파일이 변환되고 있습니다. 나중에 다시 시도하십시오.',
    uploadingMsg3: '전환 진행 요청이 실패했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    uploadingMsg4:
      'NOAM은 로컬 파일에 액세스 할 수 없습니다. 번역을 위해 PDF를 수동으로 업로드하십시오.',
    uploadingMsg5: '파일 URL을 검색하지 못했습니다. 나중에 다시 시도하거나 지원에 연락하십시오.',
    video: '동영상',
    vipBannerBtn: '치받이',
    vipBannerTips: '이번 달 PDF 번역 한도에 도달했습니다.',
    webTrans: '완전한 교차 참조',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: '예, Noam은 즉시 사용할 수있는 무료 계획을 제공합니다.',
      a2: '우리는 Pro, Pro+및 Enterprise의 세 가지 회원 계획을 제공합니다. 매월 또는 매년 지불하도록 선택할 수 있습니다. 연간 청구는 상당한 비용 절감을 제공합니다.',
      a3: '모든 기능은 두 계획 모두에서 사용할 수 있습니다. 주요 차이점은 비용입니다. 연간 구독료는 전반적으로 저렴합니다. 월간 구독은 매달 청구되며 연간 계획은 연간 한 번 청구됩니다.',
      a4: '계정 설정에서 언제든지 구독을 취소 할 수 있습니다.',
      q1: '무료 계획이 있습니까?',
      q2: '어떤 가격 책정 계획 및 지불 옵션을 사용할 수 있습니까?',
      q3: '매월 구독과 매년 구독의 차이점은 무엇입니까?',
      q4: '구독을 어떻게 취소합니까?',
    },
    around: '약',
    billedMonthly: '매월 청구',
    billedYearly: '매년 청구',
    day: '낮',
    desc: '계획을 비교하고 최고를 선택하십시오',
    detailedCompare: '계획을 비교하십시오',
    discount: '20% 할인',
    enterprise: {
      benefit1: '우선 순위 고객 지원',
      benefit2: '여러 사용자',
      benefit3: '맞춤형 솔루션',
      benefit5: '전용 계정 관리자',
      btn: '저희에게 연락하십시오',
      recommendText: '맞춤형 계획 초',
      talkToUs: '연락하십시오',
    },
    free: {
      benefit1: '인스턴트 번역 100 회/일',
      benefit2: '웹 사이트 번역 및 요약',
      benefit3: '30 이미지 번역',
      benefit4: '비디오 번역 및 요약',
      benefit5: '10 PDF 번역',
      benefit6: '5,000 개의 토큰',
      btn: '무료로 시도하십시오',
      recommendText: '무료 평가판',
    },
    imageTranslation: '이미지 번역',
    month: '월',
    monthly: '월간 간행물',
    pageTitle: '가격',
    pdf: { chat: 'PDF 채팅 쿼리', maxPage: '맥스. PDF 당 페이지', translation: 'PDF 번역' },
    preMonth: '한 달에',
    pro: {
      benefit1: '우선 순위 고객 지원',
      benefit2: '1,000,000 토큰/월',
      benefit3: '웹 사이트 번역 및 요약',
      benefit4: '200 개의 이미지 번역',
      benefit5: '비디오 번역 및 요약',
      benefit6: '200 PDF 번역',
      btn: '시작하세요',
      recommendText: '가장 인기가 있습니다',
    },
    proPlus: {
      benefit1: '우선 순위 고객 지원',
      benefit2: '3,000,000 토큰/월',
      benefit3: '웹 사이트 번역 및 요약',
      benefit4: '500 개의 이미지 번역',
      benefit5: '비디오 번역 및 요약',
      benefit6: '500 PDF 번역',
      recommendText: '최고의 가치',
    },
    video: { summary: '비디오 요약', translation: '비디오 번역' },
    web: {
      contrastiveTranslation: '이중 언어 번역',
      dictionaryTranslation: '선택 번역',
      hoverTranslation: '호버 번역',
      instantTranslation: '즉각적인 번역',
      summary: '웹 페이지 요약',
    },
    yearly: '매년',
  },
  privacy: {
    block1:
      'Noam ( "Noam", "우리", "우리"또는 "우리")는 귀하의 프라이버시를 존중하며 귀하로부터 얻은 정보를 보호하기 위해 최선을 다하고 있습니다. 이 개인 정보 보호 정책은 귀하의 웹 사이트 사용, 응용 프로그램 및 서비스 (집단적으로 "서비스")의 개인 정보 수집 또는 사용에 관한 당사의 관행에 대해 설명합니다. 이 개인 정보 보호 정책은 API와 같은 비즈니스 제품 고객을 대신하여 처리하는 컨텐츠에는 적용되지 않습니다. 이 데이터의 사용에는 이러한 제품에 대한 액세스 및 사용에 대한 고객 계약이 적용됩니다.',
    block10:
      '우리는 온라인 및 오프라인 개인 정보를 손실, 오용 및 무단 액세스, 공개, 변경 또는 적대적인 행동으로부터 보호하기 위해 상업적으로 합리적인 기술, 행정 및 조직 측정을 구현합니다. 그러나 인터넷이나 이메일 전송은 완전한 보안 또는 정확성을 보장 할 수 없습니다. 특히, 당사 또는 당사로부터 전송 된 이메일은 안전하지 않을 수 있습니다. 따라서 서비스 나 이메일을 통해 우리에게 보낼 정보를 결정할 때 특히주의해야합니다. 또한, 당사는 서비스 또는 타사 웹 사이트의 보안 설정 또는 보안 조치의 개인 정보 설정 또는 보안 조치의 방해에 대해 책임을지지 않습니다. 당사는 귀하에게 서비스를 제공하거나 분쟁, 보안 및 안전 이유를 해결하거나 법적 의무를 준수하는 것과 같은 다른 합법적 인 사업 목적으로 귀하에게 필요한만큼 귀하의 개인 정보를 보유합니다. 우리가 개인 정보를 유지하는 시간은 정보의 양, 자연, 정보의 민감도, 무단 사용 또는 공개의 잠재적 위험, 정보를 처리하는 목적 및 기타 법적 구속력있는 고려 사항과 같은 많은 요인에 달려 있습니다.',
    block11:
      '이 개인 정보 보호 정책을 수시로 업데이트 할 수 있습니다. 그렇게하면 해당 법률에 달리 요구되지 않는 한이 페이지에 업데이트 된 버전을 게시합니다.',
    block12:
      '이 개인 정보 보호 정책에 대한 답이없는 질문이나 우려 사항이 있으면 고객 서비스에 문의하십시오. 이메일:',
    block2: '귀하와 관련된 다음 개인 정보 ( "개인 정보")를 수집합니다.',
    block3:
      '귀하가 제공 한 정보 : 귀하가 당사의 서비스를 사용하거나 당사와 의사 소통하는 계정을 만들 때 다음과 같이 개인 정보를 수집합니다.',
    block4:
      '서비스 사용으로부터 자동으로받은 개인 정보 : 서비스에 액세스, 사용 또는 상호 작용할 때 액세스, 사용 또는 상호 작용 ( "기술 정보")에 대한 다음 정보를받습니다.',
    block5: '우리는 다음 목적으로 개인 정보를 사용할 수 있습니다.',
    block6:
      '요약 또는 비 식별 정보 : 우리는 식별 목적으로 재사용을 방지하기 위해 개인 정보를 집계하거나 비 식별 할 수 있으며 그러한 정보를 사용하여 서비스의 효과를 분석하고 서비스를 개선하고 서비스를 추가하고 연구 수행 및 유사한 목적으로 추가합니다. 또한 서비스 사용자의 일반적인 행동과 특성을 분석하고 제 3 자와 집계 된 정보를 공유하거나, 그러한 집계 정보를 게시하거나 일반적으로 이용할 수 있습니다. 당사는 서비스, 쿠키 및 본 개인 정보 보호 정책에 설명 된 다른 수단을 통해 집계 된 정보를 수집 할 수 있습니다. 우리는 익명 또는 식별되지 않은 형식으로 비 식별 된 정보를 유지하고 사용할 것이며, 법에 의해 요구되지 않는 한 그러한 정보를 다시 식별하려고 시도하지 않을 것입니다.',
    block7:
      '특정한 경우, 당사는 법에 의해 요구되지 않는 한 귀하에게 추가 통지없이 제 3 자에게 귀하의 개인 정보를 제공 할 수 있습니다.',
    block8:
      '귀하의 지리적 위치에 따라 유럽 경제 지역, 영국 및 전 세계의 개인은 개인 정보에 관한 특정 법적 권리를 가질 수 있습니다. 예를 들어, 귀하는 다음의 권리가있을 수 있습니다.',
    block9:
      '이 서비스에는 소셜 미디어 서비스 ( "타사 웹 사이트")를 포함하여 NOAM이 운영하거나 제어하지 않는 다른 웹 사이트에 대한 링크가 포함될 수 있습니다. 타사 웹 사이트와 공유하는 정보는이 개인 정보 보호 정책이 아니라 제 3 자 웹 사이트의 특정 개인 정보 보호 정책 및 서비스 약관에 의해 적용됩니다. 우리는 편의를 위해 이러한 링크를 제공하며 이러한 웹 사이트에 대한 우리의 보증 또는 검토를 의미하지 않습니다. 개인 정보 보호 정책 및 약관에 대한 정보는 타사 웹 사이트에 직접 문의하십시오.',
    subBlock1:
      '계정 정보 : 당사 플랫폼에서 계정을 작성할 때 귀하의 계정, 연락처 정보, 계정 자격 증명, 지불 카드 정보 및 거래 기록 ( "계정 정보")을 포함하여 귀하의 계정과 관련된 정보를 수집합니다.',
    subBlock10: '서비스 제공, 관리, 유지 및/또는 분석;',
    subBlock11: '당신과 의사 소통;',
    subBlock12: '신제품 및 서비스 개발;',
    subBlock13:
      '사기, 범죄 행위 또는 서비스 오용 방지, IT 시스템, 아키텍처 및 네트워크의 보안 보호;',
    subBlock14: '비즈니스 전송 수행;',
    subBlock15:
      '법적 의무 및 법적 절차를 준수하여 당사의 권리, 개인 정보 보호, 보안 또는 재산을 보호하고 계열사, 귀하 또는 기타 제 3 자의 권리를 보호합니다.',
    subBlock16:
      '공급 업체 및 서비스 제공 업체 : 비즈니스 운영 요구를 충족시키고 특정 서비스 및 기능을 수행하는 데 도움을주기 위해 호스팅 서비스 제공 업체, 클라우드 서비스 제공 업체 및 기타 정보 기술 서비스 제공 업체, 이메일 커뮤니케이션 소프트웨어 및 웹 분석 서비스 제공 업체 등을 포함하여 공급 업체 및 서비스 제공 업체에 개인 정보를 제공 할 수 있습니다. 이러한 파트너는 당사의 의무를 수행하는 동안 전용으로 만 개인 정보를 제공하거나 저장합니다.',
    subBlock17:
      '비즈니스 전송 : 전략적 거래, 재구성, 파산, 파산 인수 또는 다른 공급자로의 서비스 전환 (집단적으로 "거래")에 참여하는 경우, 귀하의 개인 정보 및 기타 정보 및 기타 정보 및 기타 자산과 함께 거래를 지원하고 다른 자산과 함께 거래를 지원하는 거래 상대 및 기타 당사자와 공유 될 수 있습니다.',
    subBlock18:
      '법적 요구 사항 : 당사는 귀하의 개인 정보를 정부 당국, 업계 동료 또는 서비스와의 상호 작용 정보, (1) 법률에 의해 요구되는 경우 또는 법적 의무를 준수하기 위해 그러한 조치가 필요하다고 생각하는 경우 개인 정보를 공유 할 수 있습니다. (2) 우리의 권리 나 재산을 보호하고 방어하기 위해; (3) 일방적으로 당사의 약관, 정책 또는 법률을 위반하는 것으로 판단하는 경우; (4) 사기 또는 기타 불법 활동을 감지하거나 방지하기 위해; (5) 우리의 제품, 직원 또는 사용자, 공공 안전, 보안, 무결성을 보호하기 위해; 또는 (vi) 법적 책임을 방어합니다.',
    subBlock19: '개인 정보 및 정보 처리 방법에 대한 정보에 액세스하십시오.',
    subBlock2:
      '사용자 컨텐츠 : 서비스를 사용하는 경우 당사는 귀하의 입력, 파일 업로드 또는 당사에 제공된 피드백 ( "Content")에서 개인 정보를 수집합니다 ( "Content").',
    subBlock20: '당사의 기록에서 귀하의 개인 정보를 삭제하십시오.',
    subBlock21: '개인 정보를 수정하거나 업데이트하십시오.',
    subBlock22: '귀하의 개인 정보를 제 3 자 (데이터 휴대 성)로 전송하십시오.',
    subBlock23: '귀하의 개인 정보를 처리하는 방법을 제한하십시오.',
    subBlock24:
      '귀하의 동의를 철회하십시오. 우리가 처리의 법적 근거로 동의에 의존하는 경우 언제든지 동의를 철회 할 수 있습니다.',
    subBlock25: '귀하의 개인 정보를 처리하는 방법에 반대하십시오.',
    subBlock26: '서비스 개선 및 연구 수행;',
    subBlock3:
      '커뮤니케이션 정보 : 메시지를 보내는 경우 귀하의 이름, 연락처 정보 및 메시지 내용 ( "커뮤니케이션 정보")을 수집합니다.',
    subBlock4:
      '소셜 미디어 정보 : Instagram, Facebook, Medium, Twitter, YouTube 및 LinkedIn과 같은 소셜 미디어 웹 사이트에 페이지가 있습니다. 소셜 미디어 페이지와 상호 작용할 때 연락처 정보 ( "소셜 정보")와 같이 당사에 제공하기로 선택한 개인 정보를 수집합니다. 또한 소셜 미디어 페이지를 주최하는 회사는 소셜 미디어 활동에 대한 집계 된 정보 및 분석을 제공 할 수 있습니다.',
    subBlock5:
      '데이터 로그 : 서비스를 사용할 때 브라우저에서 자동으로 보낸 정보. 로그 데이터에는 인터넷 프로토콜 (IP) 주소, 브라우저 유형 및 설정, 요청 날짜 및 시간 및 웹 사이트와 상호 작용하는 방법이 포함됩니다.',
    subBlock6:
      '사용 데이터 : 우리는 귀하가 보거나 상호 작용하는 컨텐츠 유형, 사용하는 기능, 수행 작업 및 시간대, 국가, 액세스 날짜 및 시간 및 시간, 컴퓨터 또는 모바일 장치 유형 및 컴퓨터 연결과 같은 서비스 사용에 대한 정보를 자동으로 수집 할 수 있습니다.',
    subBlock7:
      '장치 정보 : 여기에는 장치 이름, 운영 체제, 장치 식별자 및 사용중인 브라우저가 포함됩니다. 수집 된 정보는 사용중인 장치 유형 및 설정에 따라 다를 수 있습니다.',
    subBlock8:
      '쿠키 : 우리는 쿠키를 사용하여 서비스를 운영하고 관리하며 경험을 향상시킵니다. "쿠키"는 브라우저를 방문한 웹 사이트에서 보낸 정보입니다. 쿠키가 제공 될 때 모든 쿠키를 수락하거나 모든 쿠키를 거부하거나 쿠키가 제공 될 때마다 접수할지 여부를 결정할 수 있도록 브라우저를 설정할 수 있습니다. 그러나 쿠키를 거부하면 웹 사이트의 특정 영역 또는 기능의 디스플레이 또는 기능을 사용하거나 부정적인 영향을 미치지 않을 수 있습니다. 쿠키에 대한 자세한 내용은 쿠키에 대한 모든 정보를 방문하십시오.',
    subBlock9:
      '분석 : 쿠키를 사용하는 다양한 온라인 분석 제품을 사용하여 사용자가 서비스를 사용하는 방법을 분석하고 서비스를 사용할 때 경험을 향상시킬 수 있습니다.',
    subTitle1: '우리가 수집 한 개인 정보',
    subTitle2: '우리가 개인 정보를 사용하는 방법',
    subTitle3: '개인 정보 공개',
    subTitle4: '당신의 권리',
    subTitle5: '다른 웹 사이트에 대한 링크',
    subTitle6: '정보의 보안 및 유지',
    subTitle7: '개인 정보 보호 정책 수정',
    subTitle8: '저희에게 연락하는 방법',
    title: '개인 정보 보호 정책',
    updateTime: '업데이트 : 2024 년 3 월 15 일',
  },
  privacyCollectionPanel: {
    acceptBtn: '모든 것을 받아들이십시오',
    text: '경험을 향상시키기 위해 쿠키를 사용하여 콘텐츠와 서비스를 개인화합니다.',
    title: '이 웹 사이트는 쿠키를 사용합니다',
  },
  slogen: '가장 쉬운 AI 조수',
  stripe: {
    contact: '질문이 있으시면 contact@noam.tools로 문의하십시오!',
    fail: {
      btn: '다시 해 보다',
      text1: '우리는 당신의 지불을 처리 할 수 ​​없었습니다.',
      text2: '연결을 확인하고 다시 시도하십시오.',
      title: '결제가 실패했습니다',
    },
    succ: {
      btn: '갑시다',
      text1: 'Noam에 오신 것을 환영합니다.',
      text2: '시작합시다!',
      title: '축하해요',
    },
  },
  terms: {
    block1: 'NOAM을 사용해 주셔서 감사합니다!',
    block10:
      '(a) 수수료 및 청구. 귀하는 해당 가격 책정 페이지의 가격 및 약관에 따라 모든 수수료 ( "수수료")를 지불하거나 당사 사이에 서면으로 합의합니다. 우리는 송장을 발행하거나 지불을받은 경우에도 가격 오류 또는 실수를 수정할 권리가 있습니다. 유효하고 승인 된 지불 방법을 포함하여 완전하고 정확한 청구 정보를 제공합니다. 당사는 합의 된대로 정기적으로 지불 방법을 청구하지만 청구 날짜를 합리적으로 변경할 수 있습니다. 귀하는 NOAM, 계열사 및 타사 결제 프로세서를 지불하여 지불 방법을 청구합니다. 지불이 실패하면 서면 통지를 제공하고 지불을받을 때까지 서비스에 대한 액세스를 중단 할 수 있습니다. 본 계약에 달리 명시된 경우를 제외하고 지불은 환불되지 않습니다.',
    block11:
      '(b) 세금. 달리 명시되지 않는 한, 수수료에는 연방, 주, 지방 및 외국 세금, 관세 및 유사한 평가 ( "세금")가 포함되지 않습니다. 귀하는 귀하의 구매와 관련된 모든 세금에 대해 책임을 져야하며, 우리가 귀하를 송장 할 순이익을 기준으로 세금을 제외하고. 귀하는 그러한 세금을 즉시 지불하고 법적으로 유효한 개인 정보 보호 진술을 제공하고 그러한 데이터를 처리하는 데 필요한 동의를 얻는 데 동의하며, 귀하는 해당 법률에 따라 해당 데이터를 처리한다는 것을 보증합니다.',
    block12:
      '(c) 가격 변동. 귀하의 계정 및/또는 웹 사이트를 통해 귀하에게 알림으로써 가격을 변경할 수 있습니다. 가격 인상은 게시되면 즉시 효과적입니다. 가격 변경은 변경 발효 일 이후 계좌 수수료에 적용됩니다.',
    block13:
      '(d) 분쟁 및 기한 지급. 수수료 나 세금에 이의를 제기하려면 송장 날짜로부터 30 일 이내에 contact@noam.tools에 문의하십시오.',
    block14:
      '(e) 무료 사용. 무료 크레딧을 얻기 위해 여러 계정을 만들 수 없습니다. 귀하가 귀하가 선의로 무료 크레딧을 사용하지 않는다고 판단하면 표준 수수료를 청구하거나 서비스에 대한 액세스 제공을 중단 할 수 있습니다.',
    block15:
      '(a) 기밀성. NOAM의 기밀 정보에 액세스 할 수 있습니다. 서비스 사용을 목적으로 본 이용 약관에서 허용되는 기밀 정보 만 사용할 수 있습니다. 귀하는 기밀 정보를 제 3 자에게 공개 할 수 없으며, 적어도 합리적인 치료를 통해 비슷한 기밀 정보를 보호하는 것보다 기밀 정보를 보호하지 않습니다. 기밀 정보는 NOAM에 의해 기밀로 지정된 비 공개 정보이거나 소프트웨어, 사양 및 기타 비공개 비즈니스 정보를 포함한 상황에서 기밀로 합리적으로 취급되어야합니다. 기밀 정보에는 다음과 같은 정보가 포함되어 있지 않습니다. (1) 일반적으로 귀하의 결함없이 대중에게 제공됩니다. (2) 귀하는 본 약관에 따라 기밀을 받기 전에 기밀 유지 의무없이 소유권을 가지고있었습니다. (3) 기밀 의무없이 제 3자가 귀하에게 합법적으로 공개됩니다. 또는 (4) 기밀 정보를 사용하지 않고 독립적으로 개발되었습니다. 귀하는 법률, 법원 또는 기타 정부 명령에 따라 기밀 정보를 공개 할 수 있지만, 귀하는 합리적인 방식으로 NOAM에 사전 서면 통지를 제공해야하며, 가능한 한 공개 요청을 포함하여 공개 범위를 제한하기 위해 합리적인 노력을 기울여야합니다.',
    block16:
      '(b) 보안. 서비스에 대한 액세스 및 사용을 보호하기 위해 합리적이고 적절한 조치를 구현해야합니다. 서비스 사용과 관련된 취약점 또는 위반을 발견하면 NOAM에 즉시 연락하여 취약성 또는 위반에 대한 세부 정보를 제공해야합니다.',
    block17:
      '(C) 개인 데이터 처리. 서비스를 사용하여 개인 데이터를 처리하는 경우 법적으로 충분한 개인 정보 보호 진술을 제공하고 그러한 데이터를 처리하는 데 필요한 동의를 얻어야하며, 해당 법률에 따라 해당 데이터를 처리해야합니다.',
    block18:
      '(a) 종료; 보류. 이 약관은 서비스를 처음 사용하여 효과적이며 종료 될 때까지 유효합니다. 귀하는 서비스 및 컨텐츠의 사용을 중단하여 언제든지 본 이용 약관을 종료 할 수 있습니다. 우리는 어떤 이유로 든 사전 통지 로이 용어를 종료 할 수 있습니다. 우리는 섹션 2 (사용 요구 사항), 섹션 5 (기밀성, 보안 및 데이터 보호), 섹션 8 (분쟁 해결) 또는 섹션 9 (일반 약관) 또는 제 3 자 기술 제공자와의 관계 또는 법률 또는 정부 요청에 대한 준수 여부를 실질적으로 위반하는 경우 즉시이 약관을 종료 할 수 있습니다. 귀하가 본 약관을 준수하지 않거나 귀하의 사용이 당사 또는 제 3 자에게 보안 위험이있는 경우, 또는 귀하의 사용이 사기성이라고 의심되거나 당사 또는 제 3자를 책임에 노출시킬 수있는 경우 서비스에 대한 귀하의 액세스를 중단 할 수 있습니다.',
    block19:
      '(b) 종료의 효과. 종료시 서비스를 중단하고 당사가 지시 한 기밀 정보를 즉시 반환하거나 파괴합니다. 본 약관의 본질적으로 섹션 3 및 5-9를 포함하되 이에 국한되지 않는 종료 또는 만료에서 살아남을 수있는 조항은 살아남을 것이다.',
    block2:
      '본 이용 약관은 응용 프로그램 프로그래밍 인터페이스, 소프트웨어, 도구, 개발자 서비스, 데이터, 문서 및 웹 사이트를 포함하여 NOAM 서비스 사용에 적용됩니다 ( "서비스"). 당사의 서비스를 사용함으로써 귀하는 본 약관을 준수하는 데 동의합니다. 개인 정보 보호 정책은 개인 정보를 수집하고 사용하는 방법을 설명합니다.',
    block20:
      '(a) 면책. 귀하는 귀하의 콘텐츠, 서비스와 관련된 제품 또는 서비스 사용 및 본 약관 또는 해당 법률의 위반으로 귀하의 서비스 사용으로 인해 발생하는 청구, 손실 및 비용 (변호사 비용 포함)에서 무해한 관계를 맺거나 무해한 당사, 우리의 계열사 및 직원을 방어, 면책 및 보유합니다.',
    block21:
      '(b) 면책 조항. 서비스는 "있는 그대로"제공됩니다. 법에 의해 허용되는 한, 우리와 우리의 계열사 및 라이센스 제공자는 서비스와 관련하여 어떠한 종류에 대한 어떠한 종류에 대한 진술이나 보증도하지 않으며 상업성, 특정 목적에 대한 적합성, 만족스러운 품질, 비 침해 및 조용한 즐거움, 거래 또는 거래의 과정에서 발생하는 모든 보증을 포함하되 이에 국한되지 않는 보증도하지 않습니다. 당사는 서비스가 중단되지 않거나 정확하거나 오류가 없거나 컨텐츠가 안전하거나 손실되지 않거나 변경되지 않을 것이라고 보증하지 않습니다.',
    block22:
      '(c) 책임의 제한. 우리, 우리의 계열사 또는 라이센스 제공자는 우리가 그러한 손해의 가능성에 대해 조언을 받았음에도 불구하고 이익 손실, 사용 상실, 데이터 손실 또는 기타 무형의 손실을 포함하여 간접적, 부수적, 특별, 결과 또는 처벌 적 손해에 대해 책임을지지 않습니다. 본 약관에 따라, 우리의 총 책임은 청구 전 12 개월 또는 100 달러 (100 달러)에 대한 서비스에 지불 한 수수료를 초과하지 않습니다. 이 섹션의 제한은 해당 법률에 의해 허용되는 최대 범위에 적용됩니다.',
    block23:
      '서비스의 이용 약관 및 사용은 미국 법률 규칙을 제외하고는 미국 법률에 의해 적용됩니다. 신청서 사용은 다른 지역, 주, 국가 또는 국제법의 대상이 될 수 있습니다. 본 약관과 관련된 모든 법적 조치 또는 절차는 미국 법원에서 독점적으로 가져 오며 귀하는 해당 법원의 개인 관할권에 제출하는 데 동의합니다.',
    block24:
      '(a) 당사자의 관계. Noam과 ​​귀하는 독립 계약자이며, 어느 당사자도 상대방을 대표하거나 구속하거나 상대방의 사전 서면 동의없이 상대방에 대한 의무를 맡을 권한이 없습니다.',
    block25:
      '(b) 브랜드 사용. 귀하는 사전 서면 동의없이 NOAM 또는 그 계열사의 이름, 로고 또는 상표를 사용할 수 없습니다.',
    block26:
      '본 약관의 조항이 유효하지 않거나 불법적이거나 시행 불가능한 것으로 판명되면 나머지 조항은 완전한 힘과 효과를 유지합니다.',
    block3:
      '18 세 미만인 경우 서비스를 사용하기 위해 부모 나 법률 수호자로부터 허가를 받아야합니다. 다른 사람이나 단체를 대신하여 서비스를 사용하는 경우 귀하는 자신을 대신하여 본 약관을 수락 할 권한이 있어야합니다. 계정에 등록하려면 정확하고 완전한 정보를 제공해야합니다. 귀하는 귀하의 조직 이외의 사람에게 액세스 자격 증명 또는 계정을 제공 할 수 없으며 자격 증명을 사용하여 수행되는 모든 활동에 대한 책임이 있습니다.',
    block4:
      '(a) 서비스 사용. 귀하는 본 약관에 따라 서비스에 액세스 할 수 있으며 서비스를 사용할 수있는 비 독점적 권리를 부여합니다. 서비스를 사용할 때는 본 이용 약관 및 모든 해당 법률을 준수합니다. 우리와 우리의 계열사는 서비스에 대한 모든 권리, 제목 및 관심사를 소유하고 있습니다.',
    block5:
      '(b) 피드백. 우리는 피드백, 의견, 아이디어, 제안 및 개선을 환영합니다. 귀하가 그러한 콘텐츠를 제공하는 경우, 당사는 제한없이 귀하에게 보상없이 사용할 수 있습니다.',
    block6:
      '(c) 제한. 귀하는 다음과 같이 할 수 없습니다. (1) 다른 사람의 권리를 침해, 오용 또는 위반하는 방식으로 서비스를 사용합니다. (2) 서비스 모델, 알고리즘 및 시스템의 소스 코드 또는 기본 구성 요소 (해당 제한이 적용 가능한 법률에 의해 금지되지 않는 한) 리버스 엔지니어, 디 컴파일, 분해, 번역 또는 그렇지 않으면 시도합니다. (3) 서비스의 출력을 사용하여 NOAM과 경쟁하는 모델을 개발하십시오. (4) API를 통해 허용되지 않는 한 스크래핑, 웹 수확 또는 웹 데이터 추출을 포함하여 자동화 또는 프로그래밍 방법을 사용하여 서비스에서 데이터 또는 출력을 추출합니다. (5) 서비스의 출력이 우리의 사용 정책을 위반할 때 인위적으로 생성 된 것으로 나타납니다. (6) 사전 동의없이 API 키를 구매, 판매 또는 전송; 또는 (7) 13 세 미만 또는 해당 디지털 동의 연령 미만의 어린이의 개인 정보를 우리에게 제공합니다. 귀하는 문서의 속도 제한 및 기타 요구 사항을 준수합니다.',
    block7:
      '(d) 타사 서비스. 서비스와 관련된 타사 소프트웨어, 서비스 또는 기타 제품은 자체 용어로 적용되며 우리는 타사 제품에 대해 책임을지지 않습니다.',
    block8:
      '(a) 귀하의 콘텐츠. 서비스에 입력 ( "입력")을 제공하고 입력 ( "콘텐츠")에 따라 생성 및 반환 된 출력 ( "출력")을 수신 할 수 있습니다. 당사자들 사이와 해당 법률에 의해 허용되는 범위 내에서 귀하는 모든 의견을 소유합니다. NOAM은 콘텐츠를 사용하여 서비스를 제공하고 유지하고 해당 법률을 준수하며 정책을 시행 할 수 있습니다. 귀하는 해당 법률이나 본 약관을 위반하지 않도록하는 것을 포함하여 컨텐츠에 대한 책임이 있습니다.',
    block9:
      '(b) 정확도. 인공 지능과 기계 학습은 빠르게 진화하는 연구 분야입니다. 우리는 서비스를보다 정확하고 신뢰할 수 있고 안전하며 유익하게하기 위해 지속적으로 노력합니다. 기계 학습의 확률 론적 특성을 고려할 때, 우리의 서비스를 사용하면 특정한 경우에 잘못된 출력이 발생할 수 있으며, 이는 실제 개인, 장소 또는 사실을 정확하게 반영하지 않을 수 있습니다. 출력을 수동으로 검토하는 것을 포함하여 사용 사례를 기준으로 출력의 정확도를 적절하게 평가해야합니다.',
    subTitle1: '등록 및 액세스',
    subTitle10: '분리 성',
    subTitle2: '사용 요구 사항',
    subTitle3: '콘텐츠',
    subTitle4: '수수료 및 지불',
    subTitle5: '기밀, 보안 및 데이터 보호',
    subTitle6: '용어 및 종료',
    subTitle7: '보증; 면책 조항; 책임의 제한',
    subTitle8: '법과 관할권 관리',
    subTitle9: '일반적인 용어',
    title: '서비스 약관',
    updateTime: '업데이트 : 2024 년 3 월 15 일',
  },
  trialModal: {
    btn: '지금 시작하세요 밸리',
    chat: 'chatpdf',
    desc1: '시험 후에는 연간 $ 180의 요금이 청구됩니다.',
    desc2: '언제든지 취소하십시오',
    focus: '초점',
    hover: '호버 번역',
    image: '이미지 번역',
    installBtn: '선물을 받으려면 지금 설치하십시오',
    memo: '즐겨 찾기 관리',
    pdf: 'PDF 병렬 번역',
    realtime: '즉각적인 번역',
    select: '단어 선택 번역',
    title: '환영 선물',
    trialText: '7 일 재판',
    video: '비디오 번역',
    videoSummary: '비디오 요약',
    web: '병렬 번역',
    webSummary: '웹 요약',
  },
  upgradeModal: {
    btn: '지금 업그레이드하십시오',
    chat: { free: 'ChatPDF 한도에 도달했습니다', pro: '이번 달의 ChatPDF 한도에 도달했습니다' },
    contactStr: '다음 리셋을 기다리거나 contact@noam.tools로 문의하십시오.',
    limitAlertStr: '당신은 당신의 자유 계획 한도에 도달했습니다. 계속하기 위해 업그레이드하십시오.',
    more: '계획을 봅니다',
    pageLimit: { free: 'PDF 페이지 제한에 도달했습니다' },
    title: '알림',
    upgradeAlertStr:
      '당신은 당신의 자유 계획 한도에 도달했습니다. 계속하기 위해 업그레이드하십시오.',
    upload: { free: 'PDF 번역 한도에 도달했습니다', pro: '이번 달 PDF 번역 한도에 도달했습니다' },
  },
  welfare: {
    newUser: {
      joinBtn: '지금 가입하십시오',
      label: '새로운 사용자 혜택 :',
      remain: '30 개만 남았습니다',
      text: '100 개의 지점 만 사용할 수 있습니다! 지금 당신을 잡아!',
    },
  },
}

export default TRANSLATION
