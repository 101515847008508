const TRANSLATION = {
  buyModal: {
    afterFree: 'Po bezpłatnym procesie',
    btn: 'Spróbuj za darmo',
    freeTrial: '3-dniowy bezpłatny proces',
    monthLabel: 'miesiąc',
    selectLabel: 'Wybierz plan',
    title: 'Plan subskrypcji',
    yearLabel: 'rok',
  },
  contact: {
    block1:
      'W celu dostosowania, pytań lub sugestii prosimy o kontakt pod adresem contact@noam.tools. Odpowiemy niezwłocznie.',
    block2: 'Wspieraj nas, oceniając nas w sklepie Google Apps Extension pod adresem ★★★★★niej.',
    block3: 'Pomóż innym, dzieląc się tym rozszerzeniem:',
    title: 'Skontaktuj się z nami',
  },
  help: {
    AquickOverviewOfNoam: 'Szybki przegląd Noam',
    ChatPDF: 'Czat PDF',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Ulubione zarządzanie',
    Fixedplugin: 'Naprawiono wtyczkę',
    Focusing: 'Skupienie',
    Free: 'Bezpłatny',
    FreeTrialBenefits: 'Bezpłatne świadczenia próbne',
    GoWithWikipedia: 'Idź z Wikipedią',
    GoWithYouTube: 'Idź z YouTube',
    HoverTranslation: 'Tłumaczenie zawisowe',
    Howtouse: 'Jak używać',
    ImageTranslation: 'Tłumaczenie obrazu',
    InstantTranslation: 'Natychmiastowe tłumaczenie',
    Membership: 'Członkostwo',
    NoamHelp: 'Noam pomaga tłumaczyć i podsumować',
    PDFparallelTranslation: 'Tłumaczenie równoległe PDF',
    ParallelTranslation: 'Tłumaczenie równoległe',
    SkipAllSteps: 'Pomiń wszystkie kroki',
    Summary: 'Streszczenie',
    Translation: 'Tłumaczenie',
    TryItForFree: 'Wypróbuj za darmo',
    Video: 'Wideo',
    VideoSummary: 'Podsumowanie wideo',
    VideoTranslation: 'Tłumaczenie wideo',
    WebSummary: 'Podsumowanie internetowe',
    WordSelectionTranslation: 'Tłumaczenie wyboru słów',
    benefits: 'Mamy korzyści czekające na Ciebie!',
    dayFree: '3-dniowy bezpłatny proces',
    done: 'Zrobione',
    next: 'Następny',
    pin: 'Pino noam dla łatwiejszego dostępu ～～',
    planIsNotExist: 'Plan nie jest dostępny',
    startWiki: 'Zacznijmy od Wikimedia!',
    startYoutube: 'Zacznijmy od YouTube!',
    try: 'Wypróbuj to',
    tryNow: 'Spróbuj teraz!',
  },
  home: {
    PDFTranslate: {
      desc: 'Tłumaczenie dwujęzyczne + czat pdf',
      sub1: {
        desc: 'Dwujęzyczne odczyt pozwala porównać oryginalny tekst i tłumaczenie obok siebie, poprawiając wydajność czytania.',
        title: 'Dwujęzyczne czytanie',
      },
      sub2: {
        desc: 'AI analizuje i podsumowuje i może odpowiedzieć na twoje pytania na podstawie treści PDF.',
        title: 'Czat PDF',
      },
      sub3: {
        desc: 'Tłumaczenie PDF z napędem AI dla większej dokładności i wydajności.',
        title: 'Dokładność i wydajność',
      },
      title: 'PDF Tłumacz',
    },
    aiAssistant: {
      desc: 'Uczynienie tłumaczenia internetowego mądrzejszego i wygodniejszego',
      panel1: {
        desc: 'Dwujęzyczne tłumaczenia między odniesieniem pomagają porównać oryginalny tekst z tłumaczeniem, abyś mógł lepiej zrozumieć i poprawić wydajność czytania.',
        title: 'Tłumaczenie dwujęzyczne',
      },
      panel2: {
        left: {
          desc: 'Podczas przeglądania strony internetowej unosisz się nad treścią, musisz przetłumaczyć, aby zobaczyć tłumaczenie.',
          title: 'Tłumaczenie zawisowe',
        },
        right: {
          desc: 'Podczas przeglądania strony internetowej wybierz tekst, który chcesz przetłumaczyć, aby zobaczyć tłumaczenie.',
          title: 'Tłumaczenie wyboru tekstu',
        },
      },
      panel3: {
        one: {
          desc: 'Przetłumacz i podsumuj strony internetowe i treści wideo w dowolnym miejscu, w dowolnym miejscu, aby uzyskać płynniejsze wrażenia!',
          title: 'Punkt i kliknij',
        },
        three: {
          desc: 'Model dużego języka napędzanego przez AI zapewnia dokładność i spójność tłumaczenia dla ulepszonego czytania.',
          title: 'Kierowany przez AI',
        },
        two: {
          desc: 'Wielojęzyczny pełny zasięg, dwujęzyczne czytanie bez barier, tłumaczenie jednego kliknięcia-doświadcz różnicy!',
          title: 'Bardziej kompleksowe i wygodne',
        },
      },
      panel4: {
        tab1: {
          desc: 'Twój ekspert ds. Wydajności - za pomocą Chatgpt, Noam tłumaczy i podsumowuje treść wideo YouTube, aby zaoszczędzić czas!',
          key: 'Wideo',
          title: 'Tłumaczenie i podsumowanie wideo',
        },
        tab2: {
          desc: 'Kliknij, aby przetłumaczyć obrazy podczas przeglądania sieci.',
          key: 'Obraz',
          title: 'Tłumaczenie obrazu',
        },
        tab3: {
          desc: 'Natychmiast wyodrębnia kluczowe informacje ze stron internetowych i zapewnia podsumowanie, zwiększając wydajność czytania.',
          key: 'Sieć',
          title: 'Tłumaczenie i podsumowanie internetowe',
        },
        tag1: 'Prosty',
        tag2: 'Wydajny',
        tag3: 'Łatwe do zrozumienia',
      },
      title: 'AII Asystent',
    },
    getStart: 'Zacznij',
    getStartedForFree: 'Zacznij za darmo',
    howItWork: {
      desc: 'NOAM integruje modele AI, aby zapewnić użytkownikom inteligentne wrażenia dla translacji i podsumowania strony internetowej, PDF i tłumaczenie obrazu, tłumaczenie wideo i podsumowanie',
      title: 'Jak to działa',
    },
    lastBlock: { desc: 'Lepsze tłumaczenie AI dla gładszego wrażenia z czytania' },
    top: {
      desc: 'Noam to bezpłatne rozszerzenie przeglądarki AI, które pomaga tłumaczyć i podsumować strony internetowe, filmy i zawartość PDF. \\ N Dołącz do Noam i doświadcz magii!',
      title1: 'Jedna magia',
      title2: 'Jedno kliknięcie',
    },
    userReviews: {
      desc: 'Pięciogwiazdkowe recenzje w Chrome Web Store',
      title: 'Recenzje użytkowników',
    },
  },
  installTips: {
    btn: 'Spróbuj teraz',
    text: 'Zainstaluj „Wtyczkę przeglądarki Noam”, aby odblokować więcej funkcji! Odnośniki na sieci, podsumowanie wideo internetowych i wiele więcej!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'amharski',
    ar: 'arabski',
    as: 'Assamse',
    auto: 'Wykryj automatyczne',
    az: 'azerbejdżański',
    ba: 'Bashkir',
    bg: 'bułgarski',
    bn: 'Bangla',
    bo: 'Tybetańskie',
    bs: 'bośniacki',
    ca: 'Kataloński',
    cs: 'czeski',
    cy: 'walijski',
    da: 'duński',
    de: 'niemiecki',
    dsb: 'Niższy sorbian',
    dv: 'Divehi',
    el: 'grecki',
    en: 'angielski',
    es: 'hiszpański',
    et: 'estoński',
    eu: 'baskijski',
    fa: 'perski',
    fi: 'fiński',
    fil: 'Filipiński',
    fj: 'Fidżian',
    fr: 'francuski',
    frCA: 'Francuski (Kanada)',
    ga: 'irlandzki',
    gl: 'Galian',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebrajski',
    hi: 'hinduski',
    hr: 'chorwacki',
    hsb: 'Górny sorbian',
    ht: 'Haitian Creole',
    hu: 'węgierski',
    hy: 'ormiański',
    id: 'indonezyjski',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandzki',
    it: 'włoski',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (łacina)',
    ja: 'japoński',
    ka: 'gruziński',
    kk: 'Kazach',
    km: 'Khmer',
    kmr: 'Kurdish (północny)',
    kn: 'Kannada',
    ko: 'koreański',
    ku: 'Kurdish (Central)',
    ky: 'Kirgis',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'litewski',
    lug: 'Ganda',
    lv: 'łotewski',
    lzh: 'Chińczycy (literackie)',
    mai: 'Maithili',
    mg: 'malgaski',
    mi: 'Maorys',
    mk: 'Macedoński',
    ml: 'Malayalam',
    mnCyrl: 'Mongolski (cyryliczny)',
    mnMong: 'Mongolski (tradycyjny)',
    mr: 'Marathi',
    ms: 'malajski',
    mt: 'Maltańczycy',
    mww: 'Hmong Daw',
    my: 'Myanmar (birmański)',
    nb: 'norweski',
    ne: 'Nepali',
    nl: 'Holenderski',
    noSearchLang: 'Język nie jest obsługiwany',
    nso: 'Sesotho Sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro otomi',
    pa: 'Pendżab',
    pl: 'Polski',
    placeholder: 'Szukaj',
    prs: 'Dari',
    ps: 'Paszto',
    pt: 'Portugalski (Brazylia)',
    ptPT: 'Portugalski (Portugalia)',
    ro: 'rumuński',
    ru: 'rosyjski',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'słowacki',
    sl: 'słoweński',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somali',
    sq: 'albański',
    srCyrl: 'Serbski (cyryliczny)',
    srLatn: 'Serbski (łacina)',
    st: 'Sesotho',
    sv: 'szwedzki',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'tajski',
    ti: 'Tigrinya',
    tk: 'Turkmeńczycy',
    tlhLatn: 'Klingon (łacina)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongan',
    tr: 'turecki',
    tt: 'Tatar',
    ty: 'tahitański',
    ug: 'Uyghur',
    uk: 'ukraiński',
    ur: 'Urdu',
    uz: 'Uzbek (łacina)',
    vi: 'wietnamski',
    xh: 'Xhosa',
    yo: 'Joruba',
    yua: 'Yucatec Maya',
    yue: 'Kantoński (tradycyjny)',
    zh: 'chiński',
    zhHans: 'Chińskie uproszczone',
    zhHant: 'Chińskie tradycyjne',
    zu: 'Zulus',
  },
  login: {
    and: 'I',
    btn: 'Zaloguj się z Google',
    btn1: 'Zaloguj się teraz',
    desc: 'Zaloguj się, aby odblokować więcej doświadczeń',
    policy: 'Polityka prywatności',
    terms: 'Warunki użytkowania',
    tips: 'Rejestrując się, zgadzam się na',
    title: 'Witamy w Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Podążać', twitter: 'Świergot' },
    legal: { policy: 'Polityka prywatności', terms: 'Warunki usług', title: 'Prawny' },
    product: {
      image: 'Tłumaczenie obrazu',
      pdf: 'Tłumaczenie PDF',
      title: 'Produkty',
      video: 'Tłumaczenie i podsumowanie wideo',
      web: 'Tłumaczenie i podsumowanie internetowe',
    },
    resources: { title: 'Zasoby' },
  },
  pageHeader: {
    contact: 'Kontakt',
    help: 'Pomoc',
    logout: 'Wyloguj się',
    logoutSucc: 'Wylogowałem się.',
    pricing: 'Wycena',
    settings: 'Ustawienia',
    signUp: 'Zaloguj się',
  },
  pdf: {
    autoDetect: 'Auto-detect',
    cancelBtn: 'Anulować',
    cancelUpload: 'Anulować',
    chatPdf: {
      summaryError: 'Podsumowanie nie powiodło się, spróbuj ponownie później',
      chatError: 'Czat nie powiódł się, spróbuj ponownie później',
      Search: 'Szukaj',
      cancel: 'Anulować',
      chatTitle: 'Witaj w Noam! Oto podsumowanie twojego pliku PDF:',
      clear: 'Jasne',
      clearContent:
        'To rozpocznie nową rozmowę. Obecna rozmowa nie zostanie zapisana. Jesteś pewien?',
      clearDialogTitle: 'Prosimy o potwierdzenie',
      clearSucc: 'Wyczyszczone',
      copy: 'Kopia',
      copySucc: 'Skopiowane',
      delete: 'Usuwać',
      deleteCancel: 'Anulować',
      deleteContent: 'Tego pdf nie można odzyskać po usunięciu. Jesteś pewien?',
      deleteOk: 'Usuwać',
      deleteSuccess: 'Usunięte',
      deleteTitle: 'Potwierdź Usuń',
      expand: 'Zawalić się',
      historyTitle: 'Historia',
      listError:
        'Nie udało się załadować listy czytania. Spróbuj ponownie później lub skontaktuj się z obsługą.',
      loading: 'Załadunek...',
      noDataDesc: 'Nie ma jeszcze historii.',
      noMore: 'Nigdy więcej treści.',
      placeholder: 'Zapytaj cokolwiek o ten pdf',
      summaryTitle: 'Możesz być zainteresowany tymi pytaniami:',
    },
    collectionEmpty: 'Twoja kolekcja jest pusta.',
    collectionTab: 'Notatki',
    dataLoading: 'Załadunek...',
    dialogContent: 'Czy na pewno chcesz to usunąć?',
    dialogTitle: 'Prosimy o potwierdzenie',
    download: {
      both: 'Dwujęzyczny',
      btn: 'Ratować',
      cancel: 'Zamknięcie tego okna anuluje pobieranie PDF. Czy na pewno chcesz anulować?',
      desc: 'Zapisz na urządzenie: Pobieranie tłumaczeń jako obrazów; Save & Drukuj: Pobieranie tłumaczeń w oryginalnym formacie z obsługą kopii.',
      download: 'Pobierać',
      downloadMsg1: 'Postęp tłumaczenia PDF:',
      downloadMsg2: 'Szacowany czas:',
      downloadSucc: 'PDF jest gotowy! Kliknij, aby zapisać.',
      downloadTip: 'Pobieranie pdf. Nie zamykaj tego okna, aby uniknąć błędów pobierania.',
      error: 'Nie udało się uratować. Zamiast tego spróbuj wydrukować.',
      fileName: 'Nazwa pliku:',
      fileType: 'Typ pliku:',
      loading: 'Oszczędność...',
      loading2: 'Załadunek...',
      minute: 'Protokół...',
      msg1: 'Niektóre tłumaczenia w tym dokumencie są dłuższe niż oryginalny tekst i mogą się nakładać w pobranym pliku. Zalecamy przeczytanie na stronie internetowej.',
      msg2: '1. Tłumaczenie nie jest jeszcze kompletne. Pobierz po zakończeniu tłumaczenia.',
      msg3: '2. Niektóre tłumaczenia są za długie. Przeczytaj na stronie internetowej, aby uniknąć nakładania się tekstu.',
      msg4: 'Trwają zadania tłumaczeniowe. Spróbuj ponownie po ich zakończeniu.',
      progress:
        'Tłumaczenie pdf. Postęp: {{postęp}}, szacowany czas pozostały: {{minuty}} minuty ...',
      save: 'Zapisz na urządzenie',
      savePrint: 'Zapisz i wydrukuj',
      success: 'Pobrane',
      trans: 'Tylko tłumaczenie',
      transContinue: 'Kontynuuj tłumaczenie',
    },
    downloadBtn: 'Pobierać',
    dragTipModal: {
      black: 'przestrzeń',
      leftKey: 'lewy przycisk',
      ok: 'Mam to',
      press: 'Naciskać',
      tips: 'przeciągnąć i oglądać.',
    },
    entry: 'PDF',
    fileErrMsg:
      'Czytanie plików nie powiodło się. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    fileMsg: 'Obecny rozmiar pliku:',
    fileMsg2: 'Prześlij plik mniejszy niż 300 MB.',
    freeBannerBtn: 'Aktualizacja',
    freeBannerTips: 'Osiągnięto bezpłatne limity tłumaczeń PDF',
    guide: {
      placeholder: 'Wklej link PDF tutaj',
      start: 'Zacznij tłumaczyć',
      text1: 'Dwujęzyczne czytanie',
      text2:
        'Przeczytaj dwujęzycznie, aby porównać oryginalny tekst i tłumaczenie obok siebie, poprawiając wydajność.',
      text3: 'Porozmawiaj ze swoim PDF',
      text4:
        'Analiza i podsumowanie AI. Zadawaj pytania dotyczące swojego pliku PDF i uzyskaj natychmiastowe odpowiedzi.',
      text5: 'Dokładne i wydajne',
      text6: 'Tłumaczenie PDF z napędem AI dla większej dokładności i wydajności.',
      title1: 'Tłumaczenie dwujęzyczne',
      title2: 'Porozmawiaj ze swoim PDF',
      toast: 'Wprowadź ważny online link PDF kończący się w .pdf',
    },
    hasTransTask: 'Trwa zadanie tłumaczeniowe. Proszę poczekaj.',
    hoverTrans: 'OKREGÓW-TRANSLATOWE',
    initial: 'Przetłumacz pdf',
    installText: 'Twoje kompleksowe rozwiązanie dla codziennych tłumaczeń.',
    installText2: 'Najpierw zainstaluj rozszerzenie',
    installTips:
      'Zainstaluj rozszerzenie dla jeszcze większej liczby tłumaczeń PDF i płynniejszych tłumaczeń stron internetowych:',
    kw: 'zszywka',
    link: 'połączyć',
    listError:
      'Nie udało się załadować plików PDF. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    memoTotalCount: '1 przedmiot łącznie',
    new: 'nowy plik',
    noId: 'Nie znaleziono identyfikatora pliku.',
    notPdf: 'Tylko pliki PDF.',
    okBtn: 'Usuwać',
    okBtn2: 'Mam to',
    original: 'Oryginalny',
    parallel: 'Równoległy',
    parseErrDialogContent:
      'Ten link PDF nie można przetłumaczyć bezpośrednio. Pobierz pdf i prześlij go do tłumaczenia.',
    parseErrTips: 'Nie udało się przeanalizować pliku',
    parseFileToast:
      'Nie udało się przeanalizować pliku. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    pdfLoading: 'Rozbiór gramatyczny zdania...',
    searchPlaceholder: 'Szukaj',
    selectTrans: 'Wybierz tekst, aby przetłumaczyć',
    shareBtn: 'Udział',
    shareBtnText1: 'Wygeneruj łącze udostępniania',
    shareBtnText2: 'Zaktualizuj udostępnioną treść',
    shareModal: {
      cancel: 'Anulować',
      copy: 'Kopia',
      copySucc: 'Link skopiowany',
      custom: 'Dostosuj:',
      errMsg:
        'Wybrana treść nie została jeszcze w pełni przetłumaczona. PROSZĘ PROSZĘ Przed udostępnieniem.',
      gen: 'Udział',
      modify: 'Redagować',
      notSupport: 'Ten język nie jest obecnie obsługiwany.',
      page: 'Strony',
      pageNuErr: 'Wprowadź ważny numer strony.',
      range: 'Zakres:',
      remain: 'pozostały',
      remain1: 'Całkowity szacowany czas:',
      search: 'Szukaj',
      settings: 'Ustawienia udostępniania:',
      shareSetting: 'Ustawienia udostępnienia',
      shareText: 'Udział',
      shareTips: 'Udostępnij przetłumaczone Link PDF znajomym:',
      sourceLang: 'Z:',
      target: 'Do:',
      text1: 'Ustawienia udostępnienia',
      text2: 'Wygeneruj łącze udostępniania',
      totalPage: 'Łącznie 1 strona',
      trans: 'Tłumaczyć',
      translateTips:
        'Wskazówka: Jeśli tłumaczenie trwa trochę czasu, możesz zamknąć to okno i udostępnić później.',
      translating: 'Tłumaczenie PDF ...',
      update: 'Aktualizacja linku udostępniania',
    },
    shareMsg1: 'Udostępnij generowany link.',
    shareMsg2: 'Udostępniona treść zaktualizowana.',
    shareMsg3:
      'Nie udało się wygenerować łącza udostępniania. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    shareMsg4:
      'Nie udało się zaktualizować udostępnionej treści. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    shareTips: 'Twoje tłumaczenie PDF jest gotowe! Podziel się nim z przyjaciółmi.',
    start: 'Rozpocznij bezpłatny proces',
    thumbnail: 'Spis treści',
    toast1: 'Ładowanie PDF. Spróbuj ponownie później.',
    toast2: 'Treść PDF jest zbyt duża. Dialog jest obecnie nieobsługiwany.',
    toast3: 'Ściąganie. Spróbuj ponownie po zakończeniu pobierania.',
    toolbar: { Adjust: 'Regulować', autoFit: 'Auto-Fit' },
    trans: 'Tłumaczenie',
    transSucc: 'Tłumaczenie udane!',
    unLoginToast: 'Zaloguj się, aby użyć tej funkcji.',
    uploadBtn: 'Wgrywać',
    uploadErr:
      'Przesłanie pliku nie powiodło się. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    uploadErrSignUrl:
      'Nie udało się przesyłać adresu URL. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    uploadMsg1: 'Przeciągnij i upuść swój plik tutaj.',
    uploadMsg2: 'Obsługiwane typy plików: PDF |  Max Rozmiar pliku: 100 MB',
    uploadMsg3: 'Kliknij, aby wybrać lub przeciągnąć i upuść pliki tutaj.',
    uploading: 'Przesyłanie',
    uploadingMsg: 'Przesyłanie. Proszę poczekaj.',
    uploadingMsg1: 'Plik jest przesyłany. Spróbuj ponownie później.',
    uploadingMsg2: 'Plik jest obecnie konwertowany. Spróbuj ponownie później.',
    uploadingMsg3:
      'Żądanie postępu konwersji nie powiodło się. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    uploadingMsg4:
      'Noam nie może uzyskać dostępu do plików lokalnych. Proszę ręcznie prześlij plik PDF do tłumaczenia.',
    uploadingMsg5:
      'Nie udało się odzyskać URL pliku. Spróbuj ponownie później lub skontaktuj się z obsługą.',
    video: 'wideo',
    vipBannerBtn: 'Aktualizacja',
    vipBannerTips: 'Osiągnąłeś limit tłumaczenia PDF na ten miesiąc.',
    webTrans: 'Pełne odniesienie',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Tak, Noam oferuje bezpłatny plan, którego możesz od razu użyć.',
      a2: 'Oferujemy trzy plany członkostwa: Pro, Pro+i Enterprise. Możesz zapłacić co miesiąc lub co roku. Roczne fakturowanie oferuje znaczne oszczędności.',
      a3: 'Wszystkie funkcje są dostępne w obu planach. Główną różnicą jest koszt: roczne subskrypcje kosztują mniej niż ogólnie. Miesięczne subskrypcje są rozliczane co miesiąc, a roczne plany są rozliczane raz w roku.',
      a4: 'Możesz anulować subskrypcję w dowolnym momencie w ustawieniach konta.',
      q1: 'Czy istnieje bezpłatny plan?',
      q2: 'Jakie plany cenowe i opcje płatności są dostępne?',
      q3: 'Jaka jest różnica między subskrybowaniem miesięcznym vs. rocznie?',
      q4: 'Jak anulować subskrypcję?',
    },
    around: 'Wokół',
    billedMonthly: 'Rozliczane miesięcznie',
    billedYearly: 'Obliczane roczne',
    day: 'dzień',
    desc: 'Porównaj plany i wybierz najlepsze',
    detailedCompare: 'Porównaj plany',
    discount: '20% zniżki',
    enterprise: {
      benefit1: 'Priorytetowa obsługa klienta',
      benefit2: 'Wielu użytkowników',
      benefit3: 'Dostosowane rozwiązania',
      benefit5: 'Dedykowany menedżer konta',
      btn: 'Skontaktuj się z nami',
      recommendText: 'Plany niestandardowe （≥3 人）',
      talkToUs: 'Skontaktuj się',
    },
    free: {
      benefit1: 'Natychmiastowe tłumaczenie 100 razy/dzień',
      benefit2: 'Tłumaczenie i podsumowanie strony internetowej',
      benefit3: '30 tłumaczeń obrazów',
      benefit4: 'Tłumaczenie i podsumowanie wideo',
      benefit5: '10 tłumaczeń PDF',
      benefit6: '5000 tokenów',
      btn: 'Spróbuj za darmo',
      recommendText: 'Bezpłatny proces',
    },
    imageTranslation: 'Tłumaczenie obrazu',
    month: 'miesiąc',
    monthly: 'Miesięczny',
    pageTitle: 'Wycena',
    pdf: {
      chat: 'Zapytania do czatu PDF',
      maxPage: 'Max. Strony na PDF',
      translation: 'Tłumaczenie PDF',
    },
    preMonth: 'Na miesiąc',
    pro: {
      benefit1: 'Priorytetowa obsługa klienta',
      benefit2: '1 000 000 tokenów/miesiąc',
      benefit3: 'Tłumaczenie i podsumowanie strony internetowej',
      benefit4: '200 tłumaczeń obrazów',
      benefit5: 'Tłumaczenie i podsumowanie wideo',
      benefit6: '200 tłumaczeń PDF',
      btn: 'Zacznij',
      recommendText: 'Najbardziej popularny',
    },
    proPlus: {
      benefit1: 'Priorytetowa obsługa klienta',
      benefit2: '3 000 000 tokenów/miesiąc',
      benefit3: 'Tłumaczenie i podsumowanie strony internetowej',
      benefit4: '500 tłumaczeń obrazów',
      benefit5: 'Tłumaczenie i podsumowanie wideo',
      benefit6: '500 PDF Tłumaczenia',
      recommendText: 'Najlepsza wartość',
    },
    video: { summary: 'Podsumowanie wideo', translation: 'Tłumaczenie wideo' },
    web: {
      contrastiveTranslation: 'Tłumaczenie dwujęzyczne',
      dictionaryTranslation: 'Tłumaczenie wyboru',
      hoverTranslation: 'Tłumaczenie zawisowe',
      instantTranslation: 'Natychmiastowe tłumaczenie',
      summary: 'Podsumowanie strony internetowej',
    },
    yearly: 'Rocznie',
  },
  privacy: {
    block1:
      'Noam („Noam”, „my”, „nasz” lub „nas”) szanuje twoją prywatność i jest zaangażowany w ochronę wszelkich informacji, które otrzymujemy przed tobą lub o tobie. Niniejsza Polityka prywatności opisuje nasze praktyki dotyczące gromadzenia lub korzystania z danych osobowych z korzystania z naszej strony internetowej, aplikacji i usług (łącznie „Usługi”). Niniejsza Polityka prywatności nie ma zastosowania do treści, które obsługujemy w imieniu naszych klientów produktów biznesowych, takich jak nasz API. Korzystanie z tych danych podlega naszej umowie klienta, która obejmuje dostęp do tych produktów i korzystanie z nich.',
    block10:
      'Wdrażamy uzasadnione komercyjnie środki techniczne, administracyjne i organizacyjne w celu ochrony danych osobowych online i offline przed utratą, niewłaściwym użyciem i nieautoryzowanym dostępem, ujawnieniem, zmianami lub wrogim czynami. Jednak żadna transmisja Internetu ani wiadomości e -mail nie może zagwarantować pełnego bezpieczeństwa lub dokładności. W szczególności e -maile wysłane do nas lub od nas mogą nie być bezpieczne. Powinieneś więc być szczególnie ostrożne przy podejmowaniu decyzji, jakie informacje wysłać do nas za pośrednictwem Usługi lub E -maila. Ponadto nie ponosimy odpowiedzialności za jakiekolwiek utrudnienie ustawień prywatności lub środków bezpieczeństwa w Ustawieniach bezpieczeństwa lub środków bezpieczeństwa stron internetowych stron trzecich. Zachowamy Twoje dane osobowe tak długo, jak to konieczne, aby świadczyć usługi lub inne uzasadnione cele biznesowe, takie jak rozwiązywanie sporów, przyczyn bezpieczeństwa i bezpieczeństwa lub przestrzeganie naszych obowiązków prawnych. Czas, jaki zachowujemy dane osobowe, będzie zależeć od wielu czynników, takich jak ilość, charakter, wrażliwość informacji, potencjalne ryzyko nieautoryzowanego użycia lub ujawnienia, celów, dla których przetwarzamy informacje, i innych kwestii prawnie wiążących.',
    block11:
      'Od czasu do czasu możemy aktualizować niniejszą Politykę prywatności. Kiedy to zrobimy, opublikujemy zaktualizowaną wersję na tej stronie, chyba że obowiązujące prawo.',
    block12:
      'Jeśli masz jakieś pytania lub obawy dotyczące niniejszej Polityki prywatności, skontaktuj się z naszą obsługą klienta. E-mail:',
    block2: 'Zbieramy następujące dane osobowe („dane osobowe”) związane z Tobą:',
    block3:
      'Informacje dostarczone przez Ciebie: podczas tworzenia konta do korzystania z naszych usług lub komunikacji z nami zbieramy dane osobowe w następujący sposób:',
    block4:
      'Dane osobowe automatycznie otrzymywane od korzystania z usług: Po uzyskaniu dostępu, korzystania z usług lub interakcji z usługami otrzymujemy następujące informacje na temat Twojego dostępu, użytkowania lub interakcji („Informacje techniczne”):',
    block5: 'Możemy wykorzystywać dane osobowe do następujących celów:',
    block6:
      'Podsumowanie lub zidentyfikowane informacje: Możemy agregować lub zidentyfikować dane osobowe, aby zapobiec ponownemu użycia celów identyfikacyjnych i wykorzystać takie informacje do analizy skuteczności naszych usług, ulepszania i dodawania funkcji do naszych usług, przeprowadzania badań i podobnych celów. Ponadto możemy analizować ogólne zachowanie i cechy naszych użytkowników usług i udostępniać zagregowane informacje stronom trzecim, publikować takie zagregowane informacje lub uczynić je ogólnie dostępnymi. Możemy gromadzić zagregowane informacje za pośrednictwem Usług, za pośrednictwem plików cookie i za pomocą innych środków opisanych w niniejszej Polityce prywatności. Będziemy utrzymywać i wykorzystywać informacje zidentyfikowane w formularzu anonimowym lub zidentyfikowanym i nie będziemy próbować ponownie zidentyfikować takich informacji, chyba że jest to wymagane przez prawo.',
    block7:
      'W niektórych przypadkach możemy przekazać Twoje dane osobowe stronom trzecim bez dalszego powiadomienia, chyba że jest to wymagane przez prawo:',
    block8:
      'W zależności od lokalizacji geograficznej osoby w Europejskim Obszarze Gospodarczym, Wielka Brytania i na całym świecie mogą mieć pewne ustawowe prawa dotyczące ich danych osobowych. Na przykład możesz mieć prawo do:',
    block9:
      'Usługa może zawierać linki do innych stron internetowych, które nie są obsługiwane lub kontrolowane przez NOAM, w tym usługi mediów społecznościowych („strony internetowe stron trzecich”). Informacje udostępniane stronom internetowym stron trzecich będą podlegać konkretnym polityce prywatności i warunkom obsługi stron internetowych stron trzecich, a nie niniejszej Polityki prywatności. Zapewniamy te linki dla wygody i nie sugerujemy naszego poparcia lub przeglądu tych stron internetowych. Skontaktuj się bezpośrednio z stronami internetowymi stron trzecich, aby uzyskać informacje na temat ich zasad i warunków prywatności.',
    subBlock1:
      'Informacje o koncie: Podczas tworzenia konta na naszej platformie zbieramy informacje powiązane z Twoim konto, w tym imienia, dane kontaktowe, poświadczenia konta, informacje o karcie płatniczej i historia transakcji („Informacje o koncie”).',
    subBlock10: 'Świadczenie, zarządzanie, utrzymywanie i/lub analizowanie usług;',
    subBlock11: 'Komunikowanie się z tobą;',
    subBlock12: 'Opracowywanie nowych produktów i usług;',
    subBlock13:
      'Zapobieganie oszustwom, działalności przestępczej lub niewłaściwym użyciu naszych usług oraz ochronę bezpieczeństwa naszych systemów IT, architektury i sieci;',
    subBlock14: 'Prowadzenie transferów biznesowych;',
    subBlock15:
      'Zgodnie z obowiązkami prawnymi i postępowaniami prawnymi, ochroną naszych praw, prywatności, bezpieczeństwa lub własności, a także praw naszym podmiotom stowarzyszonym, ty lub innymi stronami trzecimi.',
    subBlock16:
      'Dostawcy i usługodawcy: aby pomóc nam w zaspokajaniu potrzeb biznesowych oraz w wykonywaniu niektórych usług i funkcji, możemy dostarczyć dane osobowe dostawcom i dostawcom usług, w tym dostawcy usług hostingowych, dostawców usług w chmurze i innych dostawców usług informatycznych, oprogramowanie do komunikacji e -mail i usługi analityki internetowej, itp. Te partnerzy będą dostępne, przetwarzają lub przechowywać informacje osobowe tylko w naszym kierunku.',
    subBlock17:
      'Transfery biznesowe: Jeśli angażujemy się w transakcje strategiczne, reorganizacje, bankructwa, przejęcia upadłości lub usługi przejścia do innego dostawcy (łącznie „transakcje”), twoje dane osobowe i inne informacje mogą być udostępniane z odpowiednikami transakcji i innymi stronami pomagającymi w transakcjach w zakresie należytej staranności i przesyłania w zakresie transakcji w ramach innych imptatów.',
    subBlock18:
      'Wymagania prawne: Możemy udostępniać Twoje dane osobowe władzom rządowym, rówieśnikom branżowym lub innym stronom trzecim, w tym informacji o interakcji z naszymi usługami (1), jeśli jest to wymagane przez prawo lub jeśli uważamy, że takie działanie jest konieczne do przestrzegania obowiązków prawnych; (2) w celu ochrony i obrony naszych praw lub własności; (3) jeżeli jednostronnie ustalimy, że istnieje naruszenie naszych warunków, polityk lub praw; (4) wykrycie lub zapobieganie oszustwom lub innym nielegalnym działaniom; (5) w celu ochrony naszych produktów, pracowników lub użytkowników lub bezpieczeństwa publicznego, bezpieczeństwa, integralności; lub (vi) w celu obrony przed odpowiedzialnością prawną.',
    subBlock19: 'Uzyskaj dostęp do danych osobowych i informacji o tym, jak są przetwarzane.',
    subBlock2:
      'Treść użytkownika: podczas korzystania z naszych usług zbieramy dane osobowe z twoich danych wejściowych, przesyłania plików lub przekazywanych nam („treść”).',
    subBlock20: 'Poproś o usunięcie danych osobowych z naszych rekordów.',
    subBlock21: 'Popraw lub zaktualizuj swoje dane osobowe.',
    subBlock22: 'Przenieś swoje dane osobowe do strony trzeciej (przenośność danych).',
    subBlock23: 'Ogranicz sposób, w jaki przetwarzamy Twoje dane osobowe.',
    subBlock24:
      'Wycofaj swoją zgodę - gdzie polegamy na zgodie jako podstawa prawna do przetwarzania, możesz wycofać swoją zgodę w dowolnym momencie.',
    subBlock25: 'Obecnie, jak przetwarzamy Twoje dane osobowe.',
    subBlock26: 'Poprawa naszych usług i prowadzenie badań;',
    subBlock3:
      'Informacje o komunikacji: Jeśli wyślesz nam wiadomości, zbierzemy Twoje imię i nazwisko, dane kontaktowe i treść wiadomości („Informacje o komunikacji”).',
    subBlock4:
      'Informacje o mediach społecznościowych: Mamy strony w serwisach społecznościowych, takich jak Instagram, Facebook, Medium, Twitter, YouTube i LinkedIn. Podczas interakcji z naszymi stronami mediów społecznościowych zbieramy dane osobowe, które wybierzesz nam, takie jak dane kontaktowe („informacje społeczne”). Ponadto firmy prowadzące nasze strony mediów społecznościowych mogą dostarczyć nam zagregowanych informacji i analizy na temat naszych działań w mediach społecznościowych.',
    subBlock5:
      'Dane dziennika: informacje automatycznie wysyłane przez przeglądarkę podczas korzystania z naszych usług. Dane dziennika obejmują adres protokołu internetowego (IP), typ i ustawienia przeglądarki, datę i godzinę twoich żądań oraz sposób interakcji z naszą witryną.',
    subBlock6:
      'Dane dotyczące użytkowania: Możemy automatycznie gromadzić informacje o korzystaniu z usługi, takie jak typy treści, które wyświetlisz lub wchodzisz w interakcje, używane funkcje, działania, a także strefę czasową, kraj, datę i godzinę dostępu, agent użytkownika i wersja, komputer lub urządzenie mobilne oraz połączenie komputerowe.',
    subBlock7:
      'Informacje o urządzeniu: Obejmuje to nazwę urządzenia, system operacyjny, identyfikator urządzenia i używaną przeglądarkę. Zebrane informacje mogą zależeć od typu używanego urządzenia i jego ustawień.',
    subBlock8:
      'Pliki cookie: Używamy plików cookie do obsługi i zarządzania naszymi usługami oraz poprawy twoich wrażeń. „Plik cookie” to informacje wysłane przez witrynę, którą odwiedzasz w przeglądarce. Możesz ustawić przeglądarkę, aby zaakceptował wszystkie pliki cookie, odrzucić wszystkie pliki cookie lub powiadomić cię, gdy dostarczone jest ciasteczka, abyś mógł zdecydować, czy zaakceptować ją za każdym razem. Jednak odmowa plików cookie może uniemożliwić korzystanie z wyświetlania lub funkcjonalności niektórych obszarów lub funkcji witryny. Aby uzyskać więcej informacji o plikach cookie, odwiedź wszystkie pliki cookie.',
    subBlock9:
      'Analityka: Możemy korzystać z różnych produktów analitycznych online, które wykorzystują pliki cookie, aby pomóc nam przeanalizować, w jaki sposób użytkownicy korzystają z naszych usług i poprawić Twoje wrażenia podczas korzystania z Usług.',
    subTitle1: 'Zbieramy dane osobowe',
    subTitle2: 'Jak wykorzystujemy dane osobowe',
    subTitle3: 'Ujawnienie danych osobowych',
    subTitle4: 'Twoje prawa',
    subTitle5: 'Linki do innych stron internetowych',
    subTitle6: 'Bezpieczeństwo i przechowywanie informacji',
    subTitle7: 'Modyfikacja polityki prywatności',
    subTitle8: 'Jak się z nami skontaktować',
    title: 'Polityka prywatności',
    updateTime: 'Zaktualizowano: 15 marca 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Zaakceptuj wszystko',
    text: 'Aby poprawić Twoje wrażenia, używamy plików cookie do personalizacji treści i usług.',
    title: 'Ta strona internetowa korzysta z plików cookie',
  },
  slogen: 'Najłatwiejszy asystent AI',
  stripe: {
    contact: 'Skontaktuj się z nami pod adresem contact@noam.tools z wszelkimi pytaniami!',
    fail: {
      btn: 'Spróbować ponownie',
      text1: 'Nie mogliśmy przetworzyć Twojej płatności.',
      text2: 'Sprawdź swoje połączenie i spróbuj ponownie.',
      title: 'Płatność nie powiodła się',
    },
    succ: { btn: 'Chodźmy', text1: 'Witaj w Noam,', text2: 'Zacznijmy!', title: 'Gratulacje' },
  },
  terms: {
    block1: 'Dziękuję za użycie Noam!',
    block10:
      '(a) Opłaty i faktur. Zapłacisz wszystkie opłaty („opłaty”) zgodnie z wyceną i warunkami na obowiązującej stronie cenowej lub zgodnie z uzgodnieniem na piśmie między nami. Zastrzegamy sobie prawo do korygowania błędów lub błędów cenowych, nawet jeśli wydaliśmy faktury lub otrzymaliśmy płatność. Podajesz kompletne i dokładne informacje o rozliczeniu, w tym ważną i autoryzowaną metodę płatności. Okresowo obciążamy twoją metodę płatności, jak uzgodniono, ale możemy rozsądnie zmienić datę rozliczeniową. Upoważniasz Noam, jego podmioty stowarzyszone i procesory płatności strony trzecie do obciążenia metodą płatności. Jeśli Twoja płatność się nie powiedzie, dostarczymy Ci pisemne powiadomienie i możemy zawiesić dostęp do usługi do czasu otrzymania płatności. O ile inaczej określono w niniejszej Umowie, płatności nie podlegają zwrotowi.',
    block11:
      '(b) Podatki. O ile nie określono inaczej, opłaty nie obejmują żadnych podatków federalnych, stanowych, lokalnych i zagranicznych, obowiązków i podobnych ocen („podatki”). Jesteś odpowiedzialny za wszystkie podatki związane z twoimi zakupami, z wyłączeniem podatków na podstawie naszego dochodu netto, dla których będziemy Cię fakturować. Zgadzasz się niezwłocznie zapłacić takie podatki i przekazać nam prawnie ważne oświadczenia dotyczące prywatności i uzyskać niezbędne zgodę na obsługę takich danych, i gwarantuje nam, że obsługujesz takie dane zgodnie z obowiązującym prawem.',
    block12:
      '(c) Zmiany cen. Możemy zmienić nasze ceny, powiadamiając Cię za pośrednictwem Twojego konta i/lub naszej strony internetowej. Wzrost cen będzie skuteczny natychmiast po opublikowaniu. Wszelkie zmiany cen będą miały zastosowanie do opłat za konto po dacie wejścia w życie zmiany.',
    block13:
      '(d) Spory i zaległe płatności. Jeśli chcesz zakwestionować jakiekolwiek opłaty lub podatki, skontaktuj się z contact@noam.tools w ciągu trzydziestu (30) dni od daty faktury.',
    block14:
      '(e) darmowe użycie. Nie możesz utworzyć wielu kont, aby uzyskać bezpłatne kredyty. Jeśli ustalimy, że nie korzystasz z bezpłatnych kredytów w dobrej wierze, możemy obciążyć standardowe opłaty lub przestać zapewnić dostęp do usługi.',
    block15:
      '(a) poufność. Możesz mieć dostęp do poufnych informacji Noama. Możesz używać informacji poufnych tylko zgodnie z niniejszymi Warunkami w celu korzystania z Usługi. Nie możesz ujawniać poufnych informacji żadnej strony trzeciej i będziesz chronić poufne informacje w sposób nie mniej ochronny niż chronisz własne podobne poufne informacje, przynajmniej z rozsądną starannością. Informacje poufne to informacje niepubliczne oznaczone jako poufne przez NOAM lub które należy rozsądnie traktować jako poufne w danych okolicznościach, w tym oprogramowanie, specyfikacje i inne niepubliczne informacje biznesowe. Informacje poufne nie zawierają informacji, które: (1) stają się ogólnie dostępne dla publiczności bez winy z twojej strony; (2) miałeś w posiadaniu bez żadnego obowiązku poufności przed otrzymaniem go na podstawie niniejszych Warunków; (3) jest prawnie ujawniany przez stronę trzecią bez żadnych zobowiązań poufności; lub (4) jest niezależnie opracowywany przez ciebie bez korzystania z poufnych informacji. Możesz ujawnić poufne informacje, jeśli jest to wymagane przez prawo, sąd lub inne postanowienie rządowe, ale musisz przekazać wcześniej pisemne powiadomienie NOAM i, w możliwym zakresie, podjąć rozsądne wysiłki w celu ograniczenia zakresu ujawnienia, w tym pomoc w przeciwnych wnioskach o ujawnienie.',
    block16:
      '(b) Bezpieczeństwo. Musisz wdrożyć rozsądne i odpowiednie środki, aby pomóc w ochronie dostępu do Usługi i korzystania z nich. Jeśli odkryjesz jakiekolwiek luki lub naruszenia związane z korzystaniem z Usługi, musisz niezwłocznie skontaktować się z NOAM i podać szczegółowe informacje na temat podatności lub naruszenia.',
    block17:
      '((c) Przetwarzanie danych osobowych. Jeśli korzystasz z Usługi do przetwarzania danych osobowych, musisz podać prawnie wystarczające oświadczenia dotyczące prywatności i uzyskać niezbędne zgodę na przetwarzanie takich danych, a także gwarantują nam, że obsługujesz takie dane zgodnie z obowiązującym prawem.',
    block18:
      '(a) wypowiedzenie; Zawieszenie. Niniejsze Warunki będą skuteczne od pierwszego korzystania z Usługi i pozostaną obowiązywanie do czasu zakończenia. Możesz rozwiązać niniejsze warunki w dowolnym momencie z dowolnego powodu, zaprzestając korzystania z Usługi i Treści. Możemy rozwiązać niniejsze warunki z wcześniejszym powiadomieniem z jakiegokolwiek powodu. Możemy natychmiast rozwiązać niniejsze Warunki, powiadamiając Cię, jeśli materialnie naruszysz sekcję 2 (Wymagania dotyczące użytkowania), sekcja 5 (poufność, bezpieczeństwo i ochrona danych), sekcja 8 (rozstrzyganie sporów) lub sekcja 9 (ogólne warunki) lub jeśli nasza relacja z dowolnym dostawcą technologii zewnętrznych poza naszymi zmianami kontroli lub w celu zgodności z prawem lub żądaniami rządu. Możemy zawiesić Twój dostęp do Usługi, jeśli nie przestrzegasz niniejszych Warunków, lub jeśli twoje użycie stanowi zagrożenie dla nas lub jakiejkolwiek strony trzeciej, lub jeśli podejrzewamy, że twoje użycie jest nieuczciwe lub może narazić nas lub jakąkolwiek stronę trzecią.',
    block19:
      '(b) Wpływ zakończenia. Po zakończeniu zaprzestanie usługi i niezwłocznie zwrócisz lub zniszczysz wszelkie poufne informacje zgodnie z zaleceniami. Przepisy niniejszych Warunków, które ze swojego charakteru powinny przetrwać zakończenie lub wygaśnięcie, w tym między innymi sekcja 3 i 5-9, przetrwają.',
    block2:
      'Niniejsze Warunki użytkowania dotyczą korzystania z usług NOAM, w tym naszych interfejsów programowania aplikacji, oprogramowania, narzędzi, usług programistycznych, danych, dokumentacji i strony internetowej (zbiorowo określanych jako „usługi”). Korzystając z naszych usług, zgadzasz się przestrzegać niniejszych Warunków. Nasza polityka prywatności wyjaśnia, w jaki sposób zbieramy i wykorzystujemy dane osobowe.',
    block20:
      '(a) Odszkodowanie. Będziesz bronić, odszkodować i utrzymywać nas, naszych podmiotów stowarzyszonych i naszego personelu nieszkodliwy przed wszelkimi roszczeniami, stratami i wydatkami (w tym opłatami adwokacki) wynikającymi z korzystania z usługi lub związanych z użyciem usługi, korzystania z produktów lub usług związanych z Usługą oraz Twojego naruszenia tych terminów.',
    block21:
      '(b) Zastrzeżenia. Usługa jest świadczona „tak, jak jest”. W zakresie dozwolonym przez prawo my i nasi podmioty stowarzyszone i licencjodawcy nie składają żadnych oświadczeń ani gwarancji dotyczących tej usługi i nie udaje się, w tym między innymi gwarancji przydatności handlowej, przydatności do określonego celu, zadowalającej jakości, braku narażenia i spokoju, oraz wszelkich gwarancji wynikających z transakcji lub użycia handlu. Nie gwarantujemy, że usługa będzie nieprzerwana, dokładna lub wolna od błędów lub że jakakolwiek treść będzie bezpieczna, nie utracona lub nie zmieniona.',
    block22:
      '(c) Ograniczenie odpowiedzialności. Ani my, nasi podmioty stowarzyszone, ani nasi licencjodawcy nie będą ponosić odpowiedzialności za jakiekolwiek uszkodzenia pośrednie, przypadkowe, specjalne, konsekwentne lub karne, w tym utratę zysków, utratę reputacji, utratę użytkowania, utratę danych lub inne straty niematerialne, nawet jeśli zostaliśmy powiadomieni o możliwości takich szkód. Zgodnie z niniejszymi Warunkami nasza całkowita odpowiedzialność nie przekroczy opłat, które uiszczesz za usługę w ciągu dwunastu (12) miesięcy poprzedzających roszczenie lub sto dolarów (100 USD), w zależności od tego, która wartość jest większa. Ograniczenia w niniejszej sekcji dotyczą maksymalnego zakresu dozwolonego przez obowiązujące prawo.',
    block23:
      'Warunki i korzystanie z Usług są regulowane przez prawo Stanów Zjednoczonych, z wyjątkiem przepisów dotyczących konfliktu prawa USA. Korzystanie z wniosku może również podlegać innym prawom lokalnym, stanowym, krajowym lub międzynarodowym. Wszelkie postępowanie prawne lub postępowanie związane z niniejszymi Warunkami zostaną wniesione wyłącznie do sądów Stanów Zjednoczonych, a ty zgadzasz się poddać osobistej jurysdykcji takich sądów.',
    block24:
      '(a) Związek stron. Noam i ty jesteś niezależnymi kontrahentami i żadna ze stron nie ma uprawnień do reprezentowania lub wiązania drugiej strony lub ponoszenia obowiązków dla drugiej strony bez uprzedniej pisemnej zgody drugiej strony.',
    block25:
      '(b) Zastosowanie marki. Nie możesz używać nazwy, logo lub znaków towarowych Noam ani żadnego z jego podmiotów stowarzyszonych bez naszej uprzedniej pisemnej zgody.',
    block26:
      'Jeżeli jakikolwiek postanowienie niniejszych Warunków jest ustalone jako nieważne, nielegalne lub niewykonalne, pozostałe przepisy pozostaną w pełni siły i skutku.',
    block3:
      'Jeśli masz mniej niż 18 lat, musisz uzyskać pozwolenie od rodziców lub opiekunów prawnych na skorzystanie z usługi. Jeśli korzystasz z usługi w imieniu innej osoby lub podmiotu, musisz zostać upoważniony do przyjęcia niniejszych Warunków w ich imieniu. Musisz podać dokładne i pełne informacje do zarejestrowania się na konto. Nie możesz podać swoich danych poświadczeń ani konta każdemu spoza Twojej organizacji i ponosisz odpowiedzialność za wszystkie działania przeprowadzone za pomocą twoich poświadczeń.',
    block4:
      '(a) Korzystanie z usługi. Możesz uzyskać dostęp do usługi na podstawie niniejszych Warunków, a my dajemy niewyłączne prawo do korzystania z usługi. Korzystając z Usługi, będziesz przestrzegać niniejszych Warunków i wszystkich obowiązujących przepisów. My i nasi podmioty stowarzyszone posiadamy wszelkie prawa, tytuły i interesy w służbie.',
    block5:
      '(b) Informacja zwrotna. Z zadowoleniem przyjmujemy opinie, komentarze, pomysły, sugestie i ulepszenia. Jeśli podasz jakąkolwiek taką treść, możemy go używać bez żadnych ograniczeń i bez rekompensaty.',
    block6:
      '(c) Ograniczenia. Nie możesz: (1) korzystać z Usługi w sposób, który narusza, sprzeniewierzy lub narusza wszelkie prawa innych; (2) Inżynier wsteczny, dekompiluj, demontaż, tłumaczenie lub w inny sposób próbuj odkryć kod źródłowy lub podstawowe elementy modeli usług, algorytmów i systemów (chyba że takie ograniczenia są zabronione przez obowiązujące prawo); (3) użyj wyjścia usługi, aby opracowywać modele konkurujące z NOAM; (4) wyodrębnij dane lub wyjście z usługi przy użyciu dowolnych metod automatycznych lub programowania, chyba że jest to dozwolone za pośrednictwem interfejsu API, w tym skrobania, pozyskiwania sieci lub ekstrakcji danych internetowych; (5) reprezentują wynik usługi jako sztucznie wygenerowanej, gdy nie jest, lub w inny sposób narusza nasze zasady użytkowania; (6) kup, sprzedaj lub przenieś klucze API bez naszej wcześniejszej zgody; lub (7) przekazują nam wszelkie dane osobowe dzieci w wieku poniżej 13 lat lub obowiązujący wiek cyfrowej zgody. Zastosujesz się do wszelkich limitów stawek i innych wymagań w naszej dokumentacji.',
    block7:
      '(d) Usługi stron trzecich. Wszelkie oprogramowanie, usługi lub inne produkty związane z usługą są rządzone przez własne warunki i nie ponosimy odpowiedzialności za produkty innych firm.',
    block8:
      '(a) Twoja treść. Możesz dostarczyć wejście („wejście”) do usługi i odbierać wyjście („wyjściowe”) wygenerowane i zwrócone w oparciu o dane wejściowe (wspólnie określane jako „treść”). Pomiędzy stronami oraz w zakresie dozwolonym przez obowiązujące prawo posiadasz wszystkie dane wejściowe. Noam może wykorzystywać treść do świadczenia i utrzymania usługi, przestrzegania obowiązujących przepisów i egzekwowania naszych zasad. Jesteś odpowiedzialny za treść, w tym zapewnienie, że nie narusza ona żadnych obowiązujących przepisów lub niniejszych Warunków.',
    block9:
      '(b) Dokładność. Sztuczna inteligencja i uczenie maszynowe szybko ewoluują dziedziny badań. Ciągle staramy się poprawić nasze usługi, aby była bardziej dokładna, niezawodna, bezpieczna i korzystna. Biorąc pod uwagę probabilistyczny charakter uczenia maszynowego, korzystanie z naszej usługi może powodować nieprawidłowe wyniki w niektórych przypadkach, które mogą nie odzwierciedlać dokładnie rzeczywistych osób, miejsc lub faktów. Powinieneś odpowiednio ocenić dokładność dowolnego wyników na podstawie twojego przypadku użycia, w tym poprzez ręczne przeglądanie wyników.',
    subTitle1: 'Rejestracja i dostęp',
    subTitle10: 'Oddzielność',
    subTitle2: 'Wymagania dotyczące użytkowania',
    subTitle3: 'Treść',
    subTitle4: 'Opłaty i płatności',
    subTitle5: 'Poufność, bezpieczeństwo i ochrona danych',
    subTitle6: 'Termin i zakończenie',
    subTitle7: 'Odszkodowanie; Zastrzeżenia; Ograniczenie odpowiedzialności',
    subTitle8: 'Rządzące prawem i jurysdykcją',
    subTitle9: 'Ogólne warunki',
    title: 'Warunki usług',
    updateTime: 'Zaktualizowano: 15 marca 2024',
  },
  trialModal: {
    btn: 'Zacznij teraz （tylko 0,99 $）',
    chat: 'Chatpdf',
    desc1: 'Po procesie zostaniesz obciążony roczną opłatą w wysokości 180 USD',
    desc2: 'Anuluj w dowolnym momencie',
    focus: 'Skupienie',
    hover: 'Tłumaczenie zawisowe',
    image: 'Tłumaczenie obrazu',
    installBtn: 'Zainstaluj teraz, aby otrzymać prezent',
    memo: 'Ulubione zarządzanie',
    pdf: 'Tłumaczenie równoległe PDF',
    realtime: 'Natychmiastowe tłumaczenie',
    select: 'Tłumaczenie wyboru słów',
    title: 'Prezent powitalny',
    trialText: '7-dniowy proces',
    video: 'Tłumaczenie wideo',
    videoSummary: 'Podsumowanie wideo',
    web: 'Tłumaczenie równoległe',
    webSummary: 'Podsumowanie internetowe',
  },
  upgradeModal: {
    btn: 'Uaktualnij teraz',
    chat: { free: 'Osiągnąłeś limit chatpdf', pro: 'Osiągnąłeś limit chatpdf w tym miesiącu' },
    contactStr:
      'Poczekaj na następny reset lub skontaktuj się z nami pod adresem contact@noam.tools.',
    limitAlertStr: 'Osiągnąłeś limit bezpłatnego planu. Aktualizacja, aby kontynuować.',
    more: 'Wyświetl plany',
    pageLimit: { free: 'Osiągnąłeś limit strony PDF' },
    title: 'Przypomnienie',
    upgradeAlertStr: 'Osiągnąłeś limit bezpłatnego planu. Aktualizacja, aby kontynuować.',
    upload: {
      free: 'Osiągnąłeś limit tłumaczenia PDF',
      pro: 'Osiągnąłeś limit tłumaczenia PDF na ten miesiąc',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Dołącz teraz',
      label: 'Nowe korzyści użytkownika:',
      remain: 'Pozostało tylko 30',
      text: 'Dostępnych tylko 100 miejsc! Chwyć teraz!',
    },
  },
}

export default TRANSLATION
