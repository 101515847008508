const TRANSLATION = {
  buyModal: {
    afterFree: 'Dopo la tua prova gratuita',
    btn: 'Provalo libero',
    freeTrial: 'Prova gratuita di 3 giorni',
    monthLabel: 'mese',
    selectLabel: 'Seleziona un piano',
    title: 'Piano di abbonamento',
    yearLabel: 'anno',
  },
  contact: {
    block1:
      "Per personalizzazione, domande o suggerimenti, contattaci all'indirizzo contact@noam.tools. Risponderemo prontamente.",
    block2: 'Supportaci valutandoci su Google Apps Extension Store su ★★★★★.',
    block3: 'Aiuta gli altri condividendo questa estensione:',
    title: 'Contattaci',
  },
  help: {
    AquickOverviewOfNoam: 'Una rapida panoramica di Noam',
    ChatPDF: 'Chat pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Gestione dei preferiti',
    Fixedplugin: 'Plug -in fisso',
    Focusing: 'Concentrarsi',
    Free: 'Gratuito',
    FreeTrialBenefits: 'Vantaggi di prova gratuiti',
    GoWithWikipedia: 'Vai con Wikipedia',
    GoWithYouTube: 'Vai con YouTube',
    HoverTranslation: 'Traduzione del mouse',
    Howtouse: 'Come usare',
    ImageTranslation: "Traduzione dell'immagine",
    InstantTranslation: 'Traduzione istantanea',
    Membership: 'Appartenenza',
    NoamHelp: 'Noam ti aiuta a tradurre e riassumere',
    PDFparallelTranslation: 'Traduzione parallela PDF',
    ParallelTranslation: 'Traduzione parallela',
    SkipAllSteps: 'Salta tutti i passaggi',
    Summary: 'Riepilogo',
    Translation: 'Traduzione',
    TryItForFree: 'Provalo gratuitamente',
    Video: 'Video',
    VideoSummary: 'Riepilogo video',
    VideoTranslation: 'Traduzione video',
    WebSummary: 'Riepilogo web',
    WordSelectionTranslation: 'Traduzione della selezione delle parole',
    benefits: 'Abbiamo vantaggi che ti aspettano!',
    dayFree: 'Prova gratuita di 3 giorni',
    done: 'Fatto',
    next: 'Prossimo',
    pin: 'Pin Noam per un accesso più facile ～～',
    planIsNotExist: 'Il piano non è disponibile',
    startWiki: 'Cominciamo con Wikimedia!',
    startYoutube: 'Cominciamo con YouTube!',
    try: 'Provalo',
    tryNow: 'Provalo ora!',
  },
  home: {
    PDFTranslate: {
      desc: 'Traduzione bilingue + chat pdf',
      sub1: {
        desc: "La lettura bilingue consente di confrontare il testo originale e la traduzione fianco a fianco, migliorando l'efficienza di lettura.",
        title: 'Lettura bilingue',
      },
      sub2: {
        desc: 'Analisi e sintetizza AI e può rispondere alle tue domande in base al contenuto PDF.',
        title: 'Chat pdf',
      },
      sub3: {
        desc: "Traduzione PDF basata sull'intelligenza artificiale per una maggiore precisione ed efficienza.",
        title: 'Precisione ed efficienza',
      },
      title: 'PDF Translate',
    },
    aiAssistant: {
      desc: 'Rendere la traduzione del web più intelligente e più conveniente',
      panel1: {
        desc: "Le traduzioni bilingue di riferimenti incrociate ti aiutano a confrontare il testo originale con la traduzione in modo da poter capire meglio e migliorare l'efficienza di lettura.",
        title: 'Traduzione bilingue',
      },
      panel2: {
        left: {
          desc: 'Durante la navigazione di una pagina Web, passa al mandato del contenuto che è necessario tradurre per vedere la traduzione.',
          title: 'Traduzione del mouse',
        },
        right: {
          desc: 'Durante la navigazione di una pagina Web, selezionare il testo che si desidera tradurre per vedere la traduzione.',
          title: 'Traduzione di selezione del testo',
        },
      },
      panel3: {
        one: {
          desc: "Traduci e sintetizza le pagine Web e i contenuti video sempre e ovunque, per un'esperienza più fluida!",
          title: 'Punto e clic',
        },
        three: {
          desc: "Il modello di linguaggio di grandi dimensioni Aiven garantisce l'accuratezza della traduzione e la coerenza per un'esperienza di lettura migliorata.",
          title: 'Ai-guidato',
        },
        two: {
          desc: 'Copertura completa multi-lingua, lettura bilingue senza barriere, traduzione con un clic-Esci la differenza!',
          title: 'Più completo e conveniente',
        },
      },
      panel4: {
        tab1: {
          desc: 'Il tuo esperto di efficienza - Usando Chatgpt, Noam traduce e riassume i contenuti video di YouTube per farti risparmiare tempo!',
          key: 'Video',
          title: 'Traduzione video e riepilogo',
        },
        tab2: {
          desc: 'Fai clic per tradurre le immagini durante la navigazione sul Web.',
          key: 'Immagine',
          title: 'Immagine traduci',
        },
        tab3: {
          desc: "Estrae istantaneamente le informazioni chiave dalle pagine Web e fornisce un riepilogo, migliorando l'efficienza di lettura.",
          key: 'Web',
          title: 'Web Translate & Riepilogo',
        },
        tag1: 'Semplice',
        tag2: 'Efficiente',
        tag3: 'Facile da capire',
      },
      title: 'Assistente ai',
    },
    getStart: 'Inizia',
    getStartedForFree: 'Inizia gratuitamente',
    howItWork: {
      desc: "Noam integra i modelli AI per fornire agli utenti un'esperienza intelligente per la traduzione e il riepilogo della pagina web incrociati, la traduzione PDF e l'immagine, la traduzione video e il riepilogo",
      title: 'Come funziona',
    },
    lastBlock: {
      desc: "Migliore traduzione di intelligenza artificiale per un'esperienza di lettura più fluida",
    },
    top: {
      desc: "Noam è un'estensione del browser AI gratuito che ti aiuta a tradurre e riassumere pagine Web, video e contenuti PDF. \\ N Unisciti a Noam e sperimenta la magia!",
      title1: 'Una magia',
      title2: 'Un clic',
    },
    userReviews: {
      desc: 'Recensioni a cinque stelle sul Web store di Chrome',
      title: 'Recensioni degli utenti',
    },
  },
  installTips: {
    btn: 'Provalo ora',
    text: 'Installa "Plug -in browser Noam" per sbloccare più funzionalità! Riferenze incrociate Web, riepilogo video web e altro ancora!',
  },
  locale: {
    af: 'afrikaans',
    am: 'Amarico',
    ar: 'arabo',
    as: 'Assamese',
    auto: 'Rilevamento automatico',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    bg: 'bulgaro',
    bn: 'Bangla',
    bo: 'tibetano',
    bs: 'Bosniaco',
    ca: 'catalano',
    cs: 'ceco',
    cy: 'gallese',
    da: 'danese',
    de: 'tedesco',
    dsb: 'Sorbiano inferiore',
    dv: 'Divehi',
    el: 'greco',
    en: 'Inglese',
    es: 'spagnolo',
    et: 'Estone',
    eu: 'Basco',
    fa: 'persiano',
    fi: 'finlandese',
    fil: 'Filippino',
    fj: 'Fijian',
    fr: 'francese',
    frCA: 'Francese (Canada)',
    ga: 'irlandese',
    gl: 'galiziano',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'ebraico',
    hi: 'hindi',
    hr: 'croato',
    hsb: 'Sorbian superiore',
    ht: 'Creolo haitiano',
    hu: 'ungherese',
    hy: 'armeno',
    id: 'indonesiano',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandese',
    it: 'Italiano',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latino)',
    ja: 'giapponese',
    ka: 'georgiano',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurdish (Northern)',
    kn: 'Kannada',
    ko: 'coreano',
    ku: 'Curdo (centrale)',
    ky: 'Kirgyz',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lituano',
    lug: 'Ganda',
    lv: 'lettone',
    lzh: 'Cinese (letterario)',
    mai: 'Maithili',
    mg: 'malgascio',
    mi: 'Maori',
    mk: 'macedone',
    ml: 'Malayalam',
    mnCyrl: 'Mongolo (cirillico)',
    mnMong: 'Mongolo (tradizionale)',
    mr: 'Marathi',
    ms: 'malese',
    mt: 'maltese',
    mww: 'Hmong Daw',
    my: 'Myanmar (birmano)',
    nb: 'norvegese',
    ne: 'Nepalese',
    nl: 'Olandese',
    noSearchLang: 'Lingua non supportata',
    nso: 'SESOTHO SA LEBOA',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Polacco',
    placeholder: 'Ricerca',
    prs: 'Dari',
    ps: 'Pashto',
    pt: 'Portoghese (Brasile)',
    ptPT: 'Portoghese (Portogallo)',
    ro: 'rumeno',
    ru: 'russo',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Singalese',
    sk: 'Slovacco',
    sl: 'sloveno',
    sm: 'Samoano',
    sn: 'Shona',
    so: 'Somalo',
    sq: 'albanese',
    srCyrl: 'Serbo (cirillico)',
    srLatn: 'Serbo (latino)',
    st: 'SESOTHO',
    sv: 'svedese',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    ti: 'Tigrya',
    tk: 'Turkmen',
    tlhLatn: 'Klingon (latino)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongano',
    tr: 'turco',
    tt: 'Tatar',
    ty: 'Tahitiano',
    ug: 'Uyghur',
    uk: 'ucraino',
    ur: 'Urdu',
    uz: 'Uzbek (latino)',
    vi: 'vietnamita',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Cantonese (tradizionale)',
    zh: 'cinese',
    zhHans: 'Cinese semplificato',
    zhHant: 'Tradizionale cinese',
    zu: 'Zulu',
  },
  login: {
    and: 'E',
    btn: 'Accedi con Google',
    btn1: 'Accedi ora',
    desc: 'Accedi per sbloccare più esperienze',
    policy: 'politica sulla riservatezza',
    terms: 'Termini di utilizzo',
    tips: 'Iscrivendoti, accetto il',
    title: 'Benvenuti a Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Seguire', twitter: 'Twitter' },
    legal: { policy: 'politica sulla riservatezza', terms: 'Termini di servizio', title: 'Legale' },
    product: {
      image: "Traduzione dell'immagine",
      pdf: 'Traduzione PDF',
      title: 'Prodotti',
      video: 'Traduzione video e riepilogo',
      web: 'Traduzione e riepilogo del web',
    },
    resources: { title: 'Risorse' },
  },
  pageHeader: {
    contact: 'Contatto',
    help: 'Aiuto',
    logout: 'Disconnettersi',
    logoutSucc: 'Disconnesso.',
    pricing: 'Prezzi',
    settings: 'Impostazioni',
    signUp: 'Login',
  },
  pdf: {
    autoDetect: 'Riletto automatica',
    cancelBtn: 'Cancellare',
    cancelUpload: 'Cancellare',
    chatPdf: {
      summaryError: 'Riepilogo fallito, riprova più tardi',
      chatError: 'Chat non è riuscito, riprova più tardi',
      Search: 'Ricerca',
      cancel: 'Cancellare',
      chatTitle: 'Benvenuti a Noam! Ecco un riepilogo del tuo PDF:',
      clear: 'Chiaro',
      clearContent:
        "Questo inizierà una nuova conversazione. L'attuale conversazione non sarà salvata. Sei sicuro?",
      clearDialogTitle: 'Si prega di confermare',
      clearSucc: 'Cancellato',
      copy: 'Copia',
      copySucc: 'Copiato',
      delete: 'Eliminare',
      deleteCancel: 'Cancellare',
      deleteContent: 'Questo PDF non può essere recuperato una volta eliminato. Sei sicuro?',
      deleteOk: 'Eliminare',
      deleteSuccess: 'Cancellato',
      deleteTitle: 'Conferma Elimina',
      expand: 'Crollo',
      historyTitle: 'Storia',
      listError:
        "Impossibile caricare l'elenco di lettura. Riprova più tardi o contatta l'assistenza.",
      loading: 'Caricamento...',
      noDataDesc: 'Nessuna storia ancora.',
      noMore: 'Niente più contenuti.',
      placeholder: 'Chiedi qualcosa su questo PDF',
      summaryTitle: 'Potresti essere interessato a queste domande:',
    },
    collectionEmpty: 'La tua collezione è vuota.',
    collectionTab: 'Note',
    dataLoading: 'Caricamento...',
    dialogContent: 'Sei sicuro di voler eliminare questo?',
    dialogTitle: 'Si prega di confermare',
    download: {
      both: 'Bilingue',
      btn: 'Salva',
      cancel:
        'La chiusura di questa finestra annullerà il download PDF. Sei sicuro di voler annullare?',
      desc: 'Salva sul dispositivo: scarica le traduzioni come immagini; Salva e stampa: scarica le traduzioni nel formato originale, con supporto per copia.',
      download: 'Scaricamento',
      downloadMsg1: 'PROVESS PROGRESSO DI TRADIZIONE PDF:',
      downloadMsg2: 'Tempo stimato rimanente:',
      downloadSucc: 'PDF è pronto! Fare clic per salvare.',
      downloadTip:
        'Download di PDF. Non chiudere questa finestra per evitare gli errori di download.',
      error: 'Impossibile salvare. Prova invece a stampare.',
      fileName: 'Nome file:',
      fileType: 'Tipo di file:',
      loading: 'Risparmio...',
      loading2: 'Caricamento...',
      minute: 'Minuti ...',
      msg1: 'Alcune traduzioni in questo documento sono più lunghe del testo originale e possono sovrapporsi nel file scaricato. Si consiglia di leggere sulla pagina web.',
      msg2: '1. La traduzione non è ancora completa. Si prega di scaricare dopo che la traduzione è terminata.',
      msg3: '2. Alcune traduzioni sono troppo lunghe. Leggi la pagina web per evitare la sovrapposizione del testo.',
      msg4: 'Sono in corso compiti di traduzione. Riprova dopo che sono stati completati.',
      progress:
        'Traduzione di PDF. Progress: {{Progress}}, tempo stimato rimanente: {{minuti}} minuti ...',
      save: 'Salva sul dispositivo',
      savePrint: 'Salva e stampa',
      success: 'Scaricato',
      trans: 'Solo traduzione',
      transContinue: 'Continua a tradurre',
    },
    downloadBtn: 'Scaricamento',
    dragTipModal: {
      black: 'spazio',
      leftKey: 'Pulsante sinistro',
      ok: 'Fatto',
      press: 'Premere',
      tips: 'per trascinare e visualizzare.',
    },
    entry: 'PDFS',
    fileErrMsg: "Lettura dei file non riuscita. Riprova più tardi o contatta l'assistenza.",
    fileMsg: 'Dimensione del file corrente:',
    fileMsg2: 'Carica un file inferiore a 300 MB.',
    freeBannerBtn: 'Aggiornamento',
    freeBannerTips: 'Limite di traduzioni PDF gratuite raggiunte',
    guide: {
      placeholder: 'Incolla qui il link PDF',
      start: 'Inizia a tradurre',
      text1: 'Lettura bilingue',
      text2:
        "Leggi bilingualmente per confrontare il testo originale e la traduzione fianco a fianco, migliorando l'efficienza.",
      text3: 'Chatta con il tuo PDF',
      text4:
        "Analisi e riepilogo basato sull'intelligenza artificiale. Fai domande sul tuo PDF e ottieni risposte istantanee.",
      text5: 'Accurato ed efficiente',
      text6:
        "Traduzione PDF basata sull'intelligenza artificiale per una maggiore precisione ed efficienza.",
      title1: 'Traduzione bilingue',
      title2: 'Chatta con il tuo PDF',
      toast: 'Inserisci un link PDF online valido che termina in .pdf',
    },
    hasTransTask: "È in corso un'attività di traduzione. Attendere prego.",
    hoverTrans: 'Paragrafi da hover-to-translati',
    initial: 'Traduci pdf',
    installText: 'La tua soluzione unica per le traduzioni quotidiane.',
    installText2: "Si prega di installare prima l'estensione",
    installTips:
      "Installa l'estensione per traduzioni ancora più PDF e traduzioni di siti Web più fluidi:",
    kw: 'di base',
    link: 'collegamento',
    listError: "Impossibile caricare PDF. Riprova più tardi o contatta l'assistenza.",
    memoTotalCount: '1 articolo in totale',
    new: 'nuovo file',
    noId: 'ID file non trovato.',
    notPdf: 'Solo file PDF.',
    okBtn: 'Eliminare',
    okBtn2: 'Fatto',
    original: 'Originale',
    parallel: 'Parallelo',
    parseErrDialogContent:
      'Questo collegamento PDF non può essere tradotto direttamente. Si prega di scaricare il PDF e caricarlo per la traduzione.',
    parseErrTips: 'Impossibile analizzare il file',
    parseFileToast: "Impossibile analizzare il file. Riprova più tardi o contatta l'assistenza.",
    pdfLoading: 'Analisi ...',
    searchPlaceholder: 'Ricerca',
    selectTrans: 'Seleziona il testo da tradurre',
    shareBtn: 'Condividere',
    shareBtnText1: 'Generare collegamento azionario',
    shareBtnText2: 'Aggiorna il contenuto condiviso',
    shareModal: {
      cancel: 'Cancellare',
      copy: 'Copia',
      copySucc: 'Link copiato',
      custom: 'Personalizza:',
      errMsg:
        'Il contenuto selezionato non è stato ancora completamente tradotto. Si prega di tradurre prima della condivisione.',
      gen: 'Condividere',
      modify: 'Modificare',
      notSupport: 'Questa lingua non è attualmente supportata.',
      page: 'Pagine',
      pageNuErr: 'Immettere un numero di pagina valido.',
      range: 'Ambito:',
      remain: 'rimanente',
      remain1: 'Tempo stimato totale:',
      search: 'Ricerca',
      settings: 'Condividi le impostazioni:',
      shareSetting: 'Condividi le impostazioni',
      shareText: 'Condividere',
      shareTips: 'Condividi il link PDF tradotto con i tuoi amici:',
      sourceLang: 'Da:',
      target: 'A:',
      text1: 'Condividi le impostazioni',
      text2: 'Generare collegamento azionario',
      totalPage: '1 pagina totale',
      trans: 'Tradurre',
      translateTips:
        "Suggerimento: se la traduzione richiede un po 'di tempo, puoi chiudere questa finestra e condividere in seguito.",
      translating: 'Tradurre pdf ...',
      update: 'Aggiorna il link a condivisione',
    },
    shareMsg1: 'Condividi il link generato.',
    shareMsg2: 'Contenuti condivisi aggiornati.',
    shareMsg3:
      "Impossibile generare collegamento azionario. Riprova più tardi o contatta l'assistenza.",
    shareMsg4:
      "Impossibile aggiornare il contenuto condiviso. Riprova più tardi o contatta l'assistenza.",
    shareTips: 'La tua traduzione PDF è pronta! Condividilo con i tuoi amici.',
    start: 'Inizia la tua prova gratuita',
    thumbnail: 'Sommario',
    toast1: 'Caricamento PDF. Per favore riprova più tardi.',
    toast2:
      'Il contenuto di PDF è troppo grande. La finestra di dialogo non è attualmente non supportata.',
    toast3: 'Download. Riprova dopo il termine del download.',
    toolbar: { Adjust: 'Regolare', autoFit: 'Auto-FIT' },
    trans: 'Traduzione',
    transSucc: 'Traduzione di successo!',
    unLoginToast: 'Accedi per utilizzare questa funzione.',
    uploadBtn: 'Caricamento',
    uploadErr: "Caricamento del file non riuscito. Riprova più tardi o contatta l'assistenza.",
    uploadErrSignUrl:
      "Impossibile ottenere URL di caricamento. Riprova più tardi o contatta l'assistenza.",
    uploadMsg1: 'Trascina e lascia cadere il file qui.',
    uploadMsg2: 'Tipi di file supportati: PDF |  Dimensione massima del file: 100 MB',
    uploadMsg3: 'Fare clic per selezionare o trascinare i file qui.',
    uploading: 'Caricamento',
    uploadingMsg: 'Caricamento. Attendere prego.',
    uploadingMsg1: 'Il file sta caricando. Per favore riprova più tardi.',
    uploadingMsg2: 'Un file è attualmente in fase di convertito. Per favore riprova più tardi.',
    uploadingMsg3:
      "Richiesta di avanzamento di conversione non riuscita. Riprova più tardi o contatta l'assistenza.",
    uploadingMsg4:
      'Noam non può accedere ai file locali. Si prega di caricare manualmente un PDF per la traduzione.',
    uploadingMsg5: "Impossibile recuperare URL di file. Riprova più tardi o contatta l'assistenza.",
    video: 'video',
    vipBannerBtn: 'Aggiornamento',
    vipBannerTips: 'Hai raggiunto il limite di traduzione PDF per questo mese.',
    webTrans: 'Riferimento incrociato completo',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Sì, Noam offre un piano gratuito che puoi usare subito.',
      a2: 'Offriamo tre piani di abbonamento: Pro, Pro+e Enterprise. Puoi scegliere di pagare mensilmente o ogni anno. La fatturazione annuale offre risparmi significativi.',
      a3: "Tutte le funzionalità sono disponibili su entrambi i piani. La differenza principale è il costo: gli abbonamenti annuali costano meno nel complesso. Gli abbonamenti mensili vengono fatturati ogni mese, mentre i piani annuali vengono fatturati una volta all'anno.",
      a4: "Puoi annullare l'abbonamento in qualsiasi momento nelle impostazioni dell'account.",
      q1: "C'è un piano gratuito?",
      q2: 'Quali piani di prezzo e opzioni di pagamento sono disponibili?',
      q3: 'Qual è la differenza tra abbonarsi mensilmente contro ogni anno?',
      q4: 'Come annulla il mio abbonamento?',
    },
    around: 'In giro',
    billedMonthly: 'Fatturato mensilmente',
    billedYearly: 'Fatturato ogni anno',
    day: 'giorno',
    desc: 'Confronta i piani e scegli il meglio',
    detailedCompare: 'Confronta i piani',
    discount: '20% di sconto',
    enterprise: {
      benefit1: 'Assistenza clienti prioritaria',
      benefit2: 'Più utenti',
      benefit3: 'Soluzioni su misura',
      benefit5: 'Account Manager dedicato',
      btn: 'Contattaci',
      recommendText: 'Piani personalizzati （≥3 人）',
      talkToUs: 'Mettiti in contatto',
    },
    free: {
      benefit1: 'Traduzione istantanea 100 volte al giorno',
      benefit2: 'Traduzione e riepilogo del sito web',
      benefit3: '30 traduzioni di immagini',
      benefit4: 'Traduzione video e riepilogo',
      benefit5: '10 traduzioni PDF',
      benefit6: '5.000 token',
      btn: 'Provalo libero',
      recommendText: 'Prova gratuita',
    },
    imageTranslation: "Traduzione dell'immagine",
    month: 'mese',
    monthly: 'Mensile',
    pageTitle: 'Prezzi',
    pdf: {
      chat: 'Query di chat PDF',
      maxPage: 'Max. Pagine per pdf',
      translation: 'Traduzione PDF',
    },
    preMonth: 'Al mese',
    pro: {
      benefit1: 'Assistenza clienti prioritaria',
      benefit2: '1.000.000 di token/mese',
      benefit3: 'Traduzione e riepilogo del sito web',
      benefit4: '200 traduzioni di immagini',
      benefit5: 'Traduzione video e riepilogo',
      benefit6: '200 traduzioni PDF',
      btn: 'Inizia',
      recommendText: 'Più popolare',
    },
    proPlus: {
      benefit1: 'Assistenza clienti prioritaria',
      benefit2: '3.000.000 token/mese',
      benefit3: 'Traduzione e riepilogo del sito web',
      benefit4: '500 traduzioni di immagini',
      benefit5: 'Traduzione video e riepilogo',
      benefit6: '500 traduzioni PDF',
      recommendText: 'Miglior valore',
    },
    video: { summary: 'Riepilogo video', translation: 'Traduzione video' },
    web: {
      contrastiveTranslation: 'Traduzione bilingue',
      dictionaryTranslation: 'Traduzione di selezione',
      hoverTranslation: 'Traduzione del mouse',
      instantTranslation: 'Traduzione istantanea',
      summary: 'Riepilogo della pagina Web',
    },
    yearly: 'Annuale',
  },
  privacy: {
    block1:
      'Noam ("Noam", "noi", "nostro," o "noi") rispetta la tua privacy e si impegna a proteggere qualsiasi informazione che otteniamo da te o su di te. Questa politica sulla privacy descrive le nostre pratiche in merito alla raccolta o all\'uso di informazioni personali dall\'uso del nostro sito Web, applicazioni e servizi (collettivamente, i "servizi"). La presente Informativa sulla privacy non si applica ai contenuti che gestiamo per conto dei nostri clienti di prodotti aziendali, come la nostra API. L\'uso di questi dati è soggetto al nostro accordo con i clienti, che copre l\'accesso e l\'uso di questi prodotti.',
    block10:
      "Implementiamo misure tecniche, amministrative e organizzative commercialmente ragionevoli per proteggere le informazioni personali online e offline da perdite, abusi e accesso non autorizzato, divulgazione, alterazione o atti ostili. Tuttavia, nessuna trasmissione Internet o e -mail può garantire una sicurezza o una precisione completa. In particolare, le e -mail inviate a noi o da noi potrebbero non essere sicure. Quindi, dovresti stare particolarmente attento quando decidi quali informazioni inviarci tramite il servizio o l'e -mail. Inoltre, non siamo responsabili per qualsiasi ostruzione di impostazioni di privacy o misure di sicurezza nel servizio o nelle impostazioni di sicurezza dei siti Web di terze parti o misure di sicurezza. Conserveremo le tue informazioni personali per tutto il tempo necessario per farti fornire servizi o per altri scopi commerciali legittimi, come la risoluzione delle controversie, i motivi di sicurezza e la sicurezza o il rispetto dei nostri obblighi legali. Il periodo di tempo manteniamo le informazioni personali dipenderà da molti fattori, come la quantità, la natura, la sensibilità delle informazioni, i potenziali rischi di uso o divulgazione non autorizzati, gli scopi per i quali elaboriamo le informazioni e altre considerazioni legalmente vincolanti.",
    block11:
      'Di tanto in tanto potremmo aggiornare questa politica sulla privacy. Quando lo facciamo, pubblicheremo una versione aggiornata in questa pagina, se non diversamente richiesto dalla legge applicabile.',
    block12:
      'Se hai domande senza risposta o preoccupazioni sulla presente Informativa sulla privacy, contattare il nostro servizio clienti. E-mail:',
    block2:
      'Raccogliamo le seguenti informazioni personali ("informazioni personali") relative a te:',
    block3:
      'Informazioni fornite da te: quando crei un account per utilizzare i nostri servizi o comunicare con noi, raccogliamo le informazioni personali come segue:',
    block4:
      'Informazioni personali ricevute automaticamente dall\'uso dei servizi: quando accedi, utilizzo o interagisci con i Servizi, riceviamo le seguenti informazioni su accesso, utilizzo o interazione ("Informazioni tecniche"):',
    block5: 'Potremmo utilizzare le informazioni personali per i seguenti scopi:',
    block6:
      "Informazioni di riepilogo o de-identificate: possiamo aggregare o disaidentificare le informazioni personali per prevenirne il riutilizzo a fini di identificazione e utilizzare tali informazioni per analizzare l'efficacia dei nostri servizi, migliorare e aggiungere funzionalità ai nostri servizi, condurre ricerche e per scopi simili. Inoltre, possiamo analizzare il comportamento generale e le caratteristiche dei nostri utenti del servizio e condividere informazioni aggregate con terzi, pubblicare tali informazioni aggregate o renderle generalmente disponibili. Potremmo raccogliere informazioni aggregate attraverso i servizi, attraverso i cookie e attraverso altri mezzi descritti nella presente Informativa sulla privacy. Manteneremo e utilizzeremo informazioni de-identificate in una forma anonima o de-identificata e non tenteremo di re-identificare tali informazioni se non richiesto dalla legge.",
    block7:
      'In alcuni casi, potremmo fornire le tue informazioni personali a terzi senza ulteriori preavviso, a meno che non sia richiesto dalla legge:',
    block8:
      'A seconda della posizione geografica, le persone nella zona economica europea, il Regno Unito e a livello globale possono avere alcuni diritti legali in merito alle loro informazioni personali. Ad esempio, potresti avere il diritto di:',
    block9:
      'Il servizio può contenere collegamenti ad altri siti Web non gestiti o controllati da NOAM, inclusi i servizi di social media ("siti Web di terze parti"). Le informazioni che condividi con siti Web di terze parti saranno regolate dalle politiche specifiche sulla privacy e dalle condizioni di servizio dei siti Web di terzi, non dalla presente Informativa sulla privacy. Forniamo questi collegamenti per comodità e non impliciamo la nostra approvazione o la nostra revisione di questi siti Web. Si prega di contattare direttamente i siti Web di terze parti per informazioni sulle loro politiche e termini sulla privacy.',
    subBlock1:
      "Informazioni sull'account: quando crei un account sulla nostra piattaforma, raccogliamo informazioni relative al tuo account, incluso il tuo nome, informazioni di contatto, credenziali dell'account, informazioni sulla carta di pagamento e cronologia delle transazioni (\"Informazioni sull'account\").",
    subBlock10: 'Fornire, gestire, mantenere e/o analizzare i servizi;',
    subBlock11: 'Comunicare con te;',
    subBlock12: 'Sviluppare nuovi prodotti e servizi;',
    subBlock13:
      'Prevenire frodi, attività criminali o uso improprio dei nostri servizi e protezione della sicurezza dei nostri sistemi IT, architettura e reti;',
    subBlock14: 'Condurre trasferimenti di attività;',
    subBlock15:
      'Rispettare gli obblighi legali e i procedimenti legali, proteggere i nostri diritti, privacy, sicurezza o proprietà, nonché quelli delle nostre affiliate, tu o altre terze parti.',
    subBlock16:
      'Fornitori e fornitori di servizi: per aiutarci a soddisfare le esigenze operative aziendali ed eseguire determinati servizi e funzioni, possiamo fornire informazioni personali a fornitori e fornitori di servizi, tra cui fornitori di servizi di hosting, fornitori di servizi cloud e altri fornitori di servizi di informatica, software di comunicazione e -mail e fornitori di servizi di analisi web, ecc.',
    subBlock17:
      'Trasferimenti aziendali: se impegniamo in transazioni strategiche, riorganizzazioni, fallimenti, acquisizioni di fallimento o transizione di servizi a un altro fornitore (collettivamente, "transazioni"), le informazioni personali e altre informazioni possono essere condivise con le controparti delle transazioni e le altre parti che assistono nelle transazioni durante la dovuta diligenza e trasferite come parte delle transazioni insieme a altre attività di affari.',
    subBlock18:
      'Requisiti legali: potremmo condividere le tue informazioni personali con le autorità governative, i colleghi del settore o altre terze parti, comprese le informazioni di interazione con i nostri servizi, (1) se richiesto dalla legge o se riteniamo che tale azione sia necessaria per conformarsi agli obblighi legali; (2) proteggere e difendere i nostri diritti o proprietà; (3) se determiniamo unilateralmente che esiste una violazione dei nostri termini, politiche o leggi; (4) rilevare o prevenire frodi o altre attività illegali; (5) proteggere i nostri prodotti, dipendenti o utenti o sicurezza pubblica, sicurezza, integrità; o (vi) di difendersi dalla responsabilità legale.',
    subBlock19: 'Accedi alle tue informazioni personali e informazioni su come vengono elaborate.',
    subBlock2:
      'Contenuto dell\'utente: quando si utilizzano i nostri servizi, raccogliamo informazioni personali dai tuoi input, caricamenti di file o feedback forniti a noi ("Contenuto").',
    subBlock20: 'Richiedi la cancellazione delle informazioni personali dai nostri record.',
    subBlock21: 'Correggi o aggiorna le tue informazioni personali.',
    subBlock22: 'Trasferisci le tue informazioni personali a terzi (portabilità dei dati).',
    subBlock23: 'Limita il modo in cui elaboriamo le tue informazioni personali.',
    subBlock24:
      "Preleva il tuo consenso: dove facciamo affidamento sul consenso come base giuridica per l'elaborazione, è possibile ritirare il consenso in qualsiasi momento.",
    subBlock25: 'Oppuremo a come elaboriamo le tue informazioni personali.',
    subBlock26: 'Migliorare i nostri servizi e condurre ricerche;',
    subBlock3:
      'Informazioni sulla comunicazione: se ci invii messaggi, raccoglieremo il tuo nome, le informazioni di contatto e il contenuto dei tuoi messaggi ("Informazioni sulla comunicazione").',
    subBlock4:
      'Informazioni sui social media: abbiamo pagine su siti Web di social media come Instagram, Facebook, Medium, Twitter, YouTube e LinkedIn. Quando interagisci con le nostre pagine di social media, raccogliamo le informazioni personali che scegli di fornirci, come i tuoi dettagli di contatto ("Informazioni sociali"). Inoltre, le aziende che ospitano le nostre pagine di social media possono fornirci informazioni e analisi aggregate sulle nostre attività sui social media.',
    subBlock5:
      "Registra dati: informazioni inviate automaticamente dal browser quando si utilizzano i nostri servizi. I dati di registro includono l'indirizzo IP (Internet Protocol), il tipo di browser e le impostazioni, la data e l'ora delle richieste e il modo in cui interagisci con il nostro sito Web.",
    subBlock6:
      "Dati di utilizzo: possiamo raccogliere automaticamente informazioni sull'uso del servizio, come i tipi di contenuti con cui visualizzi o interagisci, le funzionalità utilizzate, le azioni che intraprendi, nonché il fuso orario, il paese, la data e il tempo di accesso, l'agente utente e la versione, il tipo di computer o il dispositivo mobile e la connessione del computer.",
    subBlock7:
      "Informazioni sul dispositivo: questo include il nome del dispositivo, il sistema operativo, l'identificatore del dispositivo e il browser che si sta utilizzando. Le informazioni raccolte possono dipendere dal tipo di dispositivo che si sta utilizzando e dalle sue impostazioni.",
    subBlock8:
      'Cookie: utilizziamo i cookie per gestire e gestire i nostri servizi e migliorare la tua esperienza. Un "cookie" è informazioni inviate da un sito Web che visiti al tuo browser. Puoi impostare il browser per accettare tutti i cookie, rifiutare tutti i cookie o avvisarti quando viene fornito un cookie in modo da poter decidere se accettarlo ogni volta. Tuttavia, il rifiuto dei cookie può impedirti di utilizzare o influire negativamente sulla visualizzazione o la funzionalità di alcune aree o caratteristiche del sito Web. Per ulteriori informazioni sui cookie, visitare tutti sui cookie.',
    subBlock9:
      'Analisi: possiamo utilizzare vari prodotti di analisi online che utilizzano i cookie per aiutarci ad analizzare il modo in cui gli utenti utilizzano i nostri servizi e migliorano la tua esperienza quando utilizzano i servizi.',
    subTitle1: 'Informazioni personali che raccogliamo',
    subTitle2: 'Come utilizziamo le informazioni personali',
    subTitle3: 'Divulgazione di informazioni personali',
    subTitle4: 'I tuoi diritti',
    subTitle5: 'Collegamenti ad altri siti Web',
    subTitle6: 'Sicurezza e conservazione delle informazioni',
    subTitle7: 'Modifica della politica sulla privacy',
    subTitle8: 'Come contattarci',
    title: 'politica sulla riservatezza',
    updateTime: 'Aggiornato: 15 marzo 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Accetta tutto',
    text: 'Per migliorare la tua esperienza, utilizziamo i cookie per personalizzare contenuti e servizi.',
    title: 'Questo sito Web utilizza i cookie',
  },
  slogen: "L'assistente AI più semplice",
  stripe: {
    contact: 'Contattaci a contact@noam.tools per qualsiasi domanda!',
    fail: {
      btn: 'Riprovare',
      text1: 'Non abbiamo potuto elaborare il tuo pagamento.',
      text2: 'Controlla la tua connessione e riprova.',
      title: 'Pagamento non riuscito',
    },
    succ: {
      btn: 'Andiamo',
      text1: 'Benvenuti a Noam,',
      text2: 'Iniziamo!',
      title: 'Congratulazioni',
    },
  },
  terms: {
    block1: 'Grazie per aver usato Noam!',
    block10:
      '(a) Commissioni e fatturazione. Pagherai tutte le commissioni ("commissioni") in base ai prezzi e ai termini nella pagina dei prezzi applicabili o come concordato per iscritto tra noi. Ci riserviamo il diritto di correggere errori o errori dei prezzi anche se abbiamo emesso fatture o ricevuti pagamenti. Fornirai informazioni complete e accurate di fatturazione, incluso un metodo di pagamento valido e autorizzato. Addebiteremo il tuo metodo di pagamento su base periodica come concordato, ma potremmo ragionevolmente cambiare la data di fatturazione. Autorizzi Noam, i suoi affiliati e i processori di pagamento di terze parti per addebitare il tuo metodo di pagamento. Se il pagamento fallisce, ti forniremo un preavviso scritto e potrebbe sospendere l\'accesso al servizio fino a quando non viene ricevuto il pagamento. Salvo quanto diversamente specificato nel presente Accordo, i pagamenti non sono rimborsabili.',
    block11:
      '(b) Tasse. Salvo diversa indicazione, le commissioni non includono tasse federali, statali, locali e esteri, doveri e valutazioni simili ("tasse"). Sei responsabile di tutte le tasse associate ai tuoi acquisti, esclusi le tasse in base al nostro reddito netto per le quali ti facciamo la fatturazione. L\'utente accetta di pagare prontamente tali tasse e di fornirci dichiarazioni di privacy legalmente valide e ottenere i consensi necessari per gestire tali dati e ci si garantisce di gestire tali dati in conformità con la legge applicabile.',
    block12:
      '(c) Cambiamenti dei prezzi. Potremmo cambiare i nostri prezzi avvisandoti tramite il tuo account e/o il nostro sito Web. Gli aumenti dei prezzi saranno effettuati immediatamente dopo la pubblicazione. Eventuali modifiche al prezzo si applicheranno alle commissioni per il tuo account dopo la data di entrata in vigore della modifica.',
    block13:
      '(d) controversie e pagamenti scaduti. Se si desidera contestare commissioni o tasse, contattare contact@noam.tools entro trenta (30) giorni dalla data della fattura.',
    block14:
      "(e) Utilizzo gratuito. Non è possibile creare più account per ottenere crediti gratuiti. Se determiniamo che non stai utilizzando i crediti gratuiti in buona fede, potremmo addebitare le commissioni standard o interrompere l'accesso al servizio.",
    block15:
      "(a) Riservato. Potresti avere accesso a informazioni riservate di Noam. È possibile utilizzare le informazioni riservate solo come consentito ai sensi dei presenti Termini allo scopo di utilizzare il Servizio. Non puoi divulgare informazioni riservate a terzi e proteggerai le informazioni riservate in modo non meno protettivo di quanto tu protegga le tue informazioni riservate simili, almeno con ragionevole cure. Le informazioni riservate sono informazioni non pubbliche designate come riservate da Noam o che dovrebbero essere ragionevolmente trattate come riservate in tali circostanze, tra cui software, specifiche e altre informazioni commerciali non pubbliche. Le informazioni riservate non includono informazioni che: (1) diventano generalmente disponibili al pubblico senza colpa da parte tua; (2) avevi in ​​tuo possesso senza alcun obbligo di riservatezza prima di riceverlo ai sensi di questi Termini; (3) è legalmente divulgato da una terza parte senza alcun obbligo di riservatezza; o (4) è sviluppato in modo indipendente da te senza utilizzare le informazioni riservate. È possibile divulgare informazioni riservate se richiesto per legge, tribunale o altro ordine governativo, ma è necessario fornire preavviso scritto a Noam in modo ragionevole e, nella misura possibile, fare ragionevoli sforzi per limitare la portata della divulgazione, incluso l'assistenza nelle richieste di divulgazione opposta.",
    block16:
      "(b) Sicurezza. È necessario implementare misure ragionevoli e appropriate per aiutare a salvaguardare l'accesso e l'uso del servizio. Se si scoprono vulnerabilità o violazioni relative all'utilizzo del servizio, è necessario contattare prontamente Noam e fornire dettagli sulla vulnerabilità o sulla violazione.",
    block17:
      "((c) Elaborazione dei dati personali. Se si utilizza il servizio per elaborare i dati personali, è necessario fornire dichiarazioni di privacy legalmente sufficienti e ottenere i consensi necessari per l'elaborazione di tali dati e ci si garantisce che si gestiscono tali dati in conformità con la legge applicabile.",
    block18:
      "(a) terminazione; Sospensione. Questi termini saranno efficaci dal tuo primo utilizzo del servizio e rimarranno in vigore fino al termine. È possibile interrompere questi termini in qualsiasi momento per qualsiasi motivo interrompendo l'uso del servizio e del contenuto. Possiamo interrompere questi termini con preavviso per qualsiasi motivo. Possiamo immediatamente interrompere questi termini avvisandoti se si viola materialmente la Sezione 2 (requisiti di utilizzo), la sezione 5 (riservatezza, sicurezza e protezione dei dati), la sezione 8 (risoluzione delle controversie) o la sezione 9 (termini generali) o se il nostro rapporto con un fornitore tecnologico di terze parti oltre le nostre modifiche di controllo o per la conformità o le richieste del governo. Potremmo sospendere l'accesso al servizio se non si rispetta i presenti Termini o se il tuo utilizzo rappresenta un rischio per la sicurezza per noi o di terze parti o se sospettiamo che il tuo uso sia fraudolento o che possiamo esporre a noi o a terzi alla responsabilità.",
    block19:
      '(b) Effetti della risoluzione. Al momento della risoluzione, cesserai utilizzando il servizio e restituire o distruggere prontamente qualsiasi informazione riservata come indicato da noi. Le disposizioni di questi termini che per loro natura dovrebbero sopravvivere alla risoluzione o alla scadenza, inclusi ma non limitati alle sezioni 3 e 5-9, sopravvivono.',
    block2:
      "Le presenti Condizioni d'uso si applicano all'utilizzo dei servizi di Noam, comprese le nostre interfacce di programmazione delle applicazioni, software, strumenti, servizi per sviluppatori, dati, documentazione e sito Web (indicati collettivamente come \"Servizi\"). Utilizzando i nostri servizi, l'utente accetta di rispettare questi termini. La nostra politica sulla privacy spiega come raccogliamo e utilizziamo le informazioni personali.",
    block20:
      "(a) Indennizzo. Difenderai, indennizzerai e trattiene noi, i nostri affiliati e il nostro personale innocuo da e contro qualsiasi reclamo, perdite e spese (comprese le spese legali) derivanti da o relative all'utilizzo del servizio, compresi i contenuti, l'utilizzo di prodotti o servizi relativi al servizio e la violazione delle questi termini o la legge applicabile.",
    block21:
      '(b) Disclaimer. Il servizio è fornito "così com\'è". Nella misura consentita dalla legge, noi e i nostri affiliati e licenziatari non rilasciano dichiarazioni o garanzie di alcun tipo in merito al servizio e non facciamo alcuna garanzia, inclusi ma non limitati a garanzie di commerciabilità, idoneità per uno scopo particolare, qualità soddisfacente, non violazione e divertimento silenzioso e alcuna garanzia che si manifesta in campo o utilizzo del commercio. Non garantiamo che il servizio sarà ininterrotto, accurato o privo di errori o che qualsiasi contenuto sarà sicuro, non perduto o non modificato.',
    block22:
      '(c) Limitazione della responsabilità. Né noi, i nostri affiliati, né i nostri licenziatari saremo responsabili per eventuali danni indiretti, accidentali, speciali, consequenziali o punitivi, compresa la perdita di profitti, la perdita di reputazione, la perdita di utilizzo, la perdita di dati o altre perdite immateriali, anche se ci è stato consigliato la possibilità di tali danni. Secondo questi termini, la nostra responsabilità totale non supererà le commissioni pagate al servizio nei dodici (12) mesi precedenti il ​​reclamo o cento dollari USA ($ 100), a seconda di quale sia maggiore. Le limitazioni in questa sezione si applicano nella misura massima consentita dalla legge applicabile.',
    block23:
      "I termini e l'uso dei servizi sono regolati dalle leggi degli Stati Uniti, ad eccezione delle regole del conflitto di leggi degli Stati Uniti. L'uso della domanda può anche essere soggetto ad altre leggi locali, statali, nazionali o internazionali. Qualsiasi azione legale o procedimento relativo a questi Termini sarà portata esclusivamente nei tribunali degli Stati Uniti e l'utente accetta di sottoporsi alla giurisdizione personale di tali tribunali.",
    block24:
      "(a) Relazione delle parti. Noam e tu sei appaltatori indipendenti, e nessuna delle parti ha il potere di rappresentare o vincolare l'altra parte o assumere obblighi per l'altra parte senza il previo consenso scritto dell'altra parte.",
    block25:
      '(b) Utilizzo del marchio. Non è possibile utilizzare il nome, i loghi o i marchi di Noam o nessuno dei suoi affiliati senza il nostro previo consenso scritto.',
    block26:
      'Se una disposizione di questi termini è determinata come non valida, illegale o inapplicabile, le disposizioni rimanenti rimarranno in vigore e effetto.',
    block3:
      "Se hai meno di 18 anni, devi ottenere il permesso ai tuoi genitori o tutori legali di utilizzare il servizio. Se si utilizza il servizio per conto di un'altra persona o entità, devi essere autorizzato ad accettare questi termini per loro conto. È necessario fornire informazioni accurate e complete per registrarsi per un account. Potresti non fornire le credenziali di accesso o l'account a chiunque al di fuori della tua organizzazione e sei responsabile di tutte le attività condotte utilizzando le tue credenziali.",
    block4:
      '(a) Uso del servizio. Puoi accedere al servizio secondo questi termini e ti concediamo un diritto non esclusivo di utilizzare il servizio. Quando si utilizza il Servizio, si rispetterai questi Termini e tutte le leggi applicabili. Noi e le nostre affiliate possediamo tutti i diritti, titoli e interessi nel servizio.',
    block5:
      '(b) Feedback. Diamo il benvenuto a feedback, commenti, idee, suggerimenti e miglioramenti. Se fornisci tali contenuti, potremmo usarlo senza alcuna restrizione e senza compensazione per te.',
    block6:
      "(c) Restrizioni. Non è possibile: (1) utilizzare il servizio in un modo che viola, appropriazione indebita o viola qualsiasi diritti di altri; ; (3) utilizzare l'output del servizio per sviluppare modelli in competizione con NOAM; ; (5) rappresentano l'output del servizio come generato artificialmente quando non è, o in altro modo viola le nostre politiche di utilizzo; (6) tasti API di acquisto, vendita o trasferimento senza il nostro previo consenso; o (7) fornirci qualsiasi informazione personale di bambini di età inferiore a 13 anni o età applicabile al consenso digitale. Rispetterai a tutti i limiti di tariffa e altri requisiti nella nostra documentazione.",
    block7:
      '(d) servizi di terze parti. Qualsiasi software, servizi o altri prodotti di terze parti associati al servizio è regolato dalle proprie condizioni e non siamo responsabili per i prodotti di terze parti.',
    block8:
      '(a) i tuoi contenuti. È possibile fornire input ("input") al servizio e ricevere output ("output") generato e restituito in base all\'ingresso (indicato collettivamente come "contenuto"). Tra le parti e nella misura consentita dalla legge applicabile, possiedi tutti gli input. Noam può utilizzare i contenuti per fornire e mantenere il servizio, rispettare le leggi applicabili e far rispettare le nostre politiche. Sei responsabile del contenuto, incluso garantire che non violi le leggi applicabili o questi Termini.',
    block9:
      "(b) precisione. L'intelligenza artificiale e l'apprendimento automatico sono campi di ricerca in rapida evoluzione. Ci impegniamo continuamente a migliorare il nostro servizio per renderlo più accurato, affidabile, sicuro e benefico. Data la natura probabilistica dell'apprendimento automatico, l'uso del nostro servizio può comportare risultati errati in alcuni casi, che potrebbero non riflettere accuratamente individui, luoghi o fatti reali. È necessario valutare in modo appropriato l'accuratezza di qualsiasi output in base al caso d'uso, anche esaminando manualmente gli output.",
    subTitle1: 'Registrazione e accesso',
    subTitle10: 'Severabilità',
    subTitle2: 'Requisiti di utilizzo',
    subTitle3: 'Contenuto',
    subTitle4: 'Commissioni e pagamenti',
    subTitle5: 'Riservatezza, sicurezza e protezione dei dati',
    subTitle6: 'Termine e risoluzione',
    subTitle7: 'Indennizzo; Disclaimer; Limitazione della responsabilità',
    subTitle8: 'Diritto e giurisdizione governanti',
    subTitle9: 'Termini generali',
    title: 'Termini di servizio',
    updateTime: 'Aggiornato: 15 marzo 2024',
  },
  trialModal: {
    btn: 'Inizia ora （solo $ 0,99）',
    chat: 'Chatpdf',
    desc1: 'Dopo il processo, ti verrà addebitata una commissione annuale di $ 180',
    desc2: 'Annulla in qualsiasi momento',
    focus: 'Concentrarsi',
    hover: 'Traduzione del mouse',
    image: "Traduzione dell'immagine",
    installBtn: 'Installa ora per ricevere il tuo regalo',
    memo: 'Gestione dei preferiti',
    pdf: 'Traduzione parallela PDF',
    realtime: 'Traduzione istantanea',
    select: 'Traduzione della selezione delle parole',
    title: 'Regalo di benvenuto',
    trialText: 'Prova di 7 giorni',
    video: 'Traduzione video',
    videoSummary: 'Riepilogo video',
    web: 'Traduzione parallela',
    webSummary: 'Riepilogo web',
  },
  upgradeModal: {
    btn: 'Aggiornamento ora',
    chat: {
      free: 'Hai raggiunto il limite di chatpdf',
      pro: 'Hai raggiunto il limite di chatpdf per questo mese',
    },
    contactStr: 'Si prega di attendere il ripristino successivo o contattaci a contact@noam.tools.',
    limitAlertStr: 'Hai raggiunto il limite di piano gratuito. Aggiornamento per continuare.',
    more: 'Visualizza i piani',
    pageLimit: { free: 'Hai raggiunto il limite della pagina PDF' },
    title: 'Promemoria',
    upgradeAlertStr: 'Hai raggiunto il limite di piano gratuito. Aggiornamento per continuare.',
    upload: {
      free: 'Hai raggiunto il tuo limite di traduzione PDF',
      pro: 'Hai raggiunto il limite di traduzione PDF per questo mese',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Unisciti ora',
      label: 'Vantaggi dei nuovi utenti:',
      remain: 'Solo 30 rimasti',
      text: 'Solo 100 punti disponibili! Prendi il tuo adesso!',
    },
  },
}

export default TRANSLATION
