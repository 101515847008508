const TRANSLATION = {
  buyModal: {
    afterFree: '免費試用後',
    btn: '免費嘗試',
    freeTrial: '3天免費試用',
    monthLabel: '月',
    selectLabel: '選擇一個計劃',
    title: '訂閱計劃',
    yearLabel: '年',
  },
  contact: {
    block1: '有關自定義，問題或建議，請通過contact@noam.tools與我們聯繫。我們會迅速做出回應。',
    block2: '通過★★★★★在Google Apps擴展商店中對我們進行評分，以支持我們。',
    block3: '通過分享此擴展名來幫助他人：',
    title: '聯繫我們',
  },
  help: {
    AquickOverviewOfNoam: 'NOAM的快速概述',
    ChatPDF: '聊天pdf',
    ChatPDF2: 'chatpdf',
    FavoritesManagement: '最愛管理',
    Fixedplugin: '固定插件',
    Focusing: '專注',
    Free: '自由的',
    FreeTrialBenefits: '免費試用福利',
    GoWithWikipedia: '和維基百科一起去',
    GoWithYouTube: '與YouTube一起去',
    HoverTranslation: '懸停翻譯',
    Howtouse: '如何使用',
    ImageTranslation: '圖像翻譯',
    InstantTranslation: '即時翻譯',
    Membership: '會員資格',
    NoamHelp: 'NOAM可以幫助您翻譯和總結',
    PDFparallelTranslation: 'PDF平行翻譯',
    ParallelTranslation: '平行翻譯',
    SkipAllSteps: '跳過所有步驟',
    Summary: '概括',
    Translation: '翻譯',
    TryItForFree: '免費嘗試',
    Video: '影片',
    VideoSummary: '視頻摘要',
    VideoTranslation: '視頻翻譯',
    WebSummary: '網絡摘要',
    WordSelectionTranslation: '單詞選擇翻譯',
    benefits: '我們有好處等待您！',
    dayFree: '3天免費試用',
    done: '完畢',
    next: '下一個',
    pin: 'PIN NOAM可更輕鬆訪問～～',
    planIsNotExist: '計劃不可用',
    startWiki: '讓我們從Wikimedia開始！',
    startYoutube: '讓我們從YouTube開始！',
    try: '嘗試一下',
    tryNow: '現在就嘗試一下！',
  },
  home: {
    PDFTranslate: {
      desc: '雙語翻譯 +聊天pdf',
      sub1: {
        desc: '雙語閱讀使您可以比較原始文本和並排翻譯，從而提高閱讀效率。',
        title: '雙語閱讀',
      },
      sub2: { desc: 'AI分析和總結，可以根據PDF內容回答您的問題。', title: '聊天pdf' },
      sub3: { desc: 'AI驅動的PDF翻譯可提高準確性和效率。', title: '準確性和效率' },
      title: 'PDF翻譯',
    },
    aiAssistant: {
      desc: '使網絡翻譯更聰明，更方便',
      panel1: {
        desc: '雙語交叉引用翻譯可幫助您將原始文本與翻譯進行比較，以便您可以更好地理解並提高閱讀效率。',
        title: '雙語翻譯',
      },
      panel2: {
        left: { desc: '瀏覽網頁時，請懸停在內容上，您需要翻譯以查看翻譯。', title: '懸停翻譯' },
        right: { desc: '瀏覽網頁時，選擇要翻譯的文本以查看翻譯。', title: '文本選擇翻譯' },
      },
      panel3: {
        one: { desc: '隨時隨地翻譯和總結網頁和視頻內容，以獲得更順暢的體驗！', title: '點並單擊' },
        three: {
          desc: 'AI驅動的大型語言模型可確保翻譯精度和一致性，以增強閱讀體驗。',
          title: 'AI驅動',
        },
        two: { desc: '多語言全覆蓋，雙語屏障閱讀，一鍵翻譯 - 體驗差異！', title: '更全面和方便' },
      },
      panel4: {
        tab1: {
          desc: '您的效率專家 - 使用Chatgpt，NOAM翻譯並總結了YouTube視頻內容，以節省您的時間！',
          key: '影片',
          title: '視頻翻譯和摘要',
        },
        tab2: { desc: '單擊以在瀏覽網絡時翻譯圖像。', key: '圖像', title: '圖像翻譯' },
        tab3: {
          desc: '立即從網頁中提取關鍵信息，並提供摘要，從而提高您的閱讀效率。',
          key: '網絡',
          title: '網絡翻譯和摘要',
        },
        tag1: '簡單的',
        tag2: '高效的',
        tag3: '易於理解',
      },
      title: 'AI助手',
    },
    getStart: '開始',
    getStartedForFree: '免費入門',
    howItWork: {
      desc: 'NOAM集成了AI模型，為用戶提供智能體驗，用於跨語言網頁翻譯和摘要，PDF和圖像翻譯，視頻翻譯和摘要',
      title: '它如何工作',
    },
    lastBlock: { desc: '更好的AI翻譯，以獲得更流暢的閱讀體驗' },
    top: {
      desc: 'NOAM是一個免費的AI瀏覽器擴展程序，可幫助您翻譯和總結網頁，視頻和PDF內容。\\ n加入NOAM並體驗魔術！',
      title1: '一個魔術',
      title2: '一鍵',
    },
    userReviews: { desc: 'Chrome網絡商店上的五星級評論', title: '用戶評論' },
  },
  installTips: {
    btn: '現在嘗試',
    text: '安裝“ NOAM瀏覽器插件”以解鎖更多功能！網絡交叉引用，\\ n Web視頻摘要等等！',
  },
  locale: {
    af: '南非荷蘭語',
    am: '阿姆哈拉語',
    ar: '阿拉伯',
    as: '阿薩姆人',
    auto: '自動檢測',
    az: '亞塞拜然',
    ba: '巴什基',
    bg: '保加利亞語',
    bn: '孟加拉',
    bo: '藏',
    bs: '波斯尼亞',
    ca: '加泰羅尼亞',
    cs: '捷克',
    cy: '威爾士語',
    da: '丹麥語',
    de: '德語',
    dsb: '下索爾比安',
    dv: '潛水',
    el: '希臘語',
    en: '英語',
    es: '西班牙語',
    et: '愛沙尼亞人',
    eu: '巴斯克',
    fa: '波斯語',
    fi: '芬蘭',
    fil: '菲律賓',
    fj: '斐濟人',
    fr: '法語',
    frCA: '法語（加拿大）',
    ga: '愛爾蘭人',
    gl: '加利西亞人',
    gom: '康卡尼',
    gu: '古吉拉特語',
    ha: '豪薩',
    he: '希伯來語',
    hi: '印地語',
    hr: '克羅地亞人',
    hsb: '上索比人',
    ht: '海地克里奧爾人',
    hu: '匈牙利',
    hy: '亞美尼亞人',
    id: '印度尼西亞',
    ig: '伊博',
    ikt: 'inuinnaqtun',
    is: '冰島',
    it: '意大利人',
    iu: 'Inuktitut',
    iuLatn: 'inuktitut（拉丁）',
    ja: '日本人',
    ka: '格魯吉亞人',
    kk: '哈薩克',
    km: '高棉',
    kmr: '庫爾德人（北部）',
    kn: '卡納達語',
    ko: '韓國人',
    ku: '庫爾德（中央）',
    ky: '吉爾吉斯',
    ln: '林加拉',
    lo: '寮國',
    lt: '立陶宛語',
    lug: '甘達',
    lv: '拉脫維亞',
    lzh: '中文（文學）',
    mai: 'Maithili',
    mg: '馬爾加什',
    mi: '毛利人',
    mk: '馬其頓',
    ml: '馬拉雅拉姆語',
    mnCyrl: '蒙古人（西里爾）',
    mnMong: '蒙古人（傳統）',
    mr: '馬拉地語',
    ms: '馬來語',
    mt: '馬爾他',
    mww: '苗族道',
    my: '緬甸（緬甸）',
    nb: '挪威',
    ne: '尼泊爾',
    nl: '荷蘭',
    noSearchLang: '語言不支持',
    nso: 'Sesotho Sa Leboa',
    nya: 'Nyanja',
    or: '奧迪亞',
    otq: 'QuerétaroOtomi',
    pa: '旁遮普',
    pl: '拋光',
    placeholder: '搜尋',
    prs: '達里',
    ps: 'Pashto',
    pt: '葡萄牙（巴西）',
    ptPT: '葡萄牙（葡萄牙）',
    ro: '羅馬尼亞人',
    ru: '俄語',
    run: 'rundi',
    rw: 'Kinyarwanda',
    sd: '信德',
    si: '僧伽羅',
    sk: '斯洛伐克',
    sl: '斯洛文尼亞人',
    sm: '薩摩亞',
    sn: '肖納',
    so: '索馬利亞',
    sq: '阿爾巴尼亞人',
    srCyrl: '塞爾維亞人（西里爾）',
    srLatn: '塞爾維亞（拉丁）',
    st: '塞索托',
    sv: '瑞典',
    sw: '斯瓦希里語',
    ta: '泰米爾人',
    te: '泰盧固語',
    th: '泰國',
    ti: 'tigrinya',
    tk: '土庫曼',
    tlhLatn: '克林貢（拉丁）',
    tlhPiqd: '克林貢（PIQAD）',
    tn: 'Setswana',
    to: '東加',
    tr: '土耳其',
    tt: '塔塔爾',
    ty: '大溪地',
    ug: 'Uyghur',
    uk: '烏克蘭',
    ur: '烏爾都語',
    uz: '烏茲別克（拉丁）',
    vi: '越南人',
    xh: 'xhosa',
    yo: '約魯巴',
    yua: 'Yucatec Maya',
    yue: '廣東話（傳統）',
    zh: '中國人',
    zhHans: '中文簡化',
    zhHant: '中國傳統',
    zu: '祖魯',
  },
  login: {
    and: '和',
    btn: '與Google登錄',
    btn1: '立即登錄',
    desc: '登錄以解鎖更多經驗',
    policy: '隱私政策',
    terms: '使用條款',
    tips: '通過註冊，我同意',
    title: '歡迎來到Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: '跟隨', twitter: '嘰嘰喳喳' },
    legal: { policy: '隱私政策', terms: '服務條款', title: '合法的' },
    product: {
      image: '圖像翻譯',
      pdf: 'PDF翻譯',
      title: '產品',
      video: '視頻翻譯和摘要',
      web: '網絡翻譯和摘要',
    },
    resources: { title: '資源' },
  },
  pageHeader: {
    contact: '接觸',
    help: '幫助',
    logout: '註銷',
    logoutSucc: '登入.',
    pricing: '定價',
    settings: '設定',
    signUp: '登入',
  },
  pdf: {
    autoDetect: '自動檢測',
    cancelBtn: '取消',
    cancelUpload: '取消',
    chatPdf: {
      summaryError: '總結失敗了，請稍後再試',
      chatError: '聊天失敗，請稍後再試',
      Search: '搜尋',
      cancel: '取消',
      chatTitle: '歡迎來到Noam！這是您的PDF的摘要：',
      clear: '清除',
      clearContent: '這將開始新的對話。當前的對話將無法保存。你確定嗎？',
      clearDialogTitle: '請確認',
      clearSucc: '清除',
      copy: '複製',
      copySucc: '複製',
      delete: '刪除',
      deleteCancel: '取消',
      deleteContent: '刪除後無法恢復此PDF。你確定嗎？',
      deleteOk: '刪除',
      deleteSuccess: '刪除',
      deleteTitle: '確認刪除',
      expand: '坍塌',
      historyTitle: '歷史',
      listError: '無法加載閱讀列表。請稍後再試，或聯繫支持。',
      loading: '載入中...',
      noDataDesc: '還沒有歷史。',
      noMore: '沒有更多的內容。',
      placeholder: '詢問有關此PDF的任何信息',
      summaryTitle: '您可能對這些問題感興趣：',
    },
    collectionEmpty: '您的收藏是空的。',
    collectionTab: '筆記',
    dataLoading: '載入中...',
    dialogContent: '您確定要刪除這個嗎？',
    dialogTitle: '請確認',
    download: {
      both: '雙語',
      btn: '節省',
      cancel: '關閉此窗口將取消PDF下載。您確定要取消嗎？',
      desc: '保存到設備：將翻譯以圖像下載；保存和打印：以原始格式下載翻譯，並提供副本支持。',
      download: '下載',
      downloadMsg1: 'PDF翻譯進度：',
      downloadMsg2: '估計剩餘時間：',
      downloadSucc: 'PDF準備好了！單擊以保存。',
      downloadTip: '下載PDF。不要關閉此窗口以避免下載錯誤。',
      error: '無法保存。嘗試打印。',
      fileName: '文件名：',
      fileType: '文件類型：',
      loading: '保存...',
      loading2: '載入中...',
      minute: '分鐘...',
      msg1: '本文檔中的某些翻譯比原始文本更長，並且可能在下載的文件中重疊。我們建議在網頁上閱讀。',
      msg2: '1。翻譯尚未完成。翻譯完成後，請下載。',
      msg3: '2。一些翻譯太長。在網頁上閱讀以避免文本重疊。',
      msg4: '翻譯任務正在進行中。完成後，請重試。',
      progress: '翻譯PDF。進度：{{{progress}}，估計的剩餘時間：{{分鐘}}分鐘...',
      save: '保存到設備',
      savePrint: '保存和打印',
      success: '下載',
      trans: '僅翻譯',
      transContinue: '繼續翻譯',
    },
    downloadBtn: '下載',
    dragTipModal: {
      black: '空間',
      leftKey: '左鍵',
      ok: '知道了',
      press: '按',
      tips: '拖動和查看。',
    },
    entry: 'PDFS',
    fileErrMsg: '文件讀數失敗。請稍後再試，或聯繫支持。',
    fileMsg: '當前文件大小：',
    fileMsg2: '請上傳小於300MB的文件。',
    freeBannerBtn: '升級',
    freeBannerTips: '達到了免費的PDF翻譯限制',
    guide: {
      placeholder: '在這裡粘貼PDF鏈接',
      start: '開始翻譯',
      text1: '雙語閱讀',
      text2: '讀取雙語，以比較原始文本和翻譯並排的翻譯，從而提高效率。',
      text3: '與您的PDF聊天',
      text4: 'AI驅動分析和摘要。詢問有關您的PDF的問題，並立即得到答案。',
      text5: '準確而高效',
      text6: 'AI驅動的PDF翻譯可提高準確性和效率。',
      title1: '雙語翻譯',
      title2: '與您的PDF聊天',
      toast: '請輸入有效的在線pdf鏈接，以.pdf結尾',
    },
    hasTransTask: '翻譯任務正在進行中。請稍等。',
    hoverTrans: '懸停到翻譯段落',
    initial: '翻譯PDF',
    installText: '您的日常翻譯的一站式解決方案。',
    installText2: '請先安裝擴展名',
    installTips: '安裝擴展程序以進行更多的PDF翻譯，並更流暢的網站翻譯：',
    kw: '主食',
    link: '關聯',
    listError: '無法加載PDF。請稍後再試，或聯繫支持。',
    memoTotalCount: '總計1個項目',
    new: '新文件',
    noId: '找不到文件ID。',
    notPdf: '僅PDF文件。',
    okBtn: '刪除',
    okBtn2: '知道了',
    original: '原來的',
    parallel: '平行線',
    parseErrDialogContent: '此PDF鏈接無法直接翻譯。請下載PDF並上傳以進行翻譯。',
    parseErrTips: '無法解析文件',
    parseFileToast: '無法解析文件。請稍後再試，或聯繫支持。',
    pdfLoading: '解析...',
    searchPlaceholder: '搜尋',
    selectTrans: '選擇要翻譯的文本',
    shareBtn: '分享',
    shareBtnText1: '生成共享鏈接',
    shareBtnText2: '更新共享內容',
    shareModal: {
      cancel: '取消',
      copy: '複製',
      copySucc: '鏈接複製',
      custom: '定制：',
      errMsg: '所選內容尚未完全翻譯。請在分享之前翻譯。',
      gen: '分享',
      modify: '編輯',
      notSupport: '目前不支持此語言。',
      page: '頁面',
      pageNuErr: '請輸入有效的頁碼。',
      range: '範圍：',
      remain: '其餘的',
      remain1: '總估計時間：',
      search: '搜尋',
      settings: '共享設置：',
      shareSetting: '共享設置',
      shareText: '分享',
      shareTips: '與您的朋友分享翻譯的PDF鏈接：',
      sourceLang: '從：',
      target: '到：',
      text1: '共享設置',
      text2: '生成共享鏈接',
      totalPage: '總計1頁',
      trans: '翻譯',
      translateTips: '提示：如果翻譯需要一段時間，則可以關閉此窗口並稍後共享。',
      translating: '翻譯PDF ...',
      update: '更新共享鏈接',
    },
    shareMsg1: '共享鏈接生成。',
    shareMsg2: '共享內容更新。',
    shareMsg3: '無法生成共享鏈接。請稍後再試，或聯繫支持。',
    shareMsg4: '無法更新共享內容。請稍後再試，或聯繫支持。',
    shareTips: '您的PDF翻譯已經準備好了！與您的朋友分享。',
    start: '開始免費試用',
    thumbnail: '目錄',
    toast1: '加載PDF。請稍後再試。',
    toast2: 'PDF內容太大。對話框目前不支持。',
    toast3: '下載。下載完成後，請重試。',
    toolbar: { Adjust: '調整', autoFit: '自動擬合' },
    trans: '翻譯',
    transSucc: '翻譯成功！',
    unLoginToast: '請登錄以使用此功能。',
    uploadBtn: '上傳',
    uploadErr: '文件上傳失敗。請稍後再試，或聯繫支持。',
    uploadErrSignUrl: '無法上傳URL。請稍後再試，或聯繫支持。',
    uploadMsg1: '在這裡拖動文件。',
    uploadMsg2: '支持的文件類型：PDF |  最大文件大小：100MB',
    uploadMsg3: '單擊以在此處選擇或拖放文件。',
    uploading: '上傳',
    uploadingMsg: '上傳。請稍等。',
    uploadingMsg1: '文件正在上傳。請稍後再試。',
    uploadingMsg2: '文件當前正在轉換。請稍後再試。',
    uploadingMsg3: '轉換進度請求失敗。請稍後再試，或聯繫支持。',
    uploadingMsg4: 'NOAM無法訪問本地文件。請手動上傳PDF進行翻譯。',
    uploadingMsg5: '無法檢索文件URL。請稍後再試，或聯繫支持。',
    video: '影片',
    vipBannerBtn: '升級',
    vipBannerTips: '您已經達到了本月的PDF翻譯限制。',
    webTrans: '完整的交叉引用',
  },
  pricing: {
    FAQ: '常問問題',
    FQAObj: {
      a1: '是的，NOAM提供了可以立即使用的免費計劃。',
      a2: '我們提供三個會員計劃：Pro，Pro+和Enterprise。您可以選擇每月或每年支付。年度計費可節省大量費用。',
      a3: '兩個計劃都可以使用所有功能。主要區別是成本：年度訂閱的整體成本降低。每月訂閱每月收費，而年度計劃每年一次收費一次。',
      a4: '您可以隨時在帳戶設置中取消訂閱。',
      q1: '有免費計劃嗎？',
      q2: '有哪些定價計劃和付款方式可用？',
      q3: '訂閱每月與每年訂閱有什麼區別？',
      q4: '如何取消訂閱？',
    },
    around: '大約',
    billedMonthly: '每月收費',
    billedYearly: '每年計費',
    day: '天',
    desc: '比較計劃並選擇最好的',
    detailedCompare: '比較計劃',
    discount: '20％的折扣',
    enterprise: {
      benefit1: '優先客戶支持',
      benefit2: '多個用戶',
      benefit3: '量身定制的解決方案',
      benefit5: '專用客戶經理',
      btn: '聯繫我們',
      recommendText: '自定義計劃（≥3人）',
      talkToUs: '取得聯繫',
    },
    free: {
      benefit1: '即時翻譯100次/天',
      benefit2: '網站翻譯和摘要',
      benefit3: '30個圖像翻譯',
      benefit4: '視頻翻譯和摘要',
      benefit5: '10 pdf翻譯',
      benefit6: '5,000個令牌',
      btn: '免費嘗試',
      recommendText: '免費試用',
    },
    imageTranslation: '圖像翻譯',
    month: '月',
    monthly: '每月',
    pageTitle: '定價',
    pdf: { chat: 'PDF聊天查詢', maxPage: '最大限度。每個pdf頁', translation: 'PDF翻譯' },
    preMonth: '每月',
    pro: {
      benefit1: '優先客戶支持',
      benefit2: '每月1,000,000個令牌',
      benefit3: '網站翻譯和摘要',
      benefit4: '200圖像翻譯',
      benefit5: '視頻翻譯和摘要',
      benefit6: '200 PDF翻譯',
      btn: '開始',
      recommendText: '最受歡迎',
    },
    proPlus: {
      benefit1: '優先客戶支持',
      benefit2: '每月3,000,000個令牌',
      benefit3: '網站翻譯和摘要',
      benefit4: '500圖像翻譯',
      benefit5: '視頻翻譯和摘要',
      benefit6: '500 PDF翻譯',
      recommendText: '最佳價值',
    },
    video: { summary: '視頻摘要', translation: '視頻翻譯' },
    web: {
      contrastiveTranslation: '雙語翻譯',
      dictionaryTranslation: '選擇翻譯',
      hoverTranslation: '懸停翻譯',
      instantTranslation: '即時翻譯',
      summary: '網頁摘要',
    },
    yearly: '每年',
  },
  privacy: {
    block1:
      'NOAM（“ Noam”，“我們”，“我們的”或“我們”）尊重您的隱私，並致力於保護我們從您或您那裡獲得的任何信息。本隱私政策描述了我們從您使用我們的網站，應用程序和服務（統稱為“服務”）中收集或使用個人信息的實踐。本隱私政策不適用於我們代表我們的業務產品客戶（例如API）處理的內容。此數據的使用符合我們的客戶協議，該協議涵蓋了這些產品的訪問和使用。',
    block10:
      '我們實施商業上合理的技術，行政和組織措施，以保護在線和離線個人信息免受損失，濫用以及未經授權的訪問，披露，更改或敵對行為的行為。但是，沒有任何互聯網或電子郵件傳輸可以保證完整的安全性或準確性。特別是，發送給我們或從我們這裡發送的電子郵件可能不安全。因此，在決定通過服務或電子郵件發送給我們的信息時，您應該特別謹慎。此外，我們對服務或第三方網站的安全設置或安全措施中的隱私設置或安全措施的任何妨礙。我們將在必要時保留您的個人信息，以便為您提供服務或出於其他合法的商業目的，例如解決爭議，安全和安全原因，或遵守我們的法律義務。我們保留個人信息的時間長度將取決於許多因素，例如信息的數量，性質，敏感性，未經授權使用或披露的潛在風險，我們處理信息的目的以及其他具有法律約束力的考慮因素。',
    block11:
      '我們可能會不時更新本隱私政策。當我們這樣做時，除非適用法律另有規定，否則我們將在此頁面上發布更新版本。',
    block12: '如果您對本隱私政策有任何未解決的問題或疑慮，請聯繫我們的客戶服務。電子郵件：',
    block2: '我們收集與您相關的以下個人信息（“個人信息”）：',
    block3: '您提供的信息：當您創建一個帳戶使用我們的服務或與我們通信時，我們將收集個人信息如下：',
    block4:
      '個人信息自動從您的服務使用中自動收到：當您訪問，使用或與服務互動時，我們會收到有關您的訪問，使用或交互的以下信息（“技術信息”）：',
    block5: '我們可能將個人信息用於以下目的：',
    block6:
      '摘要或取消識別信息：我們可以匯總或取消識別個人信息，以防止其為了識別目的重用，並使用此類信息來分析我們服務的有效性，改進和添加服務，進行我們的服務，進行研究以及出於類似目的。此外，我們可以分析服務用戶的一般行為和特徵，並與第三方共享匯總的信息，發布此類匯總信息或使其通常可用。我們可以通過服務，cookie以及本隱私政策中描述的其他方式收集匯總的信息。我們將以匿名或取消識別的形式維護和使用取消識別的信息，除非法律要求，否則我們將不會嘗試重新識別此類信息。',
    block7:
      '在某些情況下，除非法律要求，否則我們可能會向第三方提供您的個人信息，而無需進一步通知您：',
    block8:
      '根據您的地理位置，歐洲經濟區，英國和全球的個人可能具有有關其個人信息的某些法定權利。例如，您可能有權：',
    block9:
      '該服務可能包含指向NOAM運營或控制的其他網站的鏈接，包括社交媒體服務（“第三方網站”）。您與第三方網站共享的信息將受第三方網站的特定隱私政策和服務條款的約束，而不是本隱私政策。為了方便起見，我們提供這些鏈接，並不意味著我們對這些網站的認可或審查。請直接聯繫第三方網站以獲取有關其隱私政策和條款的信息。',
    subBlock1:
      '帳戶信息：當您在我們的平台上創建一個帳戶時，我們會收集與您的帳戶有關的信息，包括您的姓名，聯繫信息，帳戶憑據，支付卡信息和交易歷史記錄（“帳戶信息”）。',
    subBlock10: '提供，管理，維護和/或分析服務；',
    subBlock11: '與您交流；',
    subBlock12: '開發新產品和服務；',
    subBlock13: '防止欺詐，犯罪活動或濫用我們的服務，並保護我們的IT系統，架構和網絡的安全；',
    subBlock14: '進行業務轉移；',
    subBlock15:
      '遵守法律義務和法律訴訟，保護我們的權利，隱私，安全或財產以及我們的分支機構，您或其他第三方的權利。',
    subBlock16:
      '供應商和服務提供商：為了幫助我們滿足業務運營需求並執行某些服務和功能，我們可能會向供應商和服務提供商提供個人信息，包括託管服務提供商，雲服務提供商以及其他信息技術服務提供商，電子郵件通信軟件以及Web Analytics Servictics Services Services Services等。這些黨派將在我們的方向上訪問，或者在我們的方向上訪問他們的個人信息，以便在我們的方向上進行個人信息。',
    subBlock17:
      '業務轉移：如果我們從事戰略交易，重組，破產，破產接管或服務過渡到另一個提供商（統稱為“交易”），則您的個人信息和其他信息可能會與交易對應人員以及其他派對在盡職協會的交易中的交易和其他方面的交易中的交易，並與其他交易者一起轉移，並作為其他協同行為轉移到其他方面的一部分或連續獎勵。',
    subBlock18:
      '法律要求：我們可能會與政府當局，行業同行或其他第三方共享您的個人信息，包括您與我們的服務的互動信息，（1）如果法律要求，或者如果我們認為必須採取這種行動來遵守法律義務； （2）保護和捍衛我們的權利或財產； （3）如果我們單方面確定違反了我們的條款，政策或法律； （4）檢測或防止欺詐或其他非法活動； （5）保護我們的產品，員工或用戶，或公共安全，安全，誠信；或（vi）防止法律責任。',
    subBlock19: '訪問您的個人信息和有關如何處理的信息。',
    subBlock2:
      '用戶內容：當您使用我們的服務時，我們會從您提供給我們的輸入，文件上傳或反饋（“內容”）中收集個人信息。',
    subBlock20: '請求從我們的記錄中刪除您的個人信息。',
    subBlock21: '更正或更新您的個人信息。',
    subBlock22: '將您的個人信息轉移到第三方（數據可移植性）。',
    subBlock23: '限制我們如何處理您的個人信息。',
    subBlock24: '撤回您的同意 - 我們依靠同意作為處理的法律依據，您可以隨時撤回同意。',
    subBlock25: '反對我們如何處理您的個人信息。',
    subBlock26: '改善我們的服務和進行研究；',
    subBlock3:
      '通信信息：如果您向我們發送消息，我們將收集您的姓名，聯繫信息和消息的內容（“通信信息”）。',
    subBlock4:
      '社交媒體信息：我們在社交媒體網站上有頁面，例如Instagram，Facebook，Medium，Twitter，YouTube和LinkedIn。當您與我們的社交媒體頁面互動時，我們會收集您選擇提供給我們的個人信息，例如您的聯繫方式（“社交信息”）。此外，主持我們社交媒體頁面的公司可能會為我們提供有關社交媒體活動的匯總信息和分析。',
    subBlock5:
      '日誌數據：使用我們的服務時，瀏覽器會自動發送信息。日誌數據包括您的Internet協議（IP）地址，瀏覽器類型和設置，請求的日期和時間以及與我們的網站互動的方式。',
    subBlock6:
      '用法數據：我們可以自動收集有關您使用服務的信息，例如您查看或與之交互的內容類型，使用的功能，所採用的操作以及您的時區，國家 /地區，訪問日期和時間，用戶代理和版本，計算機或移動設備類型以及計算機連接。',
    subBlock7:
      '設備信息：這包括設備的名稱，操作系統，設備標識符以及所使用的瀏覽器。收集的信息可能取決於您使用的設備及其設置。',
    subBlock8:
      'Cookie：我們使用Cookie來運營和管理我們的服務並改善您的體驗。 “ cookie”是您訪問瀏覽器網站發送的信息。您可以將瀏覽器設置為接受所有cookie，拒絕所有cookie或在提供cookie時通知您，以便您可以決定是否每次接受它。但是，拒絕Cookie可能會阻止您使用或負面影響網站某些領域或功能的顯示或功能。有關Cookie的更多信息，請訪問有關Cookie的所有信息。',
    subBlock9:
      '分析：我們可能會使用各種在線分析產品，這些產品使用Cookie來幫助我們分析用戶在使用服務時如何使用我們的服務並改善您的體驗。',
    subTitle1: '我們收集的個人信息',
    subTitle2: '我們如何使用個人信息',
    subTitle3: '披露個人信息',
    subTitle4: '您的權利',
    subTitle5: '鏈接到其他網站',
    subTitle6: '安全與保留信息',
    subTitle7: '修改隱私政策',
    subTitle8: '如何與我們聯繫',
    title: '隱私政策',
    updateTime: '更新：2024年3月15日',
  },
  privacyCollectionPanel: {
    acceptBtn: '接受全部',
    text: '為了改善您的經驗，我們使用cookie個性化內容和服務。',
    title: '本網站使用cookie',
  },
  slogen: '最簡單的AI助手',
  stripe: {
    contact: '請通過任何疑問與我們聯繫contact@noam.tools！',
    fail: {
      btn: '重試',
      text1: '我們無法處理您的付款。',
      text2: '檢查您的連接，然後重試。',
      title: '付款失敗',
    },
    succ: { btn: '我們走吧', text1: '歡迎來到Noam，', text2: '讓我們開始吧！', title: '恭喜' },
  },
  terms: {
    block1: '感謝您使用NOAM！',
    block10:
      '（a）費用和計費。您將根據適用定價頁面上的定價和條款或我們之間的書面書面認同的條款支付所有費用（“費用”）。即使我們已發行發票或收到付款，我們也保留糾正定價錯誤或錯誤的權利。您將提供完整而準確的計費信息，包括有效和授權的付款方式。我們將按照商定的定期收取您的付款方式，但我們可以合理地更改計費日期。您授權NOAM，其分支機構和第三方付款處理器收取您的付款方式。如果您的付款失敗，我們將為您提供書面通知，並可能暫停對服務的訪問，直到收到付款為止。除本協議中另有規定外，付款不可退還。',
    block11:
      '（b）稅。除非另有說明，否則費用不包括任何联邦，州，地方和外國稅，關稅和類似評估（“稅收”）。您應對與您購買相關的所有稅收負責，不包括基於我們的淨收入的稅款，我們將為您開具發票。您同意迅速繳納此類稅，並為我們提供合法有效的隱私聲明，並獲得處理此類數據的必要同意，並保證您根據適用的法律處理此類數據。',
    block12:
      '（c）價格變化。我們可能會通過您的帳戶和/或我們的網站通知您，更改價格。價格上漲將在發布後立即生效。更改後，任何價格更改都適用於您的帳戶費用。',
    block13:
      '（d）爭議和逾期付款。如果您想提出任何費用或稅款的質疑，請在發票日期的三十（30）天內與contact@noam.tools聯繫。',
    block14:
      '（e）免費使用。您不得創建多個帳戶以獲取免費信用。如果我們確定您沒有真誠地使用免費信貸，我們可能會向您收取標準費用或停止提供服務的訪問。',
    block15:
      '（a）機密性。您可能可以訪問NOAM的機密信息。您只能使用本條款允許的機密信息，以便使用該服務。您可能不會向任何第三方披露機密信息，並且至少在合理的護理下，您將以保護自己的類似機密信息的方式保護機密信息。機密信息是NOAM指定為機密的非公開信息，或在這種情況下應合理地將其視為機密的信息，包括軟件，規格和其他非公開業務信息。機密信息不包括以下信息：（1）通常不會在您的情況下向公眾使用； （2）您擁有的沒有任何保密性的義務，然後再根據本條款獲得； （3）第三方合法向您披露，沒有任何保密義務；或（4）您在不使用機密信息的情況下獨立開發。如果法律，法院或其他政府命令的要求，您可以披露機密信息，但您必須以合理的方式向NOAM提供書面通知，並在可能的範圍內做出合理的努力來限制披露範圍，包括協助我們處理反對披露請求。',
    block16:
      '（b）安全。您必須採取合理且適當的措施，以幫助維護您對服務的訪問和使用。如果您發現與服務使用有關的任何漏洞或違規行為，則必須及時聯繫NOAM並提供漏洞或違規的詳細信息。',
    block17:
      '（（c）處理個人數據。如果您使用該服務處理個人數據，則必須提供法律上足夠的隱私聲明並獲得處理此類數據的必要同意，並且您保證您根據適用的法律處理此類數據。',
    block18:
      '（a）終止；暫停。這些條款將從您首次使用該服務中有效，並將一直有效直到終止。您可以通過任何原因終止這些條款，通過停止使用服務和內容的使用。由於任何原因，我們可以在事先通知中終止這些條款。我們可能會立即通過通知您第2節（使用要求），第5節（機密性，安全性和數據保護），第8節（爭議解決）或第9節（一般條款），或我們與我們與我們控制範圍之外的任何第三方技術提供商的關係，或者以符合法律或政府要求的任何關係，我們可能會立即終止這些條款。如果您不遵守這些條款，或者您的使用對我們或任何第三方構成安全風險，或者如果我們懷疑您的使用是欺詐性的，或者可能使我們或任何第三方承擔責任，我們可能會暫停您對服務的訪問。',
    block19:
      '（b）終止的影響。終止後，您將使用該服務停止，並立即按照我們指示的任何機密信息返回或銷毀任何機密信息。這些條款的規定將其性質應在終止或到期，包括但不限於第3和5-9節，應得以生存。',
    block2:
      '這些使用條款適用於您使用NOAM服務，包括我們的應用程序編程接口，軟件，工具，開發人員服務，數據，文檔和網站（統稱為“服務”）。通過使用我們的服務，您同意遵守這些條款。我們的隱私政策解釋了我們如何收集和使用個人信息。',
    block20:
      '（a）賠償。您將捍衛，賠償並持有我們，我們的分支機構以及我們的人員對與您使用的服務，包括您的內容，您對服務相關的產品或服務的使用以及對這些條款或適用法律的違法的使用，包括您的內容，您的使用，包括您的內容，您對服務的使用，包括您的內容或服務的任何索賠，損失和費用（包括律師費）無害。',
    block21:
      '（b）免責聲明。提供的服務是“原樣”。在法律允許的範圍內，我們和我們的關聯公司和許可人對服務沒有任何形式的陳述或保證，也沒有做出任何保證，包括但不限於對適銷性，適合特定目的的適合性，令人滿意的質量，非融資和安靜的享受以及貿易交易或用途的任何保證。我們不保證該服務不會中斷，準確或無錯誤，或者任何內容都將是安全的，不會丟失或不會更改的。',
    block22:
      '（c）責任限制。即使我們已被告知，我們，我們的分支機構和許可人都不應對任何間接，偶然，特殊，結果或懲罰性損害賠償，包括利潤損失，聲譽喪失，使用損失，數據丟失，數據丟失或其他無形損失，即使我們已被告知我們有可能遭受此類損害的可能性。根據這些條款，我們的總責任將不會超過您在索賠之前的十二（12）個月內支付給服務的費用，或一百美元（$ 100），以較高者為準。本節中的限制適用於適用法律允許的最大範圍。',
    block23:
      '服務條款和您對服務的使用受美國法律的管轄，但美國法律衝突規則除外。您對申請的使用也可能遵守其他地方，州，國家或國際法律。與這些條款有關的任何法律訴訟或訴訟將僅在美國法院提起，您同意服從此類法院的個人管轄權。',
    block24:
      '（a）各方的關係。諾姆（Noam）和您是獨立承包商，並且沒有其他當事方未經對方事先書面同意，既沒有權力代表或束縛另一方或承擔對方的義務。',
    block25:
      '（b）品牌使用。未經我們事先的書面同意，您不得使用NOAM或其任何分支機構的名稱，徽標或商標。',
    block26:
      '如果確定這些條款的任何規定被確定為無效，非法或無法執行的，則其餘規定將保持全部效力和效力。',
    block3:
      '如果您未滿18歲，則必須獲得父母或法定監護人使用該服務的許可。如果您代表他人或實體使用服務，則必須授權代表他們接受這些條款。您必須提供準確而完整的信息才能註冊帳戶。您可能不會向組織以外的任何人提供訪問憑據或帳戶，並且您對使用您的憑據進行的所有活動負責。',
    block4:
      '（a）使用服務。您可以根據這些條款訪問該服務，我們授予您使用該服務的非排他性權利。使用服務時，您將遵守這些條款和所有適用的法律。我們和我們的分支機構擁有服務中的所有權利，頭銜和利益。',
    block5:
      '（b）反饋。我們歡迎反饋，評論，想法，建議和改進。如果您提供任何此類內容，我們可以無需任何限制，而無需賠償您。',
    block6:
      '（c）限制。您可能不會：（1）以侵犯，濫用或侵犯他人任何權利的方式使用服務； （2）反向工程師，反編譯，拆卸，翻譯或以其他方式嘗試發現服務模型，算法和系統的源代碼或基礎組件（除非適用法律禁止此類限制）； （3）使用服務的輸出來開發與NOAM競爭的模型； （4）除非通過API允許，包括刮擦，Web收集或Web數據提取，否則使用任何自動化或編程方法從服務中提取數據或輸出； （5）表示服務的輸出是人為生成的，或者違反了我們的使用政策； （6）未經我們事先同意購買，出售或轉移API鍵；或（7）為我們提供13歲以下兒童或適用數字同意年齡的兒童的任何個人信息。您將遵守我們的文檔中的任何費率限制和其他要求。',
    block7:
      '（d）第三方服務。與服務相關的任何第三方軟件，服務或其他產品都受其條款的約束，我們對第三方產品概不負責。',
    block8:
      '（a）您的內容。您可以根據輸入（共同稱為“內容”）生成和返回的服務並接收輸出（“輸出”）輸入（“輸出”）。在各方之間以及適用法律允許的範圍內，您擁有所有意見。 Noam可以使用內容來提供和維護服務，遵守適用的法律，並執行我們的政策。您應對內容負責，包括確保它不違反任何適用法律或這些條款。',
    block9:
      '（b）準確性。人工智能和機器學習是迅速發展的研究領域。我們不斷努力改善我們的服務，以使其更準確，可靠，安全和有益。鑑於機器學習的概率性質，使用我們的服務可能會導致某些情況下的輸出不正確，這可能無法準確反映出真實的個人，地方或事實。您應該根據用例適當評估任何輸出的準確性，包括手動審查輸出。',
    subTitle1: '註冊和訪問',
    subTitle10: '嚴重性',
    subTitle2: '使用要求',
    subTitle3: '內容',
    subTitle4: '費用和付款',
    subTitle5: '機密性，安全性和數據保護',
    subTitle6: '術語和終止',
    subTitle7: '保障;免責聲明；責任限制',
    subTitle8: '管理法律和管轄權',
    subTitle9: '一般術語',
    title: '服務條款',
    updateTime: '更新：2024年3月15日',
  },
  trialModal: {
    btn: '立即開始（（僅$ 0.99）',
    chat: 'chatpdf',
    desc1: '審判後，您將收取180美元的年費',
    desc2: '隨時取消',
    focus: '專注',
    hover: '懸停翻譯',
    image: '圖像翻譯',
    installBtn: '立即安裝以接收您的禮物',
    memo: '最愛管理',
    pdf: 'PDF平行翻譯',
    realtime: '即時翻譯',
    select: '單詞選擇翻譯',
    title: '歡迎禮物',
    trialText: '7天試用',
    video: '視頻翻譯',
    videoSummary: '視頻摘要',
    web: '平行翻譯',
    webSummary: '網絡摘要',
  },
  upgradeModal: {
    btn: '現在升級',
    chat: { free: '您已經達到了CHATPDF限制', pro: '您已經達到了本月的CHATPDF限制' },
    contactStr: '請等待下一個重置，或通過contact@noam.tool與我們聯繫。',
    limitAlertStr: '您已經達到了免費計劃限制。升級以繼續。',
    more: '查看計劃',
    pageLimit: { free: '您已達到PDF頁面限制' },
    title: '提醒',
    upgradeAlertStr: '您已經達到了免費計劃限制。升級以繼續。',
    upload: { free: '您已經達到了PDF翻譯限制', pro: '您已經達到了本月的PDF翻譯限制' },
  },
  welfare: {
    newUser: {
      joinBtn: '立即加入',
      label: '新的用戶利益：',
      remain: '只剩30',
      text: '只有100個景點！立即抓住你的！',
    },
  },
}

export default TRANSLATION
