const TRANSLATION = {
  buyModal: {
    afterFree: '無料の試用版の後',
    btn: '無料でお試しください',
    freeTrial: '3日間の無料トライアル',
    monthLabel: '月',
    selectLabel: 'プランを選択します',
    title: 'サブスクリプションプラン',
    yearLabel: '年',
  },
  contact: {
    block1:
      'カスタマイズ、質問、または提案については、contact@noam.toolsまでお問い合わせください。すぐに応答します。',
    block2: '★★★★★のGoogleアプリ拡張ストアで私たちを評価することで私たちをサポートしてください。',
    block3: 'この拡張機能を共有して他の人を助けてください：',
    title: 'お問い合わせ',
  },
  help: {
    AquickOverviewOfNoam: 'NOAMの簡単な概要',
    ChatPDF: 'チャットPDF',
    ChatPDF2: 'chatpdf',
    FavoritesManagement: 'お気に入りの管理',
    Fixedplugin: '固定プラグイン',
    Focusing: '焦点',
    Free: '無料',
    FreeTrialBenefits: '無料の試用給付',
    GoWithWikipedia: 'ウィキペディアと一緒に行きます',
    GoWithYouTube: 'YouTubeを使用してください',
    HoverTranslation: 'ホバー翻訳',
    Howtouse: '使い方',
    ImageTranslation: '画像翻訳',
    InstantTranslation: 'インスタント翻訳',
    Membership: 'メンバーシップ',
    NoamHelp: 'NOAMは、翻訳して要約するのに役立ちます',
    PDFparallelTranslation: 'PDF並列変換',
    ParallelTranslation: '平行翻訳',
    SkipAllSteps: 'すべての手順をスキップします',
    Summary: 'まとめ',
    Translation: '翻訳',
    TryItForFree: '無料で試してみてください',
    Video: 'ビデオ',
    VideoSummary: 'ビデオの概要',
    VideoTranslation: 'ビデオ翻訳',
    WebSummary: 'Webサマリー',
    WordSelectionTranslation: '単語選択翻訳',
    benefits: '私たちにはあなたを待つためのメリットがあります！',
    dayFree: '3日間の無料トライアル',
    done: '終わり',
    next: '次',
    pin: 'アクセスを容易にするためのピンノーム～～',
    planIsNotExist: '計画は利用できません',
    startWiki: 'ウィキメディアから始めましょう！',
    startYoutube: 'YouTubeから始めましょう！',
    try: '試してみてください',
    tryNow: '今すぐ試してみてください！',
  },
  home: {
    PDFTranslate: {
      desc: 'バイリンガル翻訳 +チャットPDF',
      sub1: {
        desc: 'バイリンガルの読書を使用すると、元のテキストと翻訳を並べて比較して、読解効率を向上させることができます。',
        title: 'バイリンガルの読書',
      },
      sub2: {
        desc: 'AIは分析および要約を行い、PDFコンテンツに基づいて質問に答えることができます。',
        title: 'チャットPDF',
      },
      sub3: { desc: 'より高い精度と効率のためのAI搭載のPDF翻訳。', title: '精度と効率' },
      title: 'PDF翻訳',
    },
    aiAssistant: {
      desc: 'Web翻訳をより賢く、より便利にします',
      panel1: {
        desc: 'バイリンガルの相互参照の翻訳は、元のテキストを翻訳と比較するのに役立ちます。そのため、理解し、読解効率を改善できます。',
        title: 'バイリンガル翻訳',
      },
      panel2: {
        left: {
          desc: 'Webページを閲覧するときは、コンテンツの上にホバリングする必要があります。翻訳に翻訳する必要があります。',
          title: 'ホバー翻訳',
        },
        right: {
          desc: 'Webページを閲覧するときは、翻訳するテキストを選択して翻訳を確認します。',
          title: 'テキスト選択翻訳',
        },
      },
      panel3: {
        one: {
          desc: 'Webページやビデオコンテンツをいつでもどこでも翻訳して要約して、よりスムーズな体験をしてください！',
          title: 'ポイントしてクリックします',
        },
        three: {
          desc: 'AI駆動型の大型言語モデルは、読書体験を強化するための翻訳の正確性と一貫性を保証します。',
          title: 'AI駆動型',
        },
        two: {
          desc: '多言語の完全なカバレッジ、バイリンガルバリアフリーリーディング、ワンクリック翻訳 - 違いを体験してください！',
          title: 'より包括的で便利です',
        },
      },
      panel4: {
        tab1: {
          desc: '効率の専門家 -  chatgptを使用して、ノームはYouTubeビデオコンテンツを翻訳して要約して時間を節約します！',
          key: 'ビデオ',
          title: 'ビデオ翻訳と要約',
        },
        tab2: {
          desc: 'クリックして、Webを閲覧しながら画像を翻訳します。',
          key: '画像',
          title: '画像翻訳',
        },
        tab3: {
          desc: 'Webページから重要な情報を即座に抽出し、概要を提供し、読解効率を高めます。',
          key: 'ウェブ',
          title: 'Web翻訳と要約',
        },
        tag1: '単純',
        tag2: '効率的',
        tag3: '理解しやすい',
      },
      title: 'AIアシスタント',
    },
    getStart: '始めましょう',
    getStartedForFree: '無料で始めましょう',
    howItWork: {
      desc: 'NOAMはAIモデルを統合して、ユーザーに言語間のWebページの翻訳と要約、PDFと画像翻訳、ビデオ翻訳、要約のインテリジェントエクスペリエンスを提供します',
      title: 'それがどのように機能するか',
    },
    lastBlock: { desc: 'よりスムーズな読書体験のためのより良いAI翻訳' },
    top: {
      desc: 'NOAMは、Webページ、ビデオ、PDFコンテンツを翻訳して要約するのに役立つ無料のAIブラウザ拡張機能です。',
      title1: '1つの魔法',
      title2: 'ワンクリック',
    },
    userReviews: { desc: 'Chrome Webストアでの5つ星のレビュー', title: 'ユーザーレビュー' },
  },
  installTips: {
    btn: '今すぐ試してみてください',
    text: '「NOAMブラウザプラグイン」をインストールして、より多くの機能のロックを解除してください！ Webクロスリファレンス、\\ n Webビデオの要約など！',
  },
  locale: {
    af: 'アフリカーンス',
    am: 'アムハラ語',
    ar: 'アラビア語',
    as: 'アッサム',
    auto: '自動検出',
    az: 'アゼルバイジャン',
    ba: 'バシキル',
    bg: 'ブルガリア',
    bn: 'バングラ',
    bo: 'チベット',
    bs: 'ボスニア人',
    ca: 'カタロニア',
    cs: 'チェコ',
    cy: 'ウェールズ',
    da: 'デンマーク語',
    de: 'ドイツ語',
    dsb: '低ソルビアン',
    dv: 'ダイヴィヒ',
    el: 'ギリシャ語',
    en: '英語',
    es: 'スペイン語',
    et: 'エストニアン',
    eu: 'バスク',
    fa: 'ペルシャ語',
    fi: 'フィンランド語',
    fil: 'フィリピン人',
    fj: 'フィジアン',
    fr: 'フランス語',
    frCA: 'フランス語（カナダ）',
    ga: 'アイルランド',
    gl: 'ガリシア語',
    gom: 'コンカニ',
    gu: 'グジャラート語',
    ha: 'ハウサ',
    he: 'ヘブライ語',
    hi: 'ヒンディー語',
    hr: 'クロアチア語',
    hsb: 'アッパーソビアン',
    ht: 'ハイチのクレオール',
    hu: 'ハンガリー',
    hy: 'アルメニア人',
    id: 'インドネシア語',
    ig: 'イボ',
    ikt: 'inuinnaqtun',
    is: 'アイスランド語',
    it: 'イタリア語',
    iu: 'inuktitut',
    iuLatn: 'inuktitut（ラテン）',
    ja: '日本語',
    ka: 'ジョージアン',
    kk: 'カザフ',
    km: 'クメール',
    kmr: 'クルド人（北）',
    kn: 'カンナダ',
    ko: '韓国語',
    ku: 'クルド人（中央）',
    ky: 'キルギス',
    ln: 'リンガラ',
    lo: 'ラオス',
    lt: 'リトアニアン',
    lug: 'ガンダ',
    lv: 'ラトビアン',
    lzh: '中国語（文学）',
    mai: 'マイチリ',
    mg: 'マラガシー',
    mi: 'マオリ',
    mk: 'マケドニア語',
    ml: 'マラヤーラム語',
    mnCyrl: 'モンゴル人（キリル語）',
    mnMong: 'モンゴル人（伝統）',
    mr: 'マラーティー',
    ms: 'マレー',
    mt: 'マルタ',
    mww: 'モンゴン・ドー',
    my: 'ミャンマー（ビルマ）',
    nb: 'ノルウェー語',
    ne: 'ネパール',
    nl: 'オランダ語',
    noSearchLang: 'サポートされていない言語',
    nso: 'セソトサルボア',
    nya: 'ニャンジャ',
    or: 'オディア',
    otq: 'QuerétaroOtomi',
    pa: 'パンジャブ',
    pl: '研磨',
    placeholder: '検索',
    prs: 'ダリ',
    ps: 'パシュ',
    pt: 'ポルトガル語（ブラジル）',
    ptPT: 'ポルトガル語（ポルトガル）',
    ro: 'ルーマニア人',
    ru: 'ロシア',
    run: 'rundi',
    rw: 'キニャルワンダ',
    sd: 'シンディ',
    si: 'シンハラ',
    sk: 'スロバキア',
    sl: 'スロベニア人',
    sm: 'サモアン',
    sn: 'ショナ',
    so: 'ソマリア',
    sq: 'アルバニア語',
    srCyrl: 'セルビア人（キリル語）',
    srLatn: 'セルビア人（ラテン語）',
    st: 'セソト',
    sv: 'スウェーデン語',
    sw: 'スワヒリ',
    ta: 'タミル語',
    te: 'テルグ語',
    th: 'タイ',
    ti: 'ティグリニャ',
    tk: 'タークメン',
    tlhLatn: 'クリンゴン（ラテン）',
    tlhPiqd: 'クリンゴン（ピカド）',
    tn: 'セットワナ',
    to: 'トンガン',
    tr: 'トルコ語',
    tt: 'タタール',
    ty: 'タヒチアン',
    ug: 'uyghur',
    uk: 'ウクライナ人',
    ur: 'ウルドゥー語',
    uz: 'ウズベック（ラテン）',
    vi: 'ベトナム人',
    xh: 'xhosa',
    yo: 'ヨルバ',
    yua: 'Yucatec Maya',
    yue: '広東語（伝統）',
    zh: '中国語',
    zhHans: '中国語が簡素化されました',
    zhHant: '中国の伝統',
    zu: 'ズールー',
  },
  login: {
    and: 'そして',
    btn: 'Googleでログインします',
    btn1: '今すぐログインします',
    desc: 'ログインして、より多くのエクスペリエンスのロックを解除します',
    policy: 'プライバシーポリシー',
    terms: '利用規約',
    tips: 'サインアップすることで、私は同意します',
    title: 'ノームへようこそ',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'フォローする', twitter: 'ツイッター' },
    legal: { policy: 'プライバシーポリシー', terms: '利用規約', title: '法律上の' },
    product: {
      image: '画像翻訳',
      pdf: 'PDF翻訳',
      title: '製品',
      video: 'ビデオ翻訳と要約',
      web: 'Web翻訳と要約',
    },
    resources: { title: 'リソース' },
  },
  pageHeader: {
    contact: '接触',
    help: 'ヘルプ',
    logout: 'ログアウト',
    logoutSucc: 'ログアウトしました。',
    pricing: '価格設定',
    settings: '設定',
    signUp: 'ログイン',
  },
  pdf: {
    autoDetect: '自動検出',
    cancelBtn: 'キャンセル',
    cancelUpload: 'キャンセル',
    chatPdf: {
      summaryError: '概要に失敗しました。後でもう一度やり直してください',
      chatError: 'チャットが失敗しました、後でもう一度やり直してください',
      Search: '検索',
      cancel: 'キャンセル',
      chatTitle: 'ノームへようこそ！これがあなたのPDFの要約です：',
      clear: 'クリア',
      clearContent: 'これは新しい会話を開始します。現在の会話は保存されません。本気ですか？',
      clearDialogTitle: '確認してください',
      clearSucc: 'クリアされた',
      copy: 'コピー',
      copySucc: 'コピー',
      delete: '消去',
      deleteCancel: 'キャンセル',
      deleteContent: 'このPDFは、削除されると回復できません。本気ですか？',
      deleteOk: '消去',
      deleteSuccess: '削除されました',
      deleteTitle: '削除を確認します',
      expand: '崩壊',
      historyTitle: '歴史',
      listError:
        '読み取りリストの読み込みに失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
      loading: '読み込み...',
      noDataDesc: 'まだ歴史はありません。',
      noMore: 'これ以上のコンテンツはありません。',
      placeholder: 'このPDFについて何か尋ねてください',
      summaryTitle: 'あなたはこれらの質問に興味があるかもしれません：',
    },
    collectionEmpty: 'あなたのコレクションは空です。',
    collectionTab: 'メモ',
    dataLoading: '読み込み...',
    dialogContent: 'これを削除したいですか？',
    dialogTitle: '確認してください',
    download: {
      both: 'バイリンガル',
      btn: '保存',
      cancel:
        'このウィンドウを閉じると、PDFのダウンロードがキャンセルされます。キャンセルしたいですか？',
      desc: 'デバイスに保存：画像として翻訳をダウンロードします。保存と印刷：コピーサポートを使用して、元の形式で翻訳をダウンロードします。',
      download: 'ダウンロード',
      downloadMsg1: 'PDF翻訳の進捗状況：',
      downloadMsg2: '残りの推定時間：',
      downloadSucc: 'PDFの準備ができました！クリックして保存します。',
      downloadTip:
        'PDFのダウンロード。ダウンロードエラーを避けるためにこのウィンドウを閉じないでください。',
      error: '保存できませんでした。代わりに印刷してみてください。',
      fileName: 'ファイル名：',
      fileType: 'ファイルタイプ：',
      loading: '節約...',
      loading2: '読み込み...',
      minute: '分...',
      msg1: 'このドキュメントの一部の翻訳は、元のテキストよりも長く、ダウンロードされたファイルで重複する場合があります。 Webページで読むことをお勧めします。',
      msg2: '1。翻訳はまだ完全ではありません。翻訳が終了したらダウンロードしてください。',
      msg3: '2。いくつかの翻訳は長すぎます。テキストの重複を避けるために、Webページをお読みください。',
      msg4: '翻訳タスクが進行中です。完成したらもう一度やり直してください。',
      progress:
        'PDFの翻訳。進行状況：{{Progress}}、見積もられた時間の残り：{{minutes}} minutes ...',
      save: 'デバイスに保存します',
      savePrint: '保存して印刷します',
      success: 'ダウンロード',
      trans: '翻訳のみ',
      transContinue: '翻訳を続けます',
    },
    downloadBtn: 'ダウンロード',
    dragTipModal: {
      black: '空間',
      leftKey: '左ボタン',
      ok: 'わかった',
      press: 'プレス',
      tips: 'ドラッグして表示します。',
    },
    entry: 'PDFS',
    fileErrMsg:
      'ファイルの読み取りが失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
    fileMsg: '現在のファイルサイズ：',
    fileMsg2: '300MB未満のファイルをアップロードしてください。',
    freeBannerBtn: 'アップグレード',
    freeBannerTips: '無料のPDF翻訳制限に達しました',
    guide: {
      placeholder: 'ここでPDFリンクを貼り付けます',
      start: '翻訳を開始します',
      text1: 'バイリンガルの読書',
      text2:
        '元のテキストと翻訳を並べて比較するために、バイリンガルを読んで、効率を向上させてください。',
      text3: 'PDFとチャットします',
      text4: 'AI搭載の分析と要約。 PDFについて質問し、すぐに答えを得てください。',
      text5: '正確で効率的です',
      text6: 'より高い精度と効率のためのAI搭載のPDF翻訳。',
      title1: 'バイリンガル翻訳',
      title2: 'PDFとチャットします',
      toast: '.pdfで終了する有効なオンラインPDFリンクを入力してください',
    },
    hasTransTask: '翻訳タスクが進行中です。お待ちください。',
    hoverTrans: 'ホバーから翻訳する段落',
    initial: 'PDFを翻訳します',
    installText: '日常の翻訳のためのワンストップソリューション。',
    installText2: '最初に拡張機能をインストールしてください',
    installTips:
      'さらに多くのPDF翻訳のために拡張機能をインストールし、よりスムーズなWebサイト翻訳：',
    kw: '主食',
    link: 'リンク',
    listError: 'PDFSのロードに失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
    memoTotalCount: '1つのアイテム合計',
    new: '新しいファイル',
    noId: 'ファイルIDが見つかりません。',
    notPdf: 'PDFファイルのみ。',
    okBtn: '消去',
    okBtn2: 'わかった',
    original: 'オリジナル',
    parallel: '平行',
    parseErrDialogContent:
      'このPDFリンクは直接翻訳できません。 PDFをダウンロードして、翻訳用にアップロードしてください。',
    parseErrTips: 'ファイルの解析に失敗しました',
    parseFileToast:
      'ファイルの解析に失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
    pdfLoading: '解析...',
    searchPlaceholder: '検索',
    selectTrans: '翻訳するテキストを選択します',
    shareBtn: '共有',
    shareBtnText1: '共有リンクを生成します',
    shareBtnText2: '共有コンテンツを更新します',
    shareModal: {
      cancel: 'キャンセル',
      copy: 'コピー',
      copySucc: 'コピーされたリンク',
      custom: 'カスタマイズ：',
      errMsg: '選択したコンテンツはまだ完全に翻訳されていません。共有する前に翻訳してください。',
      gen: '共有',
      modify: '編集',
      notSupport: 'この言語は現在サポートされていません。',
      page: 'ページ',
      pageNuErr: '有効なページ番号を入力してください。',
      range: '範囲：',
      remain: '残り',
      remain1: '合計推定時間：',
      search: '検索',
      settings: '共有設定：',
      shareSetting: '設定を共有します',
      shareText: '共有',
      shareTips: '翻訳されたPDFリンクを友達と共有します。',
      sourceLang: 'から：',
      target: 'に：',
      text1: '設定を共有します',
      text2: '共有リンクを生成します',
      totalPage: '合計1ページ',
      trans: '翻訳する',
      translateTips:
        'ヒント：翻訳にしばらく時間がかかっている場合は、このウィンドウを閉じて後で共有できます。',
      translating: 'PDFの翻訳...',
      update: '共有リンクを更新します',
    },
    shareMsg1: '生成されたリンクを共有します。',
    shareMsg2: '共有コンテンツが更新されました。',
    shareMsg3:
      '共有リンクを生成できませんでした。後でもう一度やり直すか、サポートに連絡してください。',
    shareMsg4:
      '共有コンテンツの更新に失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
    shareTips: 'あなたのPDF翻訳は準備ができています！友達と共有してください。',
    start: '無料トライアルを開始します',
    thumbnail: '目次',
    toast1: 'PDFの読み込み。後でもう一度やり直してください。',
    toast2: 'PDFコンテンツが大きすぎます。ダイアログは現在サポートされていません。',
    toast3: 'ダウンロード。ダウンロードが終了したら再試行してください。',
    toolbar: { Adjust: '調整する', autoFit: 'オートフィット' },
    trans: '翻訳',
    transSucc: '翻訳が成功しました！',
    unLoginToast: 'この機能を使用するには、ログインしてください。',
    uploadBtn: 'アップロード',
    uploadErr:
      'ファイルのアップロードに失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
    uploadErrSignUrl:
      'URLのアップロードを取得できませんでした。後でもう一度やり直すか、サポートに連絡してください。',
    uploadMsg1: 'ここにファイルをドラッグしてドロップします。',
    uploadMsg2: 'サポートされているファイルタイプ：PDF |  最大ファイルサイズ：100MB',
    uploadMsg3: 'クリックして、ファイルを選択またはドラッグします。',
    uploading: 'アップロード',
    uploadingMsg: 'アップロード。お待ちください。',
    uploadingMsg1: 'ファイルはアップロードされています。後でもう一度やり直してください。',
    uploadingMsg2: '現在、ファイルが変換されています。後でもう一度やり直してください。',
    uploadingMsg3:
      '変換の進行状況リクエストに失敗しました。後でもう一度やり直すか、サポートに連絡してください。',
    uploadingMsg4:
      'NOAMはローカルファイルにアクセスできません。翻訳用にPDFを手動でアップロードしてください。',
    uploadingMsg5:
      'ファイルURLを取得できませんでした。後でもう一度やり直すか、サポートに連絡してください。',
    video: 'ビデオ',
    vipBannerBtn: 'アップグレード',
    vipBannerTips: '今月はPDF翻訳の制限に達しました。',
    webTrans: '完全な相互参照',
  },
  pricing: {
    FAQ: 'よくある質問',
    FQAObj: {
      a1: 'はい、NOAMはすぐに使用できる無料プランを提供しています。',
      a2: 'Pro、Pro+、およびEnterpriseの3つのメンバーシッププランを提供しています。毎月または毎年支払うことを選択できます。年次請求は大幅な節約を提供します。',
      a3: 'すべての機能は両方のプランで利用できます。主な違いはコストです。年間サブスクリプションは全体的にコストが低くなります。毎月のサブスクリプションは毎月請求されますが、年間プランは年に1回請求されます。',
      a4: 'アカウント設定でいつでもサブスクリプションをキャンセルできます。',
      q1: '無料の計画はありますか？',
      q2: 'どのような価格設定計画と支払いオプションが利用可能ですか？',
      q3: '毎月の購読と毎年の購読の違いは何ですか？',
      q4: 'サブスクリプションをキャンセルするにはどうすればよいですか？',
    },
    around: 'その周り',
    billedMonthly: '毎月請求されます',
    billedYearly: '毎年請求されます',
    day: '日',
    desc: '計画を比較し、最高のものを選択してください',
    detailedCompare: '計画を比較します',
    discount: '20％オフ',
    enterprise: {
      benefit1: '優先顧客サポート',
      benefit2: '複数のユーザー',
      benefit3: 'テーラードソリューション',
      benefit5: '専用アカウントマネージャー',
      btn: 'お問い合わせ',
      recommendText: 'カスタムプラン（≥3',
      talkToUs: '連絡してください',
    },
    free: {
      benefit1: 'インスタント翻訳1日/日',
      benefit2: 'ウェブサイトの翻訳と要約',
      benefit3: '30の画像翻訳',
      benefit4: 'ビデオ翻訳と要約',
      benefit5: '10 PDF翻訳',
      benefit6: '5,000トークン',
      btn: '無料でお試しください',
      recommendText: '無料トライアル',
    },
    imageTranslation: '画像翻訳',
    month: '月',
    monthly: '毎月',
    pageTitle: '価格設定',
    pdf: {
      chat: 'PDFチャットクエリ',
      maxPage: 'マックス。 PDFあたりのページ',
      translation: 'PDF翻訳',
    },
    preMonth: '月あたり',
    pro: {
      benefit1: '優先顧客サポート',
      benefit2: '1,000,000トークン/月',
      benefit3: 'ウェブサイトの翻訳と要約',
      benefit4: '200の画像翻訳',
      benefit5: 'ビデオ翻訳と要約',
      benefit6: '200 PDF翻訳',
      btn: '始めましょう',
      recommendText: '最も人気があります',
    },
    proPlus: {
      benefit1: '優先顧客サポート',
      benefit2: '3,000,000トークン/月',
      benefit3: 'ウェブサイトの翻訳と要約',
      benefit4: '500の画像翻訳',
      benefit5: 'ビデオ翻訳と要約',
      benefit6: '500 PDF翻訳',
      recommendText: '最高の価値',
    },
    video: { summary: 'ビデオの概要', translation: 'ビデオ翻訳' },
    web: {
      contrastiveTranslation: 'バイリンガル翻訳',
      dictionaryTranslation: '選択翻訳',
      hoverTranslation: 'ホバー翻訳',
      instantTranslation: 'インスタント翻訳',
      summary: 'Webページの概要',
    },
    yearly: '毎年',
  },
  privacy: {
    block1:
      'ノーム（ "noam、" "we"、 "our"、または "us"）は、あなたのプライバシーを尊重し、あなたまたはあなたについて得る情報を保護することにコミットしています。このプライバシーポリシーは、当社のウェブサイト、アプリケーション、およびサービス（総称して「サービス」）の使用から個人情報の収集または使用に関する当社の慣行について説明しています。このプライバシーポリシーは、APIなどのビジネス製品顧客に代わって処理されるコンテンツには適用されません。このデータの使用は、これらの製品へのアクセスと使用をカバーする顧客契約の対象となります。',
    block10:
      '私たちは、損失、誤用、および不正なアクセス、開示、変更、または敵対的な行為からオンラインおよびオフラインの個人情報を保護するための商業的に合理的な技術的、管理的、および組織的措置を実装します。ただし、インターネットや電子メールの送信では、完全なセキュリティや正確性を保証できません。特に、当社または当社から送信されたメールは安全ではない場合があります。したがって、サービスまたは電子メールを通じて私たちに送信する情報を決定する際には、特に注意する必要があります。さらに、当社は、サービスまたはサードパーティのWebサイトのセキュリティ設定またはセキュリティ対策におけるプライバシー設定またはセキュリティ対策の妨害について責任を負いません。当社は、紛争、セキュリティ、安全上の理由を解決したり、法的義務を遵守するなど、サービスを提供したり、他の正当なビジネス目的で提供するために必要な限りお客様の個人情報を保持します。個人情報を保持する時間の長さは、情報の量、性質、感度、不正使用または開示の潜在的なリスク、情報を処理する目的、その他の法的拘束力のある考慮事項など、多くの要因に依存します。',
    block11:
      'このプライバシーポリシーを随時更新する場合があります。その場合、適用法で特に要求されない限り、このページに更新されたバージョンを投稿します。',
    block12:
      'このプライバシーポリシーに関する未回答の質問や懸念がある場合は、カスタマーサービスにお問い合わせください。メール：',
    block2: '私たちはあなたに関連する次の個人情報（「個人情報」）を収集します。',
    block3:
      'お客様が提供する情報：お客様が当社のサービスを使用するか、当社と通信するためのアカウントを作成する場合、次のように個人情報を収集します。',
    block4:
      'サービスの使用から自動的に受け取った個人情報：サービスにアクセス、使用、または対話する場合、アクセス、使用、または対話に関する次の情報を受け取ります（「技術情報」）：',
    block5: '次の目的で個人情報を使用する場合があります。',
    block6:
      '概要または識別された情報：個人情報を集約または識別して、識別目的で再利用を防ぎ、そのような情報を使用してサービスの有効性を分析し、サービスに機能を改善し、研究を実施し、同様の目的で追加する場合があります。さらに、サービスユーザーの一般的な行動と特性を分析し、集計情報を第三者と共有したり、そのような集計情報を公開したり、一般的に利用可能にしたりする場合があります。私たちは、サービスを通じて、Cookieを通じて、およびこのプライバシーポリシーに記載されている他の手段を通じて集約された情報を収集する場合があります。当社は、匿名または識別されたフォームで廃止された情報を維持および使用し、法律で要求されない限り、そのような情報を再特定しようとはしません。',
    block7:
      '特定のケースでは、法律で要求されない限り、お客様に通知することなく、第三者にお客様の個人情報を提供する場合があります。',
    block8:
      '地理的な場所に応じて、欧州経済圏、英国、および世界的には、個人情報に関して特定の法定権を持つ場合があります。たとえば、次の権利がある場合があります。',
    block9:
      'このサービスには、ソーシャルメディアサービス（「サードパーティのWebサイト」）を含むNOAMによって運用または管理されていない他のWebサイトへのリンクが含まれている場合があります。サードパーティのWebサイトで共有する情報は、このプライバシーポリシーではなく、サードパーティのWebサイトの特定のプライバシーポリシーと利用規約に準拠します。これらのリンクを便利に提供し、これらのWebサイトの承認やレビューを意味するものではありません。プライバシーポリシーと用語に関する情報については、サードパーティのWebサイトに直接お問い合わせください。',
    subBlock1:
      'アカウント情報：プラットフォームでアカウントを作成する場合、お客様の名前、連絡先情報、アカウントの資格情報、支払いカード情報、トランザクション履歴（「アカウント情報」）など、アカウントに関連する情報を収集します。',
    subBlock10: 'サービスの提供、管理、保守、および/または分析。',
    subBlock11: 'あなたとのコミュニケーション;',
    subBlock12: '新製品とサービスの開発。',
    subBlock13:
      '当社のサービスの詐欺、犯罪行為、または誤用を防ぎ、ITシステム、アーキテクチャ、およびネットワークのセキュリティを保護します。',
    subBlock14: 'ビジネス転送の実施。',
    subBlock15:
      '法的義務と法的手続きを遵守し、当社の権利、プライバシー、セキュリティ、または財産、および当社の関連会社、お客様、または他の第三者の権利を保護します。',
    subBlock16:
      'サプライヤーとサービスプロバイダー：ビジネス運用のニーズを満たし、特定のサービスと機能を実行するのを支援するために、ホスティングサービスプロバイダー、クラウドサービスプロバイダー、その他の情報技術サービスプロバイダー、電子メール通信ソフトウェア、およびWeb分析サービスプロバイダーなどを含むサプライヤーとサービスプロバイダーに個人情報を提供する場合があります。',
    subBlock17:
      'ビジネス転送：戦略的取引、再編成、破産、破産の買収、または他のプロバイダーへのサービスの移行（総称して、「取引」）に従事すると、お客様の個人情報やその他の情報は、デューデリジェンス中に取引中に取引を支援する他の当事者と取引の対応者と共有され、他の資産に沿った取引の一部として共有されます。',
    subBlock18:
      '法的要件：当社は、当社の個人情報を政府当局、業界の仲間、または当社のサービスとの相互作用情報を含むその他の第三者と共有する場合があります。 （2）当社の権利または財産を保護および守る。 （3）条件、ポリシー、または法律の違反があると一方的に判断した場合。 （4）詐欺またはその他の違法行為を検出または防止する。 （5）当社の製品、従業員、またはユーザー、または公共の安全、セキュリティ、誠実さを保護する。または（vi）法的責任を防御する。',
    subBlock19:
      'あなたの個人情報とそれがどのように処理されているかについての情報にアクセスします。',
    subBlock2:
      'ユーザーコンテンツ：当社のサービスを使用する場合、入力、ファイルアップロード、または当社に提供されたフィードバック（「コンテンツ」）から個人情報を収集します。',
    subBlock20: '私たちの記録からあなたの個人情報の削除を要求します。',
    subBlock21: '個人情報を修正または更新します。',
    subBlock22: '個人情報を第三者に転送します（データ移植性）。',
    subBlock23: '個人情報の処理方法を制限します。',
    subBlock24:
      'あなたの同意を撤回します - 処理の法的根拠として同意に依存している場合、いつでも同意を撤回できます。',
    subBlock25: 'お客様の個人情報の処理方法に反対します。',
    subBlock26: 'サービスの改善と研究の実施。',
    subBlock3:
      '通信情報：メッセージを送信すると、名前、連絡先情報、およびメッセージの内容（「コミュニケーション情報」）を収集します。',
    subBlock4:
      'ソーシャルメディア情報：Instagram、Facebook、Medium、Twitter、YouTube、LinkedInなどのソーシャルメディアWebサイトにページがあります。ソーシャルメディアページとやり取りするとき、連絡先の詳細（「ソーシャル情報」）など、私たちに提供することを選択した個人情報を収集します。さらに、ソーシャルメディアページをホストしている企業は、ソーシャルメディア活動に関する集計された情報と分析を提供する場合があります。',
    subBlock5:
      'ログデータ：サービスを使用するときにブラウザによって自動的に送信される情報。ログデータには、インターネットプロトコル（IP）アドレス、ブラウザのタイプと設定、リクエストの日付と時刻、および当社のWebサイトとの対話方法が含まれます。',
    subBlock6:
      '使用データ：表示またはやり取りするコンテンツの種類、使用する機能、使用するアクション、タイムゾーン、国、アクセスの日付と時刻、ユーザーエージェントとバージョン、コンピューターまたはモバイルデバイスタイプ、コンピューター接続など、サービスの使用に関する情報を自動的に収集することができます。',
    subBlock7:
      'デバイス情報：これには、デバイスの名前、オペレーティングシステム、デバイス識別子、および使用しているブラウザが含まれます。収集された情報は、使用しているデバイスのタイプとその設定に依存する場合があります。',
    subBlock8:
      'Cookie：Cookieを使用して、サービスを操作および管理し、エクスペリエンスを向上させます。 「Cookie」は、ブラウザにアクセスするWebサイトから送信された情報です。ブラウザを設定して、すべてのCookieを受け入れたり、すべてのCookieを拒否したり、Cookieが提供されているときに通知して、毎回受け入れるかどうかを決定できるようにできます。ただし、Cookieを拒否すると、Webサイトの特定の領域または機能の表示または機能を使用したり、機能したりすることができない場合があります。 Cookieの詳細については、Cookieのすべてをご覧ください。',
    subBlock9:
      '分析：Cookieを使用するさまざまなオンライン分析製品を使用して、ユーザーが当社のサービスを使用する方法を分析し、サービスを使用するときに体験を向上させることができます。',
    subTitle1: '私たちが収集する個人情報',
    subTitle2: '個人情報の使用方法',
    subTitle3: '個人情報の開示',
    subTitle4: 'あなたの権利',
    subTitle5: '他のWebサイトへのリンク',
    subTitle6: '情報のセキュリティと保持',
    subTitle7: 'プライバシーポリシーの変更',
    subTitle8: '私たちに連絡する方法',
    title: 'プライバシーポリシー',
    updateTime: '更新：2024年3月15日',
  },
  privacyCollectionPanel: {
    acceptBtn: 'すべてを受け入れます',
    text: '体験を向上させるために、Cookieを使用してコンテンツとサービスをパーソナライズします。',
    title: 'このWebサイトはCookieを使用しています',
  },
  slogen: '最も簡単なAIアシスタント',
  stripe: {
    contact: '質問があれば、contact@noam.toolsまでお問い合わせください！',
    fail: {
      btn: 'リトライ',
      text1: '支払いを処理できませんでした。',
      text2: '接続を確認して、再試行してください。',
      title: '支払いは失敗しました',
    },
    succ: {
      btn: 'さあ行こう',
      text1: 'ノームへようこそ、',
      text2: '始めましょう！',
      title: 'おめでとう',
    },
  },
  terms: {
    block1: 'ノームを使ってくれてありがとう！',
    block10:
      '（a）料金と請求。該当する価格ページの価格と条件に従って、または当社間の書面で合意されている場合、すべての料金（「料金」）を支払います。請求書を発行したり、支払いを受け取った場合でも、価格設定のエラーや間違いを修正する権利を留保します。有効で承認された支払い方法を含む、完全かつ正確な請求情報を提供します。合意されたとおりに支払い方法を定期的に請求しますが、請求日を合理的に変更する場合があります。 NOAM、その関連会社、およびサードパーティの支払いプロセッサに支払い方法を請求することを許可します。お客様の支払いが失敗した場合、書面による通知を提供し、支払いが受信されるまでサービスへのアクセスを一時停止する場合があります。本契約で別途指定されている場合を除き、支払いは返金不可です。',
    block11:
      '（b）税金。特に明記されていない限り、手数料には、連邦、州、地方、外国の税金、義務、および同様の評価（「税金」）は含まれません。お客様は、お客様の購入に関連するすべての税金について責任を負います。これは、当社がお客様に請求する当社の純利益に基づいて税金を除外します。お客様は、そのような税金を迅速に支払い、法的に有効なプライバシー声明を提供し、そのようなデータを処理するために必要な同意を得ることに同意し、適用法に従ってそのようなデータを処理することを保証します。',
    block12:
      '（c）価格の変化。アカウントやウェブサイトを通じて通知することにより、価格を変更する場合があります。価格の上昇は、投稿するとすぐに有効になります。価格の変更は、変更の発効日後のアカウントの料金に適用されます。',
    block13:
      '（d）紛争と期限切れの支払い。料金または税金に異議を唱えたい場合は、請求書の日付から30日以内にcontact@noam.toolsに連絡してください。',
    block14:
      '（e）無料使用。無料のクレジットを取得するために複数のアカウントを作成することはできません。誠実に無料のクレジットを使用していないと判断した場合、標準料金を請求するか、サービスへのアクセスを停止することができます。',
    block15:
      '（a）機密性。 NOAMの機密情報にアクセスできる場合があります。サービスを使用する目的で、これらの条件に基づいて許可されている機密情報のみを使用できます。機密情報を第三者に開示することはできません。また、少なくとも合理的な注意を払って、独自の同様の機密情報を保護するよりも、保護的な方法で機密情報を保護します。機密情報は、NOAMによって機密として指定されている非公開情報または、ソフトウェア、仕様、その他の非公開事業情報など、状況下で合理的に扱われるべきです。機密情報には、次の情報が含まれていません。（1）あなたの側の過失なしに一般的に一般に利用可能になります。 （2）これらの条件に基づいてそれを受け取る前に、機密性の義務なしにあなたは所有していました。 （3）守秘義務のない第三者によって合法的に開示されています。または（4）機密情報を使用せずに独立して開発されます。法律、裁判所、またはその他の政府命令で要求されている場合は機密情報を開示することができますが、合理的な方法でNOAMに事前の書面による通知を提供し、可能な限り、対立する開示要求における私たちを支援するなど、開示の範囲を制限する合理的な努力をする必要があります。',
    block16:
      '（b）セキュリティ。サービスへのアクセスと使用を保護するために、合理的かつ適切な対策を実装する必要があります。サービスの使用に関連する脆弱性または違反を発見した場合は、すぐにNOAMに連絡し、脆弱性または違反の詳細を提供する必要があります。',
    block17:
      '（c）個人データの処理。サービスを使用して個人データを処理する場合、法的に十分なプライバシーステートメントを提供し、そのようなデータを処理するために必要な同意を得る必要があり、適用法に従ってそのようなデータを処理することを保証する必要があります。',
    block18:
      '（a）終了。サスペンション。これらの条件は、サービスの最初の使用から効果的であり、終了するまで有効になります。サービスとコンテンツの使用を中止することにより、何らかの理由でこれらの規約をいつでも終了できます。何らかの理由で事前の通知でこれらの条件を終了する場合があります。セクション2（使用法要件）、セクション5（機密性、セキュリティ、およびデータ保護）、セクション8（紛争解決）、またはセクション9（一般条件）、または制御を超えたサードパーティのテクノロジープロバイダーとの関係、または法律または政府要求の準拠のために、あなたが実質的に違反した場合、これらの条件をすぐに終了することができます。これらの条件を遵守しなかった場合、または使用が当社または第三者にセキュリティリスクをもたらす場合、または使用が不正であるか、当社または第三者が責任を負う可能性があると疑っている場合、サービスへのアクセスを一時停止する場合があります。',
    block19:
      '（b）終了の影響。終了すると、サービスの使用をやめ、私たちが指示したように機密情報を迅速に返却または破壊します。これらの性質上、セクション3および5-9を含むがこれらに限定されない、その性質上、終了または満了に耐えるべきであるという規定は、生き残るものとします。',
    block2:
      'これらの利用規約は、アプリケーションプログラミングインターフェイス、ソフトウェア、ツール、開発者サービス、データ、ドキュメント、およびWebサイト（「サービス」と呼ばれる）など、NOAMのサービスの使用に適用されます。当社のサービスを使用することにより、お客様はこれらの条件を順守することに同意します。当社のプライバシーポリシーは、個人情報の収集と使用方法を説明しています。',
    block20:
      '（a）補償。お客様は、コンテンツ、サービスの使用、サービスに関連するサービスまたはサービスの使用、およびこれらの条件または適用法の違反など、サービスの使用に関連する請求、損失、および費用（弁護士費用を含む）に無害な私たち、関連会社、および当社の職員を擁護し、補償し、保持します。',
    block21:
      '（b）免責事項。サービスは「現状のまま」提供されます。法律で許可されている範囲で、当社と当社の関連会社とライセンサーは、サービスに関していかなる種類の表明も保証もしません。商品性、満足のいく品質、非侵害、静かな楽しみ、および取引の適用または貿易の使用や使用の手続きの保証を含むがこれらに限定されない保証はありません。サービスが中断されず、正確であるか、エラーがない場合、またはコンテンツが安全であるか、失われないか、変更されないことを保証しません。',
    block22:
      '（c）責任の制限。当社、関連会社、ライセンサーも、そのような損害の可能性についてアドバイスされていても、利益の損失、評判の損失、データの喪失、またはその他の無形の損失を含む、間接的、偶発的、特別な、結果的、または懲罰的損害について責任を負いません。これらの条件では、当社の総責任は、請求の前の12か月または100米ドル（100ドル）のいずれか大きい方でサービスに支払った料金を超えません。このセクションの制限は、適用法で許可されている最大範囲に適用されます。',
    block23:
      '条件とサービスの使用は、米国の法律紛争規則を除き、米国の法律に準拠しています。アプリケーションの使用は、他の地方、州、国内、または国際法の対象となる場合があります。これらの条件に関連する法的措置または訴訟は、米国の裁判所にのみ提起され、お客様はそのような裁判所の個人管轄権に提出することに同意します。',
    block24:
      '（a）当事者の関係。 NOAMとあなたは独立した請負業者であり、どちらの当事者も、相手の事前の書面による同意なしに相手の当事者を代表または拘束したり、相手の義務を引き受ける権限を持っていません。',
    block25:
      '（b）ブランドの使用。 NOAMまたはその関連会社の名前、ロゴ、または商標を事前の書面による同意なしに使用することはできません。',
    block26:
      'これらの規約の任意の規定が無効、違法、または執行不能であると判断された場合、残りの規定は完全に有効になります。',
    block3:
      'あなたが18歳未満の場合、あなたはあなたの両親または法的保護者からサービスを使用する許可を取得する必要があります。他の人または団体に代わってサービスを使用している場合、これらの条件に代わってこれらの条件を受け入れることを許可する必要があります。アカウントに登録するには、正確かつ完全な情報を提供する必要があります。あなたはあなたの組織以外の誰にでもあなたのアクセス資格情報またはアカウントを提供することはできません、そしてあなたはあなたの資格を使用して実施されるすべての活動に対して責任を負います。',
    block4:
      '（a）サービスの使用。これらの条件に基づいてサービスにアクセスすることができ、サービスを使用する非独占的な権利を付与します。サービスを使用する場合、お客様はこれらの条件とすべての該当する法律を遵守します。私たちと当社は、サービスに対するすべての権利、タイトル、利益を所有しています。',
    block5:
      '（b）フィードバック。フィードバック、コメント、アイデア、提案、改善を歓迎します。そのようなコンテンツを提供する場合、私たちは制限なしに、そしてあなたへの補償なしでそれを使用する場合があります。',
    block6:
      '（c）制限。 （1）他者の権利を侵害、誤用、または侵害する方法でサービスを使用することはできません。 （2）リバースエンジニア、逆コンパイル、分解、翻訳、またはその他のその他のその他のその他のその他のその他のその他の方法で、サービスモデル、アルゴリズム、およびシステムの基礎となるコンポーネントを発見しようとします（そのような制限が該当する法律で禁止されていない限り）。 （3）サービスの出力を使用して、NOAMと競合するモデルを開発する。 （4）スクレイピング、ウェブ収穫、またはWebデータ抽出など、APIを介して許可されない限り、自動化またはプログラミング方法を使用して、サービスからデータまたは出力を抽出します。 （5）使用ポリシーがない場合、またはその他の方法で違反している場合、人工的に生成されたサービスの出力を表します。 （6）事前の同意なしにAPIキーを購入、販売、または転送する。または（7）13歳未満またはデジタル同意の年齢の該当する年齢の子供の個人情報を提供します。当社のドキュメントでは、あらゆるレートの制限やその他の要件を遵守します。',
    block7:
      '（d）サードパーティサービス。サードパーティのソフトウェア、サービス、またはサービスに関連するその他の製品は、独自の条件で管理されており、サードパーティ製品については責任を負いません。',
    block8:
      '（a）コンテンツ。入力（「入力」）をサービスに提供し、入力に基づいて生成および返される出力（「出力」）を受信（「コンテンツ」と総称します）。当事者間、および適用法で許可されている範囲で、お客様はすべての入力を所有しています。 NOAMは、コンテンツを使用してサービスを提供および維持し、該当する法律を遵守し、ポリシーを実施する場合があります。お客様は、適用される法律またはこれらの条件に違反しないようにするなど、コンテンツに責任があります。',
    block9:
      '（b）精度。人工知能と機械学習は、急速に進化する研究分野です。私たちは、サービスを改善して、より正確で信頼性が高く、安全で、有益にするよう継続的に努力しています。機械学習の確率的な性質を考えると、当社のサービスを使用すると、特定の場合に誤った出力が生じる可能性があります。これは、実際の個人、場所、または事実を正確に反映していない場合があります。出力を手動で確認することを含め、ユースケースに基づいて出力の精度を適切に評価する必要があります。',
    subTitle1: '登録とアクセス',
    subTitle10: '可読性',
    subTitle2: '使用要件',
    subTitle3: 'コンテンツ',
    subTitle4: '料金と支払い',
    subTitle5: '機密性、セキュリティ、およびデータ保護',
    subTitle6: '用語と終了',
    subTitle7: '補償;免責事項;責任の制限',
    subTitle8: '法律と管轄権',
    subTitle9: '一般用語',
    title: '利用規約',
    updateTime: '更新：2024年3月15日',
  },
  trialModal: {
    btn: '今すぐ始めましょう（わずか0.99ドル',
    chat: 'chatpdf',
    desc1: 'あなたの裁判の後、あなたは180ドルの年会費が請求されます',
    desc2: 'いつでもキャンセルします',
    focus: '焦点',
    hover: 'ホバー翻訳',
    image: '画像翻訳',
    installBtn: '今すぐインストールしてギフトを受け取ります',
    memo: 'お気に入りの管理',
    pdf: 'PDF並列変換',
    realtime: 'インスタント翻訳',
    select: '単語選択翻訳',
    title: 'ようこそギフト',
    trialText: '7日間の試験',
    video: 'ビデオ翻訳',
    videoSummary: 'ビデオの概要',
    web: '平行翻訳',
    webSummary: 'Webサマリー',
  },
  upgradeModal: {
    btn: '今すぐアップグレードしてください',
    chat: { free: 'chatpdf制限に達しました', pro: '今月のCHATPDFの制限に達しました' },
    contactStr: '次のリセットを待つか、contact@noam.toolsまでお問い合わせください。',
    limitAlertStr: 'あなたはあなたの無料プランの制限に達しました。続行するためにアップグレード。',
    more: '計画を表示します',
    pageLimit: { free: 'PDFページの制限に達しました' },
    title: 'リマインダー',
    upgradeAlertStr: 'あなたはあなたの無料プランの制限に達しました。続行するためにアップグレード。',
    upload: { free: 'PDFの翻訳制限に達しました', pro: '今月はPDF翻訳の制限に達しました' },
  },
  welfare: {
    newUser: {
      joinBtn: '今すぐ参加してください',
      label: '新しいユーザーの利点：',
      remain: '残り30のみ',
      text: '利用可能な100スポットのみ！今すぐあなたをつかんでください！',
    },
  },
}

export default TRANSLATION
