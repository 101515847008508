const TRANSLATION = {
  buyModal: {
    afterFree: 'Po vaší bezplatné zkušební verzi',
    btn: 'Zkuste to zdarma',
    freeTrial: '3denní bezplatná zkušební verze',
    monthLabel: 'měsíc',
    selectLabel: 'Vyberte plán',
    title: 'Plán předplatného',
    yearLabel: 'rok',
  },
  contact: {
    block1:
      'Pro přizpůsobení, otázky nebo návrhy nás prosím kontaktujte na adrese contact@noam.tools. Okamžitě odpovíme.',
    block2: 'Podporujte nás tím, že nás hodnotíte v obchodě Google Apps Extension Store v ★★★★★.',
    block3: 'Pomozte ostatním sdílením tohoto rozšíření:',
    title: 'Kontaktujte nás',
  },
  help: {
    AquickOverviewOfNoam: 'Rychlý přehled NOAM',
    ChatPDF: 'Chat pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Oblíbené vedení',
    Fixedplugin: 'Pevný plugin',
    Focusing: 'Zaostření',
    Free: 'Uvolnit',
    FreeTrialBenefits: 'Bezplatné zkušební výhody',
    GoWithWikipedia: 'Jděte s Wikipedií',
    GoWithYouTube: 'Jděte s YouTube',
    HoverTranslation: 'Překlad vznáší',
    Howtouse: 'Jak používat',
    ImageTranslation: 'Překlad obrázku',
    InstantTranslation: 'Okamžitý překlad',
    Membership: 'Členství',
    NoamHelp: 'NOAM vám pomůže překládat a shrnout',
    PDFparallelTranslation: 'PDF paralelní překlad',
    ParallelTranslation: 'Paralelní překlad',
    SkipAllSteps: 'Přeskočit všechny kroky',
    Summary: 'Shrnutí',
    Translation: 'Překlad',
    TryItForFree: 'Zkuste to zdarma',
    Video: 'Video',
    VideoSummary: 'Shrnutí videa',
    VideoTranslation: 'Překlad videa',
    WebSummary: 'Shrnutí webu',
    WordSelectionTranslation: 'Překlad výběru slov',
    benefits: 'Čekáme na vás výhody!',
    dayFree: '3denní bezplatná zkušební verze',
    done: 'Hotovo',
    next: 'Další',
    pin: 'Pin NOAM pro snazší přístup ～～',
    planIsNotExist: 'Plán není k dispozici',
    startWiki: 'Začněme s Wikimedia!',
    startYoutube: 'Začněme na YouTube!',
    try: 'Zkuste to',
    tryNow: 'Zkuste to hned!',
  },
  home: {
    PDFTranslate: {
      desc: 'Dvojjazyčný překlad + chat PDF',
      sub1: {
        desc: 'Dvojjazyčné čtení umožňuje porovnat původní text a překlad vedle sebe a zlepšit účinnost čtení.',
        title: 'Dvojjazyčné čtení',
      },
      sub2: {
        desc: 'AIS analýzy a shrnutí a mohou odpovědět na vaše otázky na základě obsahu PDF.',
        title: 'Chat pdf',
      },
      sub3: {
        desc: 'Překlad PDF poháněný AI pro větší přesnost a účinnost.',
        title: 'Přesnost a efektivita',
      },
      title: 'PDF překládat',
    },
    aiAssistant: {
      desc: 'Učinit webový překlad chytřejší a pohodlnější',
      panel1: {
        desc: 'Dvojjazyčné překlady křížového odkazování vám pomohou porovnat původní text s překladem, abyste mohli lépe porozumět a zlepšit efektivitu čtení.',
        title: 'Dvojjazyčný překlad',
      },
      panel2: {
        left: {
          desc: 'Při procházení webové stránky se nasaďte nad obsahem, který musíte přeložit, abyste viděli překlad.',
          title: 'Překlad vznáší',
        },
        right: {
          desc: 'Při procházení webové stránky vyberte text, který chcete přeložit a zobrazit překlad.',
          title: 'Překlad výběru textu',
        },
      },
      panel3: {
        one: {
          desc: 'Přeložit a shrnout webové stránky a video obsah kdykoli a kdekoli, pro hladší zážitek!',
          title: 'Bod a klikněte',
        },
        three: {
          desc: 'Velký jazykový model řízený AI zajišťuje přesnost a konzistenci překladu pro zvýšený zážitek ze čtení.',
          title: 'AI řízen',
        },
        two: {
          desc: 'Úplné pokrytí více jazyků, dvojjazyčné čtení bez bariéry, překlad jednoho kliknutí-zažijte rozdíl!',
          title: 'Komplexnější a pohodlnější',
        },
      },
      panel4: {
        tab1: {
          desc: 'Váš odborník na efektivitu - Používání ChatGPT, NOAM překládá a shrnuje video obsah YouTube, aby vám ušetřil čas!',
          key: 'Video',
          title: 'Překlad a shrnutí videa',
        },
        tab2: {
          desc: 'Kliknutím překládáte obrázky při procházení webu.',
          key: 'Obraz',
          title: 'Překlad obrázku',
        },
        tab3: {
          desc: 'Okamžitě extrahuje klíčové informace z webových stránek a poskytuje shrnutí a zvyšuje efektivitu čtení.',
          key: 'Web',
          title: 'Webový překlad a shrnutí',
        },
        tag1: 'Jednoduchý',
        tag2: 'Účinný',
        tag3: 'Snadno pochopitelné',
      },
      title: 'AIS asistent',
    },
    getStart: 'Začněte',
    getStartedForFree: 'Začněte zdarma',
    howItWork: {
      desc: 'NOAM integruje modely AI tak, aby uživatelům poskytovala inteligentní zážitek pro překlad a shrnutí webové stránky mezi jazykem, překlad PDF a obrazu, překlad videa a shrnutí',
      title: 'Jak to funguje',
    },
    lastBlock: { desc: 'Lepší překlad AI pro hladší zážitek ze čtení' },
    top: {
      desc: 'NOAM je bezplatné rozšíření AI prohlížeče, které vám pomůže překládat a shrnout webové stránky, videa a obsah PDF.',
      title1: 'Jedna magie',
      title2: 'Jedno kliknutí',
    },
    userReviews: {
      desc: 'Pětihvězdičkové recenze v webovém obchodě Chrome',
      title: 'Uživatelské recenze',
    },
  },
  installTips: {
    btn: 'Zkuste to hned',
    text: 'Nainstalujte plugin „Noam prohlížeč“ pro odemknutí dalších funkcí! Web Cross-Reference, \\ n webové video shrnutí a další!',
  },
  locale: {
    af: 'afrikánština',
    am: 'Amharic',
    ar: 'arabština',
    as: 'Assamese',
    auto: 'Automatické detekce',
    az: 'Ázerbájdžán',
    ba: 'Bashkir',
    bg: 'bulharský',
    bn: 'Bangla',
    bo: 'Tibetan',
    bs: 'Bosnian',
    ca: 'Katalánsko',
    cs: 'čeština',
    cy: 'velština',
    da: 'dánština',
    de: 'Němec',
    dsb: 'Dolní sorbian',
    dv: 'Divehi',
    el: 'řecký',
    en: 'angličtina',
    es: 'španělština',
    et: 'estonština',
    eu: 'Baskicka',
    fa: 'Peršan',
    fi: 'Finský',
    fil: 'Filipín',
    fj: 'Fidžian',
    fr: 'francouzština',
    frCA: 'Francouzština (Kanada)',
    ga: 'irština',
    gl: 'Galicijský',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebrejština',
    hi: 'hindština',
    hr: 'chorvatský',
    hsb: 'Horní sorbian',
    ht: 'Haitian Creole',
    hu: 'maďarský',
    hy: 'Arménština',
    id: 'indonéština',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandský',
    it: 'italština',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latina)',
    ja: 'japonský',
    ka: 'Georgian',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurdský (severní)',
    kn: 'Kannada',
    ko: 'korejština',
    ku: 'Kurdský (centrální)',
    ky: 'Kyrgyz',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Litevský',
    lug: 'Ganda',
    lv: 'lotyšský',
    lzh: 'Číňan (literární)',
    mai: 'Maithili',
    mg: 'Malagasy',
    mi: 'Māori',
    mk: 'Makedonian',
    ml: 'Malayalam',
    mnCyrl: 'Mongolský (Cyrillic)',
    mnMong: 'Mongolský (tradiční)',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'maltština',
    mww: 'Hmong Daw',
    my: 'Myanmar (Barmse)',
    nb: 'Norwegian',
    ne: 'Nepál',
    nl: 'holandský',
    noSearchLang: 'Jazyk není podporován',
    nso: 'Sesotho sa leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'polština',
    placeholder: 'Vyhledávání',
    prs: 'Dari',
    ps: 'Pašto',
    pt: 'Portugalština (Brazílie)',
    ptPT: 'Portugalština (Portugalsko)',
    ro: 'rumunština',
    ru: 'ruština',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovák',
    sl: 'slovinský',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somálský',
    sq: 'Albánština',
    srCyrl: 'Srbská (cyrillic)',
    srLatn: 'Srbci (latina)',
    st: 'Sesotho',
    sv: 'švédský',
    sw: 'Svahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tlhLatn: 'Klingon (latina)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongan',
    tr: 'turečtina',
    tt: 'Tatar',
    ty: 'Tahitian',
    ug: 'Uyghur',
    uk: 'ukrajinština',
    ur: 'Urdu',
    uz: 'Uzbek (latina)',
    vi: 'vietnamština',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Cantonese (tradiční)',
    zh: 'čínština',
    zhHans: 'ERROR: [Chinese Simplified]',
    zhHant: 'Čínský tradiční',
    zu: 'Zulu',
  },
  login: {
    and: 'a',
    btn: 'Přihlaste se s Googlem',
    btn1: 'Přihlaste se hned',
    desc: 'Přihlaste se a odemkněte další zkušenosti',
    policy: 'Zásady ochrany osobních údajů',
    terms: 'Podmínky použití',
    tips: 'Přihlášením souhlasím s',
    title: 'Vítejte v Noamu',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Následovat', twitter: 'Cvrlikání' },
    legal: { policy: 'Zásady ochrany osobních údajů', terms: 'Podmínky služby', title: 'Právní' },
    product: {
      image: 'Překlad obrázku',
      pdf: 'PDF překlad',
      title: 'Produkty',
      video: 'Překlad a shrnutí videa',
      web: 'Webový překlad a shrnutí',
    },
    resources: { title: 'Zdroje' },
  },
  pageHeader: {
    contact: 'Kontakt',
    help: 'Pomoc',
    logout: 'Odhlaste',
    logoutSucc: 'Přihlášeno.',
    pricing: 'Ceny',
    settings: 'Nastavení',
    signUp: 'Přihlaste se',
  },
  pdf: {
    autoDetect: 'Automatické detekce',
    cancelBtn: 'Zrušit',
    cancelUpload: 'Zrušit',
    chatPdf: {
      summaryError: 'Shrnutí selhalo, zkuste to prosím znovu později',
      chatError: 'Chat selhal, zkuste to prosím znovu později',
      Search: 'Vyhledávání',
      cancel: 'Zrušit',
      chatTitle: 'Vítejte v Noamu! Zde je shrnutí vašeho PDF:',
      clear: 'Jasný',
      clearContent: 'To zahájí novou konverzaci. Současná konverzace nebude uložena. Jsi si jistý?',
      clearDialogTitle: 'Potvrďte prosím',
      clearSucc: 'Vyčištěno',
      copy: 'Kopie',
      copySucc: 'Zkopírováno',
      delete: 'Vymazat',
      deleteCancel: 'Zrušit',
      deleteContent: 'Tento PDF nelze získat po smazání. Jsi si jistý?',
      deleteOk: 'Vymazat',
      deleteSuccess: 'Smazáno',
      deleteTitle: 'Potvrďte odstranění',
      expand: 'Kolaps',
      historyTitle: 'Dějiny',
      listError:
        'Nepodařilo se načíst seznam čtení. Zkuste to prosím znovu později nebo kontaktujte podporu.',
      loading: 'Načítání ...',
      noDataDesc: 'Zatím žádná historie.',
      noMore: 'Žádný další obsah.',
      placeholder: 'Zeptejte se na tento PDF',
      summaryTitle: 'Možná vás tyto otázky zajímají:',
    },
    collectionEmpty: 'Vaše sbírka je prázdná.',
    collectionTab: 'Poznámky',
    dataLoading: 'Načítání ...',
    dialogContent: 'Jste si jisti, že to chcete smazat?',
    dialogTitle: 'Potvrďte prosím',
    download: {
      both: 'Bilingvní',
      btn: 'Uložit',
      cancel: 'Uzavření tohoto okna zruší stahování PDF. Jste si jisti, že chcete zrušit?',
      desc: 'Uložit do zařízení: Stahování překladů jako obrázky; Uložit a tisk: Stahování překladů v původním formátu s podporou kopírování.',
      download: 'Stáhnout',
      downloadMsg1: 'PDF PŘEDCHOZÍ PROGREMN:',
      downloadMsg2: 'Odhadovaný čas zbývající:',
      downloadSucc: 'PDF je připraven! Kliknutím uložíte.',
      downloadTip: 'Stahování PDF. Nezavřete toto okno, abyste se vyhnuli chybám stahování.',
      error: 'Nepodařilo se to ušetřit. Zkuste místo toho tisk.',
      fileName: 'Název souboru:',
      fileType: 'Typ souboru:',
      loading: 'Úspora ...',
      loading2: 'Načítání ...',
      minute: 'Zápis...',
      msg1: 'Některé překlady v tomto dokumentu jsou delší než původní text a mohou se ve staženém souboru překrývat. Doporučujeme číst na webové stránce.',
      msg2: '1. Překlad ještě není úplný. Stáhněte si prosím po dokončení překladu.',
      msg3: '2. Některé překlady jsou příliš dlouhé. Přečtěte si na webové stránce, abyste se vyhnuli překrývání textu.',
      msg4: 'Probíhají překlady. Po dokončení to zkuste znovu.',
      progress:
        'Překlad PDF. Progress: {{Progress}}, Odhadovaný čas zbývající: {{minuty}} minuty ...',
      save: 'Uložit do zařízení',
      savePrint: 'Uložit a tisk',
      success: 'Stažené',
      trans: 'Pouze překlad',
      transContinue: 'Pokračujte v překladu',
    },
    downloadBtn: 'Stáhnout',
    dragTipModal: {
      black: 'plocha',
      leftKey: 'levé tlačítko',
      ok: 'Mám to',
      press: 'Stiskněte',
      tips: 'Přetáhnout a zobrazit.',
    },
    entry: 'PDFS',
    fileErrMsg: 'Čtení souboru selhalo. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    fileMsg: 'Aktuální velikost souboru:',
    fileMsg2: 'Nahrajte prosím soubor menší než 300 MB.',
    freeBannerBtn: 'Upgrade',
    freeBannerTips: 'Omezený limit překladů PDF',
    guide: {
      placeholder: 'Vložte odkaz PDF zde',
      start: 'Začněte překládat',
      text1: 'Dvojjazyčné čtení',
      text2:
        'Přečtěte si dvojjazyčně, abyste porovnali původní text a překlad vedle sebe a zlepšili vaši účinnost.',
      text3: 'Chatujte s vaším PDF',
      text4: 'Analýza a shrnutí poháněná AI. Zeptejte se na vaše PDF a získejte okamžité odpovědi.',
      text5: 'Přesné a efektivní',
      text6: 'Překlad PDF poháněný AI pro větší přesnost a účinnost.',
      title1: 'Dvojjazyčný překlad',
      title2: 'Chatujte s vaším PDF',
      toast: 'Zadejte prosím platný online odkaz PDF končící v .pdf',
    },
    hasTransTask: 'Probíhá překladový úkol. Počkejte prosím.',
    hoverTrans: 'Umístění odstavců',
    initial: 'Přeložit PDF',
    installText: 'Vaše jednorázové řešení pro každodenní překlady.',
    installText2: 'Nejprve nainstalujte příponu',
    installTips:
      'Nainstalujte rozšíření pro ještě více překladů PDF a hladší překlady webových stránek:',
    kw: 'sešík',
    link: 'odkaz',
    listError: 'Nepodařilo se načíst PDF. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    memoTotalCount: '1 položka celkem',
    new: 'nový soubor',
    noId: 'ID souboru není nalezeno.',
    notPdf: 'Pouze soubory PDF.',
    okBtn: 'Vymazat',
    okBtn2: 'Mám to',
    original: 'Originál',
    parallel: 'Paralelní',
    parseErrDialogContent:
      'Tento odkaz PDF nelze přeložit přímo. Stáhněte si prosím PDF a nahrajte jej pro překlad.',
    parseErrTips: 'Nepodařilo se analyzovat soubor',
    parseFileToast:
      'Nepodařilo se analyzovat soubor. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    pdfLoading: 'Analýza ...',
    searchPlaceholder: 'Vyhledávání',
    selectTrans: 'Vyberte text pro překlad',
    shareBtn: 'Podíl',
    shareBtnText1: 'Generovat odkaz na sdílení',
    shareBtnText2: 'Aktualizace sdíleného obsahu',
    shareModal: {
      cancel: 'Zrušit',
      copy: 'Kopie',
      copySucc: 'Odkaz zkopírován',
      custom: 'Přizpůsobte:',
      errMsg: 'Vybraný obsah ještě nebyl plně přeložen. Přeložte prosím před sdílením.',
      gen: 'Podíl',
      modify: 'Upravit',
      notSupport: 'Tento jazyk není v současné době podporován.',
      page: 'Stránky',
      pageNuErr: 'Zadejte platné číslo stránky.',
      range: 'Rozsah:',
      remain: 'zbývající',
      remain1: 'Celková odhadovaná doba:',
      search: 'Vyhledávání',
      settings: 'Nastavení sdílení:',
      shareSetting: 'Sdílet nastavení',
      shareText: 'Podíl',
      shareTips: 'Sdílejte přeložený odkaz PDF se svými přáteli:',
      sourceLang: 'Z:',
      target: 'Na:',
      text1: 'Sdílet nastavení',
      text2: 'Generovat odkaz na sdílení',
      totalPage: '1 stránka celkem',
      trans: 'Přeložit',
      translateTips: 'Tip: Pokud překlad trvá chvíli, můžete toto okno zavřít a sdílet později.',
      translating: 'Překlad PDF ...',
      update: 'Aktualizovat odkaz na sdílení',
    },
    shareMsg1: 'Generovaný odkaz sdílení.',
    shareMsg2: 'Aktualizován sdílený obsah.',
    shareMsg3:
      'Nepodařilo se vygenerovat odkaz na sdílení. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    shareMsg4:
      'Nepodařilo se aktualizovat sdílený obsah. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    shareTips: 'Váš překlad PDF je připraven! Sdílejte to se svými přáteli.',
    start: 'Začněte svou bezplatnou zkušební verzi',
    thumbnail: 'Obsah',
    toast1: 'Načítání PDF. Zkuste to prosím znovu později.',
    toast2: 'Obsah PDF je příliš velký. Dialog je v současné době nepodporován.',
    toast3: 'Stahování. Po dokončení stahování to prosím zkuste znovu.',
    toolbar: { Adjust: 'Upravit', autoFit: 'Automatická fit' },
    trans: 'Překlad',
    transSucc: 'Překlad úspěšný!',
    unLoginToast: 'Přihlaste se prosím a použijte tuto funkci.',
    uploadBtn: 'Nahrát',
    uploadErr:
      'Nahrávání souboru selhalo. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    uploadErrSignUrl:
      'Nepodařilo se dostat nahrát URL. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    uploadMsg1: 'Přetáhněte svůj soubor zde.',
    uploadMsg2: 'Podporované typy souborů: PDF |  Maximální velikost souboru: 100 MB',
    uploadMsg3: 'Kliknutím vyberte nebo přetáhněte soubory zde.',
    uploading: 'Nahrávání',
    uploadingMsg: 'Nahrávání. Počkejte prosím.',
    uploadingMsg1: 'Soubor nahrává. Zkuste to prosím znovu později.',
    uploadingMsg2: 'Soubor se v současné době převádí. Zkuste to prosím znovu později.',
    uploadingMsg3:
      'Požadavek na přeměnu selhal. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    uploadingMsg4: 'NOAM nemůže přistupovat k místním souborům. Ručně nahrajte PDF pro překlad.',
    uploadingMsg5:
      'Nepodařilo se načíst URL souboru. Zkuste to prosím znovu později nebo kontaktujte podporu.',
    video: 'video',
    vipBannerBtn: 'Upgrade',
    vipBannerTips: 'Pro tento měsíc jste dosáhli svého překladového limitu PDF.',
    webTrans: 'Úplný křížový odkaz',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Ano, NOAM nabízí bezplatný plán, který můžete hned použít.',
      a2: 'Nabízíme tři členské plány: Pro, Pro+a Enterprise. Můžete se rozhodnout platit měsíčně nebo ročně. Roční fakturace nabízí významné úspory.',
      a3: 'Všechny funkce jsou k dispozici na obou plánech. Hlavním rozdílem jsou náklady: roční předplatné stojí celkově méně. Každý měsíc se účtuje měsíční předplatné, zatímco roční plány jsou účtovány jednou ročně.',
      a4: 'Předplatné můžete zrušit kdykoli v nastavení účtu.',
      q1: 'Existuje plán zdarma?',
      q2: 'Jaké cenové plány a možnosti plateb jsou k dispozici?',
      q3: 'Jaký je rozdíl mezi přihlášením měsíčního vs. ročně?',
      q4: 'Jak zruším své předplatné?',
    },
    around: 'Kolem',
    billedMonthly: 'Účtováno měsíčně',
    billedYearly: 'Účtováno ročně',
    day: 'den',
    desc: 'Porovnejte plány a vyberte to nejlepší',
    detailedCompare: 'Porovnejte plány',
    discount: '20% sleva',
    enterprise: {
      benefit1: 'Prioritní zákaznická podpora',
      benefit2: 'Více uživatelů',
      benefit3: 'Řešení na míru',
      benefit5: 'Vyhrazený správce účtu',
      btn: 'Kontaktujte nás',
      recommendText: 'Vlastní plány （≥ 3 人）',
      talkToUs: 'Spojte se',
    },
    free: {
      benefit1: 'Okamžitý překlad 100krát/den',
      benefit2: 'Překlad a shrnutí webových stránek',
      benefit3: '30 překladů obrázků',
      benefit4: 'Překlad a shrnutí videa',
      benefit5: '10 překladů PDF',
      benefit6: '5 000 žetonů',
      btn: 'Zkuste to zdarma',
      recommendText: 'Zkušební zkouška zdarma',
    },
    imageTranslation: 'Překlad obrázku',
    month: 'měsíc',
    monthly: 'Měsíční',
    pageTitle: 'Ceny',
    pdf: { chat: 'PDF chat dotazy', maxPage: 'Max. Stránky na PDF', translation: 'PDF překlad' },
    preMonth: 'Za měsíc',
    pro: {
      benefit1: 'Prioritní zákaznická podpora',
      benefit2: '1 000 000 žetonů/měsíc',
      benefit3: 'Překlad a shrnutí webových stránek',
      benefit4: '200 překladů obrázků',
      benefit5: 'Překlad a shrnutí videa',
      benefit6: '200 překladů PDF',
      btn: 'Začněte',
      recommendText: 'Nejoblíbenější',
    },
    proPlus: {
      benefit1: 'Prioritní zákaznická podpora',
      benefit2: '3 000 000 žetonů/měsíc',
      benefit3: 'Překlad a shrnutí webových stránek',
      benefit4: '500 překladů obrázků',
      benefit5: 'Překlad a shrnutí videa',
      benefit6: '500 překladů PDF',
      recommendText: 'Nejlepší hodnota',
    },
    video: { summary: 'Shrnutí videa', translation: 'Překlad videa' },
    web: {
      contrastiveTranslation: 'Dvojjazyčný překlad',
      dictionaryTranslation: 'Výběrový překlad',
      hoverTranslation: 'Překlad vznáší',
      instantTranslation: 'Okamžitý překlad',
      summary: 'Shrnutí webové stránky',
    },
    yearly: 'Roční',
  },
  privacy: {
    block1:
      'Noam („Noam“, „my“, „náš“ nebo „nás“) respektuje vaše soukromí a je odhodlán chránit jakékoli informace, které od vás získáme. Tyto zásady ochrany osobních údajů popisují naše postupy týkající se shromažďování nebo používání osobních údajů z vašeho používání našich webových stránek, aplikací a služeb (společně „Služby“). Tyto zásady ochrany osobních údajů se nevztahují na obsah, který řešíme, jménem našich zákazníků obchodního produktu, jako je naše API. Použití těchto údajů podléhá naší zákaznické dohodě, která zahrnuje přístup a používání těchto produktů.',
    block10:
      'Provádíme komerčně přiměřená technická, administrativní a organizační opatření k ochraně osobních a offline osobních údajů před ztrátou, zneužitím a neoprávněným přístupem, zveřejněním, změnou nebo nepřátelské činy. Žádný přenos internetu nebo e -mailu však nemůže zaručit úplnou zabezpečení nebo přesnost. Zejména e -maily zaslané nám nebo od nás nemusí být bezpečné. Při rozhodování o tom, jaké informace nám posílat prostřednictvím služby nebo e -mailu, byste tedy měli být obzvláště opatrní. Kromě toho nejsme zodpovědní za žádné překážky nastavení ochrany osobních údajů nebo bezpečnostních opatření v nastavení nebo bezpečnostních opatřeních třetích stran. Vaše osobní údaje si ponecháme tak dlouho, jak je to nutné, abychom vám poskytli služby nebo pro jiné legitimní obchodní účely, jako je řešení sporů, bezpečnostní a bezpečnostní důvody nebo dodržování našich zákonných povinností. Doba, po kterou si uchováváme osobní údaje, bude záviset na mnoha faktorech, jako je částka, povaha, citlivost informací, potenciální rizika neoprávněného použití nebo zveřejnění, účely, pro které zpracováváme informace, a další právně závazné úvahy.',
    block11:
      'Tyto zásady ochrany osobních údajů můžeme čas od času aktualizovat. Když tak učiníme, zveřejníme na této stránce aktualizovanou verzi, pokud není podle příslušného práva vyžadováno jinak.',
    block12:
      'Pokud máte nějaké nezodpovězené otázky nebo obavy ohledně těchto zásad ochrany osobních údajů, kontaktujte prosím náš zákaznický servis. E-mail:',
    block2: 'Shromažďujeme následující osobní údaje („osobní údaje“) související s vámi:',
    block3:
      'Informace poskytnuté: Když si vytvoříte účet, který budete používat naše služby nebo s námi komunikovat, shromažďujeme osobní údaje takto:',
    block4:
      'Osobní údaje automaticky obdržely z vašeho používání služeb: Když se získáte, používáte nebo komunikujete se službami, dostáváme následující informace o vašem přístupu, používání nebo interakci („technické informace“):',
    block5: 'Pro následující účely můžeme použít osobní údaje:',
    block6:
      'Souhrnné nebo de-identifikované informace: Můžeme agregovat nebo de-identifikovat osobní údaje, abychom zabránili jeho opětovnému použití pro identifikační účely a používali takové informace k analýze účinnosti našich služeb, zlepšení a přidávání funkcí do našich služeb, provádění výzkumu a pro podobné účely. Kromě toho můžeme analyzovat obecné chování a charakteristiky našich uživatelů služeb a sdílet agregované informace s třetími stranami, zveřejňovat takové agregované informace nebo je obecně zpřístupnit. Můžeme shromažďovat agregované informace prostřednictvím služeb, prostřednictvím souborů cookie a dalšími prostředky popsanými v těchto zásadách ochrany osobních údajů. Budeme udržovat a používat de-identifikované informace v anonymní nebo de-identifikované formě a nebudeme se pokusit tyto informace znovu identifikovat, pokud to není vyžadováno zákonem.',
    block7:
      'V některých případech můžeme vaše osobní údaje poskytnout třetím stranám bez dalšího upozornění, pokud to není požadováno zákonem:',
    block8:
      'V závislosti na vaší geografické poloze mohou jednotlivci v Evropském hospodářském prostoru, ve Velké Británii a globálně mít určitá zákonná práva týkající se jejich osobních údajů. Například můžete mít právo na:',
    block9:
      'Služba může obsahovat odkazy na jiné webové stránky, které nejsou provozovány nebo kontrolovány NOAM, včetně služeb sociálních médií („webové stránky třetích stran“). Informace, které sdílíte s webovými stránkami třetích stran, se budou řídit konkrétními zásadami ochrany osobních údajů a podmínky služby webových stránek třetích stran, nikoli podle těchto zásad ochrany osobních údajů. Tyto odkazy poskytujeme pro pohodlí a neznamenáme naše potvrzení nebo kontrolu těchto webových stránek. Informace o jejich zásadách a podmínkách ochrany osobních údajů kontaktujte přímo webové stránky třetích stran.',
    subBlock1:
      'Informace o účtu: Když vytvoříte účet na naší platformě, shromažďujeme informace týkající se vašeho účtu, včetně vašeho jména, kontaktních informací, pověření účtu, informací o platebních kartách a historie transakcí („Informace o účtu“).',
    subBlock10: 'Poskytování, správa, údržbu a/nebo analýzy;',
    subBlock11: 'Komunikovat s vámi;',
    subBlock12: 'Vývoj nových produktů a služeb;',
    subBlock13:
      'Prevence podvodů, trestné činnosti nebo zneužití našich služeb a ochrana bezpečnosti našich IT systémů, architektury a sítí;',
    subBlock14: 'Provádění obchodních převodů;',
    subBlock15:
      'Dodržování zákonných povinností a právních řízení, ochrany našich práv, soukromí, bezpečnosti nebo majetku, jakož i práva našich přidružených společností, vy nebo jiné třetí strany.',
    subBlock16:
      'Dodavatelé a poskytovatelé služeb: Abychom nám pomohli při uspokojování obchodních provozních potřeb a provádění určitých služeb a funkcí, můžeme poskytovat osobní údaje dodavatelům a poskytovatelům služeb, včetně poskytovatelů hostingu, poskytovatelů cloudových služeb a dalších poskytovatelů informačních technologií, e -mailové komunikační software a poskytovatelé webových analytiků a poskytovatelů služeb webových analytických služeb, atd. Tito partneři budou mít přístup, zpracovávat nebo ukládat osobní informace pro nás.',
    subBlock17:
      'Obchodní převody: Pokud se zapojíme do strategických transakcí, reorganizací, bankrotů, převzetí bankrotu nebo přechodu služeb k jinému poskytovateli (společně, „transakce“), vaše osobní údaje a další informace mohou být sdíleny s transakčními protějšky a dalšími stranami, které pomáhají při transakcích a převáděny v rámci transakcí spolu s dalšími přístupovými společnostmi nebo afiluálními společnostmi.',
    subBlock18:
      'Zákonné požadavky: Vaše osobní údaje můžeme sdílet s vládními orgány, průmyslovými vrstevníky nebo jinými třetími stranami, včetně vašich informací o interakcích s našimi službami, (1), pokud je to vyžadováno zákonem, nebo pokud se domníváme, že taková opatření je nezbytná k splnění zákonných povinností; 2) chránit a bránit naše práva nebo majetek; (3) pokud jednostranně zjistíme, že dochází k porušení našich podmínek, politik nebo zákonů; (4) odhalit nebo předcházet podvodům nebo jiným nezákonným činnostem; (5) chránit naše výrobky, zaměstnance nebo uživatele nebo veřejnou bezpečnost, zabezpečení, integritu; nebo (vi) bránit se proti právní odpovědnosti.',
    subBlock19: 'Přístup k vašim osobním údajům a informacím o tom, jak je zpracován.',
    subBlock2:
      'Uživatel obsah: Když používáte naše služby, shromažďujeme osobní údaje z vašich vstupů, nahrávání souborů nebo zpětné vazby poskytnuté („Obsah“).',
    subBlock20: 'Požádejte o odstranění vašich osobních údajů z našich záznamů.',
    subBlock21: 'Opravte nebo aktualizujte své osobní údaje.',
    subBlock22: 'Přeneste své osobní údaje na třetí stranu (přenositelnost dat).',
    subBlock23: 'Omezte, jak zpracováváme vaše osobní údaje.',
    subBlock24:
      'Získejte svůj souhlas - kde se spoléháme na souhlas jako právní základ pro zpracování, můžete svůj souhlas kdykoli zrušit.',
    subBlock25: 'Namítá to, jak zpracováváme vaše osobní údaje.',
    subBlock26: 'Zlepšení našich služeb a provádění výzkumu;',
    subBlock3:
      'Informace o komunikaci: Pokud nám posíláte zprávy, shromažďujeme vaše jméno, kontaktní informace a obsah vašich zpráv („Komunikační informace“).',
    subBlock4:
      'Informace o sociálních médiích: Máme stránky na webových stránkách sociálních médií, jako jsou Instagram, Facebook, Medium, Twitter, YouTube a LinkedIn. Když komunikujete s našimi stránkami sociálních médií, shromažďujeme osobní údaje, které nám rozhodnete poskytnout, například vaše kontaktní údaje („Sociální informace“). Společnosti, které hostují naše stránky sociálních médií, nám mohou poskytnout agregované informace a analýzu o našich aktivitách sociálních médií.',
    subBlock5:
      'Data protokolu: Informace automaticky odeslány prohlížečem při používání našich služeb. Data protokolu zahrnují vaši adresu internetového protokolu (IP), typ a nastavení prohlížeče, datum a čas vašich požadavků a způsob, jak komunikujete s naším webem.',
    subBlock6:
      'Data použití: Můžeme automaticky shromažďovat informace o vašem používání služby, jako jsou typy obsahu, s nimiž zobrazíte, funkce, které používáte, akce, které podnikáte, stejně jako vaše časové pásmo, země, datum a čas přístupu, uživatelské a verze, typ počítače nebo mobilního zařízení a připojení počítače.',
    subBlock7:
      'Informace o zařízení: To zahrnuje název zařízení, operační systém, identifikátor zařízení a prohlížeč, který používáte. Shromážděné informace mohou záviset na typu zařízení, které používáte, a jeho nastavení.',
    subBlock8:
      'Cookies: Používáme soubory cookie k provozu a správě našich služeb a zlepšení vašich zkušeností. „Cookie“ je informace zaslané webovou stránkou, kterou navštívíte v prohlížeči. Můžete nastavit prohlížeč tak, aby přijali všechny soubory cookie, odmítnuty všechny soubory cookie nebo vás informovat, kdy je poskytnut cookie, abyste se mohli rozhodnout, zda jej přijme pokaždé. Odmítnutí souborů cookie vám však může zabránit používání nebo negativně ovlivnit displej nebo funkčnost určitých oblastí nebo funkcí webu. Další informace o souborech cookies naleznete na všech cookies.',
    subBlock9:
      'Analytics: Můžeme používat různé produkty online analytiků, které nám používají cookies, aby nám pomohly analyzovat, jak uživatelé využívají naše služby a zlepšují vaše zkušenosti při používání služeb.',
    subTitle1: 'Osobní údaje, které shromažďujeme',
    subTitle2: 'Jak používáme osobní údaje',
    subTitle3: 'Zveřejnění osobních údajů',
    subTitle4: 'Vaše práva',
    subTitle5: 'Odkazy na jiné webové stránky',
    subTitle6: 'Zabezpečení a udržení informací',
    subTitle7: 'Úpravy zásad ochrany osobních údajů',
    subTitle8: 'Jak nás kontaktovat',
    title: 'Zásady ochrany osobních údajů',
    updateTime: 'Aktualizováno: 15. března 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Přijmout všechny',
    text: 'Pro zlepšení vašich zkušeností používáme soubory cookie k personalizaci obsahu a služeb.',
    title: 'Tento web používá soubory cookie',
  },
  slogen: 'Nejjednodušší asistent AI',
  stripe: {
    contact: 'Kontaktujte nás na contact@noam.tools s jakýmikoli dotazy!',
    fail: {
      btn: 'Opakovat',
      text1: 'Nemohli jsme zpracovat vaši platbu.',
      text2: 'Zkontrolujte své připojení a zkuste to znovu.',
      title: 'Platba selhala',
    },
    succ: { btn: 'Pojďme', text1: 'Vítejte v Noam,', text2: 'Začněme!', title: 'Gratuluji' },
  },
  terms: {
    block1: 'Děkujeme za použití Noamu!',
    block10:
      'a) Poplatky a fakturace. Veškeré poplatky („poplatky“) zaplatíte podle cen a podmínek na příslušné cenové stránce nebo podle dohody písemně mezi námi. Vyhrazujeme si právo opravit chyby nebo chyby stanovení cen, i když jsme vydali faktury nebo obdrželi platbu. Poskytujete úplné a přesné fakturační informace, včetně platné a autorizované metody platby. Vaše metoda platby účtujeme pravidelně podle dohodnutí, ale můžeme přiměřeně změnit datum fakturace. Opravňujete NOAM, jeho přidružené subjekty a zpracovatelé plateb třetích stran k účtování způsobu platby. Pokud vaše platba selže, poskytneme vám písemné oznámení a můžeme pozastavit přístup ke službě, dokud není přijata platba. Pokud není v této smlouvě uvedeno jinak, platby jsou nevratné.',
    block11:
      '(b) Daně. Pokud není uvedeno jinak, poplatky nezahrnují žádné federální, státní, místní a zahraniční daně, povinnosti a podobná hodnocení („daně“). Jste zodpovědní za všechny daně spojené s vašimi nákupy, s výjimkou daní na základě našeho čistého příjmu, za který vás fakturujeme. Souhlasíte s tím, že takové daně okamžitě zaplatíte a poskytnete nám právně platné prohlášení o ochraně osobních údajů a získáte nezbytné souhlasy pro řešení těchto údajů a zaručujete nám, že tyto údaje řešíte v souladu s platným právem.',
    block12:
      '(c) Změny ceny. Můžeme změnit naše ceny tím, že vás oznámíme prostřednictvím vašeho účtu a/nebo našich webových stránek. Zvýšení cen bude účinné okamžitě po odeslání. Jakékoli změny ceny se budou vztahovat na poplatky za váš účet po datu účinnosti změny.',
    block13:
      'd) Spor a splátky zpoždění. Pokud si přejete zpochybnit jakékoli poplatky nebo daně, kontaktujte contact@noam.tools do třiceti (30) dnů od data faktury.',
    block14:
      'e) Využití zdarma. Pro získání bezplatných kreditů nemusíte vytvořit více účtů. Pokud zjistíme, že nepoužíváte bezplatné kredity v dobré víře, můžeme vám účtovat standardní poplatky nebo přestat poskytovat přístup ke službě.',
    block15:
      'a) Důvěrnost. Možná máte přístup k důvěrným informacím o NOAM. Za účelem použití služby můžete používat pouze důvěrné informace, jak jsou povoleny za těchto podmínek. Nesmíte zveřejňovat důvěrné informace žádné třetí straně a budete chránit důvěrné informace způsobem neméně ochranným, než chráníte své vlastní podobné důvěrné informace, alespoň s přiměřenou péčí. Důvěrné informace jsou neveřejné informace označeny jako důvěrné společností Noam nebo které by měly být za daných okolností přiměřeně považovány za důvěrné, včetně softwaru, specifikací a dalších neveřejných obchodních informací. Důvěrné informace neobsahují informace, které: (1) se obecně stávají dostupnými veřejnosti bez viny z vaší strany; (2) Měli jste ve svém držení bez jakékoli povinnosti důvěrnosti před jejím obdržením za těchto podmínek; (3) je vám zákonně zveřejněn třetí stranou bez jakýchkoli závazků; nebo (4) je vyvinutím nezávisle bez použití důvěrných informací. Pokud vyžadujete zákon, soud nebo jiný vládní příkaz, můžete zveřejnit důvěrné informace, ale musíte NOAM poskytnout předchozí písemné oznámení přiměřeně a pokud je to možné, vynakládat přiměřené úsilí omezit rozsah zveřejňování, včetně pomoci nám při protichůdné žádosti o zveřejnění.',
    block16:
      'b) Zabezpečení. Musíte implementovat přiměřená a vhodná opatření, která vám pomohou chránit váš přístup a používání služby. Pokud zjistíte jakékoli zranitelnosti nebo porušení související s používáním služby, musíte okamžitě kontaktovat NOAM a poskytnout podrobnosti o zranitelnosti nebo porušení.',
    block17:
      '((c) Zpracování osobních údajů. Pokud používáte službu ke zpracování osobních údajů, musíte poskytnout legálně dostatečné prohlášení o ochraně osobních údajů a získat nezbytné souhlasy pro zpracování těchto údajů a zaručujete nám, že tyto údaje řešíte v souladu s platnými zákony.',
    block18:
      'a) ukončení; Suspenze. Tyto podmínky budou účinné od vašeho prvního využití služby a zůstanou v platnosti až do ukončení. Tyto podmínky můžete kdykoli z jakéhokoli důvodu ukončit ukončením využití služby a obsahu. Tyto podmínky můžeme z jakéhokoli důvodu ukončit s předchozím upozorněním. Můžeme tyto podmínky okamžitě ukončit tím, že vám oznámíte, pokud podstatně porušíte oddíl 2 (požadavky na použití), oddíl 5 (důvěrnost, bezpečnost a ochrana údajů), oddíl 8 (řešení sporů) nebo oddíl 9 (obecné podmínky), nebo pokud náš vztah s jakýmkoli poskytovatelem technologií třetích stran nad našimi kontrolními změnami nebo pro dodržování zákonů nebo žádostí o vládu. Můžeme pozastavit váš přístup ke službě, pokud se vám nepodaří dodržovat tyto podmínky, nebo pokud vaše použití představuje bezpečnostní riziko pro nás nebo jakoukoli třetí stranu, nebo pokud máme podezření, že vaše použití je podvodné nebo může vystavit nás nebo jakoukoli třetí stranu odpovědnosti.',
    block19:
      'b) Účinky ukončení. Po ukončení přestanete používat službu a okamžitě vrátit nebo zničit jakékoli důvěrné informace podle pokynů. Ustanovení těchto podmínek, která by podle jejich povahy měla přežít ukončení nebo vypršení, včetně, ale nejen na oddíly 3 a 5-9, přežijí.',
    block2:
      'Tyto podmínky použití se vztahují na vaše využití služeb NOAM, včetně našich rozhraní pro programování aplikací, softwaru, nástrojů, vývojářských služeb, dat, dokumentace a webových stránek (společně označovaných jako „služby“). Používáním našich služeb souhlasíte s tím, že tyto podmínky dodržujete. Naše zásady ochrany osobních údajů vysvětlují, jak shromažďujeme a používáme osobní údaje.',
    block20:
      'a) Odškodnění. Budete bránit, odškodnit a držet nás, naše přidružené subjekty a naše personál neškodný z jakýchkoli nároků, ztrát a výdajů (včetně poplatků za právní zastoupení), které vyplývají z používání služby nebo související s používáním produktů, používání produktů nebo služeb souvisejících se službami, a vaším porušením těchto podmínek nebo příslušných zákonů.',
    block21:
      'b) Zřeknutí se odpovědnosti. Služba je poskytována „tak, jak je“. V rozsahu povoleném zákonem my a našimi přidruženými společnostmi a poskytovatelé licencí nevedeme žádné prohlášení ani záruky ohledně jakéhokoli druhu týkající se této služby a nezaručujeme, ale nejen, ale neomezují se záruky obchodovatelnosti, kondice pro konkrétní účely, uspokojivou kvalitu, neporušení a tiché požitky, a jakékoli záruky vycházející z průběhu řešení nebo použití obchodu. Nezaručujeme, že služba bude nepřetržitá, přesná nebo bezchybná, nebo že jakýkoli obsah bude bezpečný, neztracený nebo nezměněn.',
    block22:
      '(c) Omezení odpovědnosti. Ani my, naši přidružení, ani naši licenci nebudeme odpovědní za jakékoli nepřímé, náhodné, zvláštní, následné nebo represivní škody, včetně ztráty zisků, ztráty pověsti, ztráty použití, ztrátu údajů nebo jiné nehmotné ztráty, i když jsme byli upozorněni na možnost takové náhrady. Podle těchto podmínek naše celková odpovědnost nepřesáhne poplatky, které jste zaplatili za službu ve dvanácti (12) měsících předcházejících nároku nebo sto americkým dolarům (100 $), podle toho, co je větší. Omezení v této části se vztahují na maximální rozsah povolený podle příslušného práva.',
    block23:
      'Podmínky a vaše použití služeb se řídí zákony Spojených států, s výjimkou pravidel pro konflikt zákonů USA. Vaše použití aplikace může také podléhat jiným místním, státním, národním nebo mezinárodním zákonům. Jakékoli právní kroky nebo řízení týkající se těchto podmínek budou povedeny výhradně u soudů Spojených států a souhlasíte s tím, že se podrobíte osobní jurisdikci těchto soudů.',
    block24:
      'a) Vztah stran. Noam a vy jste nezávislí dodavatelé a žádná ze stran nemá pravomoc zastupovat nebo svázat druhou stranu nebo převzít povinnosti pro druhou stranu bez předchozího písemného souhlasu druhé strany.',
    block25:
      '(b) Využití značky. Bez našeho předchozího písemného souhlasu nesmíte používat jméno, loga nebo ochranné známky Noamu nebo žádného z jeho přidružených společností.',
    block26:
      'Pokud je jakékoli ustanovení těchto podmínek považováno za neplatné, nezákonné nebo nevynutitelné, zbývající ustanovení zůstanou v plné síle a účinku.',
    block3:
      'Pokud máte mladší 18 let, musíte získat povolení od svých rodičů nebo zákonných zástupců, aby tuto službu používali. Pokud službu používáte jménem jiné osoby nebo subjektu, musíte být oprávněni tyto podmínky přijmout jejich jménem. Chcete -li se zaregistrovat na účet, musíte poskytnout přesné a úplné informace. Nesmíte poskytnout své přístupové údaje nebo účet nikomu mimo vaši organizaci a jste zodpovědní za všechny činnosti prováděné pomocí vašich přihlašovacích údajů.',
    block4:
      '(a) Použití služby. Služby můžete přistupovat podle těchto podmínek a my vám poskytneme nevýhradní právo používat službu. Při používání služby budete dodržovat tyto podmínky a všechny příslušné zákony. My a naše přidružené subjekty vlastníme všechna práva, tituly a zájmy ve službě.',
    block5:
      'b) Zpětná vazba. Vítáme zpětnou vazbu, komentáře, nápady, návrhy a vylepšení. Pokud poskytnete takový obsah, můžeme jej použít bez jakýchkoli omezení a bez náhrady.',
    block6:
      '(c) Omezení. Nemusíte: (1) používat službu způsobem, který porušuje, zneuctí nebo porušuje jakákoli práva druhých; (2) Reverzní inženýr, dekompilus, rozebírá, překládá nebo jinak se pokouší objevit zdrojový kód nebo základní komponenty modelů služeb, algoritmů a systémů (pokud taková omezení není zakázána platným zákonem); (3) Použijte výstup služby k vývoji modelů soutěžících s NOAM; (4) extrahujte data nebo výstup ze služby pomocí jakýchkoli automatizovaných nebo programovacích metod, pokud to není povoleno prostřednictvím API, včetně škrábání, sklizně na webu nebo extrakci webových dat; (5) představují výstup služby jako uměle generovanou, pokud není, nebo jinak porušuje naše zásady použití; (6) nakupovat, prodávat nebo převádět klíče API bez našeho předchozího souhlasu; nebo (7) nám poskytují jakékoli osobní údaje dětí mladších 13 let nebo platný věk digitálního souhlasu. V naší dokumentaci budete dodržovat jakékoli rychlostní limity a další požadavky.',
    block7:
      'd) Služby třetích stran. Jakýkoli software, služby, služby nebo jiné produkty spojené se službou jsou řízeny podle jejich vlastních podmínek a nejsme zodpovědní za produkty třetích stran.',
    block8:
      'a) Váš obsah. Můžete poskytnout vstup ("vstup") do služby a přijímat výstup ("výstup") generovaný a vrácený na základě vstupu (společně označovaný jako "obsah"). Mezi stranami a v rozsahu povoleném platným právem vlastníte veškerý vstup. NOAM může používat obsah k poskytování a údržbě služby, dodržovat platné zákony a prosazovat naše politiky. Jste zodpovědní za obsah, včetně zajištění toho, že neporušuje žádné příslušné zákony nebo tyto podmínky.',
    block9:
      'b) přesnost. Umělá inteligence a strojové učení jsou rychle se vyvíjející pole výzkumu. Neustále se snažíme zlepšovat naši službu, aby byla přesnější, spolehlivější, bezpečnější a prospěšnější. Vzhledem k pravděpodobnostní povaze strojového učení může používání naší služby v některých případech vést k nesprávným výstupům, které nemusí přesně odrážet skutečné jedince, místa nebo fakta. Měli byste vhodně posoudit přesnost jakéhokoli výstupu na základě vašeho případu použití, včetně ručního přezkoumání výstupů.',
    subTitle1: 'Registrace a přístup',
    subTitle10: 'Oddělení',
    subTitle2: 'Požadavky na použití',
    subTitle3: 'Obsah',
    subTitle4: 'Poplatky a platba',
    subTitle5: 'Důvěrnost, bezpečnost a ochrana údajů',
    subTitle6: 'Termín a ukončení',
    subTitle7: 'Odškodnění; Zřeknutí se odpovědnosti; Omezení odpovědnosti',
    subTitle8: 'Vládní právo a jurisdikce',
    subTitle9: 'Obecné podmínky',
    title: 'Podmínky služby',
    updateTime: 'Aktualizováno: 15. března 2024',
  },
  trialModal: {
    btn: 'Začněte hned （Pouze 0,99 $）',
    chat: 'Chatpdf',
    desc1: 'Po soudu vám bude účtován roční poplatek ve výši 180 $',
    desc2: 'Zrušit kdykoli',
    focus: 'Zaostření',
    hover: 'Překlad vznáší',
    image: 'Překlad obrázku',
    installBtn: 'Nainstalujte nyní a obdržíte svůj dárek',
    memo: 'Oblíbené vedení',
    pdf: 'PDF paralelní překlad',
    realtime: 'Okamžitý překlad',
    select: 'Překlad výběru slov',
    title: 'Vítejte dárek',
    trialText: '7denní soud',
    video: 'Překlad videa',
    videoSummary: 'Shrnutí videa',
    web: 'Paralelní překlad',
    webSummary: 'Shrnutí webu',
  },
  upgradeModal: {
    btn: 'Upgradujte nyní',
    chat: {
      free: 'Dosáhli jste limitu chatpdf',
      pro: 'Pro tento měsíc jste dosáhli limitu chatpdf',
    },
    contactStr: 'Počkejte prosím na další reset nebo nás kontaktujte na contact@noam.tools.',
    limitAlertStr: 'Dosáhli jste svého limitu volného plánu. Upgrade pokračovat.',
    more: 'Zobrazit plány',
    pageLimit: { free: 'Dosáhli jste limitu stránky PDF' },
    title: 'Připomínka',
    upgradeAlertStr: 'Dosáhli jste svého limitu volného plánu. Upgrade pokračovat.',
    upload: {
      free: 'Dosáhli jste svého limitu překladu PDF',
      pro: 'Pro tento měsíc jste dosáhli svého překladového limitu PDF',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Připojte se hned',
      label: 'Výhody nového uživatele:',
      remain: 'Pouze 30 zbývajících',
      text: 'K dispozici je pouze 100 míst! Popadněte teď!',
    },
  },
}

export default TRANSLATION
