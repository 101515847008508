const TRANSLATION = {
  buyModal: {
    afterFree: 'Após sua avaliação gratuita',
    btn: 'Experimente grátis',
    freeTrial: 'Teste gratuito de 3 dias',
    monthLabel: 'mês',
    selectLabel: 'Selecione um plano',
    title: 'Plano de assinatura',
    yearLabel: 'ano',
  },
  contact: {
    block1:
      'Para personalização, perguntas ou sugestões, entre em contato conosco em contact@noam.tools. Vamos responder prontamente.',
    block2: 'Apoie -nos classificando -nos no Google Apps Extension Store em ★★★★★.',
    block3: 'Ajude outras pessoas compartilhando esta extensão:',
    title: 'Contate-nos',
  },
  help: {
    AquickOverviewOfNoam: 'Uma rápida visão geral de Noam',
    ChatPDF: 'Chat pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Gerenciamento de favoritos',
    Fixedplugin: 'Plug -in fixo',
    Focusing: 'Foco',
    Free: 'Livre',
    FreeTrialBenefits: 'Benefícios de teste gratuito',
    GoWithWikipedia: 'Vá com a Wikipedia',
    GoWithYouTube: 'Vá com o YouTube',
    HoverTranslation: 'Tradução pairar',
    Howtouse: 'Como usar',
    ImageTranslation: 'Tradução da imagem',
    InstantTranslation: 'Tradução instantânea',
    Membership: 'Associação',
    NoamHelp: 'Noam ajuda você a traduzir e resumir',
    PDFparallelTranslation: 'Tradução paralela em PDF',
    ParallelTranslation: 'Tradução paralela',
    SkipAllSteps: 'Pule todas as etapas',
    Summary: 'Resumo',
    Translation: 'Tradução',
    TryItForFree: 'Experimente de graça',
    Video: 'Vídeo',
    VideoSummary: 'Resumo do vídeo',
    VideoTranslation: 'Tradução de vídeo',
    WebSummary: 'Resumo da Web',
    WordSelectionTranslation: 'Tradução de seleção de palavras',
    benefits: 'Temos benefícios esperando por você!',
    dayFree: 'Teste gratuito de 3 dias',
    done: 'Feito',
    next: 'Próximo',
    pin: 'Pin Noam para facilitar o acesso ～～',
    planIsNotExist: 'O plano não está disponível',
    startWiki: 'Vamos começar com a Wikimedia!',
    startYoutube: 'Vamos começar com o YouTube!',
    try: 'Experimente',
    tryNow: 'Experimente agora!',
  },
  home: {
    PDFTranslate: {
      desc: 'Tradução bilíngüe + bate -papo pdf',
      sub1: {
        desc: 'A leitura bilíngue permite comparar o texto original e a tradução lado a lado, melhorando a eficiência da leitura.',
        title: 'Leitura bilíngue',
      },
      sub2: {
        desc: 'A IA analisa e resume e pode responder às suas perguntas com base no conteúdo do PDF.',
        title: 'Chat pdf',
      },
      sub3: {
        desc: 'Tradução em PDF movida a IA para maior precisão e eficiência.',
        title: 'Precisão e eficiência',
      },
      title: 'ERROR: [PDF Translate]',
    },
    aiAssistant: {
      desc: 'Tornando a tradução da web mais inteligente e mais conveniente',
      panel1: {
        desc: 'As traduções bilíngues de referência cruzada ajudam a comparar o texto original com a tradução para que você possa entender melhor e melhorar sua eficiência de leitura.',
        title: 'Tradução bilíngue',
      },
      panel2: {
        left: {
          desc: 'Ao navegar em uma página da web, passe o mouse sobre o conteúdo que você precisa traduzir para ver a tradução.',
          title: 'Tradução pairar',
        },
        right: {
          desc: 'Ao navegar em uma página da web, selecione o texto que você deseja traduzir para ver a tradução.',
          title: 'Tradução de seleção de texto',
        },
      },
      panel3: {
        one: {
          desc: 'Traduza e resuma as páginas da Web e o conteúdo de vídeo a qualquer hora, em qualquer lugar, para uma experiência mais suave!',
          title: 'Aponte e clique',
        },
        three: {
          desc: 'Modelo de grande linguagem orientado a IA garante a precisão e a consistência da tradução para uma experiência de leitura aprimorada.',
          title: 'Orientado pela IA',
        },
        two: {
          desc: 'Cobertura completa em vários idiomas, leitura bilíngue sem barreira, tradução com um clique-experimente a diferença!',
          title: 'Mais abrangente e conveniente',
        },
      },
      panel4: {
        tab1: {
          desc: 'Seu especialista em eficiência - Usando o ChatGPT, o NOAM traduz e resume o conteúdo de vídeo do YouTube para economizar seu tempo!',
          key: 'Vídeo',
          title: 'Tradução de vídeo e resumo',
        },
        tab2: {
          desc: 'Clique para traduzir imagens enquanto navega na web.',
          key: 'Imagem',
          title: 'Imagem traduzida',
        },
        tab3: {
          desc: 'Extrair instantaneamente as informações importantes das páginas da web e fornece um resumo, aprimorando sua eficiência de leitura.',
          key: 'Web',
          title: 'Web traduzido e resumo',
        },
        tag1: 'Simples',
        tag2: 'Eficiente',
        tag3: 'Fácil de entender',
      },
      title: 'Assistente de IA',
    },
    getStart: 'Comece',
    getStartedForFree: 'Comece de graça',
    howItWork: {
      desc: 'O NOAM integra os modelos de IA para fornecer aos usuários uma experiência inteligente para a tradução e resumo da página da Web entre idiomas entre idiomas, pdf e tradução de imagem, tradução de vídeo e resumo',
      title: 'Como funciona',
    },
    lastBlock: { desc: 'Melhor tradução da IA ​​para uma experiência de leitura mais suave' },
    top: {
      desc: 'O NOAM é uma extensão gratuita do navegador de IA que ajuda você a traduzir e resumir páginas da web, vídeos e conteúdo em PDF. \\ N Junte -se a Noam e experimente a magia!',
      title1: 'Uma mágica',
      title2: 'Um clique',
    },
    userReviews: {
      desc: 'Revisões de cinco estrelas na loja da Web Chrome',
      title: 'Revisões de usuários',
    },
  },
  installTips: {
    btn: 'Experimente agora',
    text: 'Instale o "Plugin Noam Browser" para desbloquear mais recursos! Referência cruzada da web, resumo de vídeos na web e muito mais!',
  },
  locale: {
    af: 'afrikaans',
    am: 'Amárico',
    ar: 'árabe',
    as: 'Assamês',
    auto: 'Detecção automática',
    az: 'Azerbaijão',
    ba: 'Bashkir',
    bg: 'búlgaro',
    bn: 'Bangla',
    bo: 'Tibetano',
    bs: 'Bósnia',
    ca: 'catalão',
    cs: 'Tcheco',
    cy: 'galês',
    da: 'dinamarquês',
    de: 'Alemão',
    dsb: 'Lower Sorbian',
    dv: 'Divehi',
    el: 'grego',
    en: 'Inglês',
    es: 'Espanhol',
    et: 'estoniano',
    eu: 'Basco',
    fa: 'persa',
    fi: 'finlandês',
    fil: 'Filipino',
    fj: 'Fijian',
    fr: 'Francês',
    frCA: 'Francês (Canadá)',
    ga: 'irlandês',
    gl: 'Galian',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebraico',
    hi: 'hindi',
    hr: 'croata',
    hsb: 'Upper Sorbian',
    ht: 'Crioulo haitiano',
    hu: 'húngaro',
    hy: 'Armênio',
    id: 'indonésio',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandês',
    it: 'italiano',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latim)',
    ja: 'japonês',
    ka: 'Georgiano',
    kk: 'Cazaque',
    km: 'Khmer',
    kmr: 'Curdo (norte)',
    kn: 'Kannada',
    ko: 'coreano',
    ku: 'Curdo (central)',
    ky: 'Kyrgyz',
    ln: 'Lingala',
    lo: 'Laos',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'letão',
    lzh: 'Chinês (literário)',
    mai: 'Maithili',
    mg: 'malgaxe',
    mi: 'Maori',
    mk: 'Macedônio',
    ml: 'Malaiala',
    mnCyrl: 'Mongólia (cirílico)',
    mnMong: 'Mongólia (tradicional)',
    mr: 'Marathi',
    ms: 'malaio',
    mt: 'maltês',
    mww: 'Hmong Daw',
    my: 'Mianmar (birmanês)',
    nb: 'norueguês',
    ne: 'Nepalês',
    nl: 'Holandês',
    noSearchLang: 'Idioma não suportado',
    nso: 'Sesoto sa leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'polonês',
    placeholder: 'Procurar',
    prs: 'Dari',
    ps: 'Pashto',
    pt: 'Português (Brasil)',
    ptPT: 'Português (Portugal)',
    ro: 'romeno',
    ru: 'russo',
    run: 'Rúndi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Eslovaco',
    sl: 'esloveno',
    sm: 'Samoano',
    sn: 'Shona',
    so: 'Somali',
    sq: 'albanês',
    srCyrl: 'Sérvio (cirílico)',
    srLatn: 'Sérvio (latim)',
    st: 'Sesoto',
    sv: 'sueco',
    sw: 'Suaíli',
    ta: 'tâmil',
    te: 'Telugu',
    th: 'Tailandês',
    ti: 'Tigrinya',
    tk: 'Turquemen',
    tlhLatn: 'Klingon (latim)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tonganês',
    tr: 'turco',
    tt: 'Tatar',
    ty: 'Tahitiano',
    ug: 'Uyghur',
    uk: 'ucraniano',
    ur: 'urdu',
    uz: 'Uzbek (latim)',
    vi: 'vietnamita',
    xh: 'Xhosa',
    yo: 'Ioruba',
    yua: 'Yucatec Maya',
    yue: 'Cantonês (tradicional)',
    zh: 'chinês',
    zhHans: 'Chinês simplificado',
    zhHant: 'Tradicional chinês',
    zu: 'zulu',
  },
  login: {
    and: 'e',
    btn: 'Faça login com o Google',
    btn1: 'Faça login agora',
    desc: 'Faça login para desbloquear mais experiências',
    policy: 'política de Privacidade',
    terms: 'Termos de uso',
    tips: 'Ao me inscrever, eu concordo com o',
    title: 'Bem -vindo a Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Seguir', twitter: 'Twitter' },
    legal: { policy: 'política de Privacidade', terms: 'Termos de Serviço', title: 'Jurídico' },
    product: {
      image: 'Tradução da imagem',
      pdf: 'Tradução em PDF',
      title: 'Produtos',
      video: 'Tradução de vídeo e resumo',
      web: 'Tradução da Web e Resumo',
    },
    resources: { title: 'Recursos' },
  },
  pageHeader: {
    contact: 'Contato',
    help: 'Ajuda',
    logout: 'Log Out',
    logoutSucc: 'Registrado.',
    pricing: 'Preço',
    settings: 'Configurações',
    signUp: 'Conecte-se',
  },
  pdf: {
    autoDetect: 'Detecção automática',
    cancelBtn: 'Cancelar',
    cancelUpload: 'Cancelar',
    chatPdf: {
      summaryError: 'Resumo falhou, tente novamente mais tarde',
      chatError: 'Bate -papo falhou, tente novamente mais tarde',
      Search: 'Procurar',
      cancel: 'Cancelar',
      chatTitle: 'Bem -vindo a Noam! Aqui está um resumo do seu PDF:',
      clear: 'Claro',
      clearContent:
        'Isso começará uma nova conversa. A conversa atual não será salva. Tem certeza?',
      clearDialogTitle: 'Por favor, confirme',
      clearSucc: 'Limpo',
      copy: 'Cópia',
      copySucc: 'Copiado',
      delete: 'Excluir',
      deleteCancel: 'Cancelar',
      deleteContent: 'Este PDF não pode ser recuperado depois de excluído. Tem certeza?',
      deleteOk: 'Excluir',
      deleteSuccess: 'Excluído',
      deleteTitle: 'Confirme excluir',
      expand: 'Colapso',
      historyTitle: 'História',
      listError:
        'Falha ao carregar a lista de leitura. Tente novamente mais tarde ou entre em contato com o suporte.',
      loading: 'Carregando...',
      noDataDesc: 'Sem história ainda.',
      noMore: 'Não há mais conteúdo.',
      placeholder: 'Pergunte qualquer coisa sobre este pdf',
      summaryTitle: 'Você pode estar interessado nessas perguntas:',
    },
    collectionEmpty: 'Sua coleção está vazia.',
    collectionTab: 'Notas',
    dataLoading: 'Carregando...',
    dialogContent: 'Tem certeza de que deseja excluir isso?',
    dialogTitle: 'Por favor, confirme',
    download: {
      both: 'Bilíngue',
      btn: 'Salvar',
      cancel:
        'O fechamento desta janela cancelará o download do PDF. Tem certeza de que deseja cancelar?',
      desc: 'Salvar no dispositivo: baixar traduções como imagens; Salvar e imprimir: Downloads traduções no formato original, com suporte de cópia.',
      download: 'Download',
      downloadMsg1: 'Progresso da tradução em PDF:',
      downloadMsg2: 'Tempo estimado restante:',
      downloadSucc: 'PDF está pronto! Clique para salvar.',
      downloadTip: 'Baixando PDF. Não feche esta janela para evitar erros de download.',
      error: 'Falhou em salvar. Tente imprimir em vez disso.',
      fileName: 'Nome do arquivo:',
      fileType: 'Tipo de arquivo:',
      loading: 'Salvando ...',
      loading2: 'Carregando...',
      minute: 'Minutos ...',
      msg1: 'Algumas traduções neste documento são mais longas que o texto original e podem se sobrepor no arquivo baixado. Recomendamos a leitura na página da web.',
      msg2: '1. A tradução ainda não está completa. Faça o download após o término da tradução.',
      msg3: '2. Algumas traduções são muito longas. Leia na página da web para evitar a sobreposição de texto.',
      msg4: 'As tarefas de tradução estão em andamento. Por favor, tente novamente depois que eles estiverem concluídos.',
      progress:
        'Traduzindo PDF. Progresso: {{Progress}}, tempo estimado restante: {{minutos}} minutos ...',
      save: 'Salvar no dispositivo',
      savePrint: 'Salvar e imprimir',
      success: 'Baixado',
      trans: 'Apenas tradução',
      transContinue: 'Continue traduzindo',
    },
    downloadBtn: 'Download',
    dragTipModal: {
      black: 'espaço',
      leftKey: 'botão esquerdo',
      ok: 'Entendi',
      press: 'Imprensa',
      tips: 'para arrastar e visualizar.',
    },
    entry: 'Pdfs',
    fileErrMsg:
      'A leitura de arquivos falhou. Tente novamente mais tarde ou entre em contato com o suporte.',
    fileMsg: 'Tamanho atual do arquivo:',
    fileMsg2: 'Faça o upload de um arquivo menor que 300 MB.',
    freeBannerBtn: 'Atualizar',
    freeBannerTips: 'Limite gratuito de traduções em PDF alcançado',
    guide: {
      placeholder: 'Cole o link em PDF aqui',
      start: 'Comece a traduzir',
      text1: 'Leitura bilíngue',
      text2:
        'Leia bilíngue para comparar o texto original e a tradução lado a lado, melhorando sua eficiência.',
      text3: 'Converse com seu pdf',
      text4:
        'ERROR: [AI-powered analysis and summarization. Ask questions about your PDF, and get instant answers.]',
      text5: 'Preciso e eficiente',
      text6: 'Tradução em PDF movida a IA para maior precisão e eficiência.',
      title1: 'Tradução bilíngue',
      title2: 'Converse com seu pdf',
      toast: 'Por favor, insira um link pdf online válido que termina em .pdf',
    },
    hasTransTask: 'Uma tarefa de tradução está em andamento. Por favor, aguarde.',
    hoverTrans: 'Parágrafos de passar a trânsito',
    initial: 'Traduzir PDF',
    installText: 'Sua solução única para traduções diárias.',
    installText2: 'Por favor, instale a extensão primeiro',
    installTips: 'Instale a extensão para mais traduções em PDF e traduções mais suaves de sites:',
    kw: 'grampo',
    link: 'link',
    listError:
      'Falha ao carregar PDFs. Tente novamente mais tarde ou entre em contato com o suporte.',
    memoTotalCount: '1 item total',
    new: 'novo arquivo',
    noId: 'ID do arquivo não encontrado.',
    notPdf: 'Apenas arquivos PDF.',
    okBtn: 'Excluir',
    okBtn2: 'Entendi',
    original: 'Original',
    parallel: 'Paralelo',
    parseErrDialogContent:
      'Este link PDF não pode ser traduzido diretamente. Faça o download do PDF e envie -o para tradução.',
    parseErrTips: 'Falha ao analisar o arquivo',
    parseFileToast:
      'Falha ao analisar o arquivo. Tente novamente mais tarde ou entre em contato com o suporte.',
    pdfLoading: 'Análise...',
    searchPlaceholder: 'Procurar',
    selectTrans: 'Selecione o texto para traduzir',
    shareBtn: 'Compartilhar',
    shareBtnText1: 'Gerar link de compartilhamento',
    shareBtnText2: 'Atualizar conteúdo compartilhado',
    shareModal: {
      cancel: 'Cancelar',
      copy: 'Cópia',
      copySucc: 'Link copiado',
      custom: 'Personalizar:',
      errMsg:
        'O conteúdo selecionado ainda não foi totalmente traduzido. Por favor, traduza antes de compartilhar.',
      gen: 'Compartilhar',
      modify: 'Editar',
      notSupport: 'Atualmente, esse idioma não é suportado.',
      page: 'Páginas',
      pageNuErr: 'Por favor, insira um número de página válido.',
      range: 'Escopo:',
      remain: 'restante',
      remain1: 'Tempo total estimado:',
      search: 'Procurar',
      settings: 'Compartilhar configurações:',
      shareSetting: 'Compartilhar configurações',
      shareText: 'Compartilhar',
      shareTips: 'Compartilhe o link de PDF traduzido com seus amigos:',
      sourceLang: 'De:',
      target: 'Para:',
      text1: 'Compartilhar configurações',
      text2: 'Gerar link de compartilhamento',
      totalPage: '1 página total',
      trans: 'Traduzir',
      translateTips:
        'Dica: se a tradução estiver demorando um pouco, você poderá fechar esta janela e compartilhar mais tarde.',
      translating: 'Traduzindo PDF ...',
      update: 'Atualizar link de compartilhamento',
    },
    shareMsg1: 'Compartilhar link gerado.',
    shareMsg2: 'Conteúdo compartilhado atualizado.',
    shareMsg3:
      'Falha ao gerar o link de compartilhamento. Tente novamente mais tarde ou entre em contato com o suporte.',
    shareMsg4:
      'Falha ao atualizar o conteúdo compartilhado. Tente novamente mais tarde ou entre em contato com o suporte.',
    shareTips: 'Sua tradução em PDF está pronta! Compartilhe com seus amigos.',
    start: 'Comece sua avaliação gratuita',
    thumbnail: 'Índice',
    toast1: 'Carregando PDF. Por favor, tente novamente mais tarde.',
    toast2: 'O conteúdo em PDF é muito grande. Atualmente, a caixa de diálogo não é suportada.',
    toast3: 'Download. Por favor, tente novamente depois que o download terminar.',
    toolbar: { Adjust: 'Ajustar', autoFit: 'Ajuste automático' },
    trans: 'Tradução',
    transSucc: 'Tradução bem -sucedida!',
    unLoginToast: 'Faça login para usar esse recurso.',
    uploadBtn: 'Carregar',
    uploadErr:
      'O upload do arquivo falhou. Tente novamente mais tarde ou entre em contato com o suporte.',
    uploadErrSignUrl:
      'Falha ao receber URL URL. Tente novamente mais tarde ou entre em contato com o suporte.',
    uploadMsg1: 'Arraste e solte seu arquivo aqui.',
    uploadMsg2: 'Tipos de arquivo suportados: PDF |  Tamanho máximo do arquivo: 100 MB',
    uploadMsg3: 'Clique para selecionar ou arrastar e soltar arquivos aqui.',
    uploading: 'Upload',
    uploadingMsg: 'Upload. Por favor, aguarde.',
    uploadingMsg1: 'O arquivo está carregando. Por favor, tente novamente mais tarde.',
    uploadingMsg2:
      'Um arquivo está sendo convertido atualmente. Por favor, tente novamente mais tarde.',
    uploadingMsg3:
      'A solicitação de progresso da conversão falhou. Tente novamente mais tarde ou entre em contato com o suporte.',
    uploadingMsg4:
      'Noam não pode acessar arquivos locais. Por favor, envie manualmente um PDF para tradução.',
    uploadingMsg5:
      'Falha ao recuperar o URL do arquivo. Tente novamente mais tarde ou entre em contato com o suporte.',
    video: 'vídeo',
    vipBannerBtn: 'Atualizar',
    vipBannerTips: 'Você atingiu seu limite de tradução em PDF para este mês.',
    webTrans: 'Referência cruzada completa',
  },
  pricing: {
    FAQ: 'Perguntas frequentes',
    FQAObj: {
      a1: 'Sim, o Noam oferece um plano gratuito que você pode usar imediatamente.',
      a2: 'Oferecemos três planos de associação: Pro, Pro+e Enterprise. Você pode optar por pagar mensalmente ou anualmente. O faturamento anual oferece economias significativas.',
      a3: 'Todos os recursos estão disponíveis nos dois planos. A principal diferença é o custo: as assinaturas anuais custam menos em geral. As assinaturas mensais são cobradas a cada mês, enquanto os planos anuais são cobrados uma vez por ano.',
      a4: 'Você pode cancelar sua assinatura a qualquer momento nas configurações da sua conta.',
      q1: 'Existe um plano gratuito?',
      q2: 'Quais planos de preços e opções de pagamento estão disponíveis?',
      q3: 'Qual é a diferença entre assinar mensalmente vs. anualmente?',
      q4: 'Como cancelar minha assinatura?',
    },
    around: 'Em volta',
    billedMonthly: 'Cobrado mensalmente',
    billedYearly: 'Cobrado anualmente',
    day: 'dia',
    desc: 'Compare planos e escolha o melhor',
    detailedCompare: 'Compare planos',
    discount: '20% de desconto',
    enterprise: {
      benefit1: 'Suporte prioritário ao cliente',
      benefit2: 'Vários usuários',
      benefit3: 'Soluções personalizadas',
      benefit5: 'Gerente de conta dedicado',
      btn: 'Contate-nos',
      recommendText: 'Planos personalizados （≥3 人）',
      talkToUs: 'Entre em contato',
    },
    free: {
      benefit1: 'Tradução instantânea 100 vezes/dia',
      benefit2: 'Tradução e resumo do site',
      benefit3: '30 traduções de imagem',
      benefit4: 'Tradução de vídeo e resumo',
      benefit5: '10 traduções em PDF',
      benefit6: '5.000 tokens',
      btn: 'Experimente grátis',
      recommendText: 'Teste gratuito',
    },
    imageTranslation: 'Tradução da imagem',
    month: 'mês',
    monthly: 'Mensal',
    pageTitle: 'Preço',
    pdf: {
      chat: 'Consultas de bate -papo em PDF',
      maxPage: 'Máx. Páginas por pdf',
      translation: 'Tradução em PDF',
    },
    preMonth: 'Por mês',
    pro: {
      benefit1: 'Suporte prioritário ao cliente',
      benefit2: '1.000.000 de tokens/mês',
      benefit3: 'Tradução e resumo do site',
      benefit4: '200 traduções de imagem',
      benefit5: 'Tradução de vídeo e resumo',
      benefit6: '200 traduções em PDF',
      btn: 'Comece',
      recommendText: 'Mais popular',
    },
    proPlus: {
      benefit1: 'Suporte prioritário ao cliente',
      benefit2: '3.000.000 de tokens/mês',
      benefit3: 'Tradução e resumo do site',
      benefit4: '500 traduções de imagem',
      benefit5: 'Tradução de vídeo e resumo',
      benefit6: '500 traduções em pdf',
      recommendText: 'Melhor valor',
    },
    video: { summary: 'Resumo do vídeo', translation: 'Tradução de vídeo' },
    web: {
      contrastiveTranslation: 'Tradução bilíngue',
      dictionaryTranslation: 'Tradução de seleção',
      hoverTranslation: 'Tradução pairar',
      instantTranslation: 'Tradução instantânea',
      summary: 'Resumo da página da web',
    },
    yearly: 'Anual',
  },
  privacy: {
    block1:
      'Noam ("noam", "nós", "nosso," ou "nós") respeita sua privacidade e está comprometido em proteger qualquer informação que obtemos de você ou sobre você. Esta Política de Privacidade descreve nossas práticas sobre a coleta ou uso de informações pessoais do seu uso de nosso site, aplicativos e serviços (coletivamente, os "Serviços"). Esta Política de Privacidade não se aplica ao conteúdo que lidamos em nome de nossos clientes de produtos de negócios, como nossa API. O uso desses dados está sujeito ao nosso contrato de cliente, que abrange o acesso e o uso desses produtos.',
    block10:
      'Implementamos medidas técnicas, administrativas e organizacionais comercialmente razoáveis ​​para proteger informações pessoais on -line e offline contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou atos hostis. No entanto, nenhuma transmissão na Internet ou por e -mail pode garantir uma segurança ou precisão completa. Em particular, os e -mails enviados para nós ou de nós podem não estar seguros. Portanto, você deve ter cuidado ao decidir quais informações nos enviarem através do serviço ou e -mail. Além disso, não somos responsáveis ​​por nenhuma obstrução de configurações de privacidade ou medidas de segurança no serviço ou nas configurações de segurança dos sites de terceiros ou medidas de segurança. Manteremos suas informações pessoais pelo tempo necessário para fornecer serviços ou para outros fins comerciais legítimos, como resolver disputas, razões de segurança e segurança ou cumprir nossas obrigações legais. O período de tempo em que mantemos informações pessoais dependerá de muitos fatores, como quantidade, natureza, sensibilidade das informações, riscos potenciais de uso ou divulgação não autorizados, os propósitos para os quais processamos as informações e outras considerações legalmente vinculativas.',
    block11:
      'Podemos atualizar esta Política de Privacidade de tempos em tempos. Quando o fizermos, publicaremos uma versão atualizada nesta página, a menos que seja exigido de outra forma pela lei aplicável.',
    block12:
      'Se você tiver perguntas ou preocupações não respondidas sobre esta política de privacidade, entre em contato com nosso atendimento ao cliente. E-mail:',
    block2:
      'Coletamos as seguintes informações pessoais ("informações pessoais") relacionadas a você:',
    block3:
      'Informações fornecidas por você: Quando você cria uma conta para usar nossos serviços ou se comunicar conosco, coletamos informações pessoais da seguinte forma:',
    block4:
      'Informações pessoais recebidas automaticamente do uso de serviços: quando você acessa, usa ou interage com os serviços, recebemos as seguintes informações sobre seu acesso, uso ou interação ("Informações técnicas"):',
    block5: 'Podemos usar informações pessoais para os seguintes fins:',
    block6:
      'Informações resumidas ou desidentificadas: podemos agregar ou desidentificar as informações pessoais para impedir sua reutilização para fins de identificação e usar essas informações para analisar a eficácia de nossos serviços, melhorar e adicionar recursos aos nossos serviços, conduzir pesquisas e para fins semelhantes. Além disso, podemos analisar o comportamento geral e as características de nossos usuários de serviços e compartilhar informações agregadas com terceiros, publicar essas informações agregadas ou disponibilizá -las geralmente. Podemos coletar informações agregadas através dos serviços, por meio de cookies e por outros meios descritos nesta política de privacidade. Manteremos e usaremos informações desidentificadas de uma forma anônima ou não identificada, e não tentaremos re-identificar essas informações, a menos que exigido por lei.',
    block7:
      'Em certos casos, podemos fornecer suas informações pessoais a terceiros sem mais aviso a você, a menos que exigido por lei:',
    block8:
      'Dependendo da sua localização geográfica, os indivíduos na área econômica européia, no Reino Unido e globalmente podem ter certos direitos estatutários sobre suas informações pessoais. Por exemplo, você pode ter o direito de:',
    block9:
      'O serviço pode conter links para outros sites não operados ou controlados pela NOAM, incluindo serviços de mídia social ("sites de terceiros"). As informações que você compartilham com sites de terceiros serão regidas pelas políticas específicas de privacidade e termos de serviço dos sites de terceiros, não por esta política de privacidade. Fornecemos esses links para conveniência e não implica nosso endosso ou revisão desses sites. Entre em contato diretamente com os sites de terceiros para obter informações sobre suas políticas e termos de privacidade.',
    subBlock1:
      'Informações da conta: Quando você cria uma conta em nossa plataforma, coletamos informações relacionadas à sua conta, incluindo seu nome, informações de contato, credenciais da conta, informações do cartão de pagamento e histórico de transações ("informações da conta").',
    subBlock10: 'Fornecer, gerenciar, manter e/ou analisar serviços;',
    subBlock11: 'Comunicando com você;',
    subBlock12: 'Desenvolvimento de novos produtos e serviços;',
    subBlock13:
      'Prevenir fraude, atividade criminosa ou uso indevido de nossos serviços e proteger a segurança de nossos sistemas de TI, arquitetura e redes;',
    subBlock14: 'Condução de transferências de negócios;',
    subBlock15:
      'Cumprindo obrigações legais e procedimentos legais, protegendo nossos direitos, privacidade, segurança ou propriedade, bem como os de nossas afiliadas, você ou outros terceiros.',
    subBlock16:
      'Fornecedores e provedores de serviços: para nos ajudar a atender às necessidades operacionais de negócios e executar determinados serviços e funções, podemos fornecer informações pessoais a fornecedores e provedores de serviços, incluindo provedores de serviços de hospedagem, provedores de serviços em nuvem e outros provedores de serviços de tecnologia da informação, ou armazenará apenas nossos prestadores de serviços de comunicação em e -mail.',
    subBlock17:
      'Transferências de negócios: se se envolveremos em transações estratégicas, reorganizações, falências, aquisições de falências ou serviços de transição para outro provedor (coletivamente, "transações"), suas informações pessoais e outras informações podem ser compartilhadas com transações com outras transações com outros consultores com outras transações.',
    subBlock18:
      'Requisitos legais: podemos compartilhar suas informações pessoais com autoridades governamentais, colegas do setor ou outros terceiros, incluindo suas informações de interação com nossos serviços, (1) se exigido por lei ou se acreditarmos que essa ação é necessária para cumprir as obrigações legais; (2) proteger e defender nossos direitos ou propriedade; (3) se determinarmos unilateralmente que há uma violação de nossos termos, políticas ou leis; (4) detectar ou prevenir fraude ou outras atividades ilegais; (5) proteger nossos produtos, funcionários ou usuários ou segurança pública, segurança, integridade; ou (vi) para se defender contra a responsabilidade legal.',
    subBlock19: 'Acesse suas informações pessoais e informações sobre como elas são processadas.',
    subBlock2:
      'Conteúdo do usuário: Quando você usa nossos serviços, coletamos informações pessoais de seus insumos, uploads de arquivos ou feedback fornecidos a nós ("Conteúdo").',
    subBlock20: 'Solicite a exclusão de suas informações pessoais de nossos registros.',
    subBlock21: 'Corrija ou atualize suas informações pessoais.',
    subBlock22: 'Transfira suas informações pessoais para terceiros (portabilidade de dados).',
    subBlock23: 'Limite como processamos suas informações pessoais.',
    subBlock24:
      'Retire seu consentimento - onde confiamos no consentimento como base legal para o processamento, você pode retirar seu consentimento a qualquer momento.',
    subBlock25: 'Objeta como processamos suas informações pessoais.',
    subBlock26: 'Melhorar nossos serviços e realizar pesquisas;',
    subBlock3:
      'Informações de comunicação: se você nos enviar mensagens, coletaremos seu nome, informações de contato e o conteúdo de suas mensagens ("Informações de comunicação").',
    subBlock4:
      'Informações das mídias sociais: temos páginas em sites de mídia social como Instagram, Facebook, Medium, Twitter, YouTube e LinkedIn. Quando você interage com nossas páginas de mídia social, coletamos as informações pessoais que você escolhe nos fornecer, como seus detalhes de contato ("informações sociais"). Além disso, as empresas que hospedam nossas páginas de mídia social podem nos fornecer informações e análises agregadas sobre nossas atividades de mídia social.',
    subBlock5:
      'Dados de log: informações enviadas automaticamente pelo seu navegador ao usar nossos serviços. Os dados de log incluem seu endereço de protocolo da Internet (IP), tipo de navegador e configurações, a data e a hora de suas solicitações e como você interage com o nosso site.',
    subBlock6:
      'Dados de uso: podemos coletar automaticamente informações sobre o uso do serviço, como os tipos de conteúdo que você visualiza ou interage, os recursos que você usa, as ações que você executa, bem como seu fuso horário, país, data e hora de acesso, agente de usuário e versão, tipo de computador ou dispositivo móvel e sua conexão com o computador.',
    subBlock7:
      'Informações do dispositivo: isso inclui o nome do dispositivo, o sistema operacional, o identificador do dispositivo e o navegador que você está usando. As informações coletadas podem depender do tipo de dispositivo que você está usando e de suas configurações.',
    subBlock8:
      'Cookies: usamos cookies para operar e gerenciar nossos serviços e melhorar sua experiência. Um "cookie" é informações enviadas por um site que você visita ao seu navegador. Você pode definir seu navegador para aceitar todos os cookies, rejeitar todos os cookies ou notificá -lo quando um cookie for fornecido para que você possa decidir se a aceitou a cada vez. No entanto, recusar cookies pode impedir que você use ou afete negativamente a exibição ou a funcionalidade de certas áreas ou recursos do site. Para mais informações sobre cookies, visite tudo sobre cookies.',
    subBlock9:
      'Analytics: podemos usar vários produtos de análise on -line que usam cookies para nos ajudar a analisar como os usuários usam nossos serviços e melhorar sua experiência ao usar os serviços.',
    subTitle1: 'Informações pessoais que coletamos',
    subTitle2: 'Como usamos informações pessoais',
    subTitle3: 'Divulgação de informações pessoais',
    subTitle4: 'Seus direitos',
    subTitle5: 'Links para outros sites',
    subTitle6: 'Segurança e retenção de informações',
    subTitle7: 'Modificação da Política de Privacidade',
    subTitle8: 'Como nos contatar',
    title: 'política de Privacidade',
    updateTime: 'Atualizado: 15 de março de 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Aceitar tudo',
    text: 'Para melhorar sua experiência, usamos cookies para personalizar conteúdo e serviços.',
    title: 'Este site usa cookies',
  },
  slogen: 'O assistente de IA mais fácil',
  stripe: {
    contact: 'Entre em contato conosco em contact@noam.tools com qualquer dúvida!',
    fail: {
      btn: 'Tente novamente',
      text1: 'Não conseguimos processar seu pagamento.',
      text2: 'Verifique sua conexão e tente novamente.',
      title: 'Pagamento falhou',
    },
    succ: { btn: 'Vamos', text1: 'Bem -vindo a Noam,', text2: 'Vamos começar!', title: 'Parabéns' },
  },
  terms: {
    block1: 'Obrigado por usar o Noam!',
    block10:
      '(a) Taxas e cobrança. Você pagará todas as taxas ("taxas") de acordo com os preços e os termos na página de preços aplicáveis ​​ou conforme acordado por escrito entre nós. Reservamo -nos o direito de corrigir erros ou erros de preços, mesmo que tenhamos emitido faturas ou recebemos pagamento. Você fornecerá informações completas e precisas de cobrança, incluindo um método de pagamento válido e autorizado. Cobraremos seu método de pagamento periodicamente conforme acordado, mas podemos mudar razoavelmente a data de cobrança. Você autoriza o NOAM, suas afiliadas e processadores de pagamento de terceiros a cobrar seu método de pagamento. Se o seu pagamento falhar, forneceremos aviso por escrito e poderá suspender o acesso ao serviço até que o pagamento seja recebido. Exceto quando especificado de outra forma neste Contrato, os pagamentos não são reembolsáveis.',
    block11:
      '(b) Impostos. Salvo indicação em contrário, as taxas não incluem impostos federais, estaduais, locais e estrangeiros, taxas e avaliações semelhantes ("impostos"). Você é responsável por todos os impostos associados às suas compras, excluindo impostos com base em nosso lucro líquido para o qual faturaremos você. Você concorda em pagar prontamente esses impostos e nos fornecer declarações de privacidade legalmente válidas e obter os consentimentos necessários para lidar com esses dados, e você nos garante que lida com esses dados de acordo com a lei aplicável.',
    block12:
      '(c) Alterações de preço. Podemos mudar nossos preços notificando você por meio de sua conta e/ou nosso site. Os aumentos de preços entrarão em vigor imediatamente após a publicação. Qualquer alteração de preço será aplicada às taxas da sua conta após a data efetiva da alteração.',
    block13:
      '(d) Disputas e pagamentos vencidos. Se você deseja contestar quaisquer taxas ou impostos, entre em contato com contact@noam.tools dentro de trinta (30) dias a partir da data da fatura.',
    block14:
      '(e) Uso gratuito. Você não pode criar várias contas para obter créditos gratuitos. Se determinarmos que você não está usando os créditos gratuitos de boa fé, podemos cobrar taxas padrão ou parar de fornecer acesso ao serviço.',
    block15:
      '(a) Confidencialidade. Você pode ter acesso a informações confidenciais da NOAM. Você só pode usar informações confidenciais permitidas sob estes Termos com o objetivo de usar o Serviço. Você não pode divulgar informações confidenciais a terceiros e protegerá as informações confidenciais de uma maneira não menos protetora do que proteger suas próprias informações confidenciais semelhantes, pelo menos com cuidados razoáveis. As informações confidenciais são informações não públicas designadas como confidenciais pela NoAM ou que devem ser razoavelmente tratadas como confidenciais nessas circunstâncias, incluindo software, especificações e outras informações comerciais não públicas. As informações confidenciais não incluem informações de que: (1) geralmente se torna disponível ao público sem falhas de sua parte; (2) você tinha em sua posse sem qualquer obrigação de confidencialidade antes de recebê -lo sob esses termos; (3) é legalmente divulgado a você por terceiros, sem nenhuma obrigações de confidencialidade; ou (4) é desenvolvido independentemente por você sem usar as informações confidenciais. Você pode divulgar informações confidenciais, se exigido por lei, tribunal ou outra ordem governamental, mas deve fornecer aviso prévio por escrito à NOAM de maneira razoável e, na medida do possível, fazer esforços razoáveis ​​para limitar o escopo da divulgação, incluindo nos auxiliar nos pedidos de divulgação opostos.',
    block16:
      '(b) Segurança. Você deve implementar medidas razoáveis ​​e apropriadas para ajudar a proteger seu acesso e uso do serviço. Se você descobrir alguma vulnerabilidade ou violações relacionadas ao uso do serviço, entre em contato imediatamente com o NoAM e fornecer detalhes da vulnerabilidade ou violação.',
    block17:
      '((c) Processamento de dados pessoais. Se você usar o Serviço para processar dados pessoais, deverá fornecer declarações de privacidade legalmente suficientes e obter os consentimentos necessários para o processamento desses dados, e você nos garante que lida com esses dados de acordo com a lei aplicável.',
    block18:
      '(a) terminação; Suspensão. Esses termos entrarão em vigor desde o seu primeiro uso do serviço e permanecerão em vigor até que sejam encerrados. Você pode encerrar estes Termos a qualquer momento, por qualquer motivo, descontinuando o uso do Serviço e do Conteúdo. Podemos encerrar esses termos com aviso prévio por qualquer motivo. Podemos rescindir imediatamente estes Termos notificando-o se você violar materialmente a Seção 2 (Requisitos de Uso), a Seção 5 (Confidencialidade, Segurança e Proteção de Dados), Seção 8 (Resolução de Disputas) ou Seção 9 (Termos Gerais) ou se nosso relacionamento com qualquer provedor de tecnologia de terceiros além de nossas mudanças de controle, ou para conformidade com a lei ou o governo. Podemos suspender seu acesso ao Serviço se você não cumprir estes Termos, ou se o seu uso representa um risco de segurança para nós ou terceiros, ou se suspeitarmos que seu uso seja fraudulento ou possa nos expor ou terceiros à responsabilidade.',
    block19:
      '(b) Efeitos da terminação. Após o término, você deixará de usar o serviço e retornará prontamente ou destruirá qualquer informação confidencial conforme indicado por nós. As disposições destes Termos que, por sua natureza, sobreviverem à rescisão ou expiração, incluindo, entre outros, as seções 3 e 5-9, sobreviverão.',
    block2:
      'Estes Termos de Uso se aplicam ao uso dos serviços do NOAM, incluindo nossas interfaces de programação de aplicativos, software, ferramentas, serviços de desenvolvedor, dados, documentação e site (coletivamente chamados de "Serviços"). Ao usar nossos serviços, você concorda em cumprir esses termos. Nossa política de privacidade explica como coletamos e usamos informações pessoais.',
    block20:
      '(a) Indenização. Você se defenderá, indenizará e nos manterá, nossas afiliadas e nosso pessoal inofensivo de e contra quaisquer reivindicações, perdas e despesas (incluindo honorários advocatícios) decorrentes ou relacionados ao uso do serviço, incluindo o seu conteúdo, o uso de produtos ou serviços relacionados ao serviço e à sua violação desses termos ou aplicável.',
    block21:
      '(b) Isenção de isenções. O serviço é fornecido "como está". Na extensão permitida por lei, nós e nossas afiliadas e licenciadores não fazemos representações ou garantias de qualquer tipo em relação ao serviço e não fazemos garantias, incluindo, entre outros, garantias de comercialização, adequação a uma finalidade específica, qualidade satisfatória, não violação e desfrute silencioso e qualquer garantia que surja, obviamente, é claro que o uso ou uso de negócios. Não garantimos que o serviço seja ininterrupto, preciso ou sem erros, ou que qualquer conteúdo seja seguro, não perdido ou não alterado.',
    block22:
      '(c) Limitação de responsabilidade. Nem nós, nossas afiliadas, nem nossos licenciadores, seremos responsáveis ​​por qualquer dano indireto, incidental, especial, conseqüente ou punitivo, incluindo perda de lucros, perda de reputação, perda de uso, perda de dados ou outras perdas intangíveis, mesmo que tenhamos sido informados da possibilidade de tais danos. Sob estes Termos, nossa responsabilidade total não excederá as taxas que você pagou ao serviço nos doze (12) meses anteriores à reivindicação ou cem dólares (US $ 100), o que for maior. As limitações nesta seção se aplicam à extensão máxima permitida pela lei aplicável.',
    block23:
      'Os termos e seu uso dos serviços são regidos pelas leis dos Estados Unidos, exceto pelas regras do conflito de leis dos EUA. Seu uso do pedido também pode estar sujeito a outras leis locais, estaduais, nacionais ou internacionais. Qualquer ação legal ou processo relacionado a estes Termos será apresentado exclusivamente nos tribunais dos Estados Unidos e você concorda em se submeter à jurisdição pessoal de tais tribunais.',
    block24:
      '(a) relacionamento das partes. Noam e você são contratados independentes, e nenhuma das partes tem o poder de representar ou vincular a outra parte ou assumir obrigações para a outra parte sem o consentimento prévio por escrito da outra parte.',
    block25:
      '(b) Uso da marca. Você não pode usar o nome, logotipos ou marcas comerciais da NOAM ou qualquer uma de suas afiliadas sem o nosso consentimento prévio por escrito.',
    block26:
      'Se qualquer disposição destes Termos estiver determinada a ser inválida, ilegal ou inexequível, as disposições restantes permanecerão em pleno vigor e efeito.',
    block3:
      'Se você tem menos de 18 anos, deve obter permissão de seus pais ou responsáveis ​​legais para usar o serviço. Se você estiver usando o serviço em nome de outra pessoa ou entidade, deve estar autorizado a aceitar esses termos em seu nome. Você deve fornecer informações precisas e completas para se registrar para uma conta. Você não pode fornecer suas credenciais ou conta de acesso a qualquer pessoa fora da sua organização e é responsável por todas as atividades realizadas usando suas credenciais.',
    block4:
      '(a) Uso do serviço. Você pode acessar o serviço sob estes Termos e concedemos a você um direito não exclusivo de usar o Serviço. Ao usar o serviço, você cumprirá estes Termos e todas as leis aplicáveis. Nós e nossas afiliadas possuímos todos os direitos, títulos e interesses no serviço.',
    block5:
      '(b) Feedback. Congratulamo -nos com feedback, comentários, idéias, sugestões e melhorias. Se você fornecer esse conteúdo, podemos usá -lo sem restrições e sem compensação para você.',
    block6:
      '(c) Restrições. Você não pode: (1) usar o serviço de uma maneira que viola, desviça indevidamente ou viola quaisquer direitos de outras pessoas; (2) engenheiro reverso, descompilar, desmontar, traduzir ou tentar descobrir o código -fonte ou componentes subjacentes dos modelos de serviço, algoritmos e sistemas (a menos que essas restrições sejam proibidas pela lei aplicável); (3) use a saída do serviço para desenvolver modelos competindo com NOAM; (4) extrair dados ou saída do serviço usando quaisquer métodos automatizados ou de programação, a menos que seja permitido através da API, incluindo raspagem, colheita da Web ou extração de dados da Web; (5) representam a saída do serviço como gerado artificialmente quando não é, ou viola nossas políticas de uso; (6) comprar, vender ou transferir chaves da API sem nosso consentimento prévio; ou (7) nos fornecer qualquer informação pessoal de crianças menores de 13 anos ou idade aplicável de consentimento digital. Você cumprirá qualquer limite de taxa e outros requisitos em nossa documentação.',
    block7:
      '(d) Serviços de terceiros. Qualquer software, serviços ou outros produtos de terceiros associados ao serviço são regidos por seus próprios termos e não somos responsáveis ​​por produtos de terceiros.',
    block8:
      '(a) Seu conteúdo. Você pode fornecer a entrada ("entrada") ao serviço e receber saída ("saída") gerado e retornado com base na entrada (coletivamente chamada de "conteúdo"). Entre as partes e, na medida do permitido pela lei aplicável, você possui todas as contribuições. O NOAM pode usar o conteúdo para fornecer e manter o serviço, cumprir as leis aplicáveis ​​e aplicar nossas políticas. Você é responsável pelo conteúdo, incluindo a garantia de que ele não viole nenhuma lei aplicável ou estes Termos.',
    block9:
      '(b) Precisão. A inteligência artificial e o aprendizado de máquina estão em rápida evolução dos campos de pesquisa. Nós nos esforçamos continuamente para melhorar nosso serviço para torná -lo mais preciso, confiável, seguro e benéfico. Dada a natureza probabilística do aprendizado de máquina, o uso de nosso serviço pode resultar em resultados incorretos em certos casos, o que pode não refletir com precisão indivíduos, lugares ou fatos reais. Você deve avaliar adequadamente a precisão de qualquer saída com base em seu caso de uso, inclusive revisando manualmente os resultados.',
    subTitle1: 'Registro e acesso',
    subTitle10: 'Separabilidade',
    subTitle2: 'Requisitos de uso',
    subTitle3: 'Contente',
    subTitle4: 'Taxas e pagamento',
    subTitle5: 'Confidencialidade, segurança e proteção de dados',
    subTitle6: 'Termo e terminação',
    subTitle7: 'Indenização; Isenções de responsabilidade; Limitação de responsabilidade',
    subTitle8: 'Lei de governo e jurisdição',
    subTitle9: 'Termos gerais',
    title: 'Termos de Serviço',
    updateTime: 'Atualizado: 15 de março de 2024',
  },
  trialModal: {
    btn: 'Comece agora （Apenas $ 0,99)',
    chat: 'Chatpdf',
    desc1: 'Após o seu julgamento, você será cobrado uma taxa anual de US $ 180',
    desc2: 'Cancelar a qualquer hora',
    focus: 'Foco',
    hover: 'Tradução pairar',
    image: 'Tradução da imagem',
    installBtn: 'Instale agora para receber seu presente',
    memo: 'Gerenciamento de favoritos',
    pdf: 'Tradução paralela em PDF',
    realtime: 'Tradução instantânea',
    select: 'Tradução de seleção de palavras',
    title: 'Presente de boas -vindas',
    trialText: 'Julgamento de 7 dias',
    video: 'Tradução de vídeo',
    videoSummary: 'Resumo do vídeo',
    web: 'Tradução paralela',
    webSummary: 'Resumo da Web',
  },
  upgradeModal: {
    btn: 'Atualize agora',
    chat: {
      free: 'Você atingiu o limite de chatpdf',
      pro: 'Você atingiu o limite de chatpdf para este mês',
    },
    contactStr: 'Aguarde a próxima redefinição ou entre em contato conosco em contact@noam.tools.',
    limitAlertStr: 'Você atingiu seu limite de plano gratuito. Atualizar para continuar.',
    more: 'Ver planos',
    pageLimit: { free: 'Você atingiu o limite da página em PDF' },
    title: 'Lembrete',
    upgradeAlertStr: 'Você atingiu seu limite de plano gratuito. Atualizar para continuar.',
    upload: {
      free: 'Você atingiu seu limite de tradução em PDF',
      pro: 'Você atingiu seu limite de tradução em PDF para este mês',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Junte -se agora',
      label: 'Novos benefícios do usuário:',
      remain: 'Apenas 30 restantes',
      text: 'Apenas 100 pontos disponíveis! Pegue o seu agora!',
    },
  },
}

export default TRANSLATION
