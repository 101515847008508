const TRANSLATION = {
  buyModal: {
    afterFree: 'Ücretsiz denemenizden sonra',
    btn: 'Ücretsiz dene',
    freeTrial: '3 günlük ücretsiz deneme',
    monthLabel: 'ay',
    selectLabel: 'Bir Plan Seçin',
    title: 'Abonelik planı',
    yearLabel: 'yıl',
  },
  contact: {
    block1:
      'Özelleştirme, sorular veya öneriler için lütfen contact@noam.tools adresinden bize ulaşın. Hemen cevap vereceğiz.',
    block2: "★★★★★ bizi Google Apps Extension Store'da derecelendirerek bizi destekleyin.",
    block3: 'Bu uzantıyı paylaşarak başkalarına yardım edin:',
    title: 'Bize Ulaşın',
  },
  help: {
    AquickOverviewOfNoam: "Noam'a hızlı bir genel bakış",
    ChatPDF: 'Sohbet pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Favoriler yönetimi',
    Fixedplugin: 'Sabit eklenti',
    Focusing: 'Odaklanma',
    Free: 'Özgür',
    FreeTrialBenefits: 'Ücretsiz Deneme Faydaları',
    GoWithWikipedia: 'Wikipedia ile git',
    GoWithYouTube: 'YouTube ile Git',
    HoverTranslation: 'Hover çevirisi',
    Howtouse: 'Nasıl Kullanılır',
    ImageTranslation: 'Resim Çeviri',
    InstantTranslation: 'Anında Çeviri',
    Membership: 'Üyelik',
    NoamHelp: 'Noam çevirmenize ve özetlemenize yardımcı olur',
    PDFparallelTranslation: 'PDF paralel çeviri',
    ParallelTranslation: 'Paralel çeviri',
    SkipAllSteps: 'Tüm Adımları Atla',
    Summary: 'Özet',
    Translation: 'Çeviri',
    TryItForFree: 'Ücretsiz dene',
    Video: 'Video',
    VideoSummary: 'Video Özeti',
    VideoTranslation: 'Video çevirisi',
    WebSummary: 'Web Özeti',
    WordSelectionTranslation: 'Kelime Seçimi Çeviri',
    benefits: 'Sizi bekleyen faydalarımız var!',
    dayFree: '3 günlük ücretsiz deneme',
    done: 'Tamamlamak',
    next: 'Sonraki',
    pin: 'Daha kolay erişim için noam pin ～～',
    planIsNotExist: 'Plan mevcut değil',
    startWiki: 'Wikimedia ile başlayalım!',
    startYoutube: 'YouTube ile başlayalım!',
    try: 'Deneyin',
    tryNow: 'Şimdi bir deneyin!',
  },
  home: {
    PDFTranslate: {
      desc: 'İki Dilli Çeviri + Sohbet PDF',
      sub1: {
        desc: 'İki dilli okuma, orijinal metni ve çeviriyi yan yana karşılaştırarak okuma verimliliğini artırmanıza olanak tanır.',
        title: 'İki dilli okuma',
      },
      sub2: {
        desc: 'AI, sorularınızı PDF içeriğine göre analiz eder ve özetler ve cevaplayabilir.',
        title: 'Sohbet pdf',
      },
      sub3: {
        desc: 'Daha fazla doğruluk ve verimlilik için AI ile çalışan PDF çevirisi.',
        title: 'Doğruluk ve verimlilik',
      },
      title: 'PDF Çevir',
    },
    aiAssistant: {
      desc: 'Web çevirisini daha akıllı ve daha uygun hale getirmek',
      panel1: {
        desc: 'İki dilli çapraz referans çevirileri, orijinal metni çeviri ile karşılaştırmanıza yardımcı olur, böylece daha iyi anlayabilir ve okuma verimliliğinizi artırabilirsiniz.',
        title: 'İki dilli çeviri',
      },
      panel2: {
        left: {
          desc: 'Bir web sayfasına göz atarken, çeviriyi görmek için çevirmeniz gereken içeriğin üzerine gelin.',
          title: 'Hover çevirisi',
        },
        right: {
          desc: 'Bir web sayfasına göz atarken çeviriyi görmek için tercüme etmek istediğiniz metni seçin.',
          title: 'Metin seçimi çevirisi',
        },
      },
      panel3: {
        one: {
          desc: 'Daha yumuşak bir deneyim için web sayfalarını ve video içeriğini istediğiniz zaman, istediğiniz zaman çevirin ve özetleyin!',
          title: 'Nokta ve tıklayın',
        },
        three: {
          desc: 'AI güdümlü büyük dil modeli, gelişmiş bir okuma deneyimi için çeviri doğruluğu ve tutarlılığı sağlar.',
          title: 'Yapay zıtlı',
        },
        two: {
          desc: 'Çok dilli tam kapsama, iki dilli bariyersiz okuma, tek tıklamayla çeviri-farkı yaşayın!',
          title: 'Daha kapsamlı ve kullanışlı',
        },
      },
      panel4: {
        tab1: {
          desc: "Verimlilik Uzmanınız - ChatGPT'yi kullanarak Noam size zaman kazanmak için YouTube video içeriğini çevirir ve özetler!",
          key: 'Video',
          title: 'Video Çeviri ve Özet',
        },
        tab2: {
          desc: "Web'e göz atarken resimleri çevirmek için tıklayın.",
          key: 'İmaj',
          title: 'Resim Çevir',
        },
        tab3: {
          desc: 'Web sayfalarından anahtar bilgileri anında çıkarır ve okuma verimliliğinizi artıran bir özet sağlar.',
          key: 'Ağ',
          title: 'Web Çeviri ve Özet',
        },
        tag1: 'Basit',
        tag2: 'Verimli',
        tag3: 'Anlaması kolay',
      },
      title: 'AI asistanı',
    },
    getStart: 'Başlamak',
    getStartedForFree: 'Ücretsiz başlayın',
    howItWork: {
      desc: 'Noam, kullanıcılara çapraz diller arası web sayfası çevirisi ve özetleme, PDF ve görüntü çevirisi, video çevirisi ve özetleme için akıllı bir deneyim sunmak için AI modellerini entegre eder.',
      title: 'Nasıl Çalışır',
    },
    lastBlock: { desc: 'Daha pürüzsüz bir okuma deneyimi için daha iyi AI çevirisi' },
    top: {
      desc: "Noam, web sayfalarını, videoları ve pdf içeriğini çevirmenize ve özetlemenize yardımcı olan ücretsiz bir AI tarayıcı uzantısıdır. \\ N Noam'a katılın ve büyüyü deneyimleyin!",
      title1: 'One Sihir',
      title2: 'Tek tıklama',
    },
    userReviews: {
      desc: 'Chrome Web Mağazasında Beş Yıldızlı İncelemeler',
      title: 'Kullanıcı İncelemeleri',
    },
  },
  installTips: {
    btn: 'Şimdi dene',
    text: 'Daha fazla özelliğin kilidini açmak için "Noam Tarayıcı Eklentisi" ni yükleyin! Web Çapraz Referans, \\ N Web Video Özetleme ve daha fazlası!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'Amharik',
    ar: 'Arapça',
    as: 'Assamca',
    auto: 'Otomatik algılama',
    az: 'Azerbaycan',
    ba: 'Bashkir',
    bg: 'Bulgarca',
    bn: 'Bangla',
    bo: 'Tibet',
    bs: 'Bosnalı',
    ca: 'Katalan',
    cs: 'Çek',
    cy: 'Galler',
    da: 'Danimarkalı',
    de: 'Almanca',
    dsb: 'Aşağı Sorbian',
    dv: 'Divehi',
    el: 'Yunan',
    en: 'İngilizce',
    es: 'İspanyol',
    et: 'Estonya',
    eu: 'Bask',
    fa: 'Farsça',
    fi: 'Fince',
    fil: 'Filipinli',
    fj: 'Fiji',
    fr: 'Fransızca',
    frCA: 'Fransızca (Kanada)',
    ga: 'İrlandalı',
    gl: 'Galicialı',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'İbranice',
    hi: 'Hintçe',
    hr: 'Hırvat',
    hsb: 'Üst Sorbian',
    ht: 'Haiti Creole',
    hu: 'Macarca',
    hy: 'Ermeni',
    id: 'Endonezyalı',
    ig: 'İgbo',
    ikt: 'İnuinnaqtun',
    is: 'İzlandaca',
    it: 'İtalyan',
    iu: 'İnuktitut',
    iuLatn: 'Inuktitut (Latin)',
    ja: 'Japonca',
    ka: 'Gürcü',
    kk: 'Kazak',
    km: 'Khmer',
    kmr: 'Kürt (Kuzey)',
    kn: 'Kannada',
    ko: 'Koreli',
    ku: 'Kürt (Merkez)',
    ky: 'Kırgız',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Litvanya',
    lug: 'Ganda',
    lv: 'Letonyalı',
    lzh: 'Çince (edebi)',
    mai: 'Miras',
    mg: 'Mada',
    mi: 'Māori',
    mk: 'Makedonya',
    ml: 'Malayalam',
    mnCyrl: 'Moğol (Kiril)',
    mnMong: 'Moğol (geleneksel)',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Maltalı',
    mww: 'Hmong Daw',
    my: 'Myanmar (Birmanya)',
    nb: 'Norveççe',
    ne: 'Nepal',
    nl: 'Flemenkçe',
    noSearchLang: 'Dil desteklenmiyor',
    nso: 'Sesotho SA LEBOA',
    nya: 'Nyanja',
    or: 'ODIA',
    otq: 'Querétaro otomi',
    pa: 'Punjabi',
    pl: 'Cila',
    placeholder: 'Aramak',
    prs: 'Dari',
    ps: 'Peşek',
    pt: 'Portekizce (Brezilya)',
    ptPT: 'Portekizce (Portekiz)',
    ro: 'Romanya',
    ru: 'Rusça',
    run: 'Runi',
    rw: 'Kindarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Sloven',
    sm: 'Samoalı',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Arnavut',
    srCyrl: 'Sırp (Kiril)',
    srLatn: 'Sırp (Latince)',
    st: 'Sesotho',
    sv: 'İsveççe',
    sw: 'Svahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Tayland',
    ti: 'Tigrinya',
    tk: 'Türkmen',
    tlhLatn: 'Klingon (Latin)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongan',
    tr: 'Türkçe',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'Uygur',
    uk: 'Ukrayna',
    ur: 'Urdu',
    uz: 'Özbek (Latin)',
    vi: 'Vietnam',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonca (geleneksel)',
    zh: 'Çince',
    zhHans: 'Çince basitleştirildi',
    zhHant: 'Çince geleneksel',
    zu: 'Zulu',
  },
  login: {
    and: 'Ve',
    btn: 'Google ile oturum açın',
    btn1: 'Şimdi giriş yapın',
    desc: 'Daha fazla deneyimin kilidini açmak için giriş yapın',
    policy: 'Gizlilik Politikası',
    terms: 'Kullanım Koşulları',
    tips: 'Kaydolarak kabul ediyorum',
    title: "Noam'a hoş geldiniz",
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Takip etmek', twitter: 'Twitter' },
    legal: { policy: 'Gizlilik Politikası', terms: 'Hizmet Şartları', title: 'Yasal' },
    product: {
      image: 'Resim Çeviri',
      pdf: 'PDF çevirisi',
      title: 'Ürünler',
      video: 'Video Çeviri ve Özet',
      web: 'Web çevirisi ve özeti',
    },
    resources: { title: 'Kaynaklar' },
  },
  pageHeader: {
    contact: 'Temas etmek',
    help: 'Yardım',
    logout: 'Çıkış yapmak',
    logoutSucc: 'Oturum açtı.',
    pricing: 'Fiyatlandırma',
    settings: 'Ayarlar',
    signUp: 'Giriş yapmak',
  },
  pdf: {
    autoDetect: 'Otomatik olarak tespit',
    cancelBtn: 'İptal etmek',
    cancelUpload: 'İptal etmek',
    chatPdf: {
      summaryError: 'Özet başarısız oldu, lütfen daha sonra tekrar deneyin',
      chatError: 'Sohbet başarısız oldu, lütfen daha sonra tekrar deneyin',
      Search: 'Aramak',
      cancel: 'İptal etmek',
      chatTitle: "Noam'a hoş geldiniz! İşte PDF'nizin bir özeti:",
      clear: 'Temizlemek',
      clearContent: 'Bu yeni bir konuşma başlatacak. Mevcut konuşma kurtarılmayacak. Emin misin?',
      clearDialogTitle: 'Lütfen onaylayın',
      clearSucc: 'Temizlenmiş',
      copy: 'Kopya',
      copySucc: 'Kopyalanmış',
      delete: 'Silmek',
      deleteCancel: 'İptal etmek',
      deleteContent: 'Bu PDF silindikten sonra kurtarılamaz. Emin misin?',
      deleteOk: 'Silmek',
      deleteSuccess: 'Silinmiş',
      deleteTitle: 'Silini onaylayın',
      expand: 'Yıkılmak',
      historyTitle: 'Tarih',
      listError:
        'Yükleme listesi yüklenemedi. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
      loading: 'Yükleme ...',
      noDataDesc: 'Henüz tarih yok.',
      noMore: 'Artık içerik yok.',
      placeholder: 'Bu PDF hakkında bir şey sorun',
      summaryTitle: 'Bu sorularla ilgilenebilirsiniz:',
    },
    collectionEmpty: 'Koleksiyonunuz boş.',
    collectionTab: 'Notalar',
    dataLoading: 'Yükleme ...',
    dialogContent: 'Bunu silmek istediğinden emin misiniz?',
    dialogTitle: 'Lütfen onaylayın',
    download: {
      both: 'İki dilli',
      btn: 'Kaydetmek',
      cancel:
        'Bu pencereyi kapatma PDF indirmesini iptal eder. İptal etmek istediğinden emin misiniz?',
      desc: 'Cihaza Kaydet: Çevirileri resim olarak indirir; Kaydet ve Yazdır: Çevirileri orijinal formatta kopyala desteğiyle indirir.',
      download: 'İndirmek',
      downloadMsg1: 'PDF Çeviri İlerlemesi:',
      downloadMsg2: 'Tahmini kalan süre:',
      downloadSucc: 'PDF hazır! Kaydetme için tıklayın.',
      downloadTip: 'PDF indirme. İndirme hatalarını önlemek için bu pencereyi kapatmayın.',
      error: 'Tasarruf edemedi. Bunun yerine yazdırmayı deneyin.',
      fileName: 'Dosya adı:',
      fileType: 'Dosya Türü:',
      loading: 'Tasarruf ...',
      loading2: 'Yükleme ...',
      minute: 'Dakikalar ...',
      msg1: 'Bu belgedeki bazı çeviriler orijinal metinden daha uzundur ve indirilen dosyada örtüşebilir. Web sayfasında okumanızı öneririz.',
      msg2: '1. Çeviri henüz tamamlanmadı. Lütfen çeviri bittikten sonra indirin.',
      msg3: '2. Bazı çeviriler çok uzun. Metin çakışmasını önlemek için web sayfasında okuyun.',
      msg4: 'Çeviri görevleri devam ediyor. Lütfen tamamlandıktan sonra tekrar deneyin.',
      progress: 'Çevirme PDF. İlerleme: {{Progress}}, Tahmini Kalan Süre: {{Dakika}} Dakika ...',
      save: 'Cihazdan Kaydet',
      savePrint: 'Kaydet ve Yazdır',
      success: 'İndirilmiş',
      trans: 'Yalnızca Çeviri',
      transContinue: 'Çevirmeye devam et',
    },
    downloadBtn: 'İndirmek',
    dragTipModal: {
      black: 'uzay',
      leftKey: 'sol düğme',
      ok: 'Anladım',
      press: 'Basmak',
      tips: 'sürüklemek ve görüntülemek için.',
    },
    entry: "PDF'ler",
    fileErrMsg:
      'Dosya okuma başarısız oldu. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    fileMsg: 'Geçerli dosya boyutu:',
    fileMsg2: "Lütfen 300MB'den küçük bir dosya yükleyin.",
    freeBannerBtn: 'Güncelleme',
    freeBannerTips: 'Ücretsiz PDF Çevirileri Limit ulaşıldı',
    guide: {
      placeholder: 'PDF bağlantısını buraya yapıştırın',
      start: 'Çevirmeye Başla',
      text1: 'İki dilli okuma',
      text2:
        'Orijinal metni ve çeviriyi yan yana karşılaştırmak için iki dilli okuyun, verimliliğinizi artırın.',
      text3: "PDF'nizle sohbet edin",
      text4:
        "AI ile çalışan analiz ve özetleme. PDF'niz hakkında sorular sorun ve anında cevaplar alın.",
      text5: 'Doğru ve Verimli',
      text6: 'Daha fazla doğruluk ve verimlilik için AI ile çalışan PDF çevirisi.',
      title1: 'İki dilli çeviri',
      title2: "PDF'nizle sohbet edin",
      toast: 'Lütfen .pdf ile biten geçerli bir çevrimiçi PDF bağlantısı girin',
    },
    hasTransTask: 'Bir çeviri görevi devam ediyor. Lütfen bekleyin.',
    hoverTrans: 'Hover-to Translate paragrafları',
    initial: 'PDF Çevir',
    installText: 'Günlük çeviriler için tek elden çözümünüz.',
    installText2: 'Lütfen önce uzantıyı kurun',
    installTips:
      'Daha fazla PDF çevirisi ve daha yumuşak web sitesi çevirileri için uzantıyı yükleyin:',
    kw: 'zımba',
    link: 'bağlantı',
    listError: 'PDF yüklenemedi. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    memoTotalCount: 'Toplam 1 madde',
    new: 'Yeni dosya',
    noId: 'Dosya kimliği bulunamadı.',
    notPdf: 'Yalnızca PDF dosyaları.',
    okBtn: 'Silmek',
    okBtn2: 'Anladım',
    original: 'Orijinal',
    parallel: 'Paralel',
    parseErrDialogContent:
      "Bu PDF bağlantısı doğrudan çevrilemez. Lütfen PDF'yi indirin ve çeviri için yükleyin.",
    parseErrTips: 'Dosyayı ayrıştıramadı',
    parseFileToast:
      'Dosyayı ayrıştıramadı. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    pdfLoading: 'Ayrıştırma ...',
    searchPlaceholder: 'Aramak',
    selectTrans: 'Çevirmek için metni seçin',
    shareBtn: 'Paylaşmak',
    shareBtnText1: 'Paylaşım Bağlantısı Oluşturun',
    shareBtnText2: 'Paylaşılan İçeriği Güncelle',
    shareModal: {
      cancel: 'İptal etmek',
      copy: 'Kopya',
      copySucc: 'Bağlantı kopyalandı',
      custom: 'Özelleştirmek:',
      errMsg: 'Seçilen içerik henüz tam olarak çevrilmemiştir. Lütfen paylaşmadan önce çevirin.',
      gen: 'Paylaşmak',
      modify: 'Düzenlemek',
      notSupport: 'Bu dil şu anda desteklenmiyor.',
      page: 'Sayfa',
      pageNuErr: 'Lütfen geçerli bir sayfa numarası girin.',
      range: 'Kapsam:',
      remain: 'geriye kalan',
      remain1: 'Toplam tahmini süre:',
      search: 'Aramak',
      settings: 'Paylaşım Ayarları:',
      shareSetting: 'Paylaşım Ayarları',
      shareText: 'Paylaşmak',
      shareTips: 'Çeviri PDF bağlantısını arkadaşlarınızla paylaşın:',
      sourceLang: 'İtibaren:',
      target: 'İle:',
      text1: 'Paylaşım Ayarları',
      text2: 'Paylaşım Bağlantısı Oluşturun',
      totalPage: 'Toplam 1 sayfa',
      trans: 'Tercüme etmek',
      translateTips:
        'İpucu: Çeviri biraz zaman alıyorsa, bu pencereyi kapatabilir ve daha sonra paylaşabilirsiniz.',
      translating: 'Çevirme PDF ...',
      update: 'GÜNCELLEME PAYLAŞI',
    },
    shareMsg1: 'Oluşturulan bağlantıyı paylaşın.',
    shareMsg2: 'Paylaşılan içerik güncellendi.',
    shareMsg3:
      'Paylaşım bağlantısı oluşturulamadı. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    shareMsg4:
      'Paylaşılan içeriği güncelleyemedi. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    shareTips: 'PDF çeviriniz hazır! Arkadaşlarınızla paylaşın.',
    start: 'Ücretsiz denemenize başlayın',
    thumbnail: 'İçindekiler',
    toast1: 'Yükleme PDF. Lütfen daha sonra tekrar deneyin.',
    toast2: 'PDF içeriği çok büyük. Diyalog şu anda desteklenmiyor.',
    toast3: 'İndirme. İndirme bittikten sonra lütfen tekrar deneyin.',
    toolbar: { Adjust: 'Ayarlamak', autoFit: 'Oto' },
    trans: 'Çeviri',
    transSucc: 'Çeviri başarılı!',
    unLoginToast: 'Lütfen bu özelliği kullanmak için giriş yapın.',
    uploadBtn: 'Yüklemek',
    uploadErr:
      'Dosya yüklemesi başarısız oldu. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    uploadErrSignUrl:
      "Yükleme URL'sini alamadı. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.",
    uploadMsg1: 'Dosyanızı buraya sürükleyin ve bırakın.',
    uploadMsg2: 'Desteklenen Dosya Türleri: PDF |  Maksimum dosya boyutu: 100MB',
    uploadMsg3: 'Dosyaları seçmek veya sürüklemek ve bırakmak için tıklayın.',
    uploading: 'Yükleme',
    uploadingMsg: 'Yükleme. Lütfen bekleyin.',
    uploadingMsg1: 'Dosya yüklüyor. Lütfen daha sonra tekrar deneyin.',
    uploadingMsg2: 'Şu anda bir dosya dönüştürülüyor. Lütfen daha sonra tekrar deneyin.',
    uploadingMsg3:
      'Dönüşüm İlerleme Talebi başarısız oldu. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.',
    uploadingMsg4: 'Noam yerel dosyalara erişemez. Çeviri için lütfen bir PDF yükleyin.',
    uploadingMsg5:
      "Dosya URL'sini alamadı. Lütfen daha sonra tekrar deneyin veya destekle iletişime geçin.",
    video: 'video',
    vipBannerBtn: 'Güncelleme',
    vipBannerTips: 'Bu ay için PDF çeviri sınırınıza ulaştınız.',
    webTrans: 'Tam çapraz referans',
  },
  pricing: {
    FAQ: 'SSS',
    FQAObj: {
      a1: 'Evet, Noam hemen kullanabileceğiniz ücretsiz bir plan sunuyor.',
      a2: 'Üç üyelik planı sunuyoruz: Pro, Pro+ve Enterprise. Aylık veya her yıl ödemeyi seçebilirsiniz. Yıllık faturalandırma önemli tasarruflar sunar.',
      a3: 'Tüm özellikler her iki planda da mevcuttur. Temel fark maliyettir: yıllık aboneliklerin maliyeti daha az. Aylık abonelikler her ay faturalandırılırken, yıllık planlar yılda bir kez faturalandırılır.',
      a4: 'Aboneliğinizi hesap ayarlarınızda istediğiniz zaman iptal edebilirsiniz.',
      q1: 'Ücretsiz bir plan var mı?',
      q2: 'Hangi fiyatlandırma planları ve ödeme seçenekleri mevcuttur?',
      q3: 'Aylık ve yıllık olarak abone olmak arasındaki fark nedir?',
      q4: 'Aboneliğimi nasıl iptal edebilirim?',
    },
    around: 'Etrafında',
    billedMonthly: 'Aylık faturalandırıldı',
    billedYearly: 'Yıllık faturalandırıldı',
    day: 'gün',
    desc: 'Planları karşılaştırın ve en iyisini seçin',
    detailedCompare: 'Planları karşılaştırın',
    discount: '% 20 indirim',
    enterprise: {
      benefit1: 'Öncelikli Müşteri Desteği',
      benefit2: 'Birden fazla kullanıcı',
      benefit3: 'Özel Çözümler',
      benefit5: 'Özel Hesap Yöneticisi',
      btn: 'Bize Ulaşın',
      recommendText: 'Özel Planlar （≥3 人）',
      talkToUs: 'Temasa geçmek',
    },
    free: {
      benefit1: 'Anında Çeviri Gün/gün 100 kez',
      benefit2: 'Web sitesi çevirisi ve özet',
      benefit3: '30 resim çevirileri',
      benefit4: 'Video Çeviri ve Özet',
      benefit5: '10 PDF çevirisi',
      benefit6: '5.000 jeton',
      btn: 'Ücretsiz dene',
      recommendText: 'Ücretsiz deneme',
    },
    imageTranslation: 'Resim Çeviri',
    month: 'ay',
    monthly: 'Aylık',
    pageTitle: 'Fiyatlandırma',
    pdf: {
      chat: 'PDF sohbet sorguları',
      maxPage: 'Maks. PDF başına sayfalar',
      translation: 'PDF çevirisi',
    },
    preMonth: 'Aylık',
    pro: {
      benefit1: 'Öncelikli Müşteri Desteği',
      benefit2: 'Aylık 1.000.000 jeton',
      benefit3: 'Web sitesi çevirisi ve özet',
      benefit4: '200 resim çevirileri',
      benefit5: 'Video Çeviri ve Özet',
      benefit6: '200 PDF çevirisi',
      btn: 'Başlamak',
      recommendText: 'En popüler',
    },
    proPlus: {
      benefit1: 'Öncelikli Müşteri Desteği',
      benefit2: '3.000.000 jeton/ay',
      benefit3: 'Web sitesi çevirisi ve özet',
      benefit4: '500 resim çevirisi',
      benefit5: 'Video Çeviri ve Özet',
      benefit6: '500 PDF çevirisi',
      recommendText: 'En İyi Değer',
    },
    video: { summary: 'Video Özeti', translation: 'Video çevirisi' },
    web: {
      contrastiveTranslation: 'İki dilli çeviri',
      dictionaryTranslation: 'Seçim çevirisi',
      hoverTranslation: 'Hover çevirisi',
      instantTranslation: 'Anında Çeviri',
      summary: 'Web sayfası özeti',
    },
    yearly: 'Yıllık',
  },
  privacy: {
    block1:
      'Noam ("Noam," "biz", "bizim" veya "biz") gizliliğinize saygı duyar ve sizden veya sizden elde ettiğimiz bilgileri korumaya karar verir. Bu Gizlilik Politikası, web sitemizi, uygulamalarımızı ve hizmetleri (toplu olarak "hizmetler") kullanmanızdan kişisel bilgilerin toplanması veya kullanımı ile ilgili uygulamalarımızı açıklamaktadır. Bu Gizlilik Politikası, API\'miz gibi iş ürün müşterilerimiz adına ele aldığımız içerik için geçerli değildir. Bu verilerin kullanımı, bu ürünlere erişimi ve kullanımını kapsayan Müşteri Sözleşmemize tabidir.',
    block10:
      'Çevrimiçi ve çevrimdışı kişisel bilgileri kayıp, kötüye kullanım ve yetkisiz erişim, ifşa, değişiklik veya düşmanca eylemlerden korumak için ticari olarak makul teknik, idari ve organizasyonel önlemler uyguluyoruz. Ancak, hiçbir internet veya e -posta iletimi tam güvenlik veya doğruluğu garanti edemez. Özellikle, bize veya bize gönderilen e -postalar güvenli olmayabilir. Bu nedenle, hizmet veya e -posta yoluyla bize hangi bilgilere gönderileceğine karar verirken özellikle dikkatli olmalısınız. Ayrıca, Hizmet veya üçüncü taraf web sitelerinin güvenlik ayarlarında veya güvenlik önlemlerindeki gizlilik ayarlarının veya güvenlik önlemlerinin engellenmesinden sorumlu değiliz. Kişisel bilgilerinizi size hizmet vermemiz veya anlaşmazlıkların çözülmesi, güvenlik ve güvenlik nedenleri veya yasal yükümlülüklerimize uymak gibi diğer meşru iş amaçları için gerektiğinde saklayacağız. Kişisel bilgileri sakladığımız süre, miktar, doğa, bilgilerin hassasiyeti, yetkisiz kullanım veya açıklama potansiyel riskleri, bilgileri işlediğimiz amaçlar ve diğer yasal olarak bağlayıcı hususlar gibi birçok faktöre bağlı olacaktır.',
    block11:
      'Bu gizlilik politikasını zaman zaman güncelleyebiliriz. Bunu yaptığımızda, geçerli yasalar tarafından aksi belirtilmedikçe, bu sayfada güncellenmiş bir sürüm yayınlayacağız.',
    block12:
      'Bu Gizlilik Politikası hakkında cevaplanmamış sorularınız veya endişeleriniz varsa, lütfen Müşteri Hizmetlerine başvurun. E -posta:',
    block2: 'Sizinle ilgili aşağıdaki kişisel bilgileri ("kişisel bilgiler") toplarız:',
    block3:
      'Sizin tarafınızdan sağlanan bilgiler: Hizmetlerimizi kullanmak veya bizimle iletişim kurmak için bir hesap oluşturduğunuzda, kişisel bilgileri aşağıdaki gibi toplarız:',
    block4:
      'Hizmetleri kullanımınızdan otomatik olarak alınan kişisel bilgiler: Hizmetlere eriştiğinizde, kullandığınızda veya etkileşim kurduğunuzda, erişim, kullanım veya etkileşim ("teknik bilgiler") hakkında aşağıdaki bilgileri alırız:',
    block5: 'Kişisel bilgileri aşağıdaki amaçlarla kullanabiliriz:',
    block6:
      'Özet veya tanımlanmamış bilgiler: Kişisel bilgileri tanımlama amacıyla yeniden kullanımını önlemek ve hizmetlerimizin etkinliğini analiz etmek, hizmetlerimize özellikleri geliştirmek ve eklemek, araştırma yapmak ve benzer amaçlar için kullanmak için kişisel bilgileri toplayabilir veya tanımlayabiliriz. Ayrıca, hizmet kullanıcılarımızın genel davranışını ve özelliklerini analiz edebilir ve toplu bilgileri üçüncü taraflarla paylaşabilir, bu tür toplu bilgileri yayınlayabilir veya genel olarak kullanılabilir hale getirebiliriz. Toplu bilgileri hizmetler aracılığıyla, çerezler aracılığıyla ve bu gizlilik politikasında açıklanan diğer yollarla toplayabiliriz. We will maintain and use de-identified information in an anonymous or de-identified form, and we will not attempt to re-identify such information unless required by law.',
    block7:
      'Bazı durumlarda, kişisel bilgilerinizi yasaların gerektirmediği sürece size daha fazla bildirimde bulunmadan üçüncü taraflara sağlayabiliriz:',
    block8:
      'Coğrafi konumunuza bağlı olarak, Avrupa Ekonomik Bölgesi, Birleşik Krallık ve küresel olarak kişisel bilgilerle ilgili belirli yasal haklara sahip olabilirler. Örneğin, aşağıdakileri yapma hakkınız olabilir:',
    block9:
      'Hizmet, Sosyal Medya Hizmetleri ("Üçüncü Taraf Web Siteleri") dahil olmak üzere Noam tarafından işletilmeyen veya kontrol edilmeyen diğer web sitelerine bağlantılar içerebilir. Üçüncü taraf web siteleriyle paylaştığınız bilgiler, bu gizlilik politikası tarafından değil, üçüncü taraf web sitelerinin belirli gizlilik politikalarına ve hizmet şartlarına tabi olacaktır. Bu bağlantıları kolaylık sağlamak için sunuyoruz ve bu web sitelerinin onaylanmamızı veya incelememizi ima etmiyoruz. Gizlilik politikaları ve şartları hakkında bilgi için lütfen üçüncü taraf web sitelerine doğrudan başvurun.',
    subBlock1:
      'Hesap Bilgileri: Platformumuzda bir hesap oluşturduğunuzda, adınız, iletişim bilgileriniz, hesap kimlik bilgileri, ödeme kartı bilgileri ve işlem geçmişiniz ("Hesap Bilgileri") dahil olmak üzere hesabınızla ilgili bilgileri toplarız.',
    subBlock10: 'Hizmetleri sağlamak, yönetmek, sürdürmek ve/veya analiz etmek;',
    subBlock11: 'Sizinle iletişim kurmak;',
    subBlock12: 'Yeni ürün ve hizmetler geliştirmek;',
    subBlock13:
      'Dolandırıcılığı, suç faaliyetini veya hizmetlerimizin kötüye kullanılmasını önlemek ve BT sistemlerimizin, mimarimizin ve ağlarımızın güvenliğini korumak;',
    subBlock14: 'Ticari transferler yapmak;',
    subBlock15:
      'Yasal yükümlülüklere ve yasal işlemlere uymak, haklarımızı, mahremiyetimizi, güvenliğimizi veya mülkümüzün yanı sıra bağlı kuruluşlarımızın, siz veya diğer üçüncü taraflarımızın korunması.',
    subBlock16:
      'Tedarikçiler ve Hizmet Sağlayıcılar: İşletme operasyonel ihtiyaçlarını karşılamamıza ve belirli hizmet ve işlevleri yerine getirmemize yardımcı olmak için, hizmet sağlayıcılar, bulut hizmet sağlayıcıları ve web analizi hizmet sağlayıcıları, e -posta iletişim yazılımı ve web analizi hizmet sağlayıcıları, vb.',
    subBlock17:
      'Ticari Transferler: Stratejik işlemler, yeniden düzenlemeler, iflaslar, iflas devralmalar veya başka bir sağlayıcıya (topluca "işlemler") geçiş yaparsak, kişisel bilgileriniz ve diğer bilgileriniz, işlemlerde veya diğer bilgilerin bir parçası olarak işlemlerde yardımcı olan ve diğer varlıklarla birlikte, diğer varlıklar ile birlikte aktarılan ve diğer bilgilerle paylaşılabilirse, diğer varlıklar ile birlikte aktarılır.',
    subBlock18:
      'Yasal Gereksinimler: Kişisel bilgilerinizi hükümet yetkilileri, endüstri akranları veya etkileşim bilgileriniz de dahil olmak üzere hizmetlerimizle paylaşabiliriz, (1) Yasa gerektiriyorsa veya bu tür bir işlemin yasal yükümlülüklere uymak için gerekli olduğuna inanırsak; (2) haklarımızı veya mülklerimizi korumak ve savunmak; (3) terimlerimizin, politikalarımızın veya yasalarımızın ihlali olduğunu tek taraflı olarak belirlersek; (4) sahtekarlığı veya diğer yasadışı faaliyetleri tespit etmek veya önlemek; (5) ürünlerimizi, çalışanlarımızı veya kullanıcılarımızı veya kamu güvenliğimizi korumak için; veya (vi) yasal sorumluluğa karşı savunmak.',
    subBlock19: 'Nasıl işlendiği hakkında kişisel bilgilerinize ve bilgilerinize erişin.',
    subBlock2:
      'Kullanıcı İçeriği: Hizmetlerimizi kullandığınızda, bize sağlanan girişlerinizden, dosya yüklemelerinizden veya geri bildirimlerinizden kişisel bilgileri toplarız ("İçerik").',
    subBlock20: 'Kişisel bilgilerinizin kayıtlarımızdan silinmesini isteyin.',
    subBlock21: 'Kişisel bilgilerinizi düzeltin veya güncelleyin.',
    subBlock22: 'Kişisel bilgilerinizi üçüncü bir tarafa aktarın (veri taşınabilirliği).',
    subBlock23: 'Kişisel bilgilerinizi nasıl işlediğimizi sınırlayın.',
    subBlock24:
      'Rızanızı geri çekin - İşleme için yasal temel olarak rızaya güveniyoruz, rızanızı istediğiniz zaman geri çekebilirsiniz.',
    subBlock25: 'Kişisel bilgilerinizi nasıl işlediğimize itiraz edin.',
    subBlock26: 'Hizmetlerimizi geliştirmek ve araştırma yapmak;',
    subBlock3:
      'İletişim Bilgileri: Bize mesaj gönderirseniz, adınızı, iletişim bilgilerinizi ve mesajlarınızın içeriğini ("iletişim bilgileri") toplarız.',
    subBlock4:
      'Sosyal Medya Bilgileri: Instagram, Facebook, Orta, Twitter, YouTube ve LinkedIn gibi sosyal medya web sitelerinde sayfalarımız var. Sosyal medya sayfalarımızla etkileşime girdiğinizde, iletişim bilgileriniz ("sosyal bilgiler") gibi bize vermeyi seçtiğiniz kişisel bilgileri toplarız. Ayrıca, sosyal medya sayfalarımızı barındıran şirketler bize sosyal medya faaliyetlerimiz hakkında toplu bilgi ve analiz sağlayabilir.',
    subBlock5:
      'Günlük verileri: Hizmetlerimizi kullanırken tarayıcınız tarafından otomatik olarak gönderilen bilgiler. Günlük verileri, İnternet Protokolü (IP) adresinizi, tarayıcı türünüzü ve ayarlarını, isteklerinizin tarihini ve saatini ve web sitemizle nasıl etkileşim kurduğunuzu içerir.',
    subBlock6:
      'Kullanım Verileri: Hizmeti kullanımınız hakkında görüntülediğiniz veya etkileşime girdiğiniz içerik türleri, kullandığınız özellikler, yaptığınız işlemlerin yanı sıra saat diliminiz, ülkeniz, erişim tarihi ve saatiniz, kullanıcı aracısı ve sürümünüz, bilgisayar veya mobil cihaz türünüz ve bilgisayar bağlantınız gibi otomatik olarak bilgi toplayabiliriz.',
    subBlock7:
      'Cihaz Bilgileri: Bu, cihazın adı, işletim sistemi, cihaz tanımlayıcısı ve kullandığınız tarayıcıyı içerir. Toplanan bilgiler, kullandığınız cihaz türüne ve ayarlarına bağlı olabilir.',
    subBlock8:
      'Çerezler: Hizmetlerimizi çalıştırmak ve yönetmek ve deneyiminizi geliştirmek için çerezleri kullanırız. "Çerez", tarayıcınıza ziyaret ettiğiniz bir web sitesi tarafından gönderilen bilgilerdir. Tarayıcınızı her seferinde kabul edip etmeyeceğinize karar verebilmeniz için tüm çerezleri kabul edecek, tüm çerezleri reddedebilir veya bir çerez sağlandığında sizi bilgilendirebilirsiniz. Bununla birlikte, çerezleri reddetmek, web sitesinin belirli alanlarının veya özelliklerinin ekranını veya işlevselliğini kullanmanızı veya olumsuz etkilemenizi engelleyebilir. Çerezler hakkında daha fazla bilgi için lütfen Çerezler hakkında her şeyi ziyaret edin.',
    subBlock9:
      'Analytics: Kullanıcıların hizmetlerimizi nasıl kullandıklarını analiz etmemize ve hizmetleri kullanırken deneyiminizi geliştirmemize yardımcı olmak için çerezleri kullanan çeşitli çevrimiçi analiz ürünlerini kullanabiliriz.',
    subTitle1: 'Topladığımız kişisel bilgiler',
    subTitle2: 'Kişisel Bilgileri Nasıl Kullanıyoruz',
    subTitle3: 'Kişisel bilgilerin açıklanması',
    subTitle4: 'Haklarınız',
    subTitle5: 'Diğer web sitelerine bağlantılar',
    subTitle6: 'Bilgi güvenliği ve elde tutulması',
    subTitle7: 'Gizlilik Politikasının Değiştirilmesi',
    subTitle8: 'Bizimle nasıl iletişime geçilir',
    title: 'Gizlilik Politikası',
    updateTime: 'Güncellendi: 15 Mart 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Hepsini kabul et',
    text: 'Deneyiminizi geliştirmek için, içerik ve hizmetleri kişiselleştirmek için çerezleri kullanırız.',
    title: 'Bu web sitesi çerezleri kullanıyor',
  },
  slogen: 'En kolay AI asistanı',
  stripe: {
    contact: 'Herhangi bir sorunuz varsa contact@noam.tools adresinden bize ulaşın!',
    fail: {
      btn: 'Yeniden yapmak',
      text1: 'Ödemenizi işleyemedik.',
      text2: 'Bağlantınızı kontrol edin ve tekrar deneyin.',
      title: 'Ödeme başarısız oldu',
    },
    succ: {
      btn: 'Hadi gidelim',
      text1: "Noam'a hoş geldiniz,",
      text2: 'Başlayalım!',
      title: 'Tebrikler',
    },
  },
  terms: {
    block1: "Noam'ı kullandığınız için teşekkürler!",
    block10:
      '(a) Ücretler ve faturalandırma. Tüm ücretleri ("Ücretler"), geçerli fiyatlandırma sayfasındaki fiyatlandırma ve şartlara göre veya aramızda yazılı olarak üzerinde anlaşmaya varılır. Faturalar veya ödeme almış olsak bile fiyatlandırma hatalarını veya hataları düzeltme hakkını saklı tutarız. Geçerli ve yetkili bir ödeme yöntemi de dahil olmak üzere eksiksiz ve doğru faturalandırma bilgileri sağlayacaksınız. Ödeme yönteminizi üzerinde kararlaştırıldığı gibi periyodik olarak ücretlendireceğiz, ancak faturalandırma tarihini makul bir şekilde değiştirebiliriz. Ödeme yönteminizi şarj etmek için Noam\'a, bağlı kuruluşlarına ve üçüncü taraf ödeme işlemcilerine yetki verirsiniz. Ödemeniz başarısız olursa, size yazılı bildirimde bulunacağız ve ödeme alınana kadar hizmete erişimi askıya alabiliriz. Bu Sözleşmede aksi belirtilmedikçe, ödemeler iade edilemez.',
    block11:
      '(b) Vergiler. Aksi belirtilmedikçe, ücretler herhangi bir federal, eyalet, yerel ve yabancı vergiler, görevler ve benzer değerlendirmeleri ("vergiler") içermez. Sizi faturalayacağımız net gelirimize dayalı vergiler hariç, alımlarınızla ilişkili tüm vergilerden siz sorumlusunuz. Bu tür vergileri derhal ödemeyi ve bize yasal olarak geçerli gizlilik beyanları sağlamayı ve bu tür verileri ele almak için gerekli onayları almayı kabul edersiniz ve bu tür verileri geçerli yasalara uygun olarak ele aldığınızı bize garanti edersiniz.',
    block12:
      '(c) Fiyat değişiklikleri. Hesabınız ve/veya web sitemiz aracılığıyla sizi bilgilendirerek fiyatlarımızı değiştirebiliriz. Fiyat artışları gönderildikten hemen sonra yürürlüğe girecektir. Herhangi bir fiyat değişikliği, değişikliğin yürürlüğe girdiği tarihten sonra hesabınız için ücretler için geçerlidir.',
    block13:
      '(d) Anlaşmazlıklar ve gecikmiş ödemeler. Herhangi bir ücrete veya vergiye itiraz etmek istiyorsanız, lütfen fatura tarihinden itibaren otuz (30) gün içinde contact@noam.tools ile iletişime geçin.',
    block14:
      '(e) Ücretsiz kullanım. Ücretsiz kredi almak için birden fazla hesap oluşturamazsınız. Ücretsiz kredileri iyi niyetle kullanmadığınızı belirlersek, size standart ücretler talep edebilir veya hizmete erişim sağlamayı bırakabiliriz.',
    block15:
      "(a) Gizlilik. Noam'ın gizli bilgilerine erişiminiz olabilir. Gizli bilgileri yalnızca bu şartlar altında izin verilen şekilde kullanabilirsiniz. Gizli bilgileri herhangi bir üçüncü tarafa ifşa edemezsiniz ve gizli bilgileri, en azından makul bir özenle, benzer gizli bilgilerinizi koruduğunuzdan daha az koruyucu olmayan bir şekilde koruyacaksınız. Gizli bilgiler, NOAM tarafından gizli olarak belirlenen veya yazılım, özellikler ve diğer kamuya açık olmayan iş bilgileri de dahil olmak üzere koşullar altında gizli olarak ele alınması gereken kamuya açık olmayan bilgilerdir. Gizli bilgiler aşağıdakileri içeren bilgileri içermez: (1) sizin tarafınızdan hata yapmadan genel olarak halka açık hale gelir; (2) bu şartlar altında almadan önce herhangi bir gizlilik yükümlülüğü olmadan sahip olduğunuz; (3) herhangi bir gizlilik yükümlülüğü olmadan üçüncü bir taraf tarafından yasal olarak açıklanmaktadır; veya (4) gizli bilgileri kullanmadan bağımsız olarak geliştirilmiştir. Yasa, mahkeme veya başka bir hükümet emrinin gerektirdiği takdirde gizli bilgileri ifşa edebilirsiniz, ancak NOAM'a makul bir şekilde önceden yazılı bildirimde bulunmanız ve açıklama taleplerine karşı bize yardımcı olmak da dahil olmak üzere açıklama kapsamını sınırlamak için makul çaba sarf etmeniz gerekir.",
    block16:
      '(b) Güvenlik. Hizmete erişiminizi ve kullanımınızı korumaya yardımcı olmak için makul ve uygun önlemler uygulamalısınız. Hizmeti kullanımınızla ilgili herhangi bir güvenlik açıkını veya ihlali keşfederseniz, derhal Noam ile iletişime geçmeli ve güvenlik açığı veya ihlalin ayrıntılarını sağlamalısınız.',
    block17:
      '((c) Kişisel verilerin işlenmesi. Hizmeti kişisel verileri işlemek için kullanırsanız, yasal olarak yeterli gizlilik beyanları sağlamalı ve bu tür verilerin işlenmesi için gerekli rızaları almalısınız ve bu verileri geçerli yasalara uygun olarak ele aldığınızı garanti etmelisiniz.',
    block18:
      '(a) fesih; Süspansiyon. Bu şartlar, hizmetin ilk kullanımından etkili olacak ve sonlandırılana kadar yürürlükte kalacaktır. Hizmet ve içeriğin kullanılmasını durdurarak herhangi bir nedenle bu şartları herhangi bir zamanda feshedebilirsiniz. Bu şartları herhangi bir nedenle önceden bildirimde bulunabiliriz. Bölüm 2 (kullanım gereksinimleri), Bölüm 5 (Gizlilik, Güvenlik ve Veri Koruma), Bölüm 8 (Uyuşmazlık Çözümü) veya Bölüm 9 (Genel Terimler) veya herhangi bir üçüncü taraf teknoloji sağlayıcısıyla olan ilişkimizin kontrol değişikliklerimizin ötesinde veya yasa veya hükümet taleplerine uyumu için maddi olarak ihlal ederseniz, bu Şartları size bildirerek derhal feshedebiliriz. Bu şartlara uyamazsanız veya kullanımınız bizim veya herhangi bir üçüncü taraf için bir güvenlik riski oluşturursanız veya kullanımınızın hileli olduğundan veya bizi veya herhangi bir üçüncü tarafı yükümlülüğe maruz bırakabileceğinden şüpheleniyorsak, hizmete erişiminizi askıya alabiliriz.',
    block19:
      '(b) Fesihin etkileri. Fesih üzerine, hizmeti kullanmayı bırakacak ve bizim tarafımıza yönelik gizli bilgileri derhal iade edecek veya yok edeceksiniz. Bu Koşulların doğası gereği, Bölüm 3 ve 5-9 dahil ancak bunlarla sınırlı olmamak üzere fesih veya son kullanma sonlandırma hükümleri hayatta kalacaktır.',
    block2:
      'Bu Kullanım Koşulları, Uygulama Programlama Arabirimlerimiz, Yazılım, Araçlar, Geliştirici Hizmetleri, Veriler, Belgeler ve Web Sitemiz (toplu olarak "Hizmetler" olarak adlandırılır) dahil olmak üzere Noam\'ın hizmetlerini kullanmanız için geçerlidir. Hizmetlerimizi kullanarak bu şartlara uymayı kabul edersiniz. Gizlilik politikamız kişisel bilgileri nasıl topladığımızı ve kullandığımızı açıklar.',
    block20:
      '(a) Tazminat. Bizi, bağlı kuruluşlarımızı ve personelimizi, içeriğinizden, hizmetinizle ilgili ürün veya hizmet kullanmanız ve bu şartlar veya uygulanabilir yasaların ihlali de dahil olmak üzere, hizmetinizden kaynaklanan veya hizmetinizden kaynaklanan herhangi bir talep, kayıp ve masraftan (avukatlık ücretleri dahil) zararsız olarak savunacak, tazmin edecek ve tutacaksınız.',
    block21:
      '(b) Feragatnameler. Hizmet "olduğu gibi" sağlanır. Yasaların izin verdiği ölçüde, biz ve iştiraklerimiz ve lisans verenlerimiz, hizmetle ilgili herhangi bir tür temsil veya garanti vermiyoruz ve satılabilirlik, belirli bir amaca uygunluk, tatmin edici kalite, ihlal etme ve sessiz zevk ve ticaretin ele alınması veya kullanımı ortaya çıkan garantiler dahil ancak bunlarla sınırlı olmamak üzere hiçbir garanti vermiyoruz. Hizmetin kesintisiz, doğru veya hatasız olacağını veya herhangi bir içeriğin güvenli, kaybedilmeyeceğini veya değiştirilmeyeceğini garanti etmiyoruz.',
    block22:
      '(c) Sorumluluğun sınırlandırılması. Ne biz, bağlı kuruluşlarımız ne de lisans verenlerimiz, bu zararlar, bu tür zararlar olasılığı tavsiye edilse bile, kâr kaybı, itibar kaybı, kullanım kaybı, veri kaybı veya diğer maddi olmayan kayıplar da dahil olmak üzere dolaylı, arızi, özel, sonuçsal veya cezai zararlardan sorumlu olmayacağız. Bu şartlar uyarınca, toplam sorumluluğumuz, talebin önceki on iki (12) ayda veya hangisi daha büyükse yüz ABD doları (100 $) ayında hizmete ödediğiniz ücretleri aşmayacaktır. Bu bölümdeki sınırlamalar, yürürlükteki yasaların izin verdiği maksimum ölçüde geçerlidir.',
    block23:
      "Hizmetleri ve hizmetleri kullanımınız, ABD'nin yasa çatışması kuralları hariç, ABD yasalarına tabidir. Başvuruyu kullanımınız diğer yerel, eyalet, ulusal veya uluslararası yasalara tabi olabilir. Bu şartlarla ilgili herhangi bir yasal işlem veya işlem sadece ABD mahkemelerine getirilecektir ve bu tür mahkemelerin kişisel yargı yetkisine sunmayı kabul edersiniz.",
    block24:
      '(a) Tarafların ilişkisi. Noam ve siz bağımsız yüklenicisiniz ve hiçbir tarafın diğer tarafı temsil etme veya bağlama veya diğer tarafın önceden yazılı izni olmadan diğer taraf için yükümlülükleri üstlenme yetkisi yoktur.',
    block25:
      '(b) Marka kullanımı. Önceden yazılı iznimiz olmadan Noam veya herhangi bir bağlı kuruluşunun adını, logolarını veya ticari markalarını kullanamazsınız.',
    block26:
      'Bu şartların herhangi bir hükmünün geçersiz, yasadışı veya uygulanamaz olduğu belirlenirse, geri kalan hükümler tam olarak yürürlükte kalacaktır.',
    block3:
      '18 yaşın altındaysanız, hizmeti kullanmak için ailenizden veya yasal vasilerden izin almalısınız. Hizmeti başka bir kişi veya kuruluş adına kullanıyorsanız, bu şartları kendi adına kabul etme yetkisine sahip olmanız gerekir. Bir hesaba kaydolmak için doğru ve eksiksiz bilgiler sağlamalısınız. Kuruluşunuz dışındaki herhangi birine erişim kimlik bilgilerinizi veya hesabınızı sağlayamazsınız ve kimlik bilgilerinizi kullanarak yürütülen tüm faaliyetlerden siz sorumlusunuz.',
    block4:
      '(a) Hizmet kullanımı. Hizmete bu şartlar altında erişebilirsiniz ve size hizmeti kullanma konusunda münhasır olmayan bir hak veririz. Hizmeti kullanırken, bu şartlara ve yürürlükteki tüm yasalara uyacaksınız. Biz ve iştiraklerimiz hizmetteki tüm haklara, unvanlara ve ilgi alanlarına sahip olduk.',
    block5:
      '(b) Geri bildirim. Geri bildirimleri, yorumları, fikirleri, önerileri ve iyileştirmeleri memnuniyetle karşılıyoruz. Böyle bir içerik sağlarsanız, bunu herhangi bir kısıtlama olmadan ve size tazminat almadan kullanabiliriz.',
    block6:
      '(c) Kısıtlamalar. Şunları yapamazsınız: (1) hizmeti başkalarının haklarını ihlal edecek, kötüye kullanacak veya ihlal edecek şekilde kullanın; (2) servis modellerinin, algoritmalarının ve sistemlerin kaynak kodunu veya temel bileşenlerini (bu tür kısıtlamalar geçerli yasalar tarafından yasaklanmadığı sürece). (3) Noam ile rekabet eden modeller geliştirmek için hizmetin çıktısını kullanın; (4) API aracılığıyla kazıma, web hasadı veya web veri çıkarımı dahil olmak üzere herhangi bir otomatik veya programlama yöntemi kullanılarak verileri veya hizmetten çıktı; (5) hizmetin çıktısını olmadığı zaman yapay olarak üretildiği veya kullanım politikalarımızı ihlal ettiği gibi temsil etmek; (6) Önceden onayımız olmadan API anahtarlarını satın almak, satmak veya aktarmak; veya (7) bize 13 yaşın altındaki çocukların veya geçerli dijital rıza yaşı yaşlarının kişisel bilgilerini sağlamak. Belgelerimizdeki herhangi bir oran limitine ve diğer gereksinimlere uyacaksınız.',
    block7:
      '(d) Üçüncü taraf hizmetleri. Hizmetle ilişkili herhangi bir üçüncü taraf yazılım, hizmet veya diğer ürünler kendi şartlarına tabidir ve üçüncü taraf ürünlerden sorumlu değiliz.',
    block8:
      '(a) İçeriğiniz. Hizmete giriş ("Giriş") sağlayabilir ve girişe göre oluşturulan ve döndürülen çıktı ("Çıkış") (toplu olarak "İçerik" olarak adlandırılır) sağlayabilirsiniz. Taraflar arasında ve yürürlükteki yasaların izin verdiği ölçüde, tüm girdilere sahip olursunuz. Noam, hizmeti sağlamak ve sürdürmek, yürürlükteki yasalara uymak ve politikalarımızı uygulamak için içeriği kullanabilir. Geçerli yasaları veya bu Koşulları ihlal etmemesini sağlamak da dahil olmak üzere içerikten siz sorumlusunuz.',
    block9:
      '(b) Doğruluk. Yapay zeka ve makine öğrenimi hızla gelişen araştırma alanlarıdır. Daha doğru, güvenilir, güvenli ve faydalı hale getirmek için hizmetimizi geliştirmeye çalışıyoruz. Makine öğreniminin olasılıksal doğası göz önüne alındığında, hizmetimizi kullanmak, gerçek bireyleri, yerleri veya gerçekleri doğru bir şekilde yansıtmayabilecek bazı durumlarda yanlış çıktılara neden olabilir. Çıktıları manuel olarak incelemek de dahil olmak üzere, kullanım durumunuza göre herhangi bir çıktının doğruluğunu uygun şekilde değerlendirmelisiniz.',
    subTitle1: 'Kayıt ve Erişim',
    subTitle10: 'Koparılabilirlik',
    subTitle2: 'Kullanım Gereksinimleri',
    subTitle3: 'İçerik',
    subTitle4: 'Ücretler ve Ödeme',
    subTitle5: 'Gizlilik, Güvenlik ve Veri Koruma',
    subTitle6: 'Terim ve fesih',
    subTitle7: 'Tazminat; Feragatnameler; Sorumluluk sınırlaması',
    subTitle8: 'Yönetim Yasası ve Yargı',
    subTitle9: 'Genel terimler',
    title: 'Hizmet Şartları',
    updateTime: 'Güncellendi: 15 Mart 2024',
  },
  trialModal: {
    btn: 'Şimdi başlayın （Sadece 0,99 $）',
    chat: 'Chatpdf',
    desc1: 'Duruşmanızdan sonra, yıllık 180 $ ücret alacaksınız',
    desc2: 'İstediğiniz zaman iptal edin',
    focus: 'Odaklanma',
    hover: 'Hover çevirisi',
    image: 'Resim Çeviri',
    installBtn: 'Hediyenizi almak için şimdi yükleyin',
    memo: 'Favoriler yönetimi',
    pdf: 'PDF paralel çeviri',
    realtime: 'Anında Çeviri',
    select: 'Kelime Seçimi Çeviri',
    title: 'Hoş Geldiniz Hediyesi',
    trialText: '7 günlük deneme',
    video: 'Video çevirisi',
    videoSummary: 'Video Özeti',
    web: 'Paralel çeviri',
    webSummary: 'Web Özeti',
  },
  upgradeModal: {
    btn: 'Şimdi yükselt',
    chat: { free: 'Chatpdf sınırına ulaştınız', pro: 'Bu ay için chatpdf sınırına ulaştınız' },
    contactStr:
      'Lütfen bir sonraki sıfırlamayı bekleyin veya contact@noam.tools adresinden bize ulaşın.',
    limitAlertStr: 'Ücretsiz plan sınırınıza ulaştınız. Devam etmek için yükseltin.',
    more: 'Planları Görüntüle',
    pageLimit: { free: 'PDF sayfa sınırına ulaştınız' },
    title: 'Hatırlatma',
    upgradeAlertStr: 'Ücretsiz plan sınırınıza ulaştınız. Devam etmek için yükseltin.',
    upload: {
      free: 'PDF çeviri sınırınıza ulaştınız',
      pro: 'Bu ay için PDF çeviri sınırınıza ulaştınız',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Şimdi Katılın',
      label: 'Yeni Kullanıcı Avantajları:',
      remain: 'Sadece 30 kaldı',
      text: 'Sadece 100 nokta mevcut! Şimdi seninkini al!',
    },
  },
}

export default TRANSLATION
