const TRANSLATION = {
  buyModal: {
    afterFree: 'بعد تجربتك المجانية',
    btn: 'جربه مجانًا',
    freeTrial: '3 أيام تجربة مجانية',
    monthLabel: 'شهر',
    selectLabel: 'حدد خطة',
    title: 'خطة الاشتراك',
    yearLabel: 'سنة',
  },
  contact: {
    block1:
      'للتخصيص أو الأسئلة أو الاقتراحات ، يرجى الاتصال بنا على contact@noam.tools. سنرد على الفور.',
    block2: 'دعمنا من خلال تصنيفنا على متجر Google Apps Extension على ★★★★★.',
    block3: 'مساعدة الآخرين من خلال مشاركة هذا التمديد:',
    title: 'اتصل بنا',
  },
  help: {
    AquickOverviewOfNoam: 'نظرة عامة سريعة على نعوم',
    ChatPDF: 'دردشة PDF',
    ChatPDF2: 'chatpdf',
    FavoritesManagement: 'إدارة المفضلة',
    Fixedplugin: 'البرنامج المساعد الثابت',
    Focusing: 'التركيز',
    Free: 'حر',
    FreeTrialBenefits: 'فوائد تجريبية مجانية',
    GoWithWikipedia: 'اذهب مع ويكيبيديا',
    GoWithYouTube: 'اذهب مع YouTube',
    HoverTranslation: 'تحوم الترجمة',
    Howtouse: 'كيفية استخدام',
    ImageTranslation: 'ترجمة الصورة',
    InstantTranslation: 'ترجمة فورية',
    Membership: 'عضوية',
    NoamHelp: 'NOAM يساعدك على ترجمة وتلخيص',
    PDFparallelTranslation: 'PDF الترجمة الموازية',
    ParallelTranslation: 'ترجمة موازية',
    SkipAllSteps: 'تخطي جميع الخطوات',
    Summary: 'ملخص',
    Translation: 'ترجمة',
    TryItForFree: 'جربه مجانًا',
    Video: 'فيديو',
    VideoSummary: 'ملخص الفيديو',
    VideoTranslation: 'ترجمة الفيديو',
    WebSummary: 'ملخص الويب',
    WordSelectionTranslation: 'ترجمة اختيار الكلمات',
    benefits: 'لدينا فوائد في انتظارك!',
    dayFree: '3 أيام تجربة مجانية',
    done: 'منتهي',
    next: 'التالي',
    pin: 'دبوس نام للوصول أسهل ～～',
    planIsNotExist: 'الخطة غير متوفرة',
    startWiki: 'لنبدأ مع ويكيميديا!',
    startYoutube: 'لنبدأ مع YouTube!',
    try: 'جربها',
    tryNow: 'جربها الآن!',
  },
  home: {
    PDFTranslate: {
      desc: 'ترجمة ثنائية اللغة + دردشة PDF',
      sub1: {
        desc: 'تتيح لك القراءة ثنائية اللغة مقارنة النص الأصلي والترجمة جنبًا إلى جنب ، مما يؤدي إلى تحسين كفاءة القراءة.',
        title: 'القراءة ثنائية اللغة',
      },
      sub2: {
        desc: 'يحلل AI وتلخيص ، ويمكنه الإجابة على أسئلتك بناءً على محتوى PDF.',
        title: 'دردشة PDF',
      },
      sub3: {
        desc: 'ترجمة PDF التي تعمل بنيو ذكور الذكاء الاصطناعى لدقة أكبر وكفاءة.',
        title: 'الدقة والكفاءة',
      },
      title: 'PDF ترجمة',
    },
    aiAssistant: {
      desc: 'جعل ترجمة الويب أكثر ذكاءً وأكثر ملاءمة',
      panel1: {
        desc: 'تساعدك ترجمات المرجع المتبادل ثنائي اللغة على مقارنة النص الأصلي بالترجمة حتى تتمكن من فهم أفضل وتحسين كفاءة القراءة الخاصة بك.',
        title: 'ترجمة ثنائية اللغة',
      },
      panel2: {
        left: {
          desc: 'عند تصفح صفحة ويب ، تحوم فوق المحتوى الذي تحتاج إلى ترجمته لرؤية الترجمة.',
          title: 'تحوم الترجمة',
        },
        right: {
          desc: 'عند تصفح صفحة الويب ، حدد النص الذي تريد ترجمته لرؤية الترجمة.',
          title: 'ترجمة اختيار النص',
        },
      },
      panel3: {
        one: {
          desc: 'ترجمة وتلخيص صفحات الويب ومحتوى الفيديو في أي وقت وفي أي مكان لتجربة أكثر سلاسة!',
          title: 'نقطة وانقر',
        },
        three: {
          desc: 'يضمن نموذج اللغة الكبير الذي يحركه الذكاء الاصطناعي دقة الترجمة والاتساق لتجربة قراءة معززة.',
          title: 'AI-يحركها',
        },
        two: {
          desc: 'تغطية كاملة متعددة اللغات ، قراءة خالية من الحاجز ثنائي اللغة ، ترجمة بنقرة واحدة-تجربة الفرق!',
          title: 'أكثر شمولا ومريحة',
        },
      },
      panel4: {
        tab1: {
          desc: 'خبير الكفاءة الخاص بك - باستخدام ChatGPT ، يترجم Noam ويلخص محتوى فيديو YouTube لتوفير الوقت!',
          key: 'فيديو',
          title: 'ترجمة الفيديو وملخص',
        },
        tab2: { desc: 'انقر لترجمة الصور أثناء تصفح الويب.', key: 'صورة', title: 'ترجمة الصورة' },
        tab3: {
          desc: 'يستخرج على الفور المعلومات الرئيسية من صفحات الويب ويوفر ملخصًا ، مما يعزز كفاءة القراءة الخاصة بك.',
          key: 'الويب',
          title: 'ويب ترجمة وملخص',
        },
        tag1: 'بسيط',
        tag2: 'فعال',
        tag3: 'من السهل الفهم',
      },
      title: 'مساعد منظمة العفو الدولية',
    },
    getStart: 'ابدأ',
    getStartedForFree: 'ابدأ مجانًا',
    howItWork: {
      desc: 'NOAM يدمج نماذج الذكاء الاصطناعي لتزويد المستخدمين بتجربة ذكية لترجمة صفحة الويب عبر اللغة والتلخيص ، PDF وترجمة الصور ، ترجمة الفيديو والتلخيص',
      title: 'كيف تعمل',
    },
    lastBlock: { desc: 'ترجمة أفضل من الذكاء الاصطناعي لتجربة قراءة أكثر سلاسة' },
    top: {
      desc: 'NOAM هو امتداد مجاني للمستعرض AI يساعدك على ترجمة وتلخيص صفحات الويب ومقاطع الفيديو ومحتوى PDF. \\ n انضم إلى NOAM وتجربة السحر!',
      title1: 'سحر واحد',
      title2: 'نقرة واحدة',
    },
    userReviews: {
      desc: 'مراجعات من فئة الخمس نجوم على متجر Chrome على الويب',
      title: 'مراجعات المستخدم',
    },
  },
  installTips: {
    btn: 'جربه الآن',
    text: 'قم بتثبيت "Noam Browser Plugin" لإلغاء تأمين المزيد من الميزات! مرجع الويب المتبادل على شبكة الإنترنت ، \\ n تلخيص فيديو الويب ، وأكثر من ذلك!',
  },
  locale: {
    af: 'أفريكانيين',
    am: 'أمهرية',
    ar: 'عربي',
    as: 'الأسامية',
    auto: 'اكتشاف السيارات',
    az: 'أذربيجاني',
    ba: 'باشكر',
    bg: 'البلغارية',
    bn: 'بنغلا',
    bo: 'التبت',
    bs: 'البوسني',
    ca: 'كاتالونية',
    cs: 'التشيكية',
    cy: 'الويلزية',
    da: 'دانماركي',
    de: 'الألمانية',
    dsb: 'السوربيان السفلي',
    dv: 'Divehi',
    el: 'اليونانية',
    en: 'إنجليزي',
    es: 'الأسبانية',
    et: 'الإستونية',
    eu: 'الباسك',
    fa: 'فارسي',
    fi: 'الفنلندية',
    fil: 'فلبينية',
    fj: 'فيجي',
    fr: 'فرنسي',
    frCA: 'الفرنسية (كندا)',
    ga: 'الأيرلندية',
    gl: 'جاليكيان',
    gom: 'كونكاني',
    gu: 'غوجاراتية',
    ha: 'الهوسا',
    he: 'العبرية',
    hi: 'الهندية',
    hr: 'الكرواتية',
    hsb: 'العلوي السوربي',
    ht: 'الكريول الهايتي',
    hu: 'المجري',
    hy: 'أرمني',
    id: 'إندونيسي',
    ig: 'الإغبو',
    ikt: 'inuinnaqtun',
    is: 'الأيسلندية',
    it: 'إيطالي',
    iu: 'inuktitut',
    iuLatn: 'inuktitut (اللاتينية)',
    ja: 'اليابانية',
    ka: 'الجورجي',
    kk: 'كازاخاخية',
    km: 'الخمير',
    kmr: 'الكردي (الشمالي)',
    kn: 'الكانادا',
    ko: 'كوري',
    ku: 'الكورديش (وسط)',
    ky: 'قيرغيز',
    ln: 'لينغالا',
    lo: 'لاو',
    lt: 'الليتواني',
    lug: 'غاندا',
    lv: 'لاتفيان',
    lzh: 'الصينية (الأدبية)',
    mai: 'Maithili',
    mg: 'الملغاشية',
    mi: 'الماوري',
    mk: 'مقدوني',
    ml: 'الملايالام',
    mnCyrl: 'المنغولي (السيريليك)',
    mnMong: 'المنغولي (التقليدي)',
    mr: 'المهاراتية',
    ms: 'الملايو',
    mt: 'المالطية',
    mww: 'همونغ داو',
    my: 'ميانمار (بورمي)',
    nb: 'النرويجية',
    ne: 'النيبالي',
    nl: 'هولندي',
    noSearchLang: 'اللغة غير مدعومة',
    nso: 'Sesotho سا ليبوا',
    nya: 'نيانجا',
    or: 'أوديا',
    otq: 'Querétaro Otomi',
    pa: 'البنجابية',
    pl: 'بولندي',
    placeholder: 'يبحث',
    prs: 'داري',
    ps: 'الباشتو',
    pt: 'البرتغالية (البرازيل)',
    ptPT: 'البرتغالية (البرتغال)',
    ro: 'روماني',
    ru: 'الروسية',
    run: 'روندي',
    rw: 'كينيارواندا',
    sd: 'السندي',
    si: 'سنهالية',
    sk: 'سلوفاك',
    sl: 'سلوفيني',
    sm: 'ساموا',
    sn: 'شونا',
    so: 'صومالي',
    sq: 'الألبانية',
    srCyrl: 'الصربي (السيريليك)',
    srLatn: 'الصربي (اللاتينية)',
    st: 'Sesotho',
    sv: 'السويدية',
    sw: 'السواحيلية',
    ta: 'التاميل',
    te: 'التيلجو',
    th: 'التايلاندية',
    ti: 'تيغنة',
    tk: 'تركمان',
    tlhLatn: 'Klingon (اللاتينية)',
    tlhPiqd: 'Klingon (PIQAD)',
    tn: 'setswana',
    to: 'تونغا',
    tr: 'التركية',
    tt: 'التتار',
    ty: 'تاهيتي',
    ug: 'أويغور',
    uk: 'الأوكرانية',
    ur: 'الأردية',
    uz: 'أوزبك (اللاتينية)',
    vi: 'الفيتنامي',
    xh: 'Xhosa',
    yo: 'يوروبا',
    yua: 'يوكاتيك مايا',
    yue: 'الكانتونية (التقليدية)',
    zh: 'الصينية',
    zhHans: 'تبسيط الصينية',
    zhHant: 'الصينية التقليدية',
    zu: 'زولو',
  },
  login: {
    and: 'و',
    btn: 'تسجيل الدخول مع Google',
    btn1: 'تسجيل الدخول الآن',
    desc: 'تسجيل الدخول لإلغاء تأمين المزيد من التجارب',
    policy: 'سياسة الخصوصية',
    terms: 'شروط الاستخدام',
    tips: 'من خلال الاشتراك ، أوافق على',
    title: 'مرحبا بكم في نعوم',
  },
  pageFooter: {
    follow: { facebook: 'فيسبوك', title: 'يتبع', twitter: 'تغريد' },
    legal: { policy: 'سياسة الخصوصية', terms: 'شروط الخدمة', title: 'قانوني' },
    product: {
      image: 'ترجمة الصورة',
      pdf: 'ترجمة PDF',
      title: 'منتجات',
      video: 'ترجمة الفيديو وملخص',
      web: 'ترجمة ويب وملخص',
    },
    resources: { title: 'موارد' },
  },
  pageHeader: {
    contact: 'اتصال',
    help: 'يساعد',
    logout: 'تسجيل الخروج',
    logoutSucc: 'تم تسجيلها.',
    pricing: 'التسعير',
    settings: 'إعدادات',
    signUp: 'تسجيل الدخول',
  },
  pdf: {
    autoDetect: 'اكتشاف تلقائي',
    cancelBtn: 'يلغي',
    cancelUpload: 'يلغي',
    chatPdf: {
      summaryError: 'فشل الملخص ، يرجى المحاولة مرة أخرى لاحقًا',
      chatError: 'فشل الدردشة ، يرجى المحاولة مرة أخرى لاحقًا',
      Search: 'يبحث',
      cancel: 'يلغي',
      chatTitle: 'مرحبا بكم في نعوم! إليك ملخص لـ PDF الخاص بك:',
      clear: 'واضح',
      clearContent: 'سيبدأ هذا محادثة جديدة. لن يتم حفظ المحادثة الحالية. هل أنت متأكد؟',
      clearDialogTitle: 'الرجاء تأكيد',
      clearSucc: 'تطهير',
      copy: 'ينسخ',
      copySucc: 'نسخ',
      delete: 'يمسح',
      deleteCancel: 'يلغي',
      deleteContent: 'لا يمكن استرداد هذا PDF بمجرد حذفه. هل أنت متأكد؟',
      deleteOk: 'يمسح',
      deleteSuccess: 'حذف',
      deleteTitle: 'تأكيد الحذف',
      expand: 'ينهار',
      historyTitle: 'تاريخ',
      listError: 'فشل تحميل قائمة القراءة. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
      loading: 'تحميل...',
      noDataDesc: 'لا يوجد تاريخ حتى الآن.',
      noMore: 'لا مزيد من المحتوى.',
      placeholder: 'اسأل أي شيء عن هذا PDF',
      summaryTitle: 'قد تكون مهتمًا بهذه الأسئلة:',
    },
    collectionEmpty: 'مجموعتك فارغة.',
    collectionTab: 'ملحوظات',
    dataLoading: 'تحميل...',
    dialogContent: 'هل أنت متأكد أنك تريد حذف هذا؟',
    dialogTitle: 'الرجاء تأكيد',
    download: {
      both: 'ثنائي اللغة',
      btn: 'يحفظ',
      cancel: 'سيؤدي إغلاق هذه النافذة إلى إلغاء تنزيل PDF. هل أنت متأكد أنك تريد الإلغاء؟',
      desc: 'حفظ إلى الجهاز: تنزيل الترجمات كصور ؛ حفظ وطباعة: تنزيل الترجمات بالتنسيق الأصلي ، مع دعم النسخ.',
      download: 'تحميل',
      downloadMsg1: 'تقدم ترجمة PDF:',
      downloadMsg2: 'الوقت المقدر المتبقي:',
      downloadSucc: 'PDF جاهز! انقر للحفظ.',
      downloadTip: 'تنزيل PDF. لا تغلق هذه النافذة لتجنب تنزيل الأخطاء.',
      error: 'فشل في الادخار. حاول الطباعة بدلا من ذلك.',
      fileName: 'اسم الملف:',
      fileType: 'نوع الملف:',
      loading: 'توفير...',
      loading2: 'تحميل...',
      minute: 'دقائق...',
      msg1: 'بعض الترجمات في هذا المستند أطول من النص الأصلي ، وقد تتداخل في الملف الذي تم تنزيله. نوصي القراءة على صفحة الويب.',
      msg2: '1. الترجمة لم تكتمل بعد. يرجى التنزيل بعد الانتهاء من الترجمة.',
      msg3: '2. بعض الترجمات طويلة جدا. اقرأ على صفحة الويب لتجنب تداخل النص.',
      msg4: 'مهام الترجمة قيد التقدم. يرجى المحاولة مرة أخرى بعد اكتمالها.',
      progress: 'ترجمة PDF. التقدم: {{Progress}} ، الوقت المقدر المتبقي: {{minutes}} ...',
      save: 'حفظ إلى الجهاز',
      savePrint: 'حفظ وطباعة',
      success: 'تم تنزيله',
      trans: 'الترجمة فقط',
      transContinue: 'استمر في الترجمة',
    },
    downloadBtn: 'تحميل',
    dragTipModal: {
      black: 'فضاء',
      leftKey: 'زر اليسار',
      ok: 'فهمتها',
      press: 'يضعط',
      tips: 'لسحب وعرض.',
    },
    entry: 'PDFs',
    fileErrMsg: 'فشل قراءة الملف. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    fileMsg: 'حجم الملف الحالي:',
    fileMsg2: 'يرجى تحميل ملف أصغر من 300 ميجابايت.',
    freeBannerBtn: 'يرقي',
    freeBannerTips: 'تم الوصول إلى حد ترجمات PDF المجانية',
    guide: {
      placeholder: 'الصق رابط PDF هنا',
      start: 'ابدأ بالترجمة',
      text1: 'القراءة ثنائية اللغة',
      text2:
        'اقرأ ثنائي اللغة لمقارنة النص الأصلي والترجمة جنبًا إلى جنب ، مما يؤدي إلى تحسين كفاءتك.',
      text3: 'الدردشة مع PDF الخاص بك',
      text4:
        'تحليل الذكاء الاصطناعي والتلخيص. اطرح أسئلة حول PDF الخاص بك ، واحصل على إجابات فورية.',
      text5: 'دقيقة وفعالة',
      text6: 'ترجمة PDF التي تعمل بنيو ذكور الذكاء الاصطناعى لدقة أكبر وكفاءة.',
      title1: 'ترجمة ثنائية اللغة',
      title2: 'الدردشة مع PDF الخاص بك',
      toast: 'الرجاء إدخال رابط PDF صالح عبر الإنترنت ينتهي في .pdf',
    },
    hasTransTask: 'مهمة الترجمة قيد التقدم. انتظر من فضلك.',
    hoverTrans: 'فقرات تحوم إلى ترجمة',
    initial: 'ترجمة PDF',
    installText: 'الحل الخاص بك واحد للترجمات اليومية.',
    installText2: 'الرجاء تثبيت الامتداد أولاً',
    installTips: 'قم بتثبيت ملحق لمزيد من ترجمات PDF ، وترجمات موقع الويب أكثر سلاسة:',
    kw: 'العنصر الأساسي',
    link: 'وصلة',
    listError: 'فشل في تحميل PDFs. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    memoTotalCount: 'إجمالي عنصر واحد',
    new: 'ملف جديد',
    noId: 'معرف الملف غير موجود.',
    notPdf: 'ملفات PDF فقط.',
    okBtn: 'يمسح',
    okBtn2: 'فهمتها',
    original: 'إبداعي',
    parallel: 'موازي',
    parseErrDialogContent: 'لا يمكن ترجمة رابط PDF هذا مباشرة. يرجى تنزيل PDF وتحميله للترجمة.',
    parseErrTips: 'فشل في تحليل الملف',
    parseFileToast: 'فشل في تحليل الملف. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    pdfLoading: 'تحليل ...',
    searchPlaceholder: 'يبحث',
    selectTrans: 'حدد نصًا للترجمة',
    shareBtn: 'يشارك',
    shareBtnText1: 'توليد رابط مشاركة',
    shareBtnText2: 'تحديث المحتوى المشترك',
    shareModal: {
      cancel: 'يلغي',
      copy: 'ينسخ',
      copySucc: 'رابط نسخ',
      custom: 'تخصيص:',
      errMsg: 'لم يتم ترجمة المحتوى المحدد بالكامل بعد. يرجى الترجمة قبل المشاركة.',
      gen: 'يشارك',
      modify: 'يحرر',
      notSupport: 'هذه اللغة غير مدعومة حاليًا.',
      page: 'الصفحات',
      pageNuErr: 'الرجاء إدخال رقم صفحة صالح.',
      range: 'نِطَاق:',
      remain: 'متبقي',
      remain1: 'إجمالي الوقت المقدر:',
      search: 'يبحث',
      settings: 'مشاركة الإعدادات:',
      shareSetting: 'مشاركة الإعدادات',
      shareText: 'يشارك',
      shareTips: 'شارك رابط PDF المترجم مع أصدقائك:',
      sourceLang: 'من:',
      target: 'ل:',
      text1: 'مشاركة الإعدادات',
      text2: 'توليد رابط مشاركة',
      totalPage: '1 صفحة المجموع',
      trans: 'يترجم',
      translateTips:
        'نصيحة: إذا كانت الترجمة تستغرق بعض الوقت ، فيمكنك إغلاق هذه النافذة والمشاركة لاحقًا.',
      translating: 'ترجمة PDF ...',
      update: 'ارتباط تحديث مشاركة',
    },
    shareMsg1: 'شارك الرابط الذي تم إنشاؤه.',
    shareMsg2: 'محتوى مشترك محدث.',
    shareMsg3: 'فشل توليد رابط مشاركة. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    shareMsg4: 'فشل في تحديث المحتوى المشترك. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    shareTips: 'ترجمة PDF جاهزة! شاركه مع أصدقائك.',
    start: 'ابدأ تجريبك المجاني',
    thumbnail: 'جدول المحتويات',
    toast1: 'تحميل pdf. يرجى المحاولة مرة أخرى لاحقًا.',
    toast2: 'محتوى PDF كبير جدًا. مربع الحوار حاليا غير مدعوم.',
    toast3: 'التنزيل. يرجى المحاولة مرة أخرى بعد الانتهاء من التنزيل.',
    toolbar: { Adjust: 'يُعدِّل', autoFit: 'ملاءمة تلقائية' },
    trans: 'ترجمة',
    transSucc: 'ترجمة ناجحة!',
    unLoginToast: 'يرجى تسجيل الدخول لاستخدام هذه الميزة.',
    uploadBtn: 'رفع',
    uploadErr: 'فشل تحميل الملف. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    uploadErrSignUrl:
      'فشل في الحصول على تحميل URL. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    uploadMsg1: 'سحب وإسقاط ملفك هنا.',
    uploadMsg2: 'أنواع الملفات المدعومة: PDF |  الحد الأقصى لحجم الملف: 100 ميجابايت',
    uploadMsg3: 'انقر لتحديد أو سحب وإسقاط الملفات هنا.',
    uploading: 'التحميل',
    uploadingMsg: 'التحميل. انتظر من فضلك.',
    uploadingMsg1: 'يتم تحميل الملف. يرجى المحاولة مرة أخرى لاحقًا.',
    uploadingMsg2: 'يتم تحويل ملف حاليًا. يرجى المحاولة مرة أخرى لاحقًا.',
    uploadingMsg3: 'فشل طلب التقدم في التحويل. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    uploadingMsg4: 'لا يمكن للنوي الوصول إلى الملفات المحلية. يرجى تحميل PDF يدويًا للترجمة.',
    uploadingMsg5:
      'فشل في استرداد عنوان URL للملف. يرجى المحاولة مرة أخرى لاحقًا ، أو الاتصال بالدعم.',
    video: 'فيديو',
    vipBannerBtn: 'يرقي',
    vipBannerTips: 'لقد وصلت إلى حد ترجمة PDF لهذا الشهر.',
    webTrans: 'المرجع المتقاطع الكامل',
  },
  pricing: {
    FAQ: 'التعليمات',
    FQAObj: {
      a1: 'نعم ، يقدم Noam خطة مجانية يمكنك استخدامها على الفور.',
      a2: 'نحن نقدم ثلاث خطط عضوية: Pro ، Pro+، and Enterprise. يمكنك اختيار الدفع شهريًا أو سنويًا. الفواتير السنوية تقدم مدخرات كبيرة.',
      a3: 'جميع الميزات متوفرة على كلا الخطين. الفرق الرئيسي هو التكلفة: الاشتراكات السنوية تكلفة أقل بشكل عام. يتم فواتير الاشتراكات الشهرية كل شهر ، في حين يتم فواتير الخطط السنوية مرة واحدة في السنة.',
      a4: 'يمكنك إلغاء اشتراكك في أي وقت في إعدادات حسابك.',
      q1: 'هل هناك خطة مجانية؟',
      q2: 'ما هي خطط التسعير وخيارات الدفع المتاحة؟',
      q3: 'ما الفرق بين الاشتراك الشهري مقابل سنويًا؟',
      q4: 'كيف يمكنني إلغاء اشتراكي؟',
    },
    around: 'حول',
    billedMonthly: 'فواتير شهريًا',
    billedYearly: 'فاتورة سنويا',
    day: 'يوم',
    desc: 'قارن الخطط واختر الأفضل',
    detailedCompare: 'مقارنة الخطط',
    discount: '20 ٪ خصم',
    enterprise: {
      benefit1: 'دعم العملاء الأولوية',
      benefit2: 'متعددين من المستخدمين',
      benefit3: 'حلول مصممة',
      benefit5: 'مدير حساب مخصص',
      btn: 'اتصل بنا',
      recommendText: 'الخطط المخصصة （≥3 人）',
      talkToUs: 'تواصل على اتصال',
    },
    free: {
      benefit1: 'ترجمة فورية 100 مرة/يوم',
      benefit2: 'ترجمة موقع الويب والملخص',
      benefit3: '30 ترجمات الصورة',
      benefit4: 'ترجمة الفيديو وملخص',
      benefit5: '10 ترجمات PDF',
      benefit6: '5000 رمز',
      btn: 'جربه مجانًا',
      recommendText: 'تجربة مجانية',
    },
    imageTranslation: 'ترجمة الصورة',
    month: 'شهر',
    monthly: 'شهريا',
    pageTitle: 'التسعير',
    pdf: {
      chat: 'استعلامات الدردشة PDF',
      maxPage: 'الأعلى. صفحات لكل pdf',
      translation: 'ترجمة PDF',
    },
    preMonth: 'كل شهر',
    pro: {
      benefit1: 'دعم العملاء الأولوية',
      benefit2: '1،000،000 رمز/شهر',
      benefit3: 'ترجمة موقع الويب والملخص',
      benefit4: '200 ترجمة صورة',
      benefit5: 'ترجمة الفيديو وملخص',
      benefit6: '200 ترجمات PDF',
      btn: 'ابدأ',
      recommendText: 'الأكثر شعبية',
    },
    proPlus: {
      benefit1: 'دعم العملاء الأولوية',
      benefit2: '3،000،000 رمز/شهر',
      benefit3: 'ترجمة موقع الويب والملخص',
      benefit4: '500 ترجمات الصور',
      benefit5: 'ترجمة الفيديو وملخص',
      benefit6: '500 ترجمات PDF',
      recommendText: 'أفضل قيمة',
    },
    video: { summary: 'ملخص الفيديو', translation: 'ترجمة الفيديو' },
    web: {
      contrastiveTranslation: 'ترجمة ثنائية اللغة',
      dictionaryTranslation: 'ترجمة الاختيار',
      hoverTranslation: 'تحوم الترجمة',
      instantTranslation: 'ترجمة فورية',
      summary: 'ملخص صفحة الويب',
    },
    yearly: 'سنوي',
  },
  privacy: {
    block1:
      'يحترم Noam ("noam" ، "نحن" ، "" أو "أو" نحن ") خصوصيتك وتلتزم بحماية أي معلومات نحصل عليها منك أو عنك. تصف سياسة الخصوصية هذه ممارساتنا المتعلقة بجمع أو استخدام المعلومات الشخصية من استخدامك لموقعنا وتطبيقاتنا وخدماتنا (بشكل جماعي ، "الخدمات"). لا تنطبق سياسة الخصوصية هذه على المحتوى الذي نتعامل معه نيابة عن عملاء منتجات الأعمال لدينا ، مثل واجهة برمجة التطبيقات الخاصة بنا. يخضع استخدام هذه البيانات لاتفاق العميل لدينا ، والذي يغطي الوصول إلى هذه المنتجات واستخدامها.',
    block10:
      'نحن نطبق التدابير التقنية والإدارية والتنظيمية المعقولة تجاريا لحماية المعلومات الشخصية عبر الإنترنت وغير المتصلة بالإنترنت من الخسارة وإساءة الاستخدام والوصول غير المصرح به أو الإفصاح أو التغيير أو الأفعال المعادية. ومع ذلك ، لا يمكن لأي نقل عبر الإنترنت أو بريد إلكتروني أن يضمن الأمن أو الدقة الكاملة. على وجه الخصوص ، قد لا تكون رسائل البريد الإلكتروني المرسلة إلينا أو منا آمنة. لذلك ، يجب أن تكون حذراً بشكل خاص عند تحديد المعلومات التي يجب إرسالها إلينا من خلال الخدمة أو البريد الإلكتروني. بالإضافة إلى ذلك ، نحن لسنا مسؤولين عن أي عرقلة لإعدادات الخصوصية أو تدابير الأمن في الخدمة أو إعدادات الأمان الخاصة بمواقع الويب الثالثة أو تدابير الأمان. سنحتفظ بمعلوماتك الشخصية طالما ضرورية لنا لتزويدك بالخدمات أو لأغراض عمل شرعية أخرى ، مثل حل النزاعات أو أسباب الأمن والسلامة أو الامتثال لالتزاماتنا القانونية. يعتمد طول الوقت الذي نحتفظ به على المعلومات الشخصية على العديد من العوامل ، مثل المبلغ والطبيعة وحساسية المعلومات والمخاطر المحتملة للاستخدام أو الكشف غير المصرح به ، والأغراض التي نقوم بمعالجتها من أجل المعلومات ، واعتبارات أخرى ملزمة قانونًا.',
    block11:
      'قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. عندما نفعل ذلك ، سننشر إصدارًا محدثًا في هذه الصفحة ، ما لم يطلب الأمر خلاف ذلك بموجب القانون المعمول به.',
    block12:
      'إذا كان لديك أي أسئلة أو مخاوف لم تتم الإجابة عليها بشأن سياسة الخصوصية هذه ، فيرجى الاتصال بخدمة العملاء الخاصة بنا. بريد إلكتروني:',
    block2: 'نجمع المعلومات الشخصية التالية ("المعلومات الشخصية") المتعلقة بك:',
    block3:
      'المعلومات التي تقدمها: عند إنشاء حساب لاستخدام خدماتنا أو التواصل معنا ، نجمع المعلومات الشخصية على النحو التالي:',
    block4:
      'المعلومات الشخصية التي تم استلامها تلقائيًا من استخدامك للخدمات: عند الوصول إلى الخدمات أو الاستخدام أو التفاعل مع الخدمات ، نتلقى المعلومات التالية حول وصولك أو استخدامك أو تفاعلك ("المعلومات الفنية"):',
    block5: 'قد نستخدم المعلومات الشخصية للأغراض التالية:',
    block6:
      'ملخص أو معلومات غير محددة: يجوز لنا تجميع أو إلغاء تحديد المعلومات الشخصية لمنع إعادة استخدامها لأغراض تحديد الهوية واستخدام هذه المعلومات لتحليل فعالية خدماتنا ، وتحسين وإضافة ميزات إلى خدماتنا ، وإجراء البحوث ، ولأغراض مماثلة. بالإضافة إلى ذلك ، قد نقوم بتحليل السلوك العام وخصائص مستخدمي الخدمات لدينا وتبادل المعلومات المجمعة مع أطراف ثالثة ، ونشر هذه المعلومات المجمعة ، أو جعلها متاحة عمومًا. يجوز لنا جمع المعلومات المجمعة من خلال الخدمات ، من خلال ملفات تعريف الارتباط ، ومن خلال وسائل أخرى موضحة في سياسة الخصوصية هذه. سنحافظ على المعلومات غير المحددة ونستخدمها في نموذج مجهول أو غير محدد ، ولن نحاول إعادة تحديد هذه المعلومات ما لم يكن مطلوبًا بموجب القانون.',
    block7:
      'في بعض الحالات ، قد نقدم معلوماتك الشخصية إلى أطراف ثالثة دون إشعار آخر لك ، ما لم يطلب القانون:',
    block8:
      'اعتمادًا على موقعك الجغرافي ، قد يكون للأفراد في المنطقة الاقتصادية الأوروبية والمملكة المتحدة والعالم حقوقًا قانونية معينة فيما يتعلق بمعلوماتهم الشخصية. على سبيل المثال ، قد يكون لديك الحق في:',
    block9:
      'قد تحتوي الخدمة على روابط لمواقع الويب الأخرى التي لا يتم تشغيلها أو التحكم فيها بواسطة NOAM ، بما في ذلك خدمات التواصل الاجتماعي ("مواقع الويب الثالثة"). تخضع المعلومات التي تشاركها مع مواقع الويب الثالثة لسياسات الخصوصية المحددة وشروط خدمة المواقع الإلكترونية من الجهات الخارجية ، وليس من خلال سياسة الخصوصية هذه. نحن نقدم هذه الروابط للراحة ولا تعني تأييدنا أو مراجعة هذه المواقع. يرجى الاتصال بمواقع الويب الثالثة مباشرة للحصول على معلومات حول سياسات وشروط الخصوصية الخاصة بهم.',
    subBlock1:
      'معلومات الحساب: عند إنشاء حساب على النظام الأساسي الخاص بنا ، نقوم بجمع المعلومات المتعلقة بحسابك ، بما في ذلك اسمك ومعلومات الاتصال وبيانات اعتماد الحساب ومعلومات بطاقة الدفع وسجل المعاملة ("معلومات الحساب").',
    subBlock10: 'توفير الخدمات وإدارتها وصيانتها و/أو تحليلها ؛',
    subBlock11: 'التواصل معك',
    subBlock12: 'تطوير منتجات وخدمات جديدة ؛',
    subBlock13:
      'منع الاحتيال أو النشاط الإجرامي أو إساءة استخدام خدماتنا ، وحماية أمن أنظمة تكنولوجيا المعلومات والهندسة المعمارية والشبكات الخاصة بنا ؛',
    subBlock14: 'إجراء عمليات نقل الأعمال ؛',
    subBlock15:
      'الامتثال للالتزامات القانونية والإجراءات القانونية ، وحماية حقوقنا أو الخصوصية أو الأمن أو الممتلكات ، وكذلك تلك الخاصة بشركات التابعة لنا أو أنت أو أطراف ثالثة أخرى.',
    subBlock16:
      'الموردين ومقدمي الخدمات: لمساعدتنا في تلبية الاحتياجات التشغيلية التجارية وأداء بعض الخدمات والوظائف ، قد نقدم معلومات شخصية للموردين ومقدمي الخدمات ، بما في ذلك استضافة مقدمي الخدمات ، ومقدمي الخدمات السحابية ، ومقدمي خدمات تكنولوجيا المعلومات الآخرين ، وبرامج الاتصال عبر البريد الإلكتروني ، ومقدمي خدمات تحليل الويب ، وما إلى ذلك. سيقوم هؤلاء الشريكون بالوصول أو معالجة أو تخزين المعلومات الشخصية فقط في اتجاهنا.',
    subBlock17:
      'نقل الأعمال: إذا شاركنا في المعاملات الاستراتيجية أو إعادة التنظيم أو الإفلاس أو عمليات الاستحواذ على الإفلاس أو الانتقال إلى الخدمات إلى مزود آخر (جماعي ، "المعاملات") ، يمكن مشاركة معلوماتك الشخصية وغيرها من المعلومات مع نظيرات المعاملات الأخرى وتساعد الأطراف الأخرى في المعاملات أثناء التجديف الواجب أو نقلها كجزء من التحويلات على طول محملات أخرى إلى النجاح.',
    subBlock18:
      'المتطلبات القانونية: قد نشارك معلوماتك الشخصية مع السلطات الحكومية أو أقرانها في الصناعة أو أطراف ثالثة أخرى ، بما في ذلك معلومات التفاعل الخاصة بك مع خدماتنا ، (1) إذا لزم الأمر بموجب القانون أو إذا كنا نعتقد أن هذا الإجراء ضروري للامتثال للالتزامات القانونية ؛ (2) لحماية حقوقنا أو ممتلكاتنا والدفاع عنها ؛ (3) إذا قررنا من جانب واحد أن هناك انتهاكًا لشروطنا أو سياساتنا أو قوانيننا ؛ (4) اكتشاف أو منع الاحتيال أو غيرها من الأنشطة غير القانونية ؛ (5) حماية منتجاتنا أو موظفينا أو المستخدمين أو السلامة العامة أو الأمن أو النزاهة ؛ أو (السادس) للدفاع ضد المسؤولية القانونية.',
    subBlock19: 'الوصول إلى معلوماتك الشخصية ومعلوماتك حول كيفية معالجتها.',
    subBlock2:
      'محتوى المستخدم: عند استخدام خدماتنا ، نقوم بجمع المعلومات الشخصية من المدخلات أو تحميل الملفات أو التعليقات المقدمة لنا ("المحتوى").',
    subBlock20: 'طلب حذف معلوماتك الشخصية من سجلاتنا.',
    subBlock21: 'تصحيح أو تحديث معلوماتك الشخصية.',
    subBlock22: 'نقل معلوماتك الشخصية إلى طرف ثالث (قابلية نقل البيانات).',
    subBlock23: 'الحد من كيفية معالجة معلوماتك الشخصية.',
    subBlock24:
      'سحب موافقتك - حيث نعتمد على الموافقة كأساس قانوني للمعالجة ، يمكنك سحب موافقتك في أي وقت.',
    subBlock25: 'الاعتراض على كيفية معالجة معلوماتك الشخصية.',
    subBlock26: 'تحسين خدماتنا وإجراء البحوث ؛',
    subBlock3:
      'معلومات الاتصال: إذا أرسلت لنا رسائل ، فسنقوم بجمع اسمك ومعلومات الاتصال الخاصة بك ومحتوى رسائلك ("معلومات الاتصال").',
    subBlock4:
      'معلومات وسائل التواصل الاجتماعي: لدينا صفحات على مواقع التواصل الاجتماعي مثل Instagram و Facebook و Medium و Twitter و YouTube و LinkedIn. عندما تتفاعل مع صفحات الوسائط الاجتماعية الخاصة بنا ، فإننا نجمع المعلومات الشخصية التي تختارها لتقديمها إلينا ، مثل تفاصيل الاتصال الخاصة بك ("المعلومات الاجتماعية"). بالإضافة إلى ذلك ، قد تزودنا الشركات التي تستضيف صفحات وسائل التواصل الاجتماعي لدينا بالمعلومات والتحليلات المجمعة حول أنشطة وسائل التواصل الاجتماعي لدينا.',
    subBlock5:
      'بيانات السجل: المعلومات التي يتم إرسالها تلقائيًا بواسطة متصفحك عند استخدام خدماتنا. تتضمن بيانات السجل عنوان بروتوكول الإنترنت (IP) ونوع المتصفح والإعدادات ، وتاريخ ووقت طلباتك ، وكيفية تفاعلك مع موقعنا.',
    subBlock6:
      'بيانات الاستخدام: يجوز لنا تلقائيًا جمع معلومات حول استخدامك للخدمة ، مثل أنواع المحتوى الذي تشاهده أو تتفاعل معه ، والميزات التي تستخدمها ، والإجراءات التي تتخذها ، بالإضافة إلى منطقتك الزمنية ، وبلدك ، وتاريخ ووقت الوصول ، ووكيل المستخدم ، ونوع الكمبيوتر أو الجهاز المحمول ، واتصال الكمبيوتر الخاص بك.',
    subBlock7:
      'معلومات الجهاز: يتضمن ذلك اسم الجهاز ونظام التشغيل ومعرف الجهاز والمتصفح الذي تستخدمه. قد تعتمد المعلومات التي تم جمعها على نوع الجهاز الذي تستخدمه وإعداداته.',
    subBlock8:
      'ملفات تعريف الارتباط: نستخدم ملفات تعريف الارتباط لتشغيل وإدارة خدماتنا وتحسين تجربتك. "ملف تعريف الارتباط" هو المعلومات التي يتم إرسالها بواسطة موقع ويب تزوره إلى متصفحك. يمكنك تعيين متصفحك لقبول جميع ملفات تعريف الارتباط ، أو رفض جميع ملفات تعريف الارتباط ، أو إخطارك عند توفير ملف تعريف الارتباط حتى تتمكن من تحديد ما إذا كنت ستقبله في كل مرة. ومع ذلك ، قد يمنعك رفض ملفات تعريف الارتباط من استخدام أو التأثير سلبًا على عرض أو وظائف بعض المناطق أو ميزات الموقع. لمزيد من المعلومات حول ملفات تعريف الارتباط ، يرجى زيارة كل شيء عن ملفات تعريف الارتباط.',
    subBlock9:
      'التحليلات: قد نستخدم العديد من منتجات التحليلات عبر الإنترنت التي تستخدم ملفات تعريف الارتباط لمساعدتنا في تحليل كيفية استخدام المستخدمين خدماتنا وتحسين تجربتك عند استخدام الخدمات.',
    subTitle1: 'المعلومات الشخصية التي نجمعها',
    subTitle2: 'كيف نستخدم المعلومات الشخصية',
    subTitle3: 'الكشف عن المعلومات الشخصية',
    subTitle4: 'حقوقك',
    subTitle5: 'روابط إلى مواقع الويب الأخرى',
    subTitle6: 'أمن المعلومات والاحتفاظ بها',
    subTitle7: 'تعديل سياسة الخصوصية',
    subTitle8: 'كيفية الاتصال بنا',
    title: 'سياسة الخصوصية',
    updateTime: 'تحديث: 15 مارس 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'قبول كل شيء',
    text: 'لتحسين تجربتك ، نستخدم ملفات تعريف الارتباط لتخصيص المحتوى والخدمات.',
    title: 'يستخدم هذا الموقع ملفات تعريف الارتباط',
  },
  slogen: 'أسهل مساعد منظمة العفو الدولية',
  stripe: {
    contact: 'اتصل بنا على contact@noam.tools مع أي أسئلة!',
    fail: {
      btn: 'إعادة المحاولة',
      text1: 'لم نتمكن من معالجة دفعتك.',
      text2: 'تحقق من اتصالك وحاول مرة أخرى.',
      title: 'فشل الدفع',
    },
    succ: { btn: 'دعنا نذهب', text1: 'مرحبا بكم في نعوم ،', text2: 'لنبدأ!', title: 'مبروك' },
  },
  terms: {
    block1: 'شكرا لك على استخدام نام!',
    block10:
      '(أ) الرسوم والفواتير. سوف تدفع جميع الرسوم ("الرسوم") وفقًا للتسعير والشروط على صفحة التسعير المعمول بها أو كما هو متفق عليه في الكتابة بيننا. نحن نحتفظ بالحق في تصحيح أخطاء التسعير أو الأخطاء حتى لو أصدرنا فواتير أو تلقينا الدفع. ستوفر معلومات فواتير كاملة ودقيقة ، بما في ذلك طريقة دفع صالحة ومصرح بها. سنقوم بفرض رسوم الدفع الخاصة بك على أساس دوري كما هو متفق عليه ، لكن قد نغير تاريخ الفوترة بشكل معقول. أنت تسمح لـ Noam ، والشركات التابعة لها ، ومعالجات الدفع الطرف الثالث لفرض رسوم الدفع الخاصة بك. إذا فشلت دفعتك ، فسنقدم لك إشعارًا مكتوبًا وقد نعلق الوصول إلى الخدمة حتى يتم استلام الدفع. باستثناء ما هو محدد خلاف ذلك في هذه الاتفاقية ، فإن المدفوعات غير قابلة للاسترداد.',
    block11:
      '(ب) الضرائب. ما لم ينص على خلاف ذلك ، لا تشمل الرسوم أي ضرائب فدرالية وحكومية ومحلية وأجنبية ، واجبات ، وتقييمات مماثلة ("الضرائب"). أنت مسؤول عن جميع الضرائب المرتبطة بمشترياتك ، باستثناء الضرائب بناءً على صافي دخلنا الذي سنقوم بفاتوته لك. أنت توافق على دفع هذه الضرائب على الفور وتزويدنا ببيانات خصوصية صالحة من الناحية القانونية والحصول على موافقات ضرورية للتعامل مع هذه البيانات ، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به.',
    block12:
      '(ج) تغييرات الأسعار. قد نغير أسعارنا من خلال إخطارك من خلال حسابك و/أو موقع الويب الخاص بنا. ستكون الزيادات في الأسعار سارية فور النشر. سيتم تطبيق أي تغييرات في السعر على رسوم حسابك بعد تاريخ سريان التغيير.',
    block13:
      '(د) النزاعات والمدفوعات المتأخرة. إذا كنت ترغب في الاعتراض على أي رسوم أو ضرائب ، فيرجى الاتصال بـ contact@noam.tools في غضون ثلاثين (30) يومًا من تاريخ الفاتورة.',
    block14:
      '(هـ) الاستخدام المجاني. لا يجوز لك إنشاء حسابات متعددة للحصول على أرصدة مجانية. إذا قررنا أنك لا تستخدم الاعتمادات المجانية بحسن نية ، فقد نفرض رسومًا قياسية لك أو نتوقف عن توفير الوصول إلى الخدمة.',
    block15:
      '(أ) السرية. قد يكون لديك إمكانية الوصول إلى معلومات سرية عن نعوم. لا يجوز لك سوى استخدام المعلومات السرية على النحو المسموح به بموجب هذه الشروط لغرض استخدام الخدمة. لا يجوز لك أن تكشف عن معلومات سرية لأي طرف ثالث ، وستحمي المعلومات السرية بطريقة لا تقل عن حماية من حماية معلوماتك السرية المماثلة ، على الأقل بعناية معقولة. المعلومات السرية هي المعلومات غير العامة المعينة على أنها سرية من قبل NOAM أو التي ينبغي أن تعامل بشكل معقول على أنها سرية في ظل هذه الظروف ، بما في ذلك البرمجيات والمواصفات وغيرها من المعلومات التجارية غير العامة. لا تتضمن المعلومات السرية معلومات: (1) تصبح متاحة بشكل عام للجمهور دون خطأ من جانبك ؛ (2) كان لديك في حوزتك دون أي التزام بالسرية قبل تلقيها بموجب هذه الشروط ؛ (3) يتم الكشف عنها بشكل قانوني لك من قبل طرف ثالث دون أي التزامات سرية ؛ أو (4) تم تطويره بشكل مستقل من قبلك دون استخدام المعلومات السرية. يمكنك الكشف عن معلومات سرية إذا كان ذلك مطلوبًا بموجب القانون أو المحكمة أو أي أمر حكومي آخر ، ولكن يجب عليك تقديم إشعار خطي مسبق إلى Noam بطريقة معقولة ، وبداية بذل جهود معقولة للحد من نطاق الكشف ، بما في ذلك مساعدتنا في معارضة طلبات الكشف.',
    block16:
      '(ب) الأمن. يجب عليك تنفيذ تدابير معقولة ومناسبة للمساعدة في حماية وصولك إلى الخدمة واستخدامها. إذا اكتشفت أي نقاط ضعف أو انتهاكات تتعلق باستخدامك للخدمة ، فيجب عليك الاتصال Noam على الفور وتقديم تفاصيل عن القابلية للضعف أو الانتهاك.',
    block17:
      '((ج) معالجة البيانات الشخصية. إذا كنت تستخدم الخدمة لمعالجة البيانات الشخصية ، فيجب عليك تقديم بيانات خصوصية كافية من الناحية القانونية والحصول على موافقات ضرورية لمعالجة هذه البيانات ، وتضمن لنا أنك تتعامل مع هذه البيانات وفقًا للقانون المعمول به.',
    block18:
      '(أ) الإنهاء ؛ تعليق. ستكون هذه المصطلحات فعالة من أول استخدام لك للخدمة وستظل سارية حتى يتم إنهاءها. يمكنك إنهاء هذه الشروط في أي وقت لأي سبب من خلال التوقف عن استخدام الخدمة والمحتوى. يجوز لنا إنهاء هذه الشروط مع إشعار مسبق لأي سبب. يجوز لنا على الفور إنهاء هذه المصطلحات عن طريق إخطارك إذا اخترقت ماديًا القسم 2 (متطلبات الاستخدام) أو القسم 5 (السرية والأمن وحماية البيانات) أو القسم 8 (حل النزاعات) أو القسم 9 (الشروط العامة) أو إذا كانت علاقتنا مع أي مزود تكنولوجيا الطرف الثالث يتجاوز تغييرات السيطرة لدينا ، أو للامتثال للقانون أو الطلبات الحكومية. قد نقوم بتعليق وصولك إلى الخدمة إذا فشلت في الامتثال لهذه الشروط ، أو إذا كان استخدامك يشكل خطراً على الأمان لنا أو أي طرف ثالث ، أو إذا كنا نشك في أن استخدامك احتيالي أو قد يعرضنا أو أي طرف ثالث في المسؤولية.',
    block19:
      '(ب) آثار الإنهاء. عند الإنهاء ، سوف تتوقف عن استخدام الخدمة وإرجاع أو تدمير أي معلومات سرية على الفور وفقًا لتوجيهاتنا. إن أحكام هذه الشروط التي يجب على طبيعتها البقاء على قيد الحياة في الإنهاء أو انتهاء الصلاحية ، بما في ذلك على سبيل المثال لا الحصر ، القسمين 3 و 5-9 ، البقاء على قيد الحياة.',
    block2:
      'تنطبق شروط الاستخدام هذه على استخدامك لخدمات NOAM ، بما في ذلك واجهات برمجة التطبيقات والبرامج والأدوات وخدمات المطورين والبيانات والوثائق والموقع (يشار إليها مجتمعة باسم "الخدمات"). باستخدام خدماتنا ، فإنك توافق على الالتزام بهذه الشروط. تشرح سياسة الخصوصية الخاصة بنا كيف نجمع واستخدام المعلومات الشخصية.',
    block20:
      '(أ) التعويض. سوف تدافع عننا وتعويضنا وتعقدنا ، والشركات التابعة لنا ، وموظفينا غير ضار من أي مطالبات وخسائر ومصروفات (بما في ذلك أتعاب المحاماة) الناشئة عن استخدامك للخدمة ، بما في ذلك المحتوى الخاص بك ، واستخدامك للمنتجات أو الخدمات المتعلقة بالخدمة ، وانتهاكك لهذه المصطلحات أو القانون القابل للتطبيق.',
    block21:
      '(ب) إخلاء المسئولية. يتم تقديم الخدمة "كما هي". إلى الحد الذي يسمح به القانون ، لا نقدم نحن وشركاتنا التابعة لنا أي تعهدات أو ضمانات من أي نوع فيما يتعلق بالخدمة ولا نلقي أي ضمانات ، بما في ذلك على سبيل المثال لا الحصر على ضمانات القابلية للتسويق ، والملاءمة لغرض معين ، وجودة مرضية ، وعدم التعرض ، والتمتع الهادئ ، وأي ضمانات ناجمة عن التعامل أو الاستخدام. لا نضمن أن الخدمة ستكون دون انقطاع أو دقيقة أو خالية من الأخطاء ، أو أن أي محتوى لن يكون آمنًا أو لا يضيع أو لا يتم تغييره.',
    block22:
      '(ج) الحد من المسؤولية. لن نتحمل نحن ، الشركات التابعة لنا ، ولا المرخصين لدينا مسؤولية أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو عقابية ، بما في ذلك فقدان الأرباح ، وفقدان السمعة ، وفقدان الاستخدام ، أو فقدان البيانات ، أو الخسائر غير الملموسة الأخرى ، حتى لو تم إخطارنا بإمكانية وجود مثل هذه الأضرار. بموجب هذه الشروط ، لن تتجاوز إجمالي مسؤوليتنا الرسوم التي دفعتها للخدمة في الاثني عشر (12) شهرًا التي سبقت المطالبة أو مائة دولار أمريكي (100 دولار) ، أيهما أكبر. تنطبق القيود الواردة في هذا القسم على الحد الأقصى المسموح به بموجب القانون المعمول به.',
    block23:
      'تخضع الشروط واستخدامك للخدمات لقوانين الولايات المتحدة ، باستثناء قواعد تعارض القوانين في الولايات المتحدة. قد يخضع استخدامك للتطبيق أيضًا للقوانين المحلية أو الحكومية أو الوطنية أو الدولية الأخرى. سيتم تقديم أي إجراء قانوني أو إجراء يتعلق بهذه الشروط حصريًا في محاكم الولايات المتحدة ، وأنت توافق على الخضوع إلى اختصاص هذه المحاكم.',
    block24:
      '(أ) علاقة الأطراف. NOAM وأنت مقاولون مستقلون ، وليس لدى أي من الطرفين القدرة على تمثيل الطرف الآخر أو توليه أو تحمل التزامات للطرف الآخر دون موافقة خطية مسبقة للطرف الآخر.',
    block25:
      '(ب) استخدام العلامة التجارية. لا يجوز لك استخدام الاسم أو الشعارات أو العلامات التجارية لـ Noam أو أي من الشركات التابعة لها دون موافقتنا الخطية السابقة.',
    block26:
      'إذا تم تحديد أي بند من هذه الشروط على أنه غير صالح أو غير قانوني أو غير قابل للتنفيذ ، فستظل الأحكام المتبقية سارية المفعول وتأثيرها.',
    block3:
      'إذا كان عمرك أقل من 18 عامًا ، فيجب عليك الحصول على إذن من والديك أو الأوصياء القانونيين لاستخدام الخدمة. إذا كنت تستخدم الخدمة نيابة عن شخص أو كيان آخر ، فيجب أن تكون مخولًا لقبول هذه الشروط نيابة عنهم. يجب عليك تقديم معلومات دقيقة وكاملة للتسجيل لحساب. لا يجوز لك تقديم بيانات اعتماد وصولك إلى أي شخص خارج مؤسستك ، وأنت مسؤول عن جميع الأنشطة التي أجريت باستخدام بيانات الاعتماد الخاصة بك.',
    block4:
      '(أ) استخدام الخدمة. يمكنك الوصول إلى الخدمة بموجب هذه الشروط ، ونمنحك حق غير حصري في استخدام الخدمة. عند استخدام الخدمة ، ستقوم بالامتثال لهذه الشروط وجميع القوانين المعمول بها. نحن والشركات التابعة لنا نمتلك جميع الحقوق والألقاب والمصالح في الخدمة.',
    block5:
      '(ب) ردود الفعل. نرحب بالتعليقات والتعليقات والأفكار والاقتراحات والتحسينات. إذا قدمت أي محتوى من هذا القبيل ، فقد نستخدمه دون أي قيود ودون تعويض لك.',
    block6:
      '(ج) القيود. لا يجوز لك: (1) استخدام الخدمة بطريقة تنتهك أو يخطئ أو ينتهك أي حقوق للآخرين ؛ (2) المهندس العكسي ، أو إزالة التجميع ، أو تفكيك ، أو ترجمة ، أو محاولة اكتشاف رمز المصدر أو المكونات الأساسية لنماذج الخدمة ، والخوارزميات ، والأنظمة (ما لم يتم حظر هذه القيود بموجب القانون المعمول به) ؛ (3) استخدام إخراج الخدمة لتطوير نماذج تتنافس مع نعوم ؛ (4) استخراج البيانات أو الإخراج من الخدمة باستخدام أي طرق آلية أو برمجة ما لم يُسمح بها من خلال واجهة برمجة التطبيقات ، بما في ذلك الكشط أو حصاد الويب أو استخراج بيانات الويب ؛ (5) تمثل إخراج الخدمة كما تم إنشاؤها بشكل مصطنع عندما لا تكون أو تنتهك سياسات الاستخدام الخاصة بنا بطريقة أخرى ؛ (6) شراء أو بيع أو نقل مفاتيح API دون موافقتنا السابقة ؛ أو (7) تزويدنا بأي معلومات شخصية عن الأطفال الذين تقل أعمارهم عن 13 عامًا أو عمر الموافقة الرقمية المعمول بها. سوف تمتثل لأي حدود سعر ومتطلبات أخرى في وثائقنا.',
    block7:
      '(د) خدمات الطرف الثالث. يخضع أي برنامج أو خدمات أو خدمات أخرى أو غيرها من المنتجات المرتبطة بالخدمة بشروطها الخاصة ، ونحن لسنا مسؤولين عن منتجات الطرف الثالث.',
    block8:
      '(أ) المحتوى الخاص بك. يمكنك توفير إدخال ("إدخال") للخدمة واستلام الإخراج ("الإخراج") الذي تم إنشاؤه وإعادته بناءً على الإدخال (يشار إليه مجتمعة باسم "المحتوى"). بين الطرفين ، وإلى الحد الذي يسمح به القانون المعمول به ، فإنك تملك جميع المدخلات. قد يستخدم NOAM المحتوى لتوفير وصيانة الخدمة ، والامتثال للقوانين المعمول بها ، وفرض سياساتنا. أنت مسؤول عن المحتوى ، بما في ذلك ضمان عدم انتهاك أي قوانين سارية أو هذه الشروط.',
    block9:
      '(ب) الدقة. الذكاء الاصطناعي والتعلم الآلي هي مجالات البحث بسرعة. نحن نسعى جاهدين لتحسين خدمتنا لجعلها أكثر دقة وموثوقية وآمنة ومفيدة. نظرًا للطبيعة الاحتمالية للتعلم الآلي ، قد يؤدي استخدام خدمتنا إلى مخرجات غير صحيحة في بعض الحالات ، والتي قد لا تعكس بدقة الأفراد أو الأماكن أو الحقائق الحقيقية. يجب عليك تقييم دقة أي إخراج بناءً على حالة الاستخدام الخاصة بك ، بما في ذلك من خلال مراجعة المخرجات يدويًا.',
    subTitle1: 'التسجيل والوصول',
    subTitle10: 'قابلية الفصل',
    subTitle2: 'متطلبات الاستخدام',
    subTitle3: 'محتوى',
    subTitle4: 'الرسوم والدفع',
    subTitle5: 'السرية والأمن وحماية البيانات',
    subTitle6: 'المصطلح والإنهاء',
    subTitle7: 'التعويض إخلاء المسئولية الحد من المسؤولية',
    subTitle8: 'الحكم القانون والولاية القضائية',
    subTitle9: 'المصطلحات العامة',
    title: 'شروط الخدمة',
    updateTime: 'تحديث: 15 مارس 2024',
  },
  trialModal: {
    btn: 'ابدأ الآن （فقط 0.99 دولار）',
    chat: 'chatpdf',
    desc1: 'بعد محاكمتك ، سيتم فرض رسوم سنوية قدرها 180 دولارًا',
    desc2: 'إلغاء في أي وقت',
    focus: 'التركيز',
    hover: 'تحوم الترجمة',
    image: 'ترجمة الصورة',
    installBtn: 'تثبيت الآن لتلقي هديتك',
    memo: 'إدارة المفضلة',
    pdf: 'PDF الترجمة الموازية',
    realtime: 'ترجمة فورية',
    select: 'ترجمة اختيار الكلمات',
    title: 'نرحب بالهدية',
    trialText: '7 أيام المحاكمة',
    video: 'ترجمة الفيديو',
    videoSummary: 'ملخص الفيديو',
    web: 'ترجمة موازية',
    webSummary: 'ملخص الويب',
  },
  upgradeModal: {
    btn: 'الترقية الآن',
    chat: { free: 'لقد وصلت إلى حد chatpdf', pro: 'لقد وصلت إلى حد chatpdf لهذا الشهر' },
    contactStr: 'يرجى انتظار إعادة التعيين التالي ، أو الاتصال بنا على contact@noam.tools.',
    limitAlertStr: 'لقد وصلت إلى حد خطتك المجانية. ترقية للمتابعة.',
    more: 'عرض الخطط',
    pageLimit: { free: 'لقد وصلت إلى حد صفحة PDF' },
    title: 'تذكير',
    upgradeAlertStr: 'لقد وصلت إلى حد خطتك المجانية. ترقية للمتابعة.',
    upload: {
      free: 'لقد وصلت إلى حد ترجمة PDF الخاص بك',
      pro: 'لقد وصلت إلى حد ترجمة PDF الخاص بك لهذا الشهر',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'انضم الآن',
      label: 'فوائد المستخدم الجديدة:',
      remain: 'فقط 30 اليسار',
      text: '100 بقعة فقط متوفرة! احصل على لك الآن!',
    },
  },
}

export default TRANSLATION
