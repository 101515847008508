const TRANSLATION = {
  buyModal: {
    afterFree: 'Après votre essai gratuit',
    btn: 'Essayez-le gratuitement',
    freeTrial: 'Essai gratuit de 3 jours',
    monthLabel: 'mois',
    selectLabel: 'Sélectionnez un plan',
    title: "Plan d'abonnement",
    yearLabel: 'année',
  },
  contact: {
    block1:
      'Pour la personnalisation, les questions ou les suggestions, veuillez nous contacter à contact@noam.tools. Nous répondrons rapidement.',
    block2: "Soutenez-nous en nous évaluant sur la boutique d'extension Google Apps à ★★★★★.",
    block3: 'Aidez les autres en partageant cette extension:',
    title: 'Contactez-nous',
  },
  help: {
    AquickOverviewOfNoam: 'Un aperçu rapide de Noam',
    ChatPDF: 'Chat pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Gestion des favoris',
    Fixedplugin: 'Plugin fixe',
    Focusing: 'Mise au point',
    Free: 'Gratuit',
    FreeTrialBenefits: "Avantages d'essai gratuits",
    GoWithWikipedia: 'Aller avec wikipedia',
    GoWithYouTube: 'Allez avec YouTube',
    HoverTranslation: 'Traduction en volants',
    Howtouse: 'Comment utiliser',
    ImageTranslation: "Traduction d'image",
    InstantTranslation: 'Traduction instantanée',
    Membership: 'Adhésion',
    NoamHelp: 'Noam vous aide à traduire et à résumer',
    PDFparallelTranslation: 'Traduction parallèle PDF',
    ParallelTranslation: 'Traduction parallèle',
    SkipAllSteps: 'Sauter toutes les étapes',
    Summary: 'Résumé',
    Translation: 'Traduction',
    TryItForFree: 'Essayez-le gratuitement',
    Video: 'Vidéo',
    VideoSummary: 'Résumé vidéo',
    VideoTranslation: 'Traduction vidéo',
    WebSummary: 'Résumé du Web',
    WordSelectionTranslation: 'Traduction de sélection de mots',
    benefits: 'Nous avons des avantages qui vous attendent!',
    dayFree: 'Essai gratuit de 3 jours',
    done: 'Fait',
    next: 'Suivant',
    pin: 'Pin Noam pour un accès plus facile ～～',
    planIsNotExist: "Le plan n'est pas disponible",
    startWiki: 'Commençons par Wikimedia!',
    startYoutube: 'Commençons par YouTube!',
    try: 'Essayez-le',
    tryNow: 'Essayez-le maintenant!',
  },
  home: {
    PDFTranslate: {
      desc: 'Traduction bilingue + chat PDF',
      sub1: {
        desc: "La lecture bilingue vous permet de comparer le texte original et la traduction côte à côte, améliorant l'efficacité de la lecture.",
        title: 'Lecture bilingue',
      },
      sub2: {
        desc: "L'IA analyse et résume et peut répondre à vos questions en fonction du contenu PDF.",
        title: 'Chat pdf',
      },
      sub3: {
        desc: 'Traduction PDF alimentée par AI pour une plus grande précision et efficacité.',
        title: 'Précision et efficacité',
      },
      title: 'PDF traduit',
    },
    aiAssistant: {
      desc: 'Rendre la traduction Web plus intelligente et plus pratique',
      panel1: {
        desc: 'Les traductions de références croisées bilingues vous aident à comparer le texte original avec la traduction afin que vous puissiez mieux comprendre et améliorer votre efficacité de lecture.',
        title: 'Traduction bilingue',
      },
      panel2: {
        left: {
          desc: 'Lorsque vous parcourez une page Web, survolez le contenu que vous devez traduire pour voir la traduction.',
          title: 'Traduction en volants',
        },
        right: {
          desc: 'Lorsque vous parcourez une page Web, sélectionnez le texte que vous souhaitez traduire pour voir la traduction.',
          title: 'Traduction de sélection de texte',
        },
      },
      panel3: {
        one: {
          desc: "Traduire et résumer les pages Web et le contenu vidéo à tout moment, n'importe où, pour une expérience plus fluide!",
          title: 'Pointer et cliquer',
        },
        three: {
          desc: "Le modèle de grande langue axé sur l'IA assure la précision et la cohérence de la traduction pour une expérience de lecture améliorée.",
          title: 'Entiné à AI',
        },
        two: {
          desc: 'Couverture complète multi-langues, lecture sans barrière bilingue, traduction en un clic - expérimenter la différence!',
          title: 'Plus complet et pratique',
        },
      },
      panel4: {
        tab1: {
          desc: 'Votre expert en efficacité - Utilisation de Chatgpt, Noam traduit et résume le contenu vidéo YouTube pour vous faire gagner du temps!',
          key: 'Vidéo',
          title: 'Traduction vidéo et résumé',
        },
        tab2: {
          desc: 'Cliquez pour traduire les images lors de la navigation sur le Web.',
          key: 'Image',
          title: 'Image traduit',
        },
        tab3: {
          desc: 'Extrait instantanément les informations clés des pages Web et fournit un résumé, améliorant votre efficacité de lecture.',
          key: 'Web',
          title: 'Web traduit et résumé',
        },
        tag1: 'Simple',
        tag2: 'Efficace',
        tag3: 'Facile à comprendre',
      },
      title: "Assistant d'IA",
    },
    getStart: 'Commencer',
    getStartedForFree: 'Commencez gratuitement',
    howItWork: {
      desc: "NOAM intègre des modèles AI pour fournir aux utilisateurs une expérience intelligente pour la traduction et le résumé des pages Web transversales, la traduction du PDF et de l'image, la traduction vidéo et le résumé",
      title: 'Comment ça marche',
    },
    lastBlock: { desc: "Meilleure traduction de l'IA pour une expérience de lecture plus fluide" },
    top: {
      desc: 'NOAM est une extension de navigateur AI gratuite qui vous aide à traduire et à résumer les pages Web, les vidéos et le contenu PDF. \\ N Rejoignez Noam et expérimentez la magie!',
      title1: 'Une magie',
      title2: 'Un clic',
    },
    userReviews: {
      desc: 'Avis cinq étoiles sur la boutique en ligne Chrome',
      title: 'Avis des utilisateurs',
    },
  },
  installTips: {
    btn: 'Essayez-le maintenant',
    text: 'Installez "NOAM Browser Plugin" pour déverrouiller plus de fonctionnalités! Référence croisée Web, \\ n Résumé vidéo Web, et plus encore!',
  },
  locale: {
    af: 'afrikaans',
    am: 'Amharique',
    ar: 'arabe',
    as: 'Assamais',
    auto: 'Détection automatique',
    az: 'azerbaïdjanais',
    ba: 'Bashkir',
    bg: 'bulgare',
    bn: 'Bangla',
    bo: 'Tibétain',
    bs: 'Bosnie',
    ca: 'catalan',
    cs: 'tchèque',
    cy: 'gallois',
    da: 'danois',
    de: 'Allemand',
    dsb: 'Sorbien inférieur',
    dv: 'Divehi',
    el: 'grec',
    en: 'Anglais',
    es: 'Espagnol',
    et: 'estonien',
    eu: 'Basque',
    fa: 'persan',
    fi: 'finlandais',
    fil: 'Philippin',
    fj: 'Fidjien',
    fr: 'Français',
    frCA: 'Français (Canada)',
    ga: 'irlandais',
    gl: 'Galicien',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Haoussa',
    he: 'hébreu',
    hi: 'hindi',
    hr: 'croate',
    hsb: 'Sorbien supérieur',
    ht: 'Créole haïtien',
    hu: 'hongrois',
    hy: 'arménien',
    id: 'indonésien',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandais',
    it: 'italien',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latin)',
    ja: 'japonais',
    ka: 'géorgien',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurde (nord)',
    kn: 'Kannada',
    ko: 'coréen',
    ku: 'Kurde (central)',
    ky: 'Kirghiz',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'lituanien',
    lug: 'Ganda',
    lv: 'letton',
    lzh: 'Chinois (littéraire)',
    mai: 'Maïthili',
    mg: 'malgache',
    mi: 'Maori',
    mk: 'Macédonien',
    ml: 'Malayalam',
    mnCyrl: 'Mongol (cyrillique)',
    mnMong: 'Mongol (traditionnel)',
    mr: 'Marathi',
    ms: 'malais',
    mt: 'maltais',
    mww: 'Hmong Daw',
    my: 'Myanmar (birman)',
    nb: 'norvégien',
    ne: 'Népalais',
    nl: 'Néerlandais',
    noSearchLang: 'Langue non prise en charge',
    nso: 'Sesotho SA Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'polonais',
    placeholder: 'Recherche',
    prs: 'Dari',
    ps: 'Pachto',
    pt: 'Portugais (Brésil)',
    ptPT: 'Portugais (Portugal)',
    ro: 'roumain',
    ru: 'russe',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Cinghalais',
    sk: 'slovaque',
    sl: 'slovène',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanais',
    srCyrl: 'Serbe (cyrillique)',
    srLatn: 'Serbe (latin)',
    st: 'Sésotho',
    sv: 'suédois',
    sw: 'Swahili',
    ta: 'Tamoul',
    te: 'Telugu',
    th: 'thaïlandais',
    ti: 'Tigrinya',
    tk: 'Turkmène',
    tlhLatn: 'Klingon (latin)',
    tlhPiqd: 'Klingon (piqad)',
    tn: 'Décors',
    to: 'ERROR: [Tongan]',
    tr: 'turc',
    tt: 'tatar',
    ty: 'Tahitien',
    ug: 'Ouïghour',
    uk: 'ukrainien',
    ur: 'Ourdou',
    uz: 'Ouzbek (latin)',
    vi: 'vietnamien',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Cantonais (traditionnel)',
    zh: 'Chinois',
    zhHans: 'Chinois simplifié',
    zhHant: 'Traditionnel chinois',
    zu: 'zoulou',
  },
  login: {
    and: 'et',
    btn: 'Connectez-vous avec Google',
    btn1: 'Connectez-vous maintenant',
    desc: "Connectez-vous pour débloquer plus d'expériences",
    policy: 'politique de confidentialité',
    terms: "Conditions d'utilisation",
    tips: "En m'inscrivant, j'accepte le",
    title: 'Bienvenue à Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Suivre', twitter: 'Gazouillement' },
    legal: {
      policy: 'politique de confidentialité',
      terms: 'Conditions de service',
      title: 'Légal',
    },
    product: {
      image: "Traduction d'image",
      pdf: 'Traduction PDF',
      title: 'Produits',
      video: 'Traduction vidéo et résumé',
      web: 'Traduction Web et résumé',
    },
    resources: { title: 'Ressources' },
  },
  pageHeader: {
    contact: 'Contact',
    help: 'Aide',
    logout: 'Déconnecter',
    logoutSucc: 'Déconnecté.',
    pricing: 'Prix',
    settings: 'Paramètres',
    signUp: 'Se connecter',
  },
  pdf: {
    autoDetect: 'Détecter automatiquement',
    cancelBtn: 'Annuler',
    cancelUpload: 'Annuler',
    chatPdf: {
      summaryError: 'Le résumé a échoué, veuillez réessayer plus tard',
      chatError: 'Le chat a échoué, veuillez réessayer plus tard',
      Search: 'Recherche',
      cancel: 'Annuler',
      chatTitle: 'Bienvenue à Noam! Voici un résumé de votre PDF:',
      clear: 'Clair',
      clearContent:
        'Cela commencera une nouvelle conversation. La conversation actuelle ne sera pas enregistrée. Es-tu sûr?',
      clearDialogTitle: 'Veuillez confirmer',
      clearSucc: 'Dégagé',
      copy: 'Copie',
      copySucc: 'Copié',
      delete: 'Supprimer',
      deleteCancel: 'Annuler',
      deleteContent: 'Ce PDF ne peut pas être récupéré une fois supprimé. Es-tu sûr?',
      deleteOk: 'Supprimer',
      deleteSuccess: 'Supprimé',
      deleteTitle: 'Confirmer la suppression',
      expand: 'Effondrement',
      historyTitle: 'Histoire',
      listError:
        'Impossible de charger la liste de lecture. Veuillez réessayer plus tard ou contacter le support.',
      loading: 'Chargement...',
      noDataDesc: "Pas encore d'histoire.",
      noMore: 'Plus de contenu.',
      placeholder: 'Demandez quoi que ce soit sur ce PDF',
      summaryTitle: 'Vous pourriez être intéressé par ces questions:',
    },
    collectionEmpty: 'Votre collection est vide.',
    collectionTab: 'Notes',
    dataLoading: 'Chargement...',
    dialogContent: 'Êtes-vous sûr de vouloir supprimer cela?',
    dialogTitle: 'Veuillez confirmer',
    download: {
      both: 'Bilingue',
      btn: 'Sauvegarder',
      cancel:
        'La fermeture de cette fenêtre annulera le téléchargement PDF. Êtes-vous sûr de vouloir annuler?',
      desc: "Enregistrer sur l'appareil: télécharge les traductions sous forme d'images; Enregistrer et imprimer: télécharge les traductions dans le format d'origine, avec la prise en charge de la copie.",
      download: 'Télécharger',
      downloadMsg1: 'Progrès de la traduction PDF:',
      downloadMsg2: 'Temps estimé restant:',
      downloadSucc: 'Le PDF est prêt! Cliquez pour enregistrer.',
      downloadTip:
        'Téléchargement PDF. Ne fermez pas cette fenêtre pour éviter les erreurs de téléchargement.',
      error: "Échec de la sauvegarde. Essayez d'imprimer à la place.",
      fileName: 'Nom de fichier:',
      fileType: 'Type de fichier:',
      loading: 'Économie...',
      loading2: 'Chargement...',
      minute: 'Minutes...',
      msg1: "Certaines traductions dans ce document sont plus longues que le texte d'origine et peuvent se chevaucher dans le fichier téléchargé. Nous vous recommandons de lire sur la page Web.",
      msg2: "1. La traduction n'est pas encore terminée. Veuillez télécharger une fois la traduction terminée.",
      msg3: '2. Certaines traductions sont trop longues. Lisez sur la page Web pour éviter le chevauchement du texte.',
      msg4: 'Les tâches de traduction sont en cours. Veuillez réessayer une fois terminé.',
      progress:
        'Traduction de PDF. Progress: {{progress}}, temps estimé restant: {{Minutes}} Minutes ...',
      save: "Enregistrer sur l'appareil",
      savePrint: 'Enregistrer et imprimer',
      success: 'Téléchargé',
      trans: 'Traduction uniquement',
      transContinue: 'Continuer à traduire',
    },
    downloadBtn: 'Télécharger',
    dragTipModal: {
      black: 'espace',
      leftKey: 'bouton gauche',
      ok: "J'ai compris",
      press: 'Presse',
      tips: 'faire glisser et afficher.',
    },
    entry: 'Pdfs',
    fileErrMsg:
      'La lecture de fichiers a échoué. Veuillez réessayer plus tard ou contacter le support.',
    fileMsg: 'Taille actuelle du fichier:',
    fileMsg2: 'Veuillez télécharger un fichier inférieur à 300 Mo.',
    freeBannerBtn: 'Mise à niveau',
    freeBannerTips: 'Limite de traductions PDF gratuites atteintes',
    guide: {
      placeholder: 'Collez le lien PDF ici',
      start: 'Commencer à traduire',
      text1: 'Lecture bilingue',
      text2:
        "Lisez en bilingue pour comparer le texte et la traduction d'origine côte à côte, en améliorant votre efficacité.",
      text3: 'Discutez avec votre PDF',
      text4:
        'Analyse et résumé alimentées par AI. Posez des questions sur votre PDF et obtenez des réponses instantanées.',
      text5: 'Précis et efficace',
      text6: 'Traduction PDF alimentée par AI pour une plus grande précision et efficacité.',
      title1: 'Traduction bilingue',
      title2: 'Discutez avec votre PDF',
      toast: 'Veuillez saisir un lien PDF en ligne valide se terminant par .pdf',
    },
    hasTransTask: "Une tâche de traduction est en cours. S'il vous plaît, attendez.",
    hoverTrans: 'Paragraphes de survol',
    initial: 'Traduire PDF',
    installText: 'Votre solution à guichet unique pour les traductions de tous les jours.',
    installText2: "Veuillez d'abord installer l'extension",
    installTips:
      "Installez l'extension pour encore plus de traductions PDF et des traductions de site Web plus lisses:",
    kw: 'agrafe',
    link: 'lien',
    listError:
      'Impossible de charger les PDF. Veuillez réessayer plus tard ou contacter le support.',
    memoTotalCount: '1 article total',
    new: 'nouveau fichier',
    noId: 'ID de fichier introuvable.',
    notPdf: 'Fichiers PDF uniquement.',
    okBtn: 'Supprimer',
    okBtn2: "J'ai compris",
    original: 'Original',
    parallel: 'Parallèle',
    parseErrDialogContent:
      'Ce lien PDF ne peut pas être traduit directement. Veuillez télécharger le PDF et le télécharger pour la traduction.',
    parseErrTips: "Échec de l'analyse du fichier",
    parseFileToast:
      "Échec de l'analyse du fichier. Veuillez réessayer plus tard ou contacter le support.",
    pdfLoading: 'Panage ...',
    searchPlaceholder: 'Recherche',
    selectTrans: 'Sélectionnez du texte à traduire',
    shareBtn: 'Partager',
    shareBtnText1: 'Générer le lien de partage',
    shareBtnText2: 'Mettre à jour le contenu partagé',
    shareModal: {
      cancel: 'Annuler',
      copy: 'Copie',
      copySucc: 'Lien copié',
      custom: 'Personnaliser:',
      errMsg:
        "Le contenu sélectionné n'a pas encore été entièrement traduit. Veuillez traduire avant le partage.",
      gen: 'Partager',
      modify: 'Modifier',
      notSupport: "Cette langue n'est pas actuellement prise en charge.",
      page: 'Pages',
      pageNuErr: 'Veuillez saisir un numéro de page valide.',
      range: 'Portée:',
      remain: 'restant',
      remain1: 'Temps estimé total:',
      search: 'Recherche',
      settings: 'Paramètres de partage:',
      shareSetting: 'Paramètres de partage',
      shareText: 'Partager',
      shareTips: 'Partagez le lien PDF traduit avec vos amis:',
      sourceLang: 'Depuis:',
      target: 'À:',
      text1: 'Paramètres de partage',
      text2: 'Générer le lien de partage',
      totalPage: '1 page total',
      trans: 'Traduire',
      translateTips:
        'ERROR: [Tip: If the translation is taking a while, you can close this window and share later.]',
      translating: 'Traduction de PDF ...',
      update: 'Mettre à jour le lien de partage',
    },
    shareMsg1: 'Partager le lien généré.',
    shareMsg2: 'Contenu partagé mis à jour.',
    shareMsg3:
      'Échec de la génération de lien de partage. Veuillez réessayer plus tard ou contacter le support.',
    shareMsg4:
      'Échec de la mise à jour du contenu partagé. Veuillez réessayer plus tard ou contacter le support.',
    shareTips: 'Votre traduction PDF est prête! Partagez-le avec vos amis.',
    start: 'Commencez votre essai gratuit',
    thumbnail: 'Table des matières',
    toast1: 'Chargement du PDF. Veuillez réessayer plus tard.',
    toast2:
      "Le contenu PDF est trop grand. La boîte de dialogue n'est actuellement pas prise en charge.",
    toast3: 'Téléchargement. Veuillez réessayer une fois le téléchargement terminé.',
    toolbar: { Adjust: 'Ajuster', autoFit: 'Auto-ajustement' },
    trans: 'Traduction',
    transSucc: 'Traduction réussie!',
    unLoginToast: 'Veuillez vous connecter pour utiliser cette fonctionnalité.',
    uploadBtn: 'Télécharger',
    uploadErr:
      'Le téléchargement de fichiers a échoué. Veuillez réessayer plus tard ou contacter le support.',
    uploadErrSignUrl:
      "Échec de l'url de téléchargement. Veuillez réessayer plus tard ou contacter le support.",
    uploadMsg1: 'Faites glisser et déposez votre fichier ici.',
    uploadMsg2: 'Types de fichiers pris en charge: PDF |  Taille du fichier maximum: 100 Mo',
    uploadMsg3: 'Cliquez pour sélectionner ou faire glisser les fichiers ici.',
    uploading: 'Téléchargement',
    uploadingMsg: "Téléchargement. S'il vous plaît, attendez.",
    uploadingMsg1: 'Le fichier est téléchargé. Veuillez réessayer plus tard.',
    uploadingMsg2:
      'Un fichier est actuellement en cours de converti. Veuillez réessayer plus tard.',
    uploadingMsg3:
      'La demande de progrès de la conversion a échoué. Veuillez réessayer plus tard ou contacter le support.',
    uploadingMsg4:
      'NOAM ne peut pas accéder aux fichiers locaux. Veuillez télécharger manuellement un PDF pour la traduction.',
    uploadingMsg5:
      "Échec de la récupération de l'URL du fichier. Veuillez réessayer plus tard ou contacter le support.",
    video: 'vidéo',
    vipBannerBtn: 'Mise à niveau',
    vipBannerTips: 'Vous avez atteint votre limite de traduction PDF pour ce mois.',
    webTrans: 'Référence croisée complète',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Oui, Noam propose un plan gratuit que vous pouvez utiliser immédiatement.',
      a2: "Nous proposons trois plans d'adhésion: Pro, Pro + et Enterprise. Vous pouvez choisir de payer mensuellement ou annuellement. La facturation annuelle offre des économies importantes.",
      a3: 'Toutes les fonctionnalités sont disponibles sur les deux plans. La principale différence est le coût: les abonnements annuels coûtent moins cher. Les abonnements mensuels sont facturés chaque mois, tandis que les plans annuels sont facturés une fois par an.',
      a4: 'Vous pouvez annuler votre abonnement à tout moment dans les paramètres de votre compte.',
      q1: 'Y a-t-il un plan gratuit?',
      q2: 'Quels plans de prix et options de paiement sont disponibles?',
      q3: "Quelle est la différence entre l'abonnement mensuel vs annuellement?",
      q4: 'Comment annuler mon abonnement?',
    },
    around: 'Autour',
    billedMonthly: 'Montrairement à facturation',
    billedYearly: 'Facturé annuel',
    day: 'jour',
    desc: 'Comparez les plans et choisissez le meilleur',
    detailedCompare: 'Comparez les plans',
    discount: '20% de réduction',
    enterprise: {
      benefit1: 'Assistance client prioritaire',
      benefit2: 'Multiples utilisateurs',
      benefit3: 'Solutions sur mesure',
      benefit5: 'Gestionnaire de compte dédié',
      btn: 'Contactez-nous',
      recommendText: 'Plans personnalisés （≥3 人）',
      talkToUs: 'Entrer en contact',
    },
    free: {
      benefit1: 'Traduction instantanée 100 fois / jour',
      benefit2: 'Traduction et résumé du site Web',
      benefit3: "30 traductions d'image",
      benefit4: 'Traduction vidéo et résumé',
      benefit5: '10 traductions PDF',
      benefit6: '5 000 jetons',
      btn: 'Essayez-le gratuitement',
      recommendText: 'Essai gratuit',
    },
    imageTranslation: "Traduction d'image",
    month: 'mois',
    monthly: 'Mensuel',
    pageTitle: 'Prix',
    pdf: {
      chat: 'Requêtes de chat PDF',
      maxPage: 'Max. Pages par pdf',
      translation: 'Traduction PDF',
    },
    preMonth: 'Par mois',
    pro: {
      benefit1: 'Assistance client prioritaire',
      benefit2: '1 000 000 jetons / mois',
      benefit3: 'Traduction et résumé du site Web',
      benefit4: "200 traductions d'image",
      benefit5: 'Traduction vidéo et résumé',
      benefit6: '200 traductions PDF',
      btn: 'Commencer',
      recommendText: 'Le plus populaire',
    },
    proPlus: {
      benefit1: 'Assistance client prioritaire',
      benefit2: '3 000 000 jetons / mois',
      benefit3: 'Traduction et résumé du site Web',
      benefit4: "500 traductions d'image",
      benefit5: 'Traduction vidéo et résumé',
      benefit6: '500 traductions PDF',
      recommendText: 'Meilleur rapport qualité-prix',
    },
    video: { summary: 'Résumé vidéo', translation: 'Traduction vidéo' },
    web: {
      contrastiveTranslation: 'Traduction bilingue',
      dictionaryTranslation: 'Traduction de sélection',
      hoverTranslation: 'Traduction en volants',
      instantTranslation: 'Traduction instantanée',
      summary: 'Résumé de la page Web',
    },
    yearly: 'Annuel',
  },
  privacy: {
    block1:
      "Noam («Noam», «nous», «notre» ou «nous») respecte votre vie privée et s'engage à protéger toute information que nous obtenons de vous ou de vous. Cette politique de confidentialité décrit nos pratiques concernant la collecte ou l'utilisation d'informations personnelles à partir de votre utilisation de notre site Web, applications et services (collectivement, les \"services\"). Cette politique de confidentialité ne s'applique pas au contenu que nous gérons au nom de nos clients de produits commerciaux, tels que notre API. L'utilisation de ces données est soumise à notre accord client, qui couvre l'accès et l'utilisation de ces produits.",
    block10:
      "Nous mettons en œuvre des mesures techniques, administratives et organisationnelles commercialement raisonnables pour protéger les informations personnelles en ligne et hors ligne contre les pertes, les abus abusives et les actes non autorisés, la divulgation, la modification ou les actes hostiles. Cependant, aucune transmission Internet ou e-mail ne peut garantir une sécurité ou une précision complète. En particulier, les e-mails qui nous sont envoyés ou de notre part peuvent ne pas être sécurisés. Ainsi, vous devez être particulièrement prudent lorsque vous décidez des informations à nous envoyer par le service ou par e-mail. De plus, nous ne sommes pas responsables de toute obstruction des paramètres de confidentialité ou des mesures de sécurité dans le service ou les paramètres de sécurité des sites Web de tiers ou des mesures de sécurité. Nous conserverons vos informations personnelles aussi longtemps que nécessaire pour que nous vous fournissez des services ou à d'autres fins commerciales légitimes, telles que la résolution des litiges, les raisons de sécurité et de sécurité, ou de respecter nos obligations légales. La durée que nous conservons les informations personnelles dépendra de nombreux facteurs, tels que le montant, la nature, la sensibilité des informations, les risques potentiels d'une utilisation ou d'une divulgation non autorisée, des objectifs pour lesquels nous traitons les informations et d'autres considérations juridiquement contraignantes.",
    block11:
      'Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Lorsque nous le faisons, nous publierons une version mise à jour sur cette page, sauf indication contraire par la loi applicable.',
    block12:
      'Si vous avez des questions ou des préoccupations sans réponse concernant cette politique de confidentialité, veuillez contacter notre service client. E-mail:',
    block2:
      'Nous collectons les informations personnelles suivantes («informations personnelles») liées à vous:',
    block3:
      'Informations fournies par vous: Lorsque vous créez un compte pour utiliser nos services ou communiquer avec nous, nous collectons des informations personnelles comme suit:',
    block4:
      'Informations personnelles reçues automatiquement de votre utilisation des services: Lorsque vous accédez, utilisez ou interagissez avec les services, nous recevons les informations suivantes sur votre accès, votre utilisation ou votre interaction ("Informations techniques"):',
    block5: 'Nous pouvons utiliser des informations personnelles aux fins suivantes:',
    block6:
      "Résumé ou des informations identifiées: nous pouvons agréger ou désactiver les informations personnelles pour empêcher sa réutilisation à des fins d'identification et utiliser ces informations pour analyser l'efficacité de nos services, améliorer et ajouter des fonctionnalités à nos services, effectuer des recherches et à des fins similaires. De plus, nous pouvons analyser le comportement général et les caractéristiques de nos utilisateurs de services et partager des informations agrégées avec des tiers, publier ces informations agrégées ou les rendre généralement disponibles. Nous pouvons collecter des informations agrégées via les services, par le biais de cookies et par d'autres moyens décrits dans cette politique de confidentialité. Nous maintiendrons et utiliserons des informations identifiées sous un formulaire anonyme ou désintéressé, et nous n'essaierons pas de réinitialiser ces informations sauf si la loi requise.",
    block7:
      'Dans certains cas, nous pouvons fournir vos informations personnelles à des tiers sans préavis, sauf si la loi est exigée:',
    block8:
      'Selon votre emplacement géographique, les individus dans le domaine économique européen, le Royaume-Uni et dans le monde peuvent avoir certains droits statutaires concernant leurs informations personnelles. Par exemple, vous pouvez avoir le droit de:',
    block9:
      "Le service peut contenir des liens vers d'autres sites Web non exploités ou contrôlés par NOAM, y compris les services de médias sociaux («sites Web tiers»). Les informations que vous partagez avec des sites Web tiers seront régies par les politiques de confidentialité spécifiques et les conditions d'utilisation des sites Web tiers, et non par la présente politique de confidentialité. Nous fournissons ces liens pour la commodité et n'impliquons pas notre approbation ou notre examen de ces sites Web. Veuillez contacter directement les sites Web tiers pour plus d'informations sur leurs politiques et conditions de confidentialité.",
    subBlock1:
      'Informations sur le compte: Lorsque vous créez un compte sur notre plate-forme, nous collectons des informations liées à votre compte, y compris votre nom, vos informations de contact, vos informations d\'identification, les informations de carte de paiement et l\'historique des transactions ("Informations de compte").',
    subBlock10: 'Fournir, gérer, maintenir et / ou analyser les services;',
    subBlock11: 'Communiquer avec vous;',
    subBlock12: 'Développer de nouveaux produits et services;',
    subBlock13:
      "Prévenir la fraude, l'activité criminelle ou une mauvaise utilisation de nos services et protéger la sécurité de nos systèmes informatiques, de nos systèmes informatiques, de nos réseaux informatiques;",
    subBlock14: 'Effectuer des transferts commerciaux;',
    subBlock15:
      "Respecter les obligations légales et les procédures judiciaires, protégeant nos droits, notre confidentialité, notre sécurité ou nos biens, ainsi que ceux de nos affiliés, de vous ou d'autres tiers.",
    subBlock16:
      "Fournisseurs et fournisseurs de services: Pour nous aider à répondre aux besoins opérationnels des entreprises et à exécuter certains services et fonctions, nous pouvons fournir des informations personnelles aux fournisseurs et aux fournisseurs de services, notamment des fournisseurs de services d'hébergement, des fournisseurs de services cloud et d'autres fournisseurs de services de technologie de l'information, des logiciels de communication par e-mail et des analyses Web de fournisseurs de services, etc.",
    subBlock17:
      "Transferts commerciaux: si nous nous engageons dans des transactions stratégiques, des réorganisations, des faillites, des prises de faillite ou des services de transition vers un autre fournisseur (collectivement, des \"transactions\"), vos informations personnelles et d'autres informations peuvent être partagées avec les homologues de transaction et d'autres parties pour les transactions pendant la diligence dommage directe et transférée dans le cadre des transactions ainsi que d'autres actifs à un successeur ou à une entreprise affiliée.",
    subBlock18:
      "Exigences légales: Nous pouvons partager vos informations personnelles avec les autorités gouvernementales, les pairs de l'industrie ou d'autres tiers, y compris vos informations d'interaction avec nos services, (1) si la loi l'exige ou si nous pensons que cette action est nécessaire pour se conformer aux obligations légales; (2) protéger et défendre nos droits ou biens; (3) Si nous déterminons unilatéralement qu'il y a une violation de nos conditions, politiques ou lois; (4) détecter ou prévenir la fraude ou d'autres activités illégales; (5) protéger nos produits, nos employés ou nos utilisateurs, ou la sécurité publique, la sécurité, l'intégrité; ou (vi) se défendre contre la responsabilité légale.",
    subBlock19:
      'Accédez à vos informations personnelles et à vos informations sur la façon dont elles sont traitées.',
    subBlock2:
      'Contenu de l\'utilisateur: Lorsque vous utilisez nos services, nous collectons des informations personnelles à partir de vos entrées, téléchargements de fichiers ou commentaires qui nous sont fournis ("Contenu").',
    subBlock20: 'Demandez la suppression de vos informations personnelles de nos dossiers.',
    subBlock21: 'Corriger ou mettre à jour vos informations personnelles.',
    subBlock22: 'Transférez vos informations personnelles à un tiers (portabilité des données).',
    subBlock23: 'Limitez la façon dont nous traitons vos informations personnelles.',
    subBlock24:
      'Retirez votre consentement - où nous comptons le consentement comme base juridique pour le traitement, vous pouvez retirer votre consentement à tout moment.',
    subBlock25: "S'opposer à la façon dont nous traitons vos informations personnelles.",
    subBlock26: 'Améliorer nos services et effectuer des recherches;',
    subBlock3:
      'Informations sur la communication: Si vous nous envoyez des messages, nous collecterons votre nom, vos coordonnées et le contenu de vos messages ("Informations de communication").',
    subBlock4:
      'Informations sur les réseaux sociaux: nous avons des pages sur les sites Web de médias sociaux tels que Instagram, Facebook, Medium, Twitter, YouTube et LinkedIn. Lorsque vous interagissez avec nos pages de médias sociaux, nous collectons les informations personnelles que vous choisissez de nous fournir, telles que vos coordonnées ("Informations sociales"). De plus, les entreprises qui hébergent nos pages de médias sociaux peuvent nous fournir des informations et des analyses agrégées sur nos activités de médias sociaux.',
    subBlock5:
      "Données de journal: informations envoyées automatiquement par votre navigateur lors de l'utilisation de nos services. Les données de journal incluent votre adresse de protocole Internet (IP), le type de navigateur et les paramètres, la date et l'heure de vos demandes, et comment vous interagissez avec notre site Web.",
    subBlock6:
      "Données d'utilisation: nous pouvons collecter automatiquement des informations sur votre utilisation du service, telles que les types de contenu que vous consultez ou interagissez, les fonctionnalités que vous utilisez, les actions que vous prenez, ainsi que votre fuseau horaire, votre pays, la date et l'heure de l'accès, l'agent et la version utilisateur, le type d'appareil informatique ou mobile, et votre connexion par ordinateur.",
    subBlock7:
      "Informations sur l'appareil: Cela inclut le nom de l'appareil, le système d'exploitation, l'identifiant de l'appareil et le navigateur que vous utilisez. Les informations collectées peuvent dépendre du type d'appareil que vous utilisez et de ses paramètres.",
    subBlock8:
      "Cookies: Nous utilisons des cookies pour utiliser et gérer nos services et améliorer votre expérience. Un \"cookie\" est des informations envoyées par un site Web que vous visitez à votre navigateur. Vous pouvez définir votre navigateur pour accepter tous les cookies, rejeter tous les cookies ou vous informer lorsqu'un cookie est fourni afin que vous puissiez décider de l'accepter à chaque fois. Cependant, refuser les cookies peut vous empêcher d'utiliser ou affecter négativement l'affichage ou la fonctionnalité de certaines zones ou caractéristiques du site Web. Pour plus d'informations sur les cookies, veuillez visiter tout sur les cookies.",
    subBlock9:
      "Analyse: Nous pouvons utiliser divers produits d'analyse en ligne qui utilisent des cookies pour nous aider à analyser la façon dont les utilisateurs utilisent nos services et améliorent votre expérience lors de l'utilisation des services.",
    subTitle1: 'Informations personnelles que nous collectons',
    subTitle2: 'Comment nous utilisons des informations personnelles',
    subTitle3: "Divulgation d'informations personnelles",
    subTitle4: 'Vos droits',
    subTitle5: "Liens vers d'autres sites Web",
    subTitle6: "Sécurité et rétention d'informations",
    subTitle7: 'Modification de la politique de confidentialité',
    subTitle8: 'Comment nous contacter',
    title: 'politique de confidentialité',
    updateTime: 'Mis à jour: 15 mars 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Accepter tout',
    text: 'Pour améliorer votre expérience, nous utilisons des cookies pour personnaliser le contenu et les services.',
    title: 'Ce site Web utilise des cookies',
  },
  slogen: "L'assistant AI le plus simple",
  stripe: {
    contact: 'Contactez-nous à contact@noam.tools pour toutes les questions!',
    fail: {
      btn: 'Réessayer',
      text1: "Nous n'avons pas pu traiter votre paiement.",
      text2: 'Vérifiez votre connexion et réessayez.',
      title: 'Échec du paiement',
    },
    succ: {
      btn: 'Allons-y',
      text1: 'Bienvenue à Noam,',
      text2: 'Commençons!',
      title: 'Félicitations',
    },
  },
  terms: {
    block1: "Merci d'utiliser Noam!",
    block10:
      '(a) Frais et facturation. Vous paierez tous les frais ("frais") en fonction des prix et des conditions de la page de tarification applicables ou comme convenu par écrit entre nous. Nous nous réservons le droit de corriger les erreurs de prix ou les erreurs même si nous avons émis des factures ou reçu un paiement. Vous fournissez des informations de facturation complètes et précises, y compris un mode de paiement valide et autorisé. Nous facturerons votre mode de paiement sur une base périodique comme convenu, mais nous pouvons raisonnablement modifier la date de facturation. Vous autorisez NOAM, ses affiliés et les processeurs de paiement tiers pour facturer votre mode de paiement. Si votre paiement échoue, nous vous fournirons un préavis écrit et pourrons suspendre l\'accès au service jusqu\'à ce que le paiement soit reçu. Sauf indication contraire dans le présent accord, les paiements ne sont pas remboursables.',
    block11:
      "(b) Taxes. Sauf indication contraire, les frais n'incluent aucune taxe fédérale, étatique, locale et étrangère, des droits et des évaluations similaires (\"taxes\"). Vous êtes responsable de toutes les taxes associées à vos achats, à l'exclusion des impôts en fonction de notre revenu net pour lequel nous vous faciliterons. Vous acceptez de payer rapidement ces impôts et de nous fournir des déclarations de confidentialité légalement valides et d'obtenir les consentements nécessaires pour gérer ces données, et vous vous garantissez que vous gérez ces données conformément à la loi applicable.",
    block12:
      "(c) Changements de prix. Nous pouvons modifier nos prix en vous informant via votre compte et / ou notre site Web. Les augmentations de prix seront en vigueur immédiatement après la publication. Toute modification des prix s'appliquera aux frais pour votre compte après la date d'entrée en vigueur du changement.",
    block13:
      '(d) Discuits et en souffrance en souffrance. Si vous souhaitez contester les frais ou taxes, veuillez contacter Contact@noam.tools dans les trente (30) jours suivant la date de la facture.',
    block14:
      "(e) Utilisation gratuite. Vous ne pouvez pas créer plusieurs comptes pour obtenir des crédits gratuits. Si nous déterminons que vous n'utilisez pas les crédits gratuits de bonne foi, nous pouvons vous facturer des frais standard ou cesser de donner accès au service.",
    block15:
      "(a) Confidentialité. Vous pouvez avoir accès à des informations confidentielles de NOAM. Vous ne pouvez utiliser que des informations confidentielles comme autorisé par ces conditions dans le but d'utiliser le service. Vous ne pouvez pas divulguer des informations confidentielles à un tiers, et vous protégerez des informations confidentielles d'une manière non moins protectrice que vous protégez vos propres informations confidentielles similaires, du moins avec des soins raisonnables. Les informations confidentielles sont des informations non publiques désignées comme confidentielles par NOAM ou qui devraient raisonnablement être traitées comme confidentielles dans les circonstances, y compris des logiciels, des spécifications et d'autres informations commerciales non publiques. Les informations confidentielles n'incluent pas d'informations qui: (1) devient généralement disponible pour le public sans faute de votre part; (2) Vous aviez en votre possession sans aucune obligation de confidentialité avant de la recevoir en vertu de ces conditions; (3) vous est légalement divulgué par un tiers sans aucune obligation de confidentialité; ou (4) est développé indépendamment par vous sans utiliser les informations confidentielles. Vous pouvez divulguer des informations confidentielles si l'exige de la loi, du tribunal ou d'une autre ordonnance gouvernementale, mais vous devez fournir un préavis écrit préalable à Noam de manière raisonnable et, dans la mesure du possible, faire des efforts raisonnables pour limiter la portée de la divulgation, notamment en nous aidant à s'opposer aux demandes de divulgation.",
    block16:
      '(b) Sécurité. Vous devez mettre en œuvre des mesures raisonnables et appropriées pour aider à protéger votre accès et votre utilisation du service. Si vous découvrez des vulnérabilités ou des violations liées à votre utilisation du service, vous devez contacter rapidement NOAM et fournir des détails sur la vulnérabilité ou la violation.',
    block17:
      '(c) Traitement des données personnelles. Si vous utilisez le service pour traiter les données personnelles, vous devez fournir des instructions de confidentialité légalement suffisantes et obtenir les consentements nécessaires pour le traitement de ces données, et vous vous garantissez que vous gérez ces données conformément à la loi applicable.',
    block18:
      "a) terminaison; Suspension. Ces termes seront efficaces à partir de votre première utilisation du service et resteront en vigueur jusqu'à la fin. Vous pouvez résilier ces conditions à tout moment pour quelque raison que ce soit en cessant d'utiliser le service et le contenu. Nous pouvons résilier ces conditions avec un avis préalable pour quelque raison que ce soit. Nous pouvons immédiatement mettre fin à ces conditions en vous informant si vous visez matériellement la section 2 (exigences d'utilisation), la section 5 (confidentialité, sécurité et protection des données), section 8 (règlement des différends) ou section 9 (Conditions générales), ou si nos relations avec un fournisseur de technologies tierces au-delà de nos changements de contrôle ou pour la conformité avec les demandes de droit ou de gouvernement. Nous pouvons suspendre votre accès au service si vous ne respectez pas ces conditions, ou si votre utilisation présente un risque de sécurité pour nous ou un tiers, ou si nous soupçonnons que votre utilisation est frauduleuse ou peut nous exposer ou un tiers à la responsabilité.",
    block19:
      "(b) Effets de la terminaison. Lors de la résiliation, vous cesseras d'utiliser le service et de retourner ou de détruire rapidement toute information confidentielle comme indiqué par nous. Les dispositions de ces conditions selon lesquelles de leur nature devraient survivre à la résiliation ou à l'expiration, y compris, mais sans s'y limiter, les articles 3 et 5-9, survivra.",
    block2:
      "Ces conditions d'utilisation s'appliquent à votre utilisation des services de Noam, y compris nos interfaces de programmation d'applications, les logiciels, les outils, les services de développeur, les données, la documentation et le site Web (collectivement appelé \"Services\"). En utilisant nos services, vous acceptez de respecter ces conditions. Notre politique de confidentialité explique comment nous collectons et utilisons des informations personnelles.",
    block20:
      "a) Indemnification. Vous nous défendrez, vous indemniserez et nous tiendrez, nos affiliés et notre personnel inoffensif de et contre toute réclamation, pertes et dépenses (y compris les honoraires d'avocat) résultant ou liés à votre utilisation du service, y compris votre contenu, votre utilisation de produits ou services liés au Service, et votre violation de ces conditions ou de la loi applicable.",
    block21:
      "(b) Avis de non-responsabilité. Le service est fourni \"tel quel.\" Dans la mesure autorisée par la loi, nous et nos affiliés et concédants de licence ne faisons aucune représentation ou garantie de toute nature concernant le service et n'agissaient aucune garantie, y compris, mais sans s'y limiter, les garanties de qualité marchande, d'adéquation dans un but particulier, de qualité satisfaisante, de non-infiltration et de plaisir silencieux, et de toute garantie découlant bien sûr du trafic ou de l'utilisation du commerce. Nous ne garantissons pas que le service sera ininterrompu, précis ou sans erreur, ou que tout contenu sera sécurisé, non perdu ou non modifié.",
    block22:
      "(c) Limitation de responsabilité. Ni nous, nos sociétés affiliées, ni nos concédants de licence, nous serons responsables de tout dommage indirect, accessoire, spécial, consécutif ou punitif, y compris la perte de bénéfices, la perte de réputation, la perte de l'utilisation, la perte de données ou d'autres pertes intangibles, même si nous avons été informés de la possibilité de ces dommages. Selon ces conditions, notre responsabilité totale ne dépassera pas les frais que vous avez payés au Service dans les douze (12) mois précédant la réclamation ou cent dollars américains (100 $), selon le plus grand. Les limitations de cette section s'appliquent dans la mesure maximale autorisée par la loi applicable.",
    block23:
      "Les conditions et votre utilisation des services sont régies par les lois des États-Unis, à l'exception des règles américaines du conflit des lois. Votre utilisation de la demande peut également être soumise à d'autres lois locales, étatiques, nationales ou internationales. Toute action en justice ou procédure concernant ces conditions sera intensifiée exclusivement devant les tribunaux des États-Unis, et vous acceptez de vous soumettre à la juridiction personnelle de ces tribunaux.",
    block24:
      "a) Relation des parties. Noam et vous êtes des entrepreneurs indépendants, et aucune des parties n'a le pouvoir de représenter ou de lier l'autre partie ou d'assumer les obligations pour l'autre partie sans le consentement écrit préalable de l'autre partie.",
    block25:
      "(b) Utilisation de la marque. Vous ne pouvez pas utiliser le nom, les logos ou les marques de NOAM ou l'un de ses affiliés sans notre consentement écrit préalable.",
    block26:
      'Si une disposition des présentes Conditions est déterminée comme invalide, illégale ou inapplicable, les dispositions restantes resteront pleinement en vigueur et en effet.',
    block3:
      "Si vous avez moins de 18 ans, vous devez obtenir la permission de vos parents ou de vos tuteurs légaux pour utiliser le service. Si vous utilisez le service au nom d'une autre personne ou entité, vous devez être autorisé à accepter ces conditions en leur nom. Vous devez fournir des informations précises et complètes pour vous inscrire à un compte. Vous ne pouvez pas fournir vos informations d'identification ou votre compte d'accès à toute personne en dehors de votre organisation, et vous êtes responsable de toutes les activités menées à l'aide de vos informations d'identification.",
    block4:
      "(a) Utilisation du service. Vous pouvez accéder au service selon ces conditions et nous vous accordons un droit non exclusif d'utiliser le service. Lorsque vous utilisez le service, vous vous conformerez à ces conditions et à toutes les lois applicables. Nous et nos affiliés possédons tous les droits, titres et intérêts dans le service.",
    block5:
      "(b) rétroaction. Nous nous réjouissons des commentaires, des commentaires, des idées, des suggestions et des améliorations. Si vous fournissez un tel contenu, nous pouvons l'utiliser sans aucune restriction et sans compensation à vous.",
    block6:
      "(c) Restrictions. Vous ne pouvez pas: (1) utiliser le service d'une manière qui enfreint, détourne ou viole les droits des autres; (2) l'ingénieur à la recherche, le décompilation, le désassemblement, la traduction ou la tente de découvrir le code source ou les composants sous-jacents des modèles de service, des algorithmes et des systèmes (à moins que ces restrictions ne soient interdites par la loi applicable); (3) utiliser la sortie du service pour développer des modèles en concurrence avec NOAM; (4) Extraire des données ou une sortie du service à l'aide de toutes les méthodes automatisées ou de programmation, sauf permis via l'API, y compris le grattage, la récolte Web ou l'extraction de données Web; (5) représentent la sortie du service comme généré artificiellement lorsqu'il ne l'est pas, ou enfreint autrement nos politiques d'utilisation; (6) acheter, vendre ou transférer des clés d'API sans notre consentement préalable; ou (7) nous fournir des informations personnelles sur les enfants de moins de 13 ans ou l'âge applicable du consentement numérique. Vous vous conformerez à toutes les limites de taux et autres exigences dans notre documentation.",
    block7:
      '(d) Services tiers. Tous les logiciels, services ou autres produits tiers associés au service sont régis par leurs propres conditions, et nous ne sommes pas responsables des produits tiers.',
    block8:
      '(a) Votre contenu. Vous pouvez fournir une entrée ("entrée") au service et recevoir la sortie ("sortie") générée et renvoyée en fonction de l\'entrée (collectivement appelée "contenu"). Entre les parties, et dans la mesure permise par la loi applicable, vous possédez toutes les contributions. NOAM peut utiliser du contenu pour fournir et maintenir le service, se conformer aux lois applicables et appliquer nos politiques. Vous êtes responsable du contenu, notamment en vous assurant qu\'il ne viole aucune lois applicables ou ces conditions.',
    block9:
      "(b) précision. L'intelligence artificielle et l'apprentissage automatique évoluent rapidement de la recherche. Nous nous efforçons continuellement d'améliorer notre service pour le rendre plus précis, fiable, sécurisé et bénéfique. Étant donné la nature probabiliste de l'apprentissage automatique, l'utilisation de notre service peut entraîner des résultats incorrects dans certains cas, ce qui peut ne pas refléter avec précision des individus, des lieux ou des faits réels. Vous devez évaluer de manière appropriée la précision de toute sortie en fonction de votre cas d'utilisation, y compris en examinant manuellement les sorties.",
    subTitle1: 'Inscription et accès',
    subTitle10: 'Semettabilité',
    subTitle2: "Exigences d'utilisation",
    subTitle3: 'Contenu',
    subTitle4: 'Frais et paiement',
    subTitle5: 'Confidentialité, sécurité et protection des données',
    subTitle6: 'Terme et résiliation',
    subTitle7: 'Indemnité; Avertissements; Limitation de responsabilité',
    subTitle8: 'Droit au droit et à la juridiction',
    subTitle9: 'Conditions générales',
    title: 'Conditions de service',
    updateTime: 'Mis à jour: 15 mars 2024',
  },
  trialModal: {
    btn: 'Commencez maintenant （seulement 0,99 $）',
    chat: 'Chatpdf',
    desc1: 'Après votre procès, vous verrez des frais annuels de 180 $',
    desc2: 'Annuler à tout moment',
    focus: 'Mise au point',
    hover: 'Traduction en volants',
    image: "Traduction d'image",
    installBtn: 'Installez maintenant pour recevoir votre cadeau',
    memo: 'Gestion des favoris',
    pdf: 'Traduction parallèle PDF',
    realtime: 'Traduction instantanée',
    select: 'Traduction de sélection de mots',
    title: 'Cadeau de bienvenue',
    trialText: 'Procès de 7 jours',
    video: 'Traduction vidéo',
    videoSummary: 'Résumé vidéo',
    web: 'Traduction parallèle',
    webSummary: 'Résumé du Web',
  },
  upgradeModal: {
    btn: 'Mettre à niveau maintenant',
    chat: {
      free: 'Vous avez atteint la limite de chatpdf',
      pro: 'Vous avez atteint la limite de chatpdf pour ce mois',
    },
    contactStr:
      'Veuillez attendre la prochaine réinitialisation, ou contactez-nous à contact@noam.tools.',
    limitAlertStr: 'Vous avez atteint votre limite de plan libre. Mettre à niveau pour continuer.',
    more: 'Afficher les plans',
    pageLimit: { free: 'Vous avez atteint la limite de page PDF' },
    title: 'Rappel',
    upgradeAlertStr:
      'Vous avez atteint votre limite de plan libre. Mettre à niveau pour continuer.',
    upload: {
      free: 'Vous avez atteint votre limite de traduction PDF',
      pro: 'Vous avez atteint votre limite de traduction PDF pour ce mois',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Rejoindre maintenant',
      label: 'NOUVEAUX Avantages des utilisateurs:',
      remain: 'Il ne reste que 30',
      text: 'Seulement 100 places disponibles! Prenez le vôtre maintenant!',
    },
  },
}

export default TRANSLATION
