const TRANSLATION = {
  buyModal: {
    afterFree: 'Na uw gratis proefperiode',
    btn: 'Probeer het gratis',
    freeTrial: '3-daagse gratis proefversie',
    monthLabel: 'maand',
    selectLabel: 'Selecteer een plan',
    title: 'Abonnementsplan',
    yearLabel: 'jaar',
  },
  contact: {
    block1:
      'Neem voor aanpassing, vragen of suggesties contact met ons op via contact@noam.tools. We zullen snel reageren.',
    block2: 'Ondersteun ons door ons te beoordelen in de Google Apps Extension Store op ★★★★★.',
    block3: 'Anderen helpen door deze extensie te delen:',
    title: 'Neem contact met ons op',
  },
  help: {
    AquickOverviewOfNoam: 'Een snel overzicht van Noam',
    ChatPDF: 'Chat PDF',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Favorieten management',
    Fixedplugin: 'Vaste plug -in',
    Focusing: 'Focus',
    Free: 'Vrij',
    FreeTrialBenefits: 'Gratis proefvoordelen',
    GoWithWikipedia: 'Ga met Wikipedia',
    GoWithYouTube: 'Ga met YouTube',
    HoverTranslation: 'Zweven vertaling',
    Howtouse: 'Hoe te gebruiken',
    ImageTranslation: 'Afbeeldingvertaling',
    InstantTranslation: 'Onmiddellijke vertaling',
    Membership: 'Lidmaatschap',
    NoamHelp: 'Noam helpt je te vertalen en samen te vatten',
    PDFparallelTranslation: 'PDF parallelle vertaling',
    ParallelTranslation: 'Parallelle vertaling',
    SkipAllSteps: 'Sla alle stappen over',
    Summary: 'Samenvatting',
    Translation: 'Vertaling',
    TryItForFree: 'Probeer het gratis',
    Video: 'Video',
    VideoSummary: 'Video -samenvatting',
    VideoTranslation: 'Video vertaling',
    WebSummary: 'Samenvatting van het web',
    WordSelectionTranslation: 'Vertaling van woordselectie',
    benefits: 'We hebben voordelen op je wachten!',
    dayFree: '3-daagse gratis proefversie',
    done: 'Klaar',
    next: 'Volgende',
    pin: 'Pin noam voor gemakkelijkere toegang ～～',
    planIsNotExist: 'Plan is niet beschikbaar',
    startWiki: 'Laten we beginnen met Wikimedia!',
    startYoutube: 'Laten we beginnen met YouTube!',
    try: 'Probeer het uit',
    tryNow: 'Probeer het nu eens!',
  },
  home: {
    PDFTranslate: {
      desc: 'Tweetalige vertaling + chat pdf',
      sub1: {
        desc: 'Met tweetalig lezen kunt u de originele tekst en de vertaling naast elkaar vergelijken, waardoor de leesefficiëntie wordt verbeterd.',
        title: 'Tweetalige lezing',
      },
      sub2: {
        desc: 'AI analyseert en vat samen en kan uw vragen beantwoorden op basis van de PDF -inhoud.',
        title: 'Chat PDF',
      },
      sub3: {
        desc: 'AI-aangedreven PDF-vertaling voor meer nauwkeurigheid en efficiëntie.',
        title: 'Nauwkeurigheid en efficiëntie',
      },
      title: 'Pdf vertalen',
    },
    aiAssistant: {
      desc: 'Webvertaling slimmer en handiger maken',
      panel1: {
        desc: 'Tweetalige kruisverwijzingsvertalingen helpen u om de originele tekst te vergelijken met de vertaling, zodat u het beter kunt begrijpen en uw leesefficiëntie kunt verbeteren.',
        title: 'Tweetalige vertaling',
      },
      panel2: {
        left: {
          desc: 'Zoek bij het bladeren van een webpagina over de inhoud die u moet vertalen om de vertaling te zien.',
          title: 'Zweven vertaling',
        },
        right: {
          desc: 'Selecteer bij het bladeren van een webpagina de tekst die u wilt vertalen om de vertaling te zien.',
          title: 'Tekstselectie vertaling',
        },
      },
      panel3: {
        one: {
          desc: 'Vertaal en vat altijd, overal, overal, overal, overal, voor een soepelere ervaring!',
          title: 'Punt en klik',
        },
        three: {
          desc: 'AI-aangedreven groot taalmodel zorgt voor vertaalnauwkeurigheid en consistentie voor een verbeterde leeservaring.',
          title: 'AI-aangedreven',
        },
        two: {
          desc: 'Multi-taal volledige dekking, tweetalige barrièrevrije lezing, vertaling met één klik-ervaar het verschil!',
          title: 'Uitgebreider en handiger',
        },
      },
      panel4: {
        tab1: {
          desc: 'Uw efficiëntie -expert - Noam met Chatgpt, vertaalt Noam en vat YouTube -video -inhoud samen om u tijd te besparen!',
          key: 'Video',
          title: 'Video vertaling en samenvatting',
        },
        tab2: {
          desc: 'Klik om afbeeldingen te vertalen tijdens het bladeren op internet.',
          key: 'Afbeelding',
          title: 'Afbeelding vertalen',
        },
        tab3: {
          desc: "Uithaalt onmiddellijk belangrijke informatie van webpagina's en biedt een samenvatting, waardoor uw leesefficiëntie wordt verbeterd.",
          key: 'Web',
          title: 'Web vertalen en samenvatten',
        },
        tag1: 'Eenvoudig',
        tag2: 'Efficiënt',
        tag3: 'Gemakkelijk te begrijpen',
      },
      title: 'AI -assistent',
    },
    getStart: 'Aan de slag gaan',
    getStartedForFree: 'Ga gratis aan de slag',
    howItWork: {
      desc: "NOAM integreert AI-modellen om gebruikers een intelligente ervaring te bieden voor vertaling en samenvatting van de webpagina's en samenvatting, PDF en beeldvertaling, video-vertaling en samenvatting",
      title: 'Hoe het werkt',
    },
    lastBlock: { desc: 'Betere AI -vertaling voor een soepelere leeservaring' },
    top: {
      desc: "NOAM is een gratis AI -browserverlenging die u helpt bij het vertalen en samenvatten van webpagina's, video's en PDF -inhoud. \\ N Doe mee met NOAM en ervaar de magie!",
      title1: 'Eén magie',
      title2: 'Één klik',
    },
    userReviews: {
      desc: 'Vijfsterrenrecensies op de Chrome Web Store',
      title: 'Gebruikersrecensies',
    },
  },
  installTips: {
    btn: 'Probeer het nu',
    text: 'Installeer "NOAM -browser plug -in" om meer functies te ontgrendelen! Web Cross-Refercing, \\ n webvideo-samenvatting en meer!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'Amharisch',
    ar: 'Arabisch',
    as: 'Assamese',
    auto: 'Automatisch detecteren',
    az: 'Azerbeidzjani',
    ba: 'Bashkir',
    bg: 'Bulgaars',
    bn: 'Bangla',
    bo: 'Tibetaans',
    bs: 'Bosnisch',
    ca: 'Catalaans',
    cs: 'Tsjechisch',
    cy: 'Wales',
    da: 'Deens',
    de: 'Duits',
    dsb: 'Lager Sorbisch',
    dv: 'Duik',
    el: 'Grieks',
    en: 'Engels',
    es: 'Spaans',
    et: 'Estlands',
    eu: 'baskisch',
    fa: 'Perzisch',
    fi: 'Fins',
    fil: 'Filipijns',
    fj: 'Fiji',
    fr: 'Frans',
    frCA: 'Frans (Canada)',
    ga: 'Iers',
    gl: 'Galicus',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Hebreeuws',
    hi: 'Hindi',
    hr: 'Kroatisch',
    hsb: 'Bovenste Sorbisch',
    ht: 'Haïtiaans Creool',
    hu: 'Hongaars',
    hy: 'Armeens',
    id: 'Indonesisch',
    ig: 'Igbo',
    ikt: 'ERROR: [Inuinnaqtun]',
    is: 'IJslands',
    it: 'Italiaans',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latijn)',
    ja: 'Japanse',
    ka: 'Georgisch',
    kk: 'Kazache',
    km: 'Khmer',
    kmr: 'Koerdisch (noordelijk)',
    kn: 'Kannada',
    ko: 'Koreaans',
    ku: 'Koerdisch (centraal)',
    ky: 'Kirgizisch',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Litouws',
    lug: 'Ganda',
    lv: 'Letland',
    lzh: 'Chinees (literair)',
    mai: 'Maithili',
    mg: 'Madagaskar',
    mi: 'Maori',
    mk: 'Macedonisch',
    ml: 'Malayalam',
    mnCyrl: 'Mongools (Cyrillic)',
    mnMong: 'Mongools (traditioneel)',
    mr: 'Marathi',
    ms: 'Maleis',
    mt: 'Maltees',
    mww: 'Hmong Daw',
    my: 'Myanmar (Birmese)',
    nb: 'Noors',
    ne: 'Nepalese',
    nl: 'Nederlands',
    noSearchLang: 'Taal niet ondersteund',
    nso: 'Sesotho Sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro otomi',
    pa: 'Punjabi',
    pl: 'Pools',
    placeholder: 'Zoekopdracht',
    prs: 'Dari',
    ps: 'Pashto',
    pt: 'Portugees (Brazilië)',
    ptPT: 'Portugees (Portugal)',
    ro: 'Roemeense',
    ru: 'Russisch',
    run: 'Rundel',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovaak',
    sl: 'Sloveens',
    sm: 'Samoaans',
    sn: 'Shona',
    so: 'Somalisch',
    sq: 'Albanees',
    srCyrl: 'Servisch (Cyrillic)',
    srLatn: 'Servisch (Latijn)',
    st: 'Sesotho',
    sv: 'Zweeds',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thais',
    ti: 'Tigrya',
    tk: 'Turkmensen',
    tlhLatn: 'Klingon (Latijn)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongaans',
    tr: 'Turks',
    tt: 'Tataar',
    ty: 'Tahitiaans',
    ug: 'Uyghur',
    uk: 'Oekraïens',
    ur: 'Urdu',
    uz: 'Uzbeek (Latijn)',
    vi: 'Vietnamees',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonees (traditioneel)',
    zh: 'Chinese',
    zhHans: 'Chinees vereenvoudigd',
    zhHant: 'Chinees traditioneel',
    zu: 'Zulu',
  },
  login: {
    and: 'En',
    btn: 'Log in met Google',
    btn1: 'Log nu in',
    desc: 'Log in om meer ervaringen te ontgrendelen',
    policy: 'Privacybeleid',
    terms: 'Gebruiksvoorwaarden',
    tips: 'Door me aan te melden, ga ik akkoord met de',
    title: 'Welkom bij Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Volgen', twitter: 'Twitter' },
    legal: { policy: 'Privacybeleid', terms: 'Voorwaarden van services', title: 'Wettelijk' },
    product: {
      image: 'Afbeeldingvertaling',
      pdf: 'PDF -vertaling',
      title: 'Producten',
      video: 'Video vertaling en samenvatting',
      web: 'Webvertaling en samenvatting',
    },
    resources: { title: 'Bronnen' },
  },
  pageHeader: {
    contact: 'Contact',
    help: 'Hulp',
    logout: 'Uitloggen',
    logoutSucc: 'Uitgelogd.',
    pricing: 'Prijzen',
    settings: 'Instellingen',
    signUp: 'Inloggen',
  },
  pdf: {
    autoDetect: 'Automatisch detecteren',
    cancelBtn: 'Annuleren',
    cancelUpload: 'Annuleren',
    chatPdf: {
      summaryError: 'Samenvatting mislukt, probeer het later opnieuw',
      chatError: 'Chat mislukt, probeer het later opnieuw',
      Search: 'Zoekopdracht',
      cancel: 'Annuleren',
      chatTitle: 'Welkom bij Noam! Hier is een samenvatting van uw PDF:',
      clear: 'Duidelijk',
      clearContent:
        'Dit begint een nieuw gesprek. Het huidige gesprek zal niet worden opgeslagen. Weet je het zeker?',
      clearDialogTitle: 'Bevestig',
      clearSucc: 'Opgeruimd',
      copy: 'Kopiëren',
      copySucc: 'Gekopieerd',
      delete: 'Verwijderen',
      deleteCancel: 'Annuleren',
      deleteContent:
        'Deze PDF kan niet worden hersteld nadat ze zijn verwijderd. Weet je het zeker?',
      deleteOk: 'Verwijderen',
      deleteSuccess: 'Verwijderd',
      deleteTitle: 'Bevestig verwijderen',
      expand: 'Instorten',
      historyTitle: 'Geschiedenis',
      listError:
        'Kan de leeslijst niet laden. Probeer het later opnieuw of neem contact op met ondersteuning.',
      loading: 'Laden ...',
      noDataDesc: 'Nog geen geschiedenis.',
      noMore: 'Geen inhoud meer.',
      placeholder: 'Vraag iets over deze PDF',
      summaryTitle: 'Je bent misschien geïnteresseerd in deze vragen:',
    },
    collectionEmpty: 'Uw verzameling is leeg.',
    collectionTab: 'Aantekeningen',
    dataLoading: 'Laden ...',
    dialogContent: 'Weet u zeker dat u dit wilt verwijderen?',
    dialogTitle: 'Bevestig',
    download: {
      both: 'Tweetalig',
      btn: 'Redden',
      cancel:
        'Door dit venster te sluiten, wordt de PDF -download geannuleerd. Weet u zeker dat u wilt annuleren?',
      desc: 'Opslaan op apparaat: downloadt vertalingen als afbeeldingen; Opslaan en afdrukken: downloadt vertalingen in het originele formaat, met kopieondersteuning.',
      download: 'Downloaden',
      downloadMsg1: 'PDF -vertaling voortgang:',
      downloadMsg2: 'Geschatte resterende tijd:',
      downloadSucc: 'PDF is klaar! Klik om op te slaan.',
      downloadTip: 'PDF downloaden. Sluit dit venster niet om downloadfouten te voorkomen.',
      error: 'Kan niet opslaan. Probeer in plaats daarvan af te drukken.',
      fileName: 'Bestandsnaam:',
      fileType: 'Bestandstype:',
      loading: 'Besparing...',
      loading2: 'Laden ...',
      minute: 'Notulen...',
      msg1: 'Sommige vertalingen in dit document zijn langer dan de originele tekst en kunnen overlappen in het gedownloade bestand. We raden aan om op de webpagina te lezen.',
      msg2: '1. Vertaling is nog niet compleet. Download alstublieft nadat de vertaling is voltooid.',
      msg3: '2. Sommige vertalingen zijn te lang. Lees op de webpagina om tekstoverlap te voorkomen.',
      msg4: 'Vertaaltaken zijn aan de gang. Probeer het opnieuw nadat ze zijn voltooid.',
      progress:
        'Pdf vertalen. Voortgang: {{progress}}, geschatte tijd resterend: {{minuten}} minuten ...',
      save: 'Opslaan op apparaat',
      savePrint: 'Opslaan en afdrukken',
      success: 'Gedownload',
      trans: 'Alleen vertaling',
      transContinue: 'Blijf vertalen',
    },
    downloadBtn: 'Downloaden',
    dragTipModal: {
      black: 'ruimte',
      leftKey: 'linkerknop',
      ok: 'Ik heb het',
      press: 'Drukken',
      tips: 'om te slepen en te bekijken.',
    },
    entry: "PDF's",
    fileErrMsg:
      'Bestand lezen is mislukt. Probeer het later opnieuw of neem contact op met ondersteuning.',
    fileMsg: 'Huidige bestandsgrootte:',
    fileMsg2: 'Upload een bestand kleiner dan 300 MB.',
    freeBannerBtn: 'Upgraden',
    freeBannerTips: 'Gratis PDF -vertalingen Limiet bereikt',
    guide: {
      placeholder: 'Plak hier de PDF -link',
      start: 'Begin met vertalen',
      text1: 'Tweetalige lezing',
      text2:
        'Lees tweetaal om de originele tekst en vertaling naast elkaar te vergelijken, waardoor uw efficiëntie wordt verbeterd.',
      text3: 'Chat met je PDF',
      text4:
        'AI-aangedreven analyse en samenvatting. Stel vragen over uw PDF en krijg onmiddellijke antwoorden.',
      text5: 'Nauwkeurig en efficiënt',
      text6: 'AI-aangedreven PDF-vertaling voor meer nauwkeurigheid en efficiëntie.',
      title1: 'Tweetalige vertaling',
      title2: 'Chat met je PDF',
      toast: 'Voer een geldige online PDF -link in. Perdf in .pdf',
    },
    hasTransTask: 'Een vertaaltaak is aan de gang. Wacht alsjeblieft.',
    hoverTrans: 'Hover-to-Translate paragrafen',
    initial: 'Vertaal PDF',
    installText: 'Uw one-stop-oplossing voor dagelijkse vertalingen.',
    installText2: 'Installeer eerst de extensie',
    installTips:
      'Installeer de extensie voor nog meer PDF -vertalingen en soepelere website -vertalingen:',
    kw: 'nietje',
    link: 'link',
    listError:
      "Kan PDF's niet laden. Probeer het later opnieuw of neem contact op met ondersteuning.",
    memoTotalCount: '1 item totaal',
    new: 'Nieuw bestand',
    noId: 'Bestands -ID niet gevonden.',
    notPdf: 'PDF -bestanden alleen.',
    okBtn: 'Verwijderen',
    okBtn2: 'Ik heb het',
    original: 'Origineel',
    parallel: 'Parallel',
    parseErrDialogContent:
      'Deze PDF -link kan niet direct worden vertaald. Download de PDF en upload deze voor vertaling.',
    parseErrTips: 'Kan het bestand niet parseren',
    parseFileToast:
      'Het bestand niet parseren. Probeer het later opnieuw of neem contact op met ondersteuning.',
    pdfLoading: 'Parsing ...',
    searchPlaceholder: 'Zoekopdracht',
    selectTrans: 'Selecteer tekst om te vertalen',
    shareBtn: 'Deel',
    shareBtnText1: 'Genereer Share Link',
    shareBtnText2: 'Update gedeelde inhoud',
    shareModal: {
      cancel: 'Annuleren',
      copy: 'Kopiëren',
      copySucc: 'Link gekopieerd',
      custom: 'Aanpassen:',
      errMsg:
        'De geselecteerde inhoud is nog niet volledig vertaald. Vertaal alstublieft voordat u deelt.',
      gen: 'Deel',
      modify: 'Bewerking',
      notSupport: 'Deze taal wordt momenteel niet ondersteund.',
      page: "Pagina's",
      pageNuErr: 'Voer een geldig paginanummer in.',
      range: 'Domein:',
      remain: 'overig',
      remain1: 'Totale geschatte tijd:',
      search: 'Zoekopdracht',
      settings: 'Instellingen delen:',
      shareSetting: 'Deel instellingen',
      shareText: 'Deel',
      shareTips: 'Deel de vertaalde PDF -link met je vrienden:',
      sourceLang: 'Van:',
      target: 'Naar:',
      text1: 'Deel instellingen',
      text2: 'Genereer Share Link',
      totalPage: '1 pagina totaal',
      trans: 'Vertalen',
      translateTips:
        'Tip: als de vertaling een tijdje duurt, kunt u dit venster sluiten en later delen.',
      translating: 'PDF vertalen ...',
      update: 'Update Share -link',
    },
    shareMsg1: 'Deellink gegenereerd.',
    shareMsg2: 'Gedeelde inhoud bijgewerkt.',
    shareMsg3:
      'Kan Share Link niet genereren. Probeer het later opnieuw of neem contact op met ondersteuning.',
    shareMsg4:
      'Gedeelde inhoud niet bijwerken. Probeer het later opnieuw of neem contact op met ondersteuning.',
    shareTips: 'Uw PDF -vertaling is klaar! Deel het met je vrienden.',
    start: 'Start uw gratis proefperiode',
    thumbnail: 'Inhoudsopgave',
    toast1: 'PDF laden. Probeer het later opnieuw.',
    toast2: 'PDF -inhoud is te groot. Dialoog is momenteel niet ondersteund.',
    toast3: 'Downloaden. Probeer het opnieuw nadat de download is voltooid.',
    toolbar: { Adjust: 'Aanpassen', autoFit: 'Automatisch' },
    trans: 'Vertaling',
    transSucc: 'Vertaling succesvol!',
    unLoginToast: 'Log in om deze functie te gebruiken.',
    uploadBtn: 'Uploaden',
    uploadErr:
      'Bestand upload is mislukt. Probeer het later opnieuw of neem contact op met ondersteuning.',
    uploadErrSignUrl:
      'Niet om URL te uploaden. Probeer het later opnieuw of neem contact op met ondersteuning.',
    uploadMsg1: 'Sleep uw bestand hier.',
    uploadMsg2: 'Ondersteunde bestandstypen: PDF |  Max Bestandsgrootte: 100 MB',
    uploadMsg3: 'Klik om hier bestanden te selecteren of te slepen en neer te zetten.',
    uploading: 'Het uploaden',
    uploadingMsg: 'Uploaden. Wacht alsjeblieft.',
    uploadingMsg1: 'Bestand is uploaden. Probeer het later opnieuw.',
    uploadingMsg2: 'Een bestand wordt momenteel geconverteerd. Probeer het later opnieuw.',
    uploadingMsg3:
      'Conversie voortgangsverzoek is mislukt. Probeer het later opnieuw of neem contact op met ondersteuning.',
    uploadingMsg4:
      'Noam heeft geen toegang tot lokale bestanden. Upload een PDF handmatig voor vertaling.',
    uploadingMsg5:
      'File URL niet ophalen. Probeer het later opnieuw of neem contact op met ondersteuning.',
    video: 'video',
    vipBannerBtn: 'Upgraden',
    vipBannerTips: 'Je hebt je PDF -vertaallimiet voor deze maand bereikt.',
    webTrans: 'Volledige kruisverwijzing',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Ja, NOAM biedt een gratis plan dat u meteen kunt gebruiken.',
      a2: 'We bieden drie lidmaatschapsplannen: Pro, Pro+en Enterprise. U kunt ervoor kiezen om maandelijks of jaarlijks te betalen. Jaarlijkse facturering biedt aanzienlijke besparingen.',
      a3: 'Alle functies zijn beschikbaar op beide plannen. Het belangrijkste verschil is de kosten: jaarlijkse abonnementen kosten minder in het algemeen. Maandelijkse abonnementen worden elke maand gefactureerd, terwijl jaarplannen eenmaal per jaar worden gefactureerd.',
      a4: 'U kunt uw abonnement op elk gewenst moment annuleren in uw accountinstellingen.',
      q1: 'Is er een gratis plan?',
      q2: 'Welke prijsplannen en betalingsopties zijn beschikbaar?',
      q3: 'Wat is het verschil tussen maandelijks abonneren versus jaarlijks?',
      q4: 'Hoe annuleer ik mijn abonnement?',
    },
    around: 'Rondom',
    billedMonthly: 'Maandelijks gefactureerd',
    billedYearly: 'Jaarlijks gefactureerd',
    day: 'dag',
    desc: 'Vergelijk plannen en kies de beste',
    detailedCompare: 'Vergelijk plannen',
    discount: '20% korting',
    enterprise: {
      benefit1: 'Prioriteit klantenondersteuning',
      benefit2: 'Meerdere gebruikers',
      benefit3: 'Oplossingen op maat',
      benefit5: 'Toegewijde accountmanager',
      btn: 'Neem contact met ons op',
      recommendText: 'Aangepaste plannen （≥3 人）',
      talkToUs: 'Contact opnemen',
    },
    free: {
      benefit1: 'Directe vertaling 100 keer/dag',
      benefit2: 'Website vertaling en samenvatting',
      benefit3: '30 Afbeeldingvertalingen',
      benefit4: 'Video vertaling en samenvatting',
      benefit5: '10 PDF -vertalingen',
      benefit6: '5.000 tokens',
      btn: 'Probeer het gratis',
      recommendText: 'Gratis proefversie',
    },
    imageTranslation: 'Afbeeldingvertaling',
    month: 'maand',
    monthly: 'Maandelijks',
    pageTitle: 'Prijzen',
    pdf: {
      chat: "PDF -chatquery's",
      maxPage: "Max. Pagina's per PDF",
      translation: 'PDF -vertaling',
    },
    preMonth: 'Per maand',
    pro: {
      benefit1: 'Prioriteit klantenondersteuning',
      benefit2: '1.000.000 tokens/maand',
      benefit3: 'Website vertaling en samenvatting',
      benefit4: '200 beeldvertalingen',
      benefit5: 'Video vertaling en samenvatting',
      benefit6: '200 PDF -vertalingen',
      btn: 'Aan de slag gaan',
      recommendText: 'Het meest populair',
    },
    proPlus: {
      benefit1: 'Prioriteit klantenondersteuning',
      benefit2: '3.000.000 tokens/maand',
      benefit3: 'Website vertaling en samenvatting',
      benefit4: '500 afbeeldingsvertalingen',
      benefit5: 'Video vertaling en samenvatting',
      benefit6: '500 PDF -vertalingen',
      recommendText: 'Beste waarde',
    },
    video: { summary: 'Video -samenvatting', translation: 'Video vertaling' },
    web: {
      contrastiveTranslation: 'Tweetalige vertaling',
      dictionaryTranslation: 'Selectie vertaling',
      hoverTranslation: 'Zweven vertaling',
      instantTranslation: 'Onmiddellijke vertaling',
      summary: 'Samenvatting van de webpagina',
    },
    yearly: 'Jaarlijks',
  },
  privacy: {
    block1:
      'Noam ("Noam," "Wij", "Our," of "Us") respecteert uw privacy en is toegewijd aan het beschermen van informatie die we van u of over u verkrijgen. Dit privacybeleid beschrijft onze praktijken met betrekking tot het verzamelen of gebruiken van persoonlijke informatie uit uw gebruik van onze website, applicaties en diensten (gezamenlijk de "Services"). Dit privacybeleid is niet van toepassing op de inhoud die we behandelen namens onze zakelijke productklanten, zoals onze API. Het gebruik van deze gegevens is onderworpen aan onze klantovereenkomst, die de toegang tot en het gebruik van deze producten omvat.',
    block10:
      "We implementeren commercieel redelijke technische, administratieve en organisatorische maatregelen om online en offline persoonlijke informatie te beschermen tegen verlies, misbruik en ongeautoriseerde toegang, openbaarmaking, wijziging of vijandige handelingen. Geen enkele internet- of e -mailoverdracht kan echter volledige beveiliging of nauwkeurigheid garanderen. In het bijzonder zijn e -mails die naar ons of van ons zijn verzonden, mogelijk niet veilig. U moet dus bijzonder voorzichtig zijn bij het beslissen welke informatie u ons via de service of e -mail u kunt sturen. Bovendien zijn we niet verantwoordelijk voor elke obstructie van privacy -instellingen of beveiligingsmaatregelen in de beveiligingsinstellingen of beveiligingsmaatregelen van externe websites. We zullen uw persoonlijke gegevens zo lang als nodig voor ons bewaren om u diensten te verlenen of voor andere legitieme zakelijke doeleinden, zoals het oplossen van geschillen, veiligheids- en veiligheidsredenen, of het naleven van onze wettelijke verplichtingen. De tijdsduur die we persoonlijke informatie behouden, hangt af van vele factoren, zoals de hoeveelheid, aard, de gevoeligheid van de informatie, de potentiële risico's van ongeoorloofd gebruik of openbaarmaking, de doeleinden waarvoor we de informatie verwerken en andere wettelijk bindende overwegingen.",
    block11:
      'We kunnen dit privacybeleid van tijd tot tijd bijwerken. Wanneer we dit doen, zullen we een bijgewerkte versie op deze pagina plaatsen, tenzij anders vereist door de toepasselijke wetgeving.',
    block12:
      'Neem contact op met onze klantenservice als u onbeantwoorde vragen of zorgen over dit privacybeleid hebt. E -mail:',
    block2:
      'We verzamelen de volgende persoonlijke informatie ("persoonlijke informatie") met betrekking tot u:',
    block3:
      'Informatie die door u is verstrekt: wanneer u een account aanmaakt om onze services te gebruiken of met ons te communiceren, verzamelen we persoonlijke informatie als volgt:',
    block4:
      'Persoonlijke informatie die automatisch wordt ontvangen van uw gebruik van services: wanneer u de Services toegang krijgt, gebruikt of communiceert, ontvangen wij de volgende informatie over uw toegang, gebruik of interactie ("Technische informatie"):',
    block5: 'We kunnen persoonlijke informatie voor de volgende doeleinden gebruiken:',
    block6:
      'Samenvatting of niet-geïdentificeerde informatie: we kunnen persoonlijke informatie verzamelen of de-identificeren om het hergebruik ervan voor identificatiedoeleinden te voorkomen en dergelijke informatie te gebruiken om de effectiviteit van onze diensten te analyseren, functies te verbeteren en toe te voegen aan onze diensten, onderzoek te doen en voor soortgelijke doeleinden. Bovendien kunnen we het algemene gedrag en de kenmerken van onze servicegebruikers analyseren en geaggregeerde informatie delen met derden, dergelijke geaggregeerde informatie publiceren of het algemeen beschikbaar maken. We kunnen geaggregeerde informatie verzamelen via de Services, via cookies en via andere middelen die in dit privacybeleid worden beschreven. We zullen de-geïdentificeerde informatie in een anonieme of niet-geïdentificeerde vorm onderhouden en gebruiken, en we zullen niet proberen dergelijke informatie opnieuw te identificeren, tenzij wettelijk vereist.',
    block7:
      'In bepaalde gevallen kunnen we uw persoonlijke informatie aan derden verstrekken zonder verdere kennisgeving aan u, tenzij wettelijk vereist:',
    block8:
      'Afhankelijk van uw geografische locatie kunnen individuen in de Europese economische omgeving, het Verenigd Koninkrijk en wereldwijd bepaalde wettelijke rechten hebben met betrekking tot hun persoonlijke informatie. U kunt bijvoorbeeld het recht hebben om:',
    block9:
      'De service kan links bevatten naar andere websites die niet door NOAM worden beheerd of beheerd, inclusief sociale mediadiensten ("Websites van derden"). De informatie die u deelt met websites van derden, wordt beheerst door het specifieke privacybeleid en de servicevoorwaarden van de websites van derden, niet door dit privacybeleid. We bieden deze links voor het gemak en impliceren niet onze goedkeuring of beoordeling van deze websites. Neem rechtstreeks contact op met websites van derden voor informatie over hun privacybeleid en -voorwaarden.',
    subBlock1:
      'Accountinformatie: wanneer u een account op ons platform maakt, verzamelen wij informatie met betrekking tot uw account, inclusief uw naam, contactgegevens, accountgegevens, betaalkaartinformatie en transactiegeschiedenis ("accountinformatie").',
    subBlock10: 'Het bieden, beheren, onderhouden en/of analyseren van diensten;',
    subBlock11: 'Met u communiceren;',
    subBlock12: 'Het ontwikkelen van nieuwe producten en diensten;',
    subBlock13:
      'Het voorkomen van fraude, criminele activiteiten of misbruik van onze diensten en het beschermen van de beveiliging van onze IT -systemen, architectuur en netwerken;',
    subBlock14: 'Het uitvoeren van zakelijke overdrachten;',
    subBlock15:
      'Naleven met wettelijke verplichtingen en gerechtelijke procedures, het beschermen van onze rechten, privacy, beveiliging of eigendom, evenals die van onze gelieerde ondernemingen, u of andere derden.',
    subBlock16:
      'Leveranciers en serviceproviders: om ons te helpen bij het voldoen aan de bedrijfsbehoeften en het uitvoeren van bepaalde services en functies, kunnen we persoonlijke informatie verstrekken aan leveranciers en dienstverleners, waaronder hosting -serviceproviders, cloudserviceproviders en andere informatietechnologie -serviceproviders, e -mailcommunicatiesoftware en webanalyseserviceproviders, enz. Bereidingen zullen toegang, of opslaan van persoonlijke informatie op onze richting terwijl ze hun tanden voor ons uitvoeren.',
    subBlock17:
      'Zakelijke overdrachten: als we strategische transacties, reorganisaties, faillissementen, faillissementsovernames of dienstenovergang naar een andere provider naar een andere provider (gezamenlijk, "transacties") uitvoeren, kunnen uw persoonlijke informatie en andere informatie worden gedeeld met transacties of andere partijen bij de due diligence en overgedragen als een deel van de transacties bij andere assets naar een opvolger van een opeenvolgende transacties.',
    subBlock18:
      "Wettelijke vereisten: we kunnen uw persoonlijke informatie delen met overheidsinstanties, collega's uit de industrie of andere derden, inclusief uw interactie -informatie met onze diensten, (1) indien vereist wettelijk of als we geloven dat dergelijke actie nodig is om te voldoen aan wettelijke verplichtingen; (2) om onze rechten of eigendom te beschermen en te verdedigen; (3) als we eenzijdig bepalen dat er een overtreding is van onze voorwaarden, beleid of wetten; (4) om fraude of andere illegale activiteiten te detecteren of te voorkomen; (5) om onze producten, werknemers of gebruikers, of openbare veiligheid, beveiliging, integriteit te beschermen; of (vi) om te verdedigen tegen wettelijke aansprakelijkheid.",
    subBlock19:
      'Toegang tot uw persoonlijke informatie en informatie over hoe deze wordt verwerkt.',
    subBlock2:
      'Gebruikersinhoud: wanneer u onze services gebruikt, verzamelen we persoonlijke informatie uit uw invoer, bestandsuploads of feedback die aan ons wordt gegeven ("Content").',
    subBlock20: 'Vraag het verwijderen van uw persoonlijke informatie uit onze gegevens aan.',
    subBlock21: 'Corrigeer of werk uw persoonlijke informatie bij.',
    subBlock22: 'Breng uw persoonlijke informatie over aan een derde partij (datadraagbaarheid).',
    subBlock23: 'Beperk hoe we uw persoonlijke informatie verwerken.',
    subBlock24:
      'Intrekking van uw toestemming - waar we vertrouwen op toestemming als wettelijke basis voor verwerking, kunt u uw toestemming op elk gewenst moment intrekken.',
    subBlock25: 'Bewerkt hoe we uw persoonlijke informatie verwerken.',
    subBlock26: 'Verbetering van onze diensten en het uitvoeren van onderzoek;',
    subBlock3:
      'Communicatie -informatie: als u ons berichten verzendt, verzamelen we uw naam, contactgegevens en de inhoud van uw berichten ("communicatie -informatie").',
    subBlock4:
      "Informatie over sociale media: we hebben pagina's op websites op sociale media zoals Instagram, Facebook, Medium, Twitter, YouTube en LinkedIn. Wanneer u interactie heeft met onze sociale media -pagina's, verzamelen we de persoonlijke informatie die u ons wilt verstrekken, zoals uw contactgegevens (\"sociale informatie\"). Bovendien kunnen de bedrijven die onze sociale media -pagina's organiseren ons geaggregeerde informatie en analyse geven over onze sociale media -activiteiten.",
    subBlock5:
      'Loggegevens: informatie automatisch verzonden door uw browser bij gebruik van onze services. Loggegevens omvatten uw Internet Protocol (IP) -adres, browsertype en instellingen, de datum en tijd van uw verzoeken en hoe u omgaat met onze website.',
    subBlock6:
      'Gebruiksgegevens: we kunnen automatisch informatie verzamelen over uw gebruik van de service, zoals de soorten inhoud die u bekijkt of communiceert, de functies die u gebruikt, de acties die u onderneemt, evenals uw tijdzone, land, datum en tijd van toegang, gebruikersagent en versie, computer- of mobiel apparaattype en uw computerverbinding.',
    subBlock7:
      'Apparaatinformatie: dit omvat de naam van het apparaat, het besturingssysteem, de apparaat -ID en de browser die u gebruikt. De verzamelde informatie kan afhangen van het type apparaat dat u gebruikt en de instellingen ervan.',
    subBlock8:
      'Cookies: we gebruiken cookies om onze services te bedienen en te beheren en uw ervaring te verbeteren. Een "cookie" is informatie verzonden door een website die u aan uw browser bezoekt. U kunt uw browser instellen om alle cookies te accepteren, alle cookies af te wijzen of u op de hoogte te stellen wanneer een cookie wordt verstrekt, zodat u kunt beslissen of u deze elke keer wilt accepteren. Het weigeren van cookies kan echter voorkomen dat u het display of de functionaliteit van bepaalde gebieden of kenmerken van de website gebruikt of negatief beïnvloedt. Ga voor meer informatie over cookies naar alles over cookies.',
    subBlock9:
      'Analytics: we kunnen verschillende online analyseproducten gebruiken die cookies gebruiken om ons te helpen analyseren hoe gebruikers onze services gebruiken en uw ervaring verbeteren bij het gebruik van de services.',
    subTitle1: 'Persoonlijke informatie die we verzamelen',
    subTitle2: 'Hoe we persoonlijke informatie gebruiken',
    subTitle3: 'Openbaarmaking van persoonlijke informatie',
    subTitle4: 'Uw rechten',
    subTitle5: 'Links naar andere websites',
    subTitle6: 'Beveiliging en behoud van informatie',
    subTitle7: 'Wijziging van het privacybeleid',
    subTitle8: 'Hoe u contact met ons kunt opnemen',
    title: 'Privacybeleid',
    updateTime: 'Bijgewerkt: 15 maart 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Accepteer alles',
    text: 'Om uw ervaring te verbeteren, gebruiken we cookies om inhoud en diensten te personaliseren.',
    title: 'Deze website maakt gebruik van cookies',
  },
  slogen: 'De eenvoudigste AI -assistent',
  stripe: {
    contact: 'Neem contact met ons op via contact@noam.tools met eventuele vragen!',
    fail: {
      btn: 'Opnieuw proberen',
      text1: 'We konden uw betaling niet verwerken.',
      text2: 'Controleer uw verbinding en probeer het opnieuw.',
      title: 'Betaling mislukt',
    },
    succ: {
      btn: 'Laten we gaan',
      text1: 'Welkom bij Noam,',
      text2: 'Laten we beginnen!',
      title: 'Gefeliciteerd',
    },
  },
  terms: {
    block1: 'Bedankt voor het gebruik van NOAM!',
    block10:
      '(a) Kosten en facturering. U betaalt alle vergoedingen ("vergoedingen") volgens de prijzen en voorwaarden op de toepasselijke prijspagina of zoals schriftelijk overeengekomen tussen ons. We behouden ons het recht voor om prijsfouten of fouten te corrigeren, zelfs als we facturen hebben uitgegeven of betaling hebben ontvangen. U verstrekt volledige en nauwkeurige factureringsinformatie, inclusief een geldige en geautoriseerde betaalmethode. We zullen uw betaalmethode periodieke basis in rekening brengen zoals overeengekomen, maar we kunnen de factureringsdatum redelijkerwijs wijzigen. U machtigt NOAM, zijn gelieerde ondernemingen en betalingsverwerkers van derden om uw betaalmethode in rekening te brengen. Als uw betaling mislukt, zullen we u een schriftelijke kennisgeving verstrekken en kunnen we de toegang tot de service opschorten totdat de betaling is ontvangen. Behalve zoals anders gespecificeerd in deze Overeenkomst, kunnen betalingen niet worden gerestitueerd.',
    block11:
      '(b) belastingen. Tenzij anders vermeld, omvatten kosten geen federale, nationale, lokale en buitenlandse belastingen, rechten en soortgelijke beoordelingen ("belastingen"). U bent verantwoordelijk voor alle belastingen in verband met uw aankopen, exclusief belastingen op basis van ons nettoresultaat waarvoor we u zullen factureren. U stemt ermee in om dergelijke belastingen onmiddellijk te betalen en ons juridisch geldige privacyverklaringen te verstrekken en de nodige toestemming te verkrijgen voor het verwerken van dergelijke gegevens, en u garandeert ons dat u dergelijke gegevens behandelt in overeenstemming met de toepasselijke wetgeving.',
    block12:
      '(c) Prijsveranderingen. We kunnen onze prijzen wijzigen door u op de hoogte te stellen via uw account en/of onze website. Prijsverhogingen zullen onmiddellijk van kracht zijn bij het plaatsen. Alle prijswijzigingen zijn van toepassing op vergoedingen voor uw account na de ingangsdatum van de wijziging.',
    block13:
      '(d) Geschillen en achterstallige betalingen. Als u vergoedingen of belastingen wilt betwisten, neem dan binnen dertig (30) dagen na de factuurdatum contact op met contact@noam.tools.',
    block14:
      '(e) gratis gebruik. U mag geen meerdere accounts maken om gratis credits te verkrijgen. Als we bepalen dat u de gratis credits te goeder trouw niet gebruikt, kunnen we u standaardkosten in rekening brengen of stoppen met toegang tot de service.',
    block15:
      '(a) Vertrouwelijkheid. Mogelijk heeft u toegang tot vertrouwelijke informatie van NOAM. U mag alleen vertrouwelijke informatie gebruiken zoals toegestaan ​​onder deze voorwaarden om de service te gebruiken. U mag geen vertrouwelijke informatie onthullen aan een derde partij, en u beschermt vertrouwelijke informatie op een manier die niet minder beschermend is dan u uw eigen vergelijkbare vertrouwelijke informatie beschermt, althans met redelijke zorg. Vertrouwelijke informatie is niet-openbare informatie die door NOAM als vertrouwelijk als vertrouwelijk is aangeduid of die redelijkerwijs onder de omstandigheden als vertrouwelijk moet worden behandeld, inclusief software, specificaties en andere niet-openbare zakelijke informatie. Vertrouwelijke informatie bevat geen informatie die: (1) in het algemeen zonder fout beschikbaar is voor het publiek; (2) u had in uw bezit zonder enige vertrouwelijkheidsperiode voordat u het onder deze voorwaarden ontving; (3) wordt u rechtmatig bekendgemaakt door een derde partij zonder enige vertrouwelijkheidsverplichtingen; of (4) is onafhankelijk door u ontwikkeld zonder de vertrouwelijke informatie te gebruiken. U kunt vertrouwelijke informatie openbaar maken indien vereist bij de wet, de rechtbank of andere overheidsbevel, maar u moet NOAM vooraf op een redelijke manier aan NOAM verstrekken en, voor zover mogelijk, redelijke inspanningen leveren om de reikwijdte van openbaarmaking te beperken, inclusief het helpen van ons bij tegengestelde openbaarmakingsverzoeken.',
    block16:
      '(b) Beveiliging. U moet redelijke en passende maatregelen implementeren om uw toegang tot en het gebruik van de service te beschermen. Als u kwetsbaarheden of overtredingen met betrekking tot uw gebruik van de service ontdekt, moet u onmiddellijk contact opnemen met NOAM en details geven over de kwetsbaarheid of overtreding.',
    block17:
      '((c) Verwerking van persoonlijke gegevens. Als u de service gebruikt om persoonlijke gegevens te verwerken, moet u wettelijk voldoende privacyverklaringen verstrekken en de nodige toestemming verkrijgen voor het verwerken van dergelijke gegevens, en u garandeert ons dat u dergelijke gegevens behandelt in overeenstemming met de toepasselijke wetgeving.',
    block18:
      '(a) beëindiging; Oponthoud. Deze voorwaarden zullen van kracht zijn op basis van uw eerste gebruik van de service en blijven van kracht totdat ze worden beëindigd. U kunt deze voorwaarden om welke reden dan ook op elk gewenst moment beëindigen door het gebruik van de service en inhoud te staken. We kunnen deze voorwaarden om welke reden dan ook beëindigen. We kunnen deze voorwaarden onmiddellijk beëindigen door u op de hoogte te stellen als u materieel sectie 2 (gebruikseisen), sectie 5 (vertrouwelijkheid, beveiliging en gegevensbescherming), sectie 8 (geschillenbeslechting) of sectie 9 (algemene voorwaarden), of als u wezenlijk overtreedt, of als onze relatie met een technologieleverancier van derden buiten onze controlewijzigingen, of voor de naleving van de wet- of overheidsverzoeken. We kunnen uw toegang tot de service opschorten als u zich niet aan deze voorwaarden houdt, of als uw gebruik een beveiligingsrisico vormt voor ons of een derde partij, of als we vermoeden dat uw gebruik frauduleus is of ons of een derde partij kan blootstellen aan aansprakelijkheid.',
    block19:
      '(b) Effecten van beëindiging. Na beëindiging stopt u met het gebruik van de service en retourneert of vernietigt u onmiddellijk alle vertrouwelijke informatie zoals voorgeschreven door ons. De bepalingen van deze voorwaarden die door hun aard beëindiging of vervaldatum moeten overleven, inclusief maar niet beperkt tot secties 3 en 5-9, zullen overleven.',
    block2:
      'Deze gebruiksvoorwaarden zijn van toepassing op uw gebruik van de services van NOAM, inclusief onze applicatieprogrammeerinterfaces, software, tools, ontwikkelaarsdiensten, gegevens, documentatie en website (gezamenlijk de "Services" genoemd). Door onze services te gebruiken, stemt u ermee in zich aan deze voorwaarden te houden. Ons privacybeleid legt uit hoe we persoonlijke informatie verzamelen en gebruiken.',
    block20:
      '(a) schadevergoeding. U zult ons, onze gelieerde ondernemingen en ons personeel, onschadelijk van en tegen eventuele claims, verliezen en uitgaven (inclusief advocatenhonoraria) verdedigen, vrijwaren en vasthouden, en die voortvloeien uit of gerelateerd aan uw gebruik van de Service, inclusief uw inhoud, uw gebruik van producten of diensten met betrekking tot de Service en uw schending van deze voorwaarden of toepasselijke wetgeving.',
    block21:
      '(b) Disclaimers. De service wordt verleend "zoals het is." Voor zover wettelijk toegestaan, geven wij en onze gelieerde ondernemingen en licentiegevers geen verklaringen of garanties van welke aard dan ook en geven geen garanties, inclusief maar niet beperkt tot garanties van verkoopbaarheid, fitness voor een bepaald doel, bevredigende kwaliteit, niet-inbreuk en rustig geniet en rustig genieten, en eventuele garanties die zich voordoen uit de handel of gebruik van handel. We garanderen niet dat de service ononderbroken, nauwkeurig of foutloos zal zijn, of dat eventuele inhoud veilig is, niet verloren of niet gewijzigd is of niet gewijzigd.',
    block22:
      '(c) Beperking van aansprakelijkheid. Noch wij, onze gelieerde ondernemingen, noch onze licentiegevers zullen aansprakelijk zijn voor indirecte, incidentele, speciale, consequente of punitieve schade, inclusief winstverlies, reputatieverlies, gebruiksverlies, gegevensverlies of andere immateriële verliezen, zelfs als we zijn geadviseerd van de mogelijkheid van dergelijke schade. Volgens deze voorwaarden zal onze totale aansprakelijkheid niet hoger zijn dan de kosten die u aan de service hebt betaald in de twaalf (12) maanden voorafgaand aan de claim of honderd Amerikaanse dollars ($ 100), afhankelijk van welke groter is. De beperkingen in deze sectie zijn van toepassing in de maximale mate toegestaan ​​door de toepasselijke wetgeving.',
    block23:
      'De voorwaarden en uw gebruik van de diensten worden beheerst door de wetten van de Verenigde Staten, behalve voor het Amerikaanse conflict van wetten. Uw gebruik van de aanvraag kan ook onderworpen zijn aan andere lokale, nationale, nationale of internationale wetten. Alle juridische stappen of procedure met betrekking tot deze voorwaarden zullen uitsluitend worden ingesteld voor de rechtbanken van de Verenigde Staten, en u stemt ermee in zich te onderwerpen aan de persoonlijke jurisdictie van dergelijke rechtbanken.',
    block24:
      '(a) Relatie van de partijen. Noam en u zijn onafhankelijke aannemers, en geen van beide partijen heeft de bevoegdheid om de andere partij te vertegenwoordigen of te binden of verplichtingen voor de andere partij te nemen zonder de voorafgaande schriftelijke toestemming van de andere partij.',
    block25:
      "(b) Merkgebruik. U mag de naam, logo's of handelsmerken van NOAM of een van de gelieerde ondernemingen niet gebruiken zonder onze voorafgaande schriftelijke toestemming.",
    block26:
      'Als een bepaling van deze voorwaarden als ongeldig, illegaal of niet -afdwingbaar wordt vastgesteld, blijven de resterende bepalingen volledig van kracht en effect.',
    block3:
      'Als u jonger bent dan 18 jaar, moet u toestemming krijgen van uw ouders of wettelijke voogden om de service te gebruiken. Als u de Service namens een andere persoon of entiteit gebruikt, moet u er gemachtigd zijn om deze voorwaarden namens hen te accepteren. U moet nauwkeurige en volledige informatie verstrekken om zich te registreren voor een account. U mag uw toegangsreferenties of -account niet aan iedereen buiten uw organisatie verstrekken en u bent verantwoordelijk voor alle activiteiten die worden uitgevoerd met uw referenties.',
    block4:
      '(a) Gebruik van service. U kunt toegang krijgen tot de service onder deze voorwaarden en wij geven u een niet-exclusieve recht om de service te gebruiken. Wanneer u de service gebruikt, houdt u zich aan deze voorwaarden en alle toepasselijke wetten. Wij en onze gelieerde ondernemingen bezitten alle rechten, titels en belangen in de dienst.',
    block5:
      '(b) Feedback. We verwelkomen feedback, opmerkingen, ideeën, suggesties en verbeteringen. Als u dergelijke inhoud verstrekt, kunnen we het zonder enige beperkingen gebruiken en zonder compensatie voor u.',
    block6:
      '(c) Beperkingen. U mag niet: (1) de service gebruiken op een manier die inbreuk maakt op, misbruikt of schendt van anderen; (2) reverse engineer, decompileren, decompileren, demonteren, vertalen of anderszins proberen de broncode of onderliggende componenten van de servicemodellen, algoritmen en systemen te ontdekken (tenzij dergelijke beperkingen verboden zijn door de toepasselijke wetgeving); (3) gebruik de output van de service om modellen te ontwikkelen die concurreren met NOAM; (4) Gegevens of uitvoer uit de service extraheren met behulp van geautomatiseerde of programmeermethoden, tenzij toegestaan ​​via de API, inclusief schrapen, weboogst of web data -extractie; (5) de output van de service weergeven als kunstmatig gegenereerd wanneer dit niet is, of anderszins overtreden van ons gebruiksbeleid; (6) API -toetsen kopen, verkopen of overbrengen zonder onze eerdere toestemming; of (7) ons persoonlijke informatie verstrekken van kinderen jonger dan 13 jaar of toepasselijke leeftijd van digitale toestemming. U zult voldoen aan alle snelheidslimieten en andere vereisten in onze documentatie.',
    block7:
      '(D) Diensten van derden. Software, services of andere producten van derden die aan de service zijn gekoppeld, worden beheerst door hun eigen voorwaarden en we zijn niet verantwoordelijk voor producten van derden.',
    block8:
      '(a) Uw inhoud. U kunt input ("invoer") aan de service verstrekken en uitvoer ("uitvoer") gegenereerd en geretourneerd op basis van de invoer (gezamenlijk aangeduid als "inhoud"). Tussen de partijen, en voor zover toegestaan ​​door de toepasselijke wetgeving, bezit u alle input. NOAM kan inhoud gebruiken om de service te bieden en te onderhouden, de toepasselijke wetten te houden en ons beleid te handhaven. U bent verantwoordelijk voor de inhoud, inclusief ervoor zorgen dat het geen toepasselijke wetten of deze voorwaarden overtreedt.',
    block9:
      '(b) Nauwkeurigheid. Kunstmatige intelligentie en machine learning zijn snel evoluerende onderzoeksgebieden. We streven er voortdurend naar om onze service te verbeteren om deze nauwkeuriger, betrouwbaarder, veilig en nuttiger te maken. Gezien de probabilistische aard van machine learning, kan het gebruik van onze service in bepaalde gevallen leiden tot onjuiste output, die mogelijk geen nauwkeurig weerspiegelen van echte individuen, plaatsen of feiten. U moet de nauwkeurigheid van elke output op de juiste manier beoordelen op basis van uw use case, inclusief door de uitvoer handmatig te beoordelen.',
    subTitle1: 'Registratie en toegang',
    subTitle10: 'Het ontnemen',
    subTitle2: 'Gebruikseisen',
    subTitle3: 'Inhoud',
    subTitle4: 'Vergoedingen en betaling',
    subTitle5: 'Vertrouwelijkheid, beveiliging en gegevensbescherming',
    subTitle6: 'Term en beëindiging',
    subTitle7: 'Schadevergoeding; Disclaimers; Beperking van aansprakelijkheid',
    subTitle8: 'Richtingsrecht en jurisdictie',
    subTitle9: 'Algemene voorwaarden',
    title: 'Voorwaarden van services',
    updateTime: 'Bijgewerkt: 15 maart 2024',
  },
  trialModal: {
    btn: 'Ga nu aan de slag （Slechts $ 0,99）',
    chat: 'Chatpdf',
    desc1: 'Na uw proces wordt u een jaarlijkse vergoeding van $ 180 in rekening gebracht',
    desc2: 'Annuleer altijd',
    focus: 'Focus',
    hover: 'Zweven vertaling',
    image: 'Afbeeldingvertaling',
    installBtn: 'Installeer nu om uw cadeau te ontvangen',
    memo: 'Favorieten management',
    pdf: 'PDF parallelle vertaling',
    realtime: 'Onmiddellijke vertaling',
    select: 'Vertaling van woordselectie',
    title: 'Welkomcadeau',
    trialText: '7-daagse proef',
    video: 'Video vertaling',
    videoSummary: 'Video -samenvatting',
    web: 'Parallelle vertaling',
    webSummary: 'Samenvatting van het web',
  },
  upgradeModal: {
    btn: 'Upgrade nu',
    chat: {
      free: 'Je hebt de CHATPDF -limiet bereikt',
      pro: 'Je hebt de chatpdf -limiet bereikt voor deze maand',
    },
    contactStr: 'Wacht op de volgende reset of neem contact met ons op via contact@noam.tools.',
    limitAlertStr: 'Je hebt je gratis planlimiet bereikt. Upgrade om door te gaan.',
    more: 'Bekijk plannen',
    pageLimit: { free: 'Je hebt de pdf -paginalimiet bereikt' },
    title: 'Herinnering',
    upgradeAlertStr: 'Je hebt je gratis planlimiet bereikt. Upgrade om door te gaan.',
    upload: {
      free: 'Je hebt je PDF -vertaallimiet bereikt',
      pro: 'Je hebt je PDF -vertaallimiet bereikt voor deze maand',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Doe nu mee',
      label: 'Nieuwe gebruikersvoordelen:',
      remain: 'Nog maar 30 over',
      text: 'Slechts 100 plekken beschikbaar! Pak de jouwe nu!',
    },
  },
}

export default TRANSLATION
