import { commonUtils } from './commonUtils'

export const getOemHeaders = () => {
  const { oemToken, oemUid } = commonUtils.getUrlParams()
  return {
    'X-Extension-Type': 'noam',
    'x-oem-token': oemToken || '',
    'x-oem-uid': oemUid || '',
  }
}
