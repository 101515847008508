const TRANSLATION = {
  buyModal: {
    afterFree: 'Después de su prueba gratuita',
    btn: 'Pruébalo gratis',
    freeTrial: 'Prueba gratuita de 3 días',
    monthLabel: 'mes',
    selectLabel: 'Seleccione un plan',
    title: 'Plan de suscripción',
    yearLabel: 'año',
  },
  contact: {
    block1:
      'Para personalización, preguntas o sugerencias, contáctenos en contact@noam.tools. Responderemos de inmediato.',
    block2: 'Apoyarnos calificándonos en la tienda de extensión de Google Apps en ★★★★★.',
    block3: 'Ayuda a otros compartiendo esta extensión:',
    title: 'Contáctenos',
  },
  help: {
    AquickOverviewOfNoam: 'Una visión general rápida de Noam',
    ChatPDF: 'Chat pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Gestión favorita',
    Fixedplugin: 'Complemento fijo',
    Focusing: 'Enfoque',
    Free: 'Gratis',
    FreeTrialBenefits: 'Beneficios de prueba gratuitos',
    GoWithWikipedia: 'Ir con wikipedia',
    GoWithYouTube: 'Ve con YouTube',
    HoverTranslation: 'Traducción de flotación',
    Howtouse: 'Cómo usar',
    ImageTranslation: 'Traducción de imágenes',
    InstantTranslation: 'Traducción instantánea',
    Membership: 'Afiliación',
    NoamHelp: 'Noam te ayuda a traducir y resumir',
    PDFparallelTranslation: 'Traducción paralela en PDF',
    ParallelTranslation: 'Traducción paralela',
    SkipAllSteps: 'Omitir todos los pasos',
    Summary: 'Resumen',
    Translation: 'Traducción',
    TryItForFree: 'Pruébalo gratis',
    Video: 'Video',
    VideoSummary: 'Resumen de video',
    VideoTranslation: 'Traducción de video',
    WebSummary: 'Resumen web',
    WordSelectionTranslation: 'Traducción de selección de palabras',
    benefits: '¡Tenemos beneficios esperándote!',
    dayFree: 'Prueba gratuita de 3 días',
    done: 'Hecho',
    next: 'Próximo',
    pin: 'Pin Noam para un acceso más fácil ～～',
    planIsNotExist: 'El plan no está disponible',
    startWiki: '¡Comencemos con Wikimedia!',
    startYoutube: '¡Comencemos con YouTube!',
    try: 'Pruébalo',
    tryNow: '¡Pruébalo ahora!',
  },
  home: {
    PDFTranslate: {
      desc: 'Traducción bilingüe + chat pdf',
      sub1: {
        desc: 'La lectura bilingüe le permite comparar el texto original y la traducción lado a lado, mejorando la eficiencia de lectura.',
        title: 'Lectura bilingüe',
      },
      sub2: {
        desc: 'AI analiza y resume, y puede responder sus preguntas basadas en el contenido de PDF.',
        title: 'Chat pdf',
      },
      sub3: {
        desc: 'Traducción PDF con AI para una mayor precisión y eficiencia.',
        title: 'Precisión y eficiencia',
      },
      title: 'PDF Traducir',
    },
    aiAssistant: {
      desc: 'Hacer la traducción web más inteligente y más conveniente',
      panel1: {
        desc: 'Las traducciones de referencia cruzada bilingüe lo ayudan a comparar el texto original con la traducción para que pueda comprender mejor y mejorar su eficiencia de lectura.',
        title: 'Traducción bilingüe',
      },
      panel2: {
        left: {
          desc: 'Al navegar por una página web, pase el contenido que necesita traducir para ver la traducción.',
          title: 'Traducción de flotación',
        },
        right: {
          desc: 'Al navegar por una página web, seleccione el texto que desea traducir para ver la traducción.',
          title: 'Traducción de selección de texto',
        },
      },
      panel3: {
        one: {
          desc: '¡Traducir y resumir las páginas web y el contenido de video en cualquier momento, en cualquier lugar, para una experiencia más suave!',
          title: 'Señalar y hacer clic',
        },
        three: {
          desc: 'El modelo de lenguaje grande impulsado por IA garantiza la precisión de la traducción y la consistencia para una experiencia de lectura mejorada.',
          title: 'Ai impulsado',
        },
        two: {
          desc: 'Cobertura completa de varios idiomas, lectura sin barreras bilingües, traducción de un solo clic-¡Experimenta la diferencia!',
          title: 'Más completo y conveniente',
        },
      },
      panel4: {
        tab1: {
          desc: 'Su experto en eficiencia: usando ChatGPT, Noam traduce y resume el contenido de video de YouTube para ahorrarle tiempo.',
          key: 'Video',
          title: 'Traducción de video y resumen',
        },
        tab2: {
          desc: 'Haga clic para traducir imágenes mientras navega por la web.',
          key: 'Imagen',
          title: 'Traducción de la imagen',
        },
        tab3: {
          desc: 'Extrae instantáneamente información clave de las páginas web y proporciona un resumen, mejorando su eficiencia de lectura.',
          key: 'Web',
          title: 'Traducción web y resumen',
        },
        tag1: 'Simple',
        tag2: 'Eficiente',
        tag3: 'Fácil de entender',
      },
      title: 'Asistente de IA',
    },
    getStart: 'Empezar',
    getStartedForFree: 'Empiece gratis',
    howItWork: {
      desc: 'Noam integra modelos AI para proporcionar a los usuarios una experiencia inteligente para la traducción y resumen de las páginas web en forma de idioma, la traducción de PDF y las imágenes, la traducción y el resumen de videos',
      title: 'Cómo funciona',
    },
    lastBlock: { desc: 'Mejor traducción de IA para una experiencia de lectura más suave' },
    top: {
      desc: 'Noam es una extensión gratuita del navegador de IA que te ayuda a traducir y resumir páginas web, videos y contenido de PDF. \\ N ¡Únete a Noam y experimenta la magia!',
      title1: 'One Magic',
      title2: 'Un solo clic',
    },
    userReviews: {
      desc: 'Reseñas de cinco estrellas en la tienda web de Chrome',
      title: 'Revisiones de usuarios',
    },
  },
  installTips: {
    btn: 'Pruébalo ahora',
    text: '¡Instale el "complemento del navegador Noam" para desbloquear más funciones! Referencias transversales web, \\ n resumen de video web, ¡y más!',
  },
  locale: {
    af: 'africaans',
    am: 'Amárico',
    ar: 'árabe',
    as: 'Assamese',
    auto: 'Detectar automáticamente',
    az: 'Azerbaiyano',
    ba: 'Brashkir',
    bg: 'búlgaro',
    bn: 'Bangla',
    bo: 'Tibetano',
    bs: 'bosnio',
    ca: 'catalán',
    cs: 'checo',
    cy: 'galés',
    da: 'danés',
    de: 'Alemán',
    dsb: 'Sorbio inferior',
    dv: 'Divehi',
    el: 'Griego',
    en: 'Inglés',
    es: 'Español',
    et: 'Estonio',
    eu: 'vasco',
    fa: 'persa',
    fi: 'finlandés',
    fil: 'Filipino',
    fj: 'Fiyiano',
    fr: 'Francés',
    frCA: 'Francés (Canadá)',
    ga: 'irlandés',
    gl: 'gallego',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebreo',
    hi: 'hindi',
    hr: 'croata',
    hsb: 'Sorbio superior',
    ht: 'Criollo haitiano',
    hu: 'húngaro',
    hy: 'armenio',
    id: 'indonesio',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'islandés',
    it: 'italiano',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (latín)',
    ja: 'japonés',
    ka: 'georgiano',
    kk: 'Kazáceo',
    km: 'Jemer',
    kmr: 'Kurdo (norte)',
    kn: 'Kannada',
    ko: 'coreano',
    ku: 'Kurdo (central)',
    ky: 'Kirguistán',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'lituano',
    lug: 'Ganda',
    lv: 'letón',
    lzh: 'Chino (literario)',
    mai: 'Maithili',
    mg: 'madagascarí',
    mi: 'Maorí',
    mk: 'macedónio',
    ml: 'Malayalam',
    mnCyrl: 'Mongol (cirílico)',
    mnMong: 'Mongol (tradicional)',
    mr: 'Marathi',
    ms: 'malayo',
    mt: 'maltés',
    mww: 'Hmong daw',
    my: 'Myanmar (birmano)',
    nb: 'noruego',
    ne: 'Nepalí',
    nl: 'Holandés',
    noSearchLang: 'Lenguaje no compatible',
    nso: 'Sesotho sa leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro otomi',
    pa: 'punjabi',
    pl: 'Polaco',
    placeholder: 'Buscar',
    prs: 'Dari',
    ps: 'Pashto',
    pt: 'Portugués (Brasil)',
    ptPT: 'Portugués (Portugal)',
    ro: 'rumano',
    ru: 'ruso',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'eslovaco',
    sl: 'esloveno',
    sm: 'Samoo',
    sn: 'Shona',
    so: 'somalí',
    sq: 'albanés',
    srCyrl: 'Serbio (cirílico)',
    srLatn: 'Serbio (latín)',
    st: 'Sesotho',
    sv: 'sueco',
    sw: 'swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'tailandés',
    ti: 'Tigrinya',
    tk: 'Turco',
    tlhLatn: 'Klingon (latín)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongano',
    tr: 'turco',
    tt: 'Tártaro',
    ty: 'Tahitiano',
    ug: 'Uigur',
    uk: 'ucranio',
    ur: 'Urdu',
    uz: 'Uzbek (latín)',
    vi: 'vietnamita',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Cantonés (tradicional)',
    zh: 'Chino',
    zhHans: 'Chino simplificado',
    zhHant: 'Tradicional chino',
    zu: 'zulú',
  },
  login: {
    and: 'y',
    btn: 'Inicie sesión con Google',
    btn1: 'Inicie sesión ahora',
    desc: 'Inicie sesión para desbloquear más experiencias',
    policy: 'política de privacidad',
    terms: 'Condiciones de uso',
    tips: 'Al registrarse, estoy de acuerdo con el',
    title: 'Bienvenido a Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Seguir', twitter: 'Gorjeo' },
    legal: { policy: 'política de privacidad', terms: 'Términos de servicio', title: 'Legal' },
    product: {
      image: 'Traducción de imágenes',
      pdf: 'Traducción a PDF',
      title: 'Productos',
      video: 'Traducción de video y resumen',
      web: 'Traducción web y resumen',
    },
    resources: { title: 'Recursos' },
  },
  pageHeader: {
    contact: 'Contacto',
    help: 'Ayuda',
    logout: 'Finalizar la sesión',
    logoutSucc: 'Desconectado.',
    pricing: 'Fijación de precios',
    settings: 'Ajustes',
    signUp: 'Acceso',
  },
  pdf: {
    autoDetect: 'Detectar automáticamente',
    cancelBtn: 'Cancelar',
    cancelUpload: 'Cancelar',
    chatPdf: {
      summaryError: 'Resumen fallido, inténtelo de nuevo más tarde',
      chatError: 'El chat falló, inténtelo de nuevo más tarde',
      Search: 'Buscar',
      cancel: 'Cancelar',
      chatTitle: '¡Bienvenido a Noam! Aquí hay un resumen de su PDF:',
      clear: 'Claro',
      clearContent:
        'Esto comenzará una nueva conversación. La conversación actual no se guardará. ¿Está seguro?',
      clearDialogTitle: 'Por favor confirmar',
      clearSucc: 'Despejado',
      copy: 'Copiar',
      copySucc: 'Copiado',
      delete: 'Borrar',
      deleteCancel: 'Cancelar',
      deleteContent: 'Este PDF no se puede recuperar una vez que se elimine. ¿Está seguro?',
      deleteOk: 'Borrar',
      deleteSuccess: 'Eliminado',
      deleteTitle: 'Confirmar eliminar',
      expand: 'Colapsar',
      historyTitle: 'Historia',
      listError:
        'No se pudo cargar la lista de lectura. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
      loading: 'Cargando...',
      noDataDesc: 'No hay historia todavía.',
      noMore: 'No más contenido.',
      placeholder: 'Pregunte cualquier cosa sobre este pdf',
      summaryTitle: 'Es posible que le interese estas preguntas:',
    },
    collectionEmpty: 'Tu colección está vacía.',
    collectionTab: 'Notas',
    dataLoading: 'Cargando...',
    dialogContent: '¿Estás seguro de que quieres eliminar esto?',
    dialogTitle: 'Por favor confirmar',
    download: {
      both: 'Bilingüe',
      btn: 'Ahorrar',
      cancel:
        'Cerrar esta ventana cancelará la descarga de PDF. ¿Estás seguro de que quieres cancelar?',
      desc: 'Guardar en el dispositivo: descarga las traducciones como imágenes; Guardar e imprimir: descarga las traducciones en el formato original, con soporte de copia.',
      download: 'Descargar',
      downloadMsg1: 'Progreso de traducción en PDF:',
      downloadMsg2: 'Tiempo estimado restante:',
      downloadSucc: '¡PDF está listo! Haga clic para guardar.',
      downloadTip: 'Descargar PDF. No cierre esta ventana para evitar errores de descarga.',
      error: 'No se pudo salvar. Intente imprimir en su lugar.',
      fileName: 'Nombre del archivo:',
      fileType: 'Tipo de archivo:',
      loading: 'Ahorro...',
      loading2: 'Cargando...',
      minute: 'Minutos...',
      msg1: 'Algunas traducciones en este documento son más largas que el texto original, y pueden superponerse en el archivo descargado. Recomendamos leer en la página web.',
      msg2: '1. La traducción aún no está completa. Descargue después de finalizar la traducción.',
      msg3: '2. Algunas traducciones son demasiado largas. Lea la página web para evitar la superposición de texto.',
      msg4: 'Las tareas de traducción están en progreso. Vuelva a intentarlo después de que se completen.',
      progress:
        'Traducir PDF. Progress: {{progreso}}, tiempo estimado restante: {{minutos}} minutos ...',
      save: 'Guardar en el dispositivo',
      savePrint: 'Guardar e imprimir',
      success: 'Descargado',
      trans: 'Solo traducción',
      transContinue: 'Continuar traduciendo',
    },
    downloadBtn: 'Descargar',
    dragTipModal: {
      black: 'espacio',
      leftKey: 'botón izquierdo',
      ok: 'Entiendo',
      press: 'Prensa',
      tips: 'para arrastrar y ver.',
    },
    entry: 'Pdfs',
    fileErrMsg:
      'La lectura de archivos falló. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    fileMsg: 'Tamaño del archivo actual:',
    fileMsg2: 'Sube un archivo más pequeño de 300 MB.',
    freeBannerBtn: 'Mejora',
    freeBannerTips: 'Límite de traducciones de PDF gratuito alcanzado',
    guide: {
      placeholder: 'Pegue el enlace PDF aquí',
      start: 'Empezar a traducir',
      text1: 'Lectura bilingüe',
      text2:
        'Lea bilingüe para comparar el texto y la traducción originales de lado a lado, mejorando su eficiencia.',
      text3: 'Chatea con tu PDF',
      text4:
        'Análisis y resumen de IA. Haga preguntas sobre su PDF y obtenga respuestas instantáneas.',
      text5: 'Preciso y eficiente',
      text6: 'Traducción PDF con AI para una mayor precisión y eficiencia.',
      title1: 'Traducción bilingüe',
      title2: 'Chatea con tu PDF',
      toast: 'Ingrese un enlace PDF en línea válido que finalice en .pdf',
    },
    hasTransTask: 'A translation task is in progress. Espere por favor.',
    hoverTrans: 'Párrafos de translato',
    initial: 'Traducir PDF',
    installText: 'Su solución única para traducciones cotidianas.',
    installText2: 'Instale primero la extensión',
    installTips:
      'Instale la extensión para obtener aún más traducciones PDF y traducciones de sitio web más suaves:',
    kw: 'grapa',
    link: 'enlace',
    listError:
      'No se pudo cargar PDFS. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    memoTotalCount: '1 artículo total',
    new: 'nuevo archivo',
    noId: 'ID de archivo no encontrado.',
    notPdf: 'Solo archivos PDF.',
    okBtn: 'Borrar',
    okBtn2: 'Entiendo',
    original: 'Original',
    parallel: 'Paralelo',
    parseErrDialogContent:
      'Este enlace PDF no se puede traducir directamente. Descargue el PDF y cárguelo para la traducción.',
    parseErrTips: 'No se pudo analizar el archivo',
    parseFileToast:
      'No se pudo analizar el archivo. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    pdfLoading: 'Analización ...',
    searchPlaceholder: 'Buscar',
    selectTrans: 'Seleccionar texto para traducir',
    shareBtn: 'Compartir',
    shareBtnText1: 'Generar un enlace de compartir',
    shareBtnText2: 'Actualizar contenido compartido',
    shareModal: {
      cancel: 'Cancelar',
      copy: 'Copiar',
      copySucc: 'Enlace copiado',
      custom: 'Personalizar:',
      errMsg:
        'El contenido seleccionado aún no se ha traducido por completo. Traducir antes de compartir.',
      gen: 'Compartir',
      modify: 'Editar',
      notSupport: 'Este idioma no es compatible actualmente.',
      page: 'Páginas',
      pageNuErr: 'Ingrese un número de página válido.',
      range: 'Alcance:',
      remain: 'restante',
      remain1: 'Tiempo estimado total:',
      search: 'Buscar',
      settings: 'Configuración de compartir:',
      shareSetting: 'Configuración de compartir',
      shareText: 'Compartir',
      shareTips: 'Comparte el enlace PDF traducido con tus amigos:',
      sourceLang: 'De:',
      target: 'A:',
      text1: 'Configuración de compartir',
      text2: 'Generar un enlace de compartir',
      totalPage: 'Total de 1 página',
      trans: 'Traducir',
      translateTips:
        'Consejo: Si la traducción está tomando un tiempo, puede cerrar esta ventana y compartir más tarde.',
      translating: 'Traducir PDF ...',
      update: 'Actualizar el enlace compartido',
    },
    shareMsg1: 'Compartir el enlace generado.',
    shareMsg2: 'Contenido compartido actualizado.',
    shareMsg3:
      'No se pudo generar un enlace de compartir. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    shareMsg4:
      'No se pudo actualizar el contenido compartido. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    shareTips: '¡Tu traducción PDF está lista! Compártalo con tus amigos.',
    start: 'Comience su prueba gratuita',
    thumbnail: 'Tabla de contenido',
    toast1: 'Cargando PDF. Vuelva a intentarlo más tarde.',
    toast2: 'El contenido de PDF es demasiado grande. El diálogo actualmente no es compatible.',
    toast3: 'Descarga. Vuelva a intentarlo después de terminar la descarga.',
    toolbar: { Adjust: 'Ajustar', autoFit: 'Ajuste automático' },
    trans: 'Traducción',
    transSucc: 'Traducción exitosa!',
    unLoginToast: 'Inicie sesión para usar esta función.',
    uploadBtn: 'Subir',
    uploadErr:
      'La carga de archivo falló. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    uploadErrSignUrl:
      'No se pudo obtener URL de carga. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    uploadMsg1: 'Arrastre y suelte su archivo aquí.',
    uploadMsg2: 'Tipos de archivos compatibles: PDF |  Tamaño de archivo máximo: 100 MB',
    uploadMsg3: 'Haga clic para seleccionar o arrastrar y soltar archivos aquí.',
    uploading: 'Carga',
    uploadingMsg: 'Carga. Espere por favor.',
    uploadingMsg1: 'El archivo está cargando. Vuelva a intentarlo más tarde.',
    uploadingMsg2: 'Actualmente se está convirtiendo un archivo. Vuelva a intentarlo más tarde.',
    uploadingMsg3:
      'La solicitud de progreso de conversión falló. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    uploadingMsg4:
      'Noam no puede acceder a archivos locales. Sube manualmente un PDF para la traducción.',
    uploadingMsg5:
      'No se pudo recuperar la URL del archivo. Vuelva a intentarlo más tarde, o comuníquese con el soporte.',
    video: 'video',
    vipBannerBtn: 'Mejora',
    vipBannerTips: 'Has alcanzado su límite de traducción PDF para este mes.',
    webTrans: 'Referencia cruzada completa',
  },
  pricing: {
    FAQ: 'Preguntas frecuentes',
    FQAObj: {
      a1: 'Sí, Noam ofrece un plan gratuito que puede usar de inmediato.',
      a2: 'Ofrecemos tres planes de membresía: Pro, Pro+y Enterprise. Puede elegir pagar mensualmente o anualmente. La facturación anual ofrece ahorros significativos.',
      a3: 'Todas las características están disponibles en ambos planes. La principal diferencia es el costo: las suscripciones anuales cuestan menos en general. Las suscripciones mensuales se facturan cada mes, mientras que los planes anuales se facturan una vez al año.',
      a4: 'Puede cancelar su suscripción en cualquier momento en la configuración de su cuenta.',
      q1: '¿Hay un plan gratuito?',
      q2: '¿Qué planes de precios y opciones de pago están disponibles?',
      q3: '¿Cuál es la diferencia entre suscribirse mensualmente vs. anualmente?',
      q4: '¿Cómo cancelo mi suscripción?',
    },
    around: 'Alrededor',
    billedMonthly: 'Facturado mensualmente',
    billedYearly: 'Facturado anualmente',
    day: 'día',
    desc: 'Compare los planes y elija lo mejor',
    detailedCompare: 'Comparar planes',
    discount: '20% de descuento',
    enterprise: {
      benefit1: 'Atención prioritaria al cliente',
      benefit2: 'Múltiples usuarios',
      benefit3: 'Soluciones a medida',
      benefit5: 'Administrador de cuentas dedicado',
      btn: 'Contáctenos',
      recommendText: 'Planes personalizados （≥3 人）',
      talkToUs: 'Ponerse en contacto',
    },
    free: {
      benefit1: 'Traducción instantánea 100 veces/día',
      benefit2: 'Traducción y resumen del sitio web',
      benefit3: '30 traducciones de imagen',
      benefit4: 'Traducción de video y resumen',
      benefit5: '10 traducciones en PDF',
      benefit6: '5,000 tokens',
      btn: 'Pruébalo gratis',
      recommendText: 'Prueba gratuita',
    },
    imageTranslation: 'Traducción de imágenes',
    month: 'mes',
    monthly: 'Mensual',
    pageTitle: 'Fijación de precios',
    pdf: {
      chat: 'Consultas de chat pdf',
      maxPage: 'Max. Páginas por pdf',
      translation: 'Traducción a PDF',
    },
    preMonth: 'Por mes',
    pro: {
      benefit1: 'Atención prioritaria al cliente',
      benefit2: '1,000,000 de tokens/mes',
      benefit3: 'Traducción y resumen del sitio web',
      benefit4: '200 traducciones de imagen',
      benefit5: 'Traducción de video y resumen',
      benefit6: '200 traducciones en PDF',
      btn: 'Empezar',
      recommendText: 'El más popular',
    },
    proPlus: {
      benefit1: 'Atención prioritaria al cliente',
      benefit2: '3,000,000 de tokens/mes',
      benefit3: 'Traducción y resumen del sitio web',
      benefit4: '500 traducciones de imagen',
      benefit5: 'Traducción de video y resumen',
      benefit6: '500 traducciones en PDF',
      recommendText: 'Mejor valor',
    },
    video: { summary: 'Resumen de video', translation: 'Traducción de video' },
    web: {
      contrastiveTranslation: 'Traducción bilingüe',
      dictionaryTranslation: 'Traducción de selección',
      hoverTranslation: 'Traducción de flotación',
      instantTranslation: 'Traducción instantánea',
      summary: 'Resumen de la página web',
    },
    yearly: 'Anual',
  },
  privacy: {
    block1:
      'Noam ("Noam", "nosotros", "nuestro" o "nosotros") respeta su privacidad y está comprometido a proteger cualquier información que obtengamos de usted o sobre usted. Esta política de privacidad describe nuestras prácticas con respecto a la recopilación o uso de información personal de su uso de nuestro sitio web, aplicaciones y servicios (colectivamente, los "Servicios"). Esta política de privacidad no se aplica al contenido que manejamos en nombre de nuestros clientes de productos comerciales, como nuestra API. El uso de estos datos está sujeto a nuestro acuerdo de clientes, que cubre el acceso y el uso de estos productos.',
    block10:
      'Implementamos medidas técnicas, administrativas y organizativas comercialmente razonables para proteger la información personal en línea y fuera de línea de la pérdida, el mal uso y el acceso no autorizado, la divulgación, la alteración o los actos hostiles. Sin embargo, ninguna transmisión de Internet o correo electrónico puede garantizar la seguridad o precisión completa. En particular, los correos electrónicos enviados a nosotros o de nosotros pueden no ser seguros. Por lo tanto, debe tener particularmente cuidadoso al decidir qué información enviarnos a través del servicio o correo electrónico. Además, no somos responsables de ninguna obstrucción de la configuración de privacidad o medidas de seguridad en el servicio o la configuración de seguridad de los sitios web de terceros o las medidas de seguridad. Retendremos su información personal durante el tiempo que sea necesario para que nos brinde servicios o para otros fines comerciales legítimos, como resolver disputas, razones de seguridad y seguridad, o cumplir con nuestras obligaciones legales. El período de tiempo que conservamos la información personal dependerá de muchos factores, como la cantidad, la naturaleza, la sensibilidad de la información, los riesgos potenciales de uso o divulgación no autorizados, los fines para los cuales procesamos la información y otras consideraciones legalmente vinculantes.',
    block11:
      'Podemos actualizar esta política de privacidad de vez en cuando. Cuando lo hagamos, publicaremos una versión actualizada en esta página, a menos que la ley aplicable requiera lo contrario.',
    block12:
      'Si tiene alguna pregunta o inquietud sin respuesta sobre esta Política de privacidad, comuníquese con nuestro servicio al cliente. Correo electrónico:',
    block2:
      'Recopilamos la siguiente información personal ("información personal") relacionada con usted:',
    block3:
      'Información proporcionada por usted: Cuando crea una cuenta para usar nuestros servicios o comunicarse con nosotros, recopilamos información personal de la siguiente manera:',
    block4:
      'Información personal recibida automáticamente de su uso de servicios: cuando accede, usa o interactúa con los Servicios, recibimos la siguiente información sobre su acceso, uso o interacción ("Información técnica"):',
    block5: 'Podemos usar información personal para los siguientes fines:',
    block6:
      'Información resumida o desidentificada: podemos agregar o desidentificar información personal para evitar su reutilización con fines de identificación y usar dicha información para analizar la efectividad de nuestros servicios, mejorar y agregar características a nuestros servicios, realizar investigaciones y para fines similares. Además, podemos analizar el comportamiento general y las características de los usuarios de nuestros servicios y compartir información agregada con terceros, publicar dicha información agregada o hacerla generalmente disponible. Podemos recopilar información agregada a través de los servicios, a través de cookies y a través de otros medios descritos en esta Política de privacidad. Mantendremos y utilizaremos información desidentificada en una forma anónima o desidentificada, y no intentaremos reidentificar dicha información a menos que la ley lo requiera.',
    block7:
      'En ciertos casos, podemos proporcionar su información personal a terceros sin notificarle, a menos que la ley lo requiera:',
    block8:
      'Dependiendo de su ubicación geográfica, las personas en el Área Económica Europea, el Reino Unido y a nivel mundial pueden tener ciertos derechos legales con respecto a su información personal. Por ejemplo, puede tener derecho a:',
    block9:
      'El servicio puede contener enlaces a otros sitios web no operados o controlados por NOAM, incluidos los servicios de redes sociales ("sitios web de terceros"). La información que comparte con sitios web de terceros se regirá por las políticas de privacidad específicas y los términos de servicio de los sitios web de terceros, no por esta Política de privacidad. Proporcionamos estos enlaces por conveniencia y no implicamos nuestro respaldo o revisión de estos sitios web. Comuníquese directamente con los sitios web de terceros para obtener información sobre sus políticas y términos de privacidad.',
    subBlock1:
      'Información de la cuenta: cuando crea una cuenta en nuestra plataforma, recopilamos información relacionada con su cuenta, incluida su nombre, información de contacto, credenciales de la cuenta, información de la tarjeta de pago e historial de transacciones ("Información de la cuenta").',
    subBlock10: 'Proporcionar, administrar, mantener y/o analizar servicios;',
    subBlock11: 'Comunicándose contigo;',
    subBlock12: 'Desarrollo de nuevos productos y servicios;',
    subBlock13:
      'Prevenir fraude, actividad criminal o mal uso de nuestros servicios, y proteger la seguridad de nuestros sistemas de TI, arquitectura y redes;',
    subBlock14: 'Realización de transferencias comerciales;',
    subBlock15:
      'Cumplir con las obligaciones legales y los procedimientos legales, proteger nuestros derechos, privacidad, seguridad o propiedad, así como los de nuestros afiliados, usted u otros terceros.',
    subBlock16:
      'Proveedores y proveedores de servicios: para ayudarnos a satisfacer las necesidades operativas comerciales y realizar ciertos servicios y funciones, podemos proporcionar información personal a proveedores y proveedores de servicios, incluidos los proveedores de servicios de alojamiento, los proveedores de servicios en la nube y otros proveedores de servicios de tecnología de la información, software de comunicación de correo electrónico y proveedores de servicios de análisis web, etc., etc. Estos socios accederán, procesan o almacenarán información personal solo en nuestra dirección mientras realizan sus huelos para los Estados Unidos.',
    subBlock17:
      'Transferencias comerciales: si participamos en transacciones estratégicas, reorganizaciones, bancarrotas, adquisiciones de bancarrota o transición de servicios a otro proveedor (colectivamente, "transacciones"), su información personal y otra información pueden compartirse con contrapartes de transacciones y otras partes que asisten en las transacciones durante la debida diligencia y transferidos como parte de las transacciones junto con otros ascendentes a un sucesor o una compañía de afiliados.',
    subBlock18:
      'Requisitos legales: podemos compartir su información personal con las autoridades gubernamentales, pares de la industria u otros terceros, incluida su información de interacción con nuestros servicios, (1) si lo exige la ley o si creemos que dicha acción es necesaria para cumplir con las obligaciones legales; (2) proteger y defender nuestros derechos o propiedad; (3) si determinamos unilateralmente que hay una violación de nuestros términos, políticas o leyes; (4) detectar o prevenir fraude u otras actividades ilegales; (5) para proteger nuestros productos, empleados y usuarios, o seguridad pública, seguridad, integridad; o (vi) defender contra la responsabilidad legal.',
    subBlock19: 'Acceda a su información personal e información sobre cómo se procesa.',
    subBlock2:
      'Contenido del usuario: cuando utiliza nuestros servicios, recopilamos información personal de sus entradas, cargas de archivos o comentarios proporcionados a nosotros ("Contenido").',
    subBlock20: 'Solicite la eliminación de su información personal de nuestros registros.',
    subBlock21: 'Corrija o actualice su información personal.',
    subBlock22: 'Transfiera su información personal a un tercero (portabilidad de datos).',
    subBlock23: 'Limite cómo procesamos su información personal.',
    subBlock24:
      'Retirar su consentimiento: donde dependemos del consentimiento como base legal para el procesamiento, puede retirar su consentimiento en cualquier momento.',
    subBlock25: 'Objeto a cómo procesamos su información personal.',
    subBlock26: 'Mejorar nuestros servicios y realizar investigaciones;',
    subBlock3:
      'Información de comunicación: si nos envía mensajes, recopilaremos su nombre, información de contacto y el contenido de sus mensajes ("Información de comunicación").',
    subBlock4:
      'Información en las redes sociales: tenemos páginas en sitios web de redes sociales como Instagram, Facebook, Medium, Twitter, YouTube y LinkedIn. Cuando interactúa con nuestras páginas de redes sociales, recopilamos la información personal que elige proporcionarnos, como sus datos de contacto ("información social"). Además, las empresas que organizan nuestras páginas de redes sociales pueden proporcionarnos información y análisis agregados sobre nuestras actividades de redes sociales.',
    subBlock5:
      'Registro de datos: información enviada automáticamente por su navegador cuando usa nuestros servicios. Los datos de registro incluyen su dirección de protocolo de Internet (IP), tipo de navegador y configuración, la fecha y la hora de sus solicitudes, y cómo interactúa con nuestro sitio web.',
    subBlock6:
      'Datos de uso: podemos recopilar automáticamente información sobre su uso del servicio, como los tipos de contenido que ve o interactúa, las características que utiliza, las acciones que realiza, así como su zona horaria, país, fecha y hora de acceso, agente de usuario y versión, computadora o tipo de dispositivo móvil, y su conexión de computadora.',
    subBlock7:
      'Información del dispositivo: Esto incluye el nombre del dispositivo, el sistema operativo, el identificador del dispositivo y el navegador que está utilizando. La información recopilada puede depender del tipo de dispositivo que esté utilizando y su configuración.',
    subBlock8:
      'Cookies: utilizamos cookies para operar y administrar nuestros servicios y mejorar su experiencia. Una "cookie" es información enviada por un sitio web que visita a su navegador. Puede configurar su navegador para aceptar todas las cookies, rechazar todas las cookies o notificarle cuándo se proporciona una cookie para que pueda decidir si aceptarlo cada vez. Sin embargo, rechazar las cookies puede evitar que use o afecte negativamente la visualización o funcionalidad de ciertas áreas o características del sitio web. Para obtener más información sobre las cookies, visite todo sobre cookies.',
    subBlock9:
      'Análisis: podemos usar varios productos de análisis en línea que usan cookies para ayudarnos a analizar cómo los usuarios usan nuestros servicios y mejoran su experiencia al usar los servicios.',
    subTitle1: 'Información personal que recopilamos',
    subTitle2: 'Cómo usamos información personal',
    subTitle3: 'Divulgación de información personal',
    subTitle4: 'Tus derechos',
    subTitle5: 'Enlaces a otros sitios web',
    subTitle6: 'Seguridad y retención de la información',
    subTitle7: 'Modificación de la política de privacidad',
    subTitle8: 'Cómo contactarnos',
    title: 'política de privacidad',
    updateTime: 'Actualizado: 15 de marzo de 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Aceptar todo',
    text: 'Para mejorar su experiencia, utilizamos cookies para personalizar el contenido y los servicios.',
    title: 'Este sitio web utiliza cookies',
  },
  slogen: 'El asistente de IA más fácil',
  stripe: {
    contact: '¡Contáctenos en contact@noam.tools con cualquier pregunta!',
    fail: {
      btn: 'Rever',
      text1: 'No pudimos procesar su pago.',
      text2: 'Revise su conexión y vuelva a intentarlo.',
      title: 'El pago fallido',
    },
    succ: {
      btn: 'Vamos',
      text1: 'Bienvenido a Noam,',
      text2: '¡Comencemos!',
      title: 'Felicidades',
    },
  },
  terms: {
    block1: '¡Gracias por usar Noam!',
    block10:
      '(a) Tarifas y facturación. Pagará todas las tarifas ("tarifas") de acuerdo con los precios y los términos en la página de precios aplicable o según lo acordado por escrito entre nosotros. Nos reservamos el derecho de corregir errores o errores de precios incluso si hemos emitido facturas o recibimos el pago. Proporcionará información de facturación completa y precisa, incluido un método de pago válido y autorizado. Cobraremos su método de pago de forma periódica según lo acordado, pero podemos cambiar razonablemente la fecha de facturación. Usted autoriza a Noam, sus afiliados y procesadores de pago de terceros para cobrar su método de pago. Si su pago falla, le proporcionaremos un aviso por escrito y podemos suspender el acceso al servicio hasta que se reciba el pago. Excepto que se especifica lo contrario en este Acuerdo, los pagos no son reembolsables.',
    block11:
      '(b) Impuestos. A menos que se indique lo contrario, las tarifas no incluyen ningún impuesto federal, estatal, local y extranjero, deberes y evaluaciones similares ("impuestos"). Usted es responsable de todos los impuestos asociados con sus compras, excluyendo los impuestos basados ​​en nuestros ingresos netos para los cuales lo facturaremos. Usted acepta pagar de inmediato dichos impuestos y proporcionarnos los estados de privacidad legalmente válidos y obtener los consentimientos necesarios para manejar dichos datos, y nos garantiza que maneja dichos datos de acuerdo con la ley aplicable.',
    block12:
      '(c) Cambios de precios. Podemos cambiar nuestros precios notificándole a través de su cuenta y/o nuestro sitio web. Los aumentos de precios serán efectivos inmediatamente después de la publicación. Cualquier cambio de precio se aplicará a las tarifas para su cuenta después de la fecha de vigencia del cambio.',
    block13:
      '(d) disputas y pagos atrasados. Si desea disputar cualquier tarifa o impuesto, comuníquese con contact@noam.tools dentro de los treinta (30) días posteriores a la fecha de la factura.',
    block14:
      '(e) Uso gratuito. No puede crear varias cuentas para obtener créditos gratuitos. Si determinamos que no está utilizando los créditos gratuitos de buena fe, podemos cobrarle tarifas estándar o dejar de proporcionar acceso al servicio.',
    block15:
      '(a) Confidencialidad. Puede tener acceso a información confidencial de Noam. Solo puede usar información confidencial según lo permitido en estos Términos con el fin de utilizar el Servicio. No puede divulgar información confidencial a ningún tercero, y protegerá la información confidencial de una manera no menos protectora de lo que protege su propia información confidencial similar, al menos con una atención razonable. La información confidencial es la información no pública designada como confidencial por Noam o que debe tratarse razonablemente como confidencial bajo las circunstancias, incluidos el software, las especificaciones y otra información comercial no pública. La información confidencial no incluye información que: (1) generalmente esté disponible para el público sin fallas de su parte; (2) Tenía en su poder sin ninguna obligación de confidencialidad antes de recibirla bajo estos Términos; (3) le divulga legalmente un tercero sin ninguna obligación de confidencialidad; o (4) es desarrollado de forma independiente por usted sin usar la información confidencial. Puede divulgar información confidencial si lo exige la ley, el tribunal u otra orden gubernamental, pero debe proporcionar un aviso previo por escrito a Noam de manera razonable y, en la medida de lo posible, hacer esfuerzos razonables para limitar el alcance de la divulgación, incluida la asistencia para oponerse a las solicitudes de divulgación.',
    block16:
      '(b) Seguridad. Debe implementar medidas razonables y apropiadas para ayudar a salvaguardar su acceso y uso del servicio. Si descubre alguna vulnerabilidad o violación relacionada con su uso del Servicio, debe contactar rápidamente a Noam y proporcionar detalles de la vulnerabilidad o violación.',
    block17:
      '((c) Procesamiento de datos personales. Si utiliza el servicio para procesar datos personales, debe proporcionar declaraciones de privacidad legalmente suficientes y obtener los consentimientos necesarios para procesar dichos datos, y nos garantiza que maneje dichos datos de acuerdo con la ley aplicable.',
    block18:
      '(a) terminación; Suspensión. Estos términos serán efectivos desde su primer uso del Servicio y permanecerán vigentes hasta que finalice. Puede rescindir estos términos en cualquier momento por cualquier motivo descontinuando el uso del servicio y el contenido. Podemos cancelar estos Términos con aviso previo por cualquier motivo. Podemos finalizar de inmediato estos Términos notificándole si incumple materialmente la Sección 2 (requisitos de uso), la Sección 5 (confidencialidad, seguridad y protección de datos), Sección 8 (Resolución de disputas) o Sección 9 (Términos generales), o si nuestra relación con cualquier proveedor de tecnología de terceros más allá de nuestros cambios de control, o para el cumplimiento de la ley o las solicitudes gubernamentales. Podemos suspender su acceso al servicio si no cumple con estos Términos, o si su uso presenta un riesgo de seguridad para nosotros o cualquier tercero, o si sospechamos que su uso es fraudulento o podemos exponernos a nosotros o a cualquier tercero a la responsabilidad.',
    block19:
      '(b) Efectos de la terminación. Tras la terminación, dejará de usar el servicio y devolverá o destruirá rápidamente cualquier información confidencial según lo indique nosotros. Las disposiciones de estos términos que, por su naturaleza, deben sobrevivir a la terminación o vencimiento, incluidas, entre otros, las secciones 3 y 5-9, sobrevivirán.',
    block2:
      'Estos términos de uso se aplican a su uso de los servicios de Noam, incluidas nuestras interfaces de programación de aplicaciones, software, herramientas, servicios de desarrolladores, datos, documentación y sitio web (denominados colectivamente los "servicios"). Al usar nuestros servicios, usted acepta cumplir con estos Términos. Nuestra política de privacidad explica cómo recopilamos y utilizamos información personal.',
    block20:
      '(a) Indemnificación. Defenderá, indemnizará y nos abrazará, nuestros afiliados y nuestro personal inofensivo de cualquier reclamo, pérdidas y gastos (incluidos los honorarios de los abogados) que surgen o se relacionan con su uso del Servicio, incluido su contenido, su uso de productos o servicios relacionados con el Servicio, y su violación de estos términos o ley aplicable.',
    block21:
      '(b) Descargos de responsabilidad. El servicio se proporciona "tal cual". En la medida permitida por la ley, nosotros y nuestros afiliados y licenciantes no hacemos representaciones ni garantías de ningún tipo con respecto al servicio y no hacemos garantías, incluidas, entre otros, garantías de comerciabilidad, idoneidad para un propósito particular, calidad satisfactoria, no infracción y disfrute silencioso, y cualquier garantía que surja del curso del trato o el uso de comercio. No garantizamos que el servicio sea ininterrumpido, preciso o sin errores, o que cualquier contenido sea seguro, no perdido o no alterado.',
    block22:
      '(c) Limitación de responsabilidad. Ni nosotros, nuestros afiliados, ni nuestros licenciantes seremos responsables de daños indirectos, incidentales, especiales, consecuentes o punitivos, incluida la pérdida de ganancias, pérdida de reputación, pérdida de uso, pérdida de datos u otras pérdidas intangibles, incluso si se nos ha informado de la posibilidad de tales daños. Según estos términos, nuestra responsabilidad total no excederá las tarifas que pagó al servicio en los doce (12) meses anteriores al reclamo o cien dólares estadounidenses ($ 100), lo que sea mayor. Las limitaciones en esta sección se aplican a la máxima extensión permitida por la ley aplicable.',
    block23:
      'Los términos y su uso de los servicios se rigen por las leyes de los Estados Unidos, a excepción de las reglas de conflicto de leyes de los Estados Unidos. Su uso de la solicitud también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales. Cualquier acción legal o procedimiento relacionado con estos Términos se traerá exclusivamente en los tribunales de los Estados Unidos, y usted acepta someterse a la jurisdicción personal de dichos tribunales.',
    block24:
      '(a) Relación de las partes. Noam y usted son contratistas independientes, y ninguna de las partes tiene el poder de representar o vincular a la otra parte o asumir obligaciones para la otra parte sin el consentimiento previo por escrito de la otra parte.',
    block25:
      '(b) Uso de la marca. No puede usar el nombre, los logotipos o las marcas comerciales de Noam ni ninguno de sus afiliados sin nuestro consentimiento previo por escrito.',
    block26:
      'Si se determina que alguna disposición de estos Términos es inválida, ilegal o inaplicable, las disposiciones restantes permanecerán en plena vigencia y efecto.',
    block3:
      'Si tiene menos de 18 años, debe obtener el permiso de sus padres o tutores legales para usar el servicio. Si está utilizando el servicio en nombre de otra persona o entidad, debe estar autorizado para aceptar estos Términos en su nombre. Debe proporcionar información precisa y completa para registrarse para una cuenta. No puede proporcionar sus credenciales o cuenta de acceso a cualquier persona fuera de su organización, y usted es responsable de todas las actividades realizadas utilizando sus credenciales.',
    block4:
      '(a) Uso del servicio. Puede acceder al servicio bajo estos Términos, y le otorgamos el derecho no exclusivo de usar el servicio. Cuando use el servicio, cumplirá con estos términos y todas las leyes aplicables. Nosotros y nuestros afiliados poseemos todos los derechos, títulos e intereses en el servicio.',
    block5:
      '(b) Comentarios. Agradecemos comentarios, comentarios, ideas, sugerencias y mejoras. Si proporciona dicho contenido, podemos usarlo sin restricciones y sin compensación.',
    block6:
      '(c) Restricciones. No puede: (1) usar el servicio de una manera que infringe, apropia mal o viola cualquier derecho de los demás; (2) ingeniería inversa, descompilar, desmontar, traducir o intentar descubrir el código fuente o los componentes subyacentes de los modelos de servicio, algoritmos y sistemas (a menos que dichas restricciones estén prohibidas por la ley aplicable); (3) Use la salida del servicio para desarrollar modelos que compitan con Noam; (4) extraer datos o salida del servicio utilizando cualquier método automatizado o de programación a menos que se permita a través de la API, incluidos los raspados, la recolección web o la extracción de datos web; (5) representar el resultado del servicio como se genera artificialmente cuando no lo es, o viola nuestras políticas de uso; (6) comprar, vender o transferir claves API sin nuestro consentimiento previo; o (7) proporcionarnos cualquier información personal de niños menores de 13 años o edad aplicable de consentimiento digital. Cumplirá con cualquier límite de tasa y otros requisitos en nuestra documentación.',
    block7:
      '(d) Servicios de terceros. Cualquier software de terceros, servicios u otros productos asociados con el servicio se rige por sus propios términos, y no somos responsables de los productos de terceros.',
    block8:
      '(a) Tu contenido. Puede proporcionar entrada ("entrada") al servicio y recibir salida ("salida") generada y devuelta en función de la entrada (denominada colectivamente "contenido"). Entre las partes, y en la medida permitida por la ley aplicable, usted posee todas las entradas. Noam puede usar contenido para proporcionar y mantener el servicio, cumplir con las leyes aplicables y hacer cumplir nuestras políticas. Usted es responsable del contenido, incluida la garantía de que no viole ninguna ley aplicable o estos términos.',
    block9:
      '(b) Precisión. La inteligencia artificial y el aprendizaje automático son campos de investigación en rápida evolución. Nos esforzamos continuamente por mejorar nuestro servicio para que sea más preciso, confiable, seguro y beneficioso. Dada la naturaleza probabilística del aprendizaje automático, el uso de nuestro servicio puede dar lugar a resultados incorrectos en ciertos casos, lo que puede no reflejar con precisión individuos, lugares o hechos reales. Debe evaluar adecuadamente la precisión de cualquier resultado en función de su caso de uso, incluso revisando manualmente las salidas.',
    subTitle1: 'Registro y acceso',
    subTitle10: 'Ráfaga',
    subTitle2: 'Requisitos de uso',
    subTitle3: 'Contenido',
    subTitle4: 'Tarifas y pago',
    subTitle5: 'Confidencialidad, seguridad y protección de datos',
    subTitle6: 'Término y terminación',
    subTitle7: 'Indemnización; Descargo de responsabilidad; Limitación de responsabilidad',
    subTitle8: 'Ley de gobierno y jurisdicción',
    subTitle9: 'Términos generales',
    title: 'Términos de servicio',
    updateTime: 'Actualizado: 15 de marzo de 2024',
  },
  trialModal: {
    btn: 'Empiece ahora （solo $ 0.99）',
    chat: 'Chatpdf',
    desc1: 'Después de su juicio, se le cobrará una tarifa anual de $ 180',
    desc2: 'Cancelar en cualquier momento',
    focus: 'Enfoque',
    hover: 'Traducción de flotación',
    image: 'Traducción de imágenes',
    installBtn: 'Instale ahora para recibir su regalo',
    memo: 'Gestión favorita',
    pdf: 'Traducción paralela en PDF',
    realtime: 'Traducción instantánea',
    select: 'Traducción de selección de palabras',
    title: 'Regalo de bienvenida',
    trialText: 'Prueba de 7 días',
    video: 'Traducción de video',
    videoSummary: 'Resumen de video',
    web: 'Traducción paralela',
    webSummary: 'Resumen web',
  },
  upgradeModal: {
    btn: 'Actualización ahora',
    chat: {
      free: 'Has alcanzado el límite de chatpdf',
      pro: 'Has alcanzado el límite de chatpdf para este mes',
    },
    contactStr: 'Espere el próximo reinicio o contáctenos en contact@noam.tools.',
    limitAlertStr: 'Has alcanzado el límite de su plan gratuito. Actualizar para continuar.',
    more: 'Ver planos',
    pageLimit: { free: 'Has llegado al límite de página PDF' },
    title: 'Recordatorio',
    upgradeAlertStr: 'Has alcanzado el límite de su plan gratuito. Actualizar para continuar.',
    upload: {
      free: 'Has alcanzado su límite de traducción de PDF',
      pro: 'Has alcanzado su límite de traducción de PDF para este mes',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Únete ahora',
      label: 'Nuevos beneficios del usuario:',
      remain: 'Solo quedan 30',
      text: '¡Solo 100 puntos disponibles! ¡Toma el tuyo ahora!',
    },
  },
}

export default TRANSLATION
