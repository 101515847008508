const TRANSLATION = {
  buyModal: {
    afterFree: 'Nach Ihrer kostenlosen Testversion',
    btn: 'Versuchen Sie es kostenlos',
    freeTrial: '3-tägige kostenlose Testversion',
    monthLabel: 'Monat',
    selectLabel: 'Wählen Sie einen Plan aus',
    title: 'Abonnementplan',
    yearLabel: 'Jahr',
  },
  contact: {
    block1:
      'Für Anpassungen, Fragen oder Vorschläge kontaktieren Sie uns bitte unter contact@noam.tools. Wir werden umgehend antworten.',
    block2:
      'Unterstützen Sie uns, indem Sie uns im Google Apps Extension Store unter ★★★★★ bewerten.',
    block3: 'Helfen Sie anderen, indem Sie diese Erweiterung teilen:',
    title: 'Kontaktieren Sie uns',
  },
  help: {
    AquickOverviewOfNoam: 'Ein kurzer Überblick über Noam',
    ChatPDF: 'Chat PDF',
    ChatPDF2: 'CHATPDF',
    FavoritesManagement: 'Favoritenmanagement',
    Fixedplugin: 'Festes Plugin',
    Focusing: 'Fokussierung',
    Free: 'Frei',
    FreeTrialBenefits: 'Kostenlose Testvorteile',
    GoWithWikipedia: 'Gehen Sie mit Wikipedia',
    GoWithYouTube: 'Geh mit YouTube',
    HoverTranslation: 'Schwebende Übersetzung',
    Howtouse: 'Wie man benutzt',
    ImageTranslation: 'Bildübersetzung',
    InstantTranslation: 'Sofortige Übersetzung',
    Membership: 'Mitgliedschaft',
    NoamHelp: 'Noam hilft Ihnen bei der Übersetzung und Zusammenfassung',
    PDFparallelTranslation: 'PDF Parallele Übersetzung',
    ParallelTranslation: 'Parallele Übersetzung',
    SkipAllSteps: 'Überspringen Sie alle Schritte',
    Summary: 'Zusammenfassung',
    Translation: 'Übersetzung',
    TryItForFree: 'Versuchen Sie es kostenlos',
    Video: 'Video',
    VideoSummary: 'Videoübersicht',
    VideoTranslation: 'Videoübersetzung',
    WebSummary: 'Webzusammenfassung',
    WordSelectionTranslation: 'Wortauswahlübersetzung',
    benefits: 'Wir haben Vorteile, die auf Sie warten!',
    dayFree: '3-tägige kostenlose Testversion',
    done: 'Erledigt',
    next: 'Nächste',
    pin: 'Pin Noam für den leichteren Zugang ～～',
    planIsNotExist: 'Plan ist nicht verfügbar',
    startWiki: 'Beginnen wir mit Wikimedia!',
    startYoutube: 'Beginnen wir mit YouTube!',
    try: 'Probieren Sie es aus',
    tryNow: 'Probieren Sie es jetzt aus!',
  },
  home: {
    PDFTranslate: {
      desc: 'Zweisprachige Übersetzung + CHAT PDF',
      sub1: {
        desc: 'Mit zweisprachiger Lektüre können Sie den Originaltext und die Übersetzung nebeneinander vergleichen und die Leseeffizienz verbessern.',
        title: 'Zweisprachiger Lesen',
      },
      sub2: {
        desc: 'AI analysiert und fasst zusammen und kann Ihre Fragen basierend auf dem PDF -Inhalt beantworten.',
        title: 'Chat PDF',
      },
      sub3: {
        desc: 'AI-betriebene PDF-Übersetzung für größere Genauigkeit und Effizienz.',
        title: 'Genauigkeit und Effizienz',
      },
      title: 'PDF übersetzen',
    },
    aiAssistant: {
      desc: 'Webübersetzung intelligenter und bequemer machen',
      panel1: {
        desc: 'Zweisprachige Übersetzungen für Kreuzversicherungen helfen Ihnen dabei, den Originaltext mit der Übersetzung zu vergleichen, damit Sie besser verstehen und Ihre Leseeffizienz verbessern können.',
        title: 'Zweisprachige Übersetzung',
      },
      panel2: {
        left: {
          desc: 'Bewegen Sie beim Durchsuchen einer Webseite den Inhalt, den Sie übersetzen müssen, um die Übersetzung anzuzeigen.',
          title: 'Schwebende Übersetzung',
        },
        right: {
          desc: 'Wählen Sie beim Surfen einer Webseite den Text aus, den Sie übersetzen möchten, um die Übersetzung anzuzeigen.',
          title: 'Textauswahlübersetzung',
        },
      },
      panel3: {
        one: {
          desc: 'Übersetzen und fassen Sie jederzeit und überall Webseiten und Videoinhalte zusammen, um eine glattere Erfahrung zu erhalten!',
          title: 'Punkt und klicken',
        },
        three: {
          desc: 'Das AI-gesteuerte Großsprachmodell gewährleistet die Übersetzungsgenauigkeit und -konsistenz für ein verbessertes Leseerlebnis.',
          title: 'AI-gesteuert',
        },
        two: {
          desc: 'Multisprachige vollständige Berichterstattung, zweisprachige Barriere-freie Lesart, One-Click-Übersetzung-Erleben Sie den Unterschied!',
          title: 'Umfassender und bequemer',
        },
      },
      panel4: {
        tab1: {
          desc: 'Ihr Effizienzexperte - Mit ChatGPT übersetzt und fasst Noam YouTube -Videoinhalte übersetzt und fasst Sie zusammen, um Ihnen Zeit zu sparen!',
          key: 'Video',
          title: 'Videoübersetzung und Zusammenfassung',
        },
        tab2: {
          desc: 'Klicken Sie hier, um Bilder zu übersetzen, während Sie das Web durchsuchen.',
          key: 'Bild',
          title: 'Bild übersetzen',
        },
        tab3: {
          desc: 'Extrahieren Sie sofort wichtige Informationen von Webseiten und bieten eine Zusammenfassung, wodurch Ihre Leseeffizienz verbessert wird.',
          key: 'Netz',
          title: 'Webübersetzung & Zusammenfassung',
        },
        tag1: 'Einfach',
        tag2: 'Effizient',
        tag3: 'Leicht zu verstehen',
      },
      title: 'AI -Assistent',
    },
    getStart: 'Fangen an',
    getStartedForFree: 'KOSTENLOS anfangen',
    howItWork: {
      desc: 'Noam integriert KI-Modelle, um den Benutzern eine intelligente Erfahrung für die Übersetzung und Zusammenfassung von Cross-Sprach-Webseiten, PDF- und Bildübersetzung, Videoübersetzung und Zusammenfassung zu bieten',
      title: 'Wie es funktioniert',
    },
    lastBlock: { desc: 'Bessere AI -Übersetzung für eine glattere Leseberfahrung' },
    top: {
      desc: 'Noam ist eine kostenlose AI -Browser -Erweiterung, mit der Sie Webseiten, Videos und PDF -Inhalte übersetzen und zusammenfassen können.',
      title1: 'Eine Magie',
      title2: 'Ein Klick',
    },
    userReviews: {
      desc: 'Fünf-Sterne-Bewertungen im Chrome Web Store',
      title: 'Benutzerbewertungen',
    },
  },
  installTips: {
    btn: 'Versuchen Sie es jetzt',
    text: 'Installieren Sie "Noam Browser Plugin", um mehr Funktionen freizuschalten! Web-Cross-Referenzing, \\ n Web-Video-Zusammenfassung und mehr!',
  },
  locale: {
    af: 'Afrikaans',
    am: 'Amharisch',
    ar: 'Arabisch',
    as: 'Assamesen',
    auto: 'Automatische Erkennung',
    az: 'Aserbaidschani',
    ba: 'Bashkir',
    bg: 'bulgarisch',
    bn: 'Bangla',
    bo: 'Tibetaner',
    bs: 'bosnisch',
    ca: 'katalanisch',
    cs: 'tschechisch',
    cy: 'Walisisch',
    da: 'dänisch',
    de: 'Deutsch',
    dsb: 'Untere Sorbianin',
    dv: 'Divehi',
    el: 'griechisch',
    en: 'Englisch',
    es: 'Spanisch',
    et: 'estnisch',
    eu: 'baskisch',
    fa: 'persisch',
    fi: 'finnisch',
    fil: 'Filipino',
    fj: 'Fidschian',
    fr: 'Französisch',
    frCA: 'Französisch (Kanada)',
    ga: 'irisch',
    gl: 'galizisch',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'hebräisch',
    hi: 'Hindi',
    hr: 'kroatisch',
    hsb: 'Obere Sorbianin',
    ht: 'Haitianische Kreolie',
    hu: 'ungarisch',
    hy: 'Armenisch',
    id: 'Indonesisch',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'isländisch',
    it: 'Italienisch',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latein)',
    ja: 'japanisch',
    ka: 'georgisch',
    kk: 'Kasachisch',
    km: 'Khmer',
    kmr: 'Kurdisch (Nord)',
    kn: 'Kannada',
    ko: 'Koreanisch',
    ku: 'Kurdisch (zentral)',
    ky: 'Kirgisische',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'litauisch',
    lug: 'Ganda',
    lv: 'lettisch',
    lzh: 'Chinesisch (literarisch)',
    mai: 'Maithili',
    mg: 'Madagasy',
    mi: 'Māori',
    mk: 'mazedonisch',
    ml: 'Malayalam',
    mnCyrl: 'Mongolisch (kyrillisch)',
    mnMong: 'Mongolisch (traditionell)',
    mr: 'Marathi',
    ms: 'malaiisch',
    mt: 'maltesisch',
    mww: 'Hmong Daw',
    my: 'Myanmar (Burmesisch)',
    nb: 'norwegisch',
    ne: 'Nepali',
    nl: 'Niederländisch',
    noSearchLang: 'Sprache nicht unterstützt',
    nso: 'Sesotho Sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro otomi',
    pa: 'Punjabi',
    pl: 'Polieren',
    placeholder: 'Suchen',
    prs: 'Dari',
    ps: 'PaShto',
    pt: 'Portugiesisch (Brasilien)',
    ptPT: 'Portugiesisch (Portugal)',
    ro: 'rumänisch',
    ru: 'Russisch',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'slowakisch',
    sl: 'Slowenisch',
    sm: 'Samoaner',
    sn: 'Shona',
    so: 'somali',
    sq: 'albanisch',
    srCyrl: 'Serbisch (kyrillisch)',
    srLatn: 'Serbisch (lateinisch)',
    st: 'Sesotho',
    sv: 'Schwedisch',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tlhLatn: 'Klingon (Latein)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongan',
    tr: 'Türkisch',
    tt: 'Tatar',
    ty: 'Tahitianer',
    ug: 'Uyghur',
    uk: 'ukrainisch',
    ur: 'Urdu',
    uz: 'Usbek (Latein)',
    vi: 'Vietnamesisch',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kantonesisch (traditionell)',
    zh: 'chinesisch',
    zhHans: 'Chinesisch vereinfacht',
    zhHant: 'Chinesisch traditionell',
    zu: 'Zulu',
  },
  login: {
    and: 'Und',
    btn: 'Melden Sie sich mit Google an',
    btn1: 'Melden Sie sich jetzt an',
    desc: 'Melden Sie sich an, um mehr Erfahrungen freizuschalten',
    policy: 'Datenschutzrichtlinie',
    terms: 'Nutzungsbedingungen',
    tips: 'Durch die Anmeldung stimme ich dem zu',
    title: 'Willkommen bei Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Folgen', twitter: 'Twitter' },
    legal: { policy: 'Datenschutzrichtlinie', terms: 'Nutzungsbedingungen', title: 'Legal' },
    product: {
      image: 'Bildübersetzung',
      pdf: 'PDF -Übersetzung',
      title: 'Produkte',
      video: 'Videoübersetzung und Zusammenfassung',
      web: 'Webübersetzung und Zusammenfassung',
    },
    resources: { title: 'Ressourcen' },
  },
  pageHeader: {
    contact: 'Kontakt',
    help: 'Helfen',
    logout: 'Melden Sie sich aus',
    logoutSucc: 'Abgemeldet.',
    pricing: 'Preisgestaltung',
    settings: 'Einstellungen',
    signUp: 'Einloggen',
  },
  pdf: {
    autoDetect: 'Automatische Erkennung',
    cancelBtn: 'Stornieren',
    cancelUpload: 'Stornieren',
    chatPdf: {
      summaryError: 'Zusammenfassung ist fehlgeschlagen, bitte versuchen Sie es später erneut',
      chatError: 'Chat fehlgeschlagen, bitte versuchen Sie es später erneut',
      Search: 'Suchen',
      cancel: 'Stornieren',
      chatTitle: 'Willkommen bei Noam! Hier ist eine Zusammenfassung Ihres PDF:',
      clear: 'Klar',
      clearContent:
        'Dies wird ein neues Gespräch beginnen. Das aktuelle Gespräch wird nicht gerettet. Bist du sicher?',
      clearDialogTitle: 'Bitte bestätigen Sie',
      clearSucc: 'Geräumt',
      copy: 'Kopie',
      copySucc: 'Kopiert',
      delete: 'Löschen',
      deleteCancel: 'Stornieren',
      deleteContent:
        'Dieser PDF kann nach dem Löschen nicht wiederhergestellt werden. Bist du sicher?',
      deleteOk: 'Löschen',
      deleteSuccess: 'Gelöscht',
      deleteTitle: 'Bestätigen Sie löschen',
      expand: 'Zusammenbruch',
      historyTitle: 'Geschichte',
      listError:
        'Die Leseliste versäumt es nicht. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
      loading: 'Laden...',
      noDataDesc: 'Noch keine Geschichte.',
      noMore: 'Kein Inhalt mehr.',
      placeholder: 'Fragen Sie etwas über diese PDF',
      summaryTitle: 'Sie könnten an diesen Fragen interessiert sein:',
    },
    collectionEmpty: 'Ihre Sammlung ist leer.',
    collectionTab: 'Notizen',
    dataLoading: 'Laden...',
    dialogContent: 'Sind Sie sicher, dass Sie dies löschen möchten?',
    dialogTitle: 'Bitte bestätigen Sie',
    download: {
      both: 'Zweisprachig',
      btn: 'Speichern',
      cancel:
        'Das Schließen dieses Fensters storniert den PDF -Download. Sind Sie sicher, dass Sie stornieren möchten?',
      desc: 'Auf Gerät speichern: Download Übersetzungen als Bilder; Speichern & Druck: Download Übersetzungen im Originalformat mit Kopierunterstützung.',
      download: 'Herunterladen',
      downloadMsg1: 'PDF -Übersetzungsfortschritt:',
      downloadMsg2: 'Schätzungsweise verbleibende Zeit:',
      downloadSucc: 'PDF ist bereit! Klicken Sie hier, um zu speichern.',
      downloadTip:
        'PDF herunterladen. Schließen Sie dieses Fenster nicht, um Downloadfehler zu vermeiden.',
      error: 'Nicht speichern. Versuchen Sie stattdessen drucken.',
      fileName: 'Dateiname:',
      fileType: 'Dateityp:',
      loading: 'Retten ...',
      loading2: 'Laden...',
      minute: 'Minuten ...',
      msg1: 'Einige Übersetzungen in diesem Dokument sind länger als der Originaltext und können sich in der heruntergeladenen Datei überschneiden. Wir empfehlen, auf der Webseite zu lesen.',
      msg2: '1. Übersetzung ist noch nicht vollständig. Bitte herunterladen, nachdem die Übersetzung fertig ist.',
      msg3: '2. Einige Übersetzungen sind zu lang. Lesen Sie auf der Webseite, um eine Textüberlappung zu vermeiden.',
      msg4: 'Übersetzungsaufgaben sind im Gange. Bitte versuchen Sie es erneut, nachdem sie fertig sind.',
      progress:
        'PDF übersetzen. Fortschritt: {{Fortschritt}}, Schätzungszeit verbleibende Zeit: {{minute}} Minuten ...',
      save: 'Auf dem Gerät speichern',
      savePrint: 'Speichern und drucken',
      success: 'Heruntergeladen',
      trans: 'Nur Übersetzung',
      transContinue: 'Weiter übersetzen',
    },
    downloadBtn: 'Herunterladen',
    dragTipModal: {
      black: 'Raum',
      leftKey: 'linker Taste',
      ok: 'Habe es',
      press: 'Drücken',
      tips: 'ERROR: [to drag and view.]',
    },
    entry: 'PDFs',
    fileErrMsg:
      'Dateilesung fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    fileMsg: 'Aktuelle Dateigröße:',
    fileMsg2: 'Bitte laden Sie eine Datei kleiner als 300 MB hoch.',
    freeBannerBtn: 'Upgrade',
    freeBannerTips: 'Kostenlose PDF -Übersetzungen Grenze erreicht',
    guide: {
      placeholder: 'Fügen Sie den PDF -Link hier ein',
      start: 'Beginnen Sie mit der Übersetzung',
      text1: 'Zweisprachiger Lesen',
      text2:
        'Lesen Sie zweisprachig vor, um den ursprünglichen Text und die Übersetzung nebeneinander zu vergleichen und Ihre Effizienz zu verbessern.',
      text3: 'Chatten Sie mit Ihrem PDF',
      text4:
        'AI-betriebene Analyse und Zusammenfassung. Stellen Sie Fragen zu Ihrem PDF und erhalten Sie sofortige Antworten.',
      text5: 'Genau und effizient',
      text6: 'AI-betriebene PDF-Übersetzung für größere Genauigkeit und Effizienz.',
      title1: 'Zweisprachige Übersetzung',
      title2: 'Chatten Sie mit Ihrem PDF',
      toast: 'Bitte geben Sie einen gültigen Online -PDF -Link ein, der in .pdf endet',
    },
    hasTransTask: 'Eine Übersetzungsaufgabe ist im Gange. Bitte warten.',
    hoverTrans: 'Schwebedurchsachenabsätze',
    initial: 'PDF übersetzen',
    installText: 'Ihre One-Stop-Lösung für alltägliche Übersetzungen.',
    installText2: 'Bitte installieren Sie zuerst die Erweiterung',
    installTips:
      'Installieren Sie die Erweiterung für noch mehr PDF -Übersetzungen und glattere Website -Übersetzungen:',
    kw: 'Klammer',
    link: 'Link',
    listError:
      'PDFs nicht laden. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    memoTotalCount: '1 Artikel Gesamt',
    new: 'Neue Datei',
    noId: 'Datei -ID nicht gefunden.',
    notPdf: 'Nur PDF -Dateien.',
    okBtn: 'Löschen',
    okBtn2: 'Habe es',
    original: 'Original',
    parallel: 'Parallel',
    parseErrDialogContent:
      'Dieser PDF -Link kann nicht direkt übersetzt werden. Bitte laden Sie die PDF herunter und laden Sie es zur Übersetzung hoch.',
    parseErrTips: 'Datei nicht analysieren',
    parseFileToast:
      'Datei nicht analysieren. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    pdfLoading: 'Parsing...',
    searchPlaceholder: 'Suchen',
    selectTrans: 'Wählen Sie Text zur Übersetzung aus',
    shareBtn: 'Aktie',
    shareBtnText1: 'Share Link erstellen',
    shareBtnText2: 'Aktualisieren Sie freigegebene Inhalte',
    shareModal: {
      cancel: 'Stornieren',
      copy: 'Kopie',
      copySucc: 'Link kopiert',
      custom: 'Anpassen:',
      errMsg:
        'Der ausgewählte Inhalt wurde noch nicht vollständig übersetzt. Bitte übersetzen Sie vor dem Teilen.',
      gen: 'Aktie',
      modify: 'Bearbeiten',
      notSupport: 'Diese Sprache wird derzeit nicht unterstützt.',
      page: 'Seiten',
      pageNuErr: 'Bitte geben Sie eine gültige Seitennummer ein.',
      range: 'Umfang:',
      remain: 'übrig',
      remain1: 'Gesamtgeschätzte Zeit:',
      search: 'Suchen',
      settings: 'Teileneinstellungen:',
      shareSetting: 'Einstellungen teilen',
      shareText: 'Aktie',
      shareTips: 'Teilen Sie den übersetzten PDF -Link mit Ihren Freunden:',
      sourceLang: 'Aus:',
      target: 'Zu:',
      text1: 'Einstellungen teilen',
      text2: 'Share Link erstellen',
      totalPage: '1 Seite Gesamt',
      trans: 'Übersetzen',
      translateTips:
        'Tipp: Wenn die Übersetzung eine Weile dauert, können Sie dieses Fenster schließen und später teilen.',
      translating: 'PDF übersetzen ...',
      update: 'Aktualisieren Sie Share Link',
    },
    shareMsg1: 'Share Link generiert.',
    shareMsg2: 'Shared Inhalte aktualisiert.',
    shareMsg3:
      'Versäumnis, eine Freigabeverbindung zu generieren. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    shareMsg4:
      'Versäumte Aktualisierung der gemeinsam genutzten Inhalte. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    shareTips: 'Ihre PDF -Übersetzung ist fertig! Teile es mit deinen Freunden.',
    start: 'Starten Sie Ihre kostenlose Testversion',
    thumbnail: 'Inhaltsverzeichnis',
    toast1: 'Laden Sie PDF. Bitte versuchen Sie es später erneut.',
    toast2: 'PDF -Inhalt ist zu groß. Der Dialog ist derzeit nicht unterstützt.',
    toast3:
      'Herunterladen. Bitte versuchen Sie es erneut, nachdem der Download fertiggestellt ist.',
    toolbar: { Adjust: 'Anpassen', autoFit: 'Automatisch' },
    trans: 'Übersetzung',
    transSucc: 'Übersetzung erfolgreich!',
    unLoginToast: 'Bitte melden Sie sich an, um diese Funktion zu verwenden.',
    uploadBtn: 'Hochladen',
    uploadErr:
      'Datei -Upload fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    uploadErrSignUrl:
      'Fehler beim Upload -URL. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    uploadMsg1: 'Drag & fallen Sie hier Ihre Datei.',
    uploadMsg2: 'Unterstützte Dateitypen: PDF |  MAX -Dateigröße: 100 MB',
    uploadMsg3: 'Klicken Sie hier, um Dateien hier auszuwählen oder zu drop & Drop.',
    uploading: 'Hochladen',
    uploadingMsg: 'Hochladen. Bitte warten.',
    uploadingMsg1: 'Datei wird hochgeladen. Bitte versuchen Sie es später erneut.',
    uploadingMsg2: 'Derzeit wird eine Datei konvertiert. Bitte versuchen Sie es später erneut.',
    uploadingMsg3:
      'Die Anfrage des Konvertierungsfortschritts ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    uploadingMsg4:
      'Noam kann nicht auf lokale Dateien zugreifen. Bitte laden Sie eine PDF manuell zur Übersetzung hoch.',
    uploadingMsg5:
      'Fehlgeschlagen, Datei -URL abzurufen. Bitte versuchen Sie es später erneut oder wenden Sie sich an Support.',
    video: 'Video',
    vipBannerBtn: 'Upgrade',
    vipBannerTips: 'Sie haben Ihr PDF -Übersetzungslimit für diesen Monat erreicht.',
    webTrans: 'Volle Kreuzversicherung',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Ja, Noam bietet einen kostenlosen Plan, den Sie sofort verwenden können.',
      a2: 'Wir bieten drei Mitgliedschaftspläne an: Pro, Pro+und Enterprise. Sie können monatlich oder jährlich bezahlen. Die jährliche Abrechnung bietet erhebliche Einsparungen.',
      a3: 'Alle Funktionen sind in beiden Plänen erhältlich. Der Hauptunterschied sind die Kosten: Jahresabonnementskosten sind insgesamt weniger. Monatliche Abonnements werden jeden Monat in Rechnung gestellt, während jährliche Pläne einmal pro Jahr in Rechnung gestellt werden.',
      a4: 'Sie können Ihr Abonnement jederzeit in Ihren Kontoeinstellungen kündigen.',
      q1: 'Gibt es einen kostenlosen Plan?',
      q2: 'Welche Preispläne und Zahlungsoptionen stehen verfügbar?',
      q3: 'Was ist der Unterschied zwischen dem Abonnieren monatlicher Abonnement und jährlich?',
      q4: 'Wie storniere ich mein Abonnement?',
    },
    around: 'Um',
    billedMonthly: 'Monatlich in Rechnung gestellt',
    billedYearly: 'Jährlich in Rechnung gestellt',
    day: 'Tag',
    desc: 'Vergleichen Sie Pläne und wählen Sie das Beste aus',
    detailedCompare: 'Vergleiche Pläne',
    discount: '20% Rabatt',
    enterprise: {
      benefit1: 'Prioritäts -Kundenunterstützung',
      benefit2: 'Mehrere Benutzer',
      benefit3: 'Maßgeschneiderte Lösungen',
      benefit5: 'Engagierter Account Manager',
      btn: 'Kontaktieren Sie uns',
      recommendText: 'Benutzerdefinierte Pläne （≥3 人）',
      talkToUs: 'Sich in Verbindung setzen',
    },
    free: {
      benefit1: 'Sofortige Übersetzung 100 Mal pro Tag',
      benefit2: 'Website -Übersetzung und Zusammenfassung',
      benefit3: '30 Bildübersetzungen',
      benefit4: 'Videoübersetzung und Zusammenfassung',
      benefit5: '10 PDF -Übersetzungen',
      benefit6: '5.000 Token',
      btn: 'Versuchen Sie es kostenlos',
      recommendText: 'Freie Testversion',
    },
    imageTranslation: 'Bildübersetzung',
    month: 'Monat',
    monthly: 'Monatlich',
    pageTitle: 'Preisgestaltung',
    pdf: {
      chat: 'PDF -Chat -Abfragen',
      maxPage: 'Max. Seiten pro pdf',
      translation: 'PDF -Übersetzung',
    },
    preMonth: 'Pro Monat',
    pro: {
      benefit1: 'Prioritäts -Kundenunterstützung',
      benefit2: '1.000.000 Token/Monat',
      benefit3: 'Website -Übersetzung und Zusammenfassung',
      benefit4: '200 Bildübersetzungen',
      benefit5: 'Videoübersetzung und Zusammenfassung',
      benefit6: '200 PDF -Übersetzungen',
      btn: 'Fangen an',
      recommendText: 'Am beliebtesten',
    },
    proPlus: {
      benefit1: 'Prioritäts -Kundenunterstützung',
      benefit2: '3.000.000 Token/Monat',
      benefit3: 'Website -Übersetzung und Zusammenfassung',
      benefit4: '500 Bildübersetzungen',
      benefit5: 'Videoübersetzung und Zusammenfassung',
      benefit6: '500 PDF -Übersetzungen',
      recommendText: 'Bester Wert',
    },
    video: { summary: 'Videoübersicht', translation: 'Videoübersetzung' },
    web: {
      contrastiveTranslation: 'Zweisprachige Übersetzung',
      dictionaryTranslation: 'Auswahlübersetzung',
      hoverTranslation: 'Schwebende Übersetzung',
      instantTranslation: 'Sofortige Übersetzung',
      summary: 'Webseite Zusammenfassung',
    },
    yearly: 'Jährlich',
  },
  privacy: {
    block1:
      'Noam ("Noam", "Wir", "Our" oder "Us") respektiert Ihre Privatsphäre und verpflichtet sich, alle Informationen zu schützen, die wir vor Ihnen oder über Sie erhalten. Diese Datenschutzrichtlinie beschreibt unsere Praktiken in Bezug auf die Erfassung oder Nutzung persönlicher Informationen aus Ihrer Nutzung unserer Website, Anwendungen und Dienste (zusammen "Dienste"). Diese Datenschutzrichtlinie gilt nicht für den Inhalt, den wir im Namen unserer Geschäftsproduktkunden wie unserer API übernehmen. Die Verwendung dieser Daten unterliegt unserer Kundenvereinbarung, die den Zugriff auf und die Verwendung dieser Produkte abdeckt.',
    block10:
      'Wir implementieren wirtschaftlich angemessene technische, administrative und organisatorische Maßnahmen zum Schutz von persönlichen Informationen online und offline vor Verlust, Missbrauch und nicht autorisierter Zugang, Offenlegung, Änderung oder feindlicher Handlungen. Keine Internet- oder E -Mail -Übertragung kann jedoch eine vollständige Sicherheit oder Genauigkeit garantieren. Insbesondere E -Mails, die an uns oder von uns gesendet werden, sind möglicherweise nicht sicher. Sie sollten also besonders vorsichtig sein, wenn Sie entscheiden, welche Informationen über den Service oder die E -Mail an uns gesendet werden sollen. Darüber hinaus sind wir nicht für die Behinderung der Datenschutzeinstellungen oder Sicherheitsmaßnahmen in den Sicherheitseinstellungen oder Sicherheitsmaßnahmen der Websites von Websites von Drittanbietern verantwortlich. Wir behalten Ihre persönlichen Daten so lange, wie es erforderlich ist, damit wir Ihnen Dienstleistungen oder für andere legitime Geschäftszwecke erbringen, z. B. bei der Beilegung von Streitigkeiten, den Sicherheitsgründen oder den Einhaltung unserer rechtlichen Verpflichtungen. Die Zeitdauer, in der wir persönliche Informationen behalten, hängt von vielen Faktoren ab, z. B. der Menge, der Natur, der Empfindlichkeit der Informationen, den potenziellen Risiken für nicht autorisierte Verwendung oder Offenlegung, den Zwecken, für die wir die Informationen verarbeiten, und andere rechtsverbindliche Überlegungen.',
    block11:
      'Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wenn wir dies tun, werden wir auf dieser Seite eine aktualisierte Version veröffentlichen, sofern nicht nach geltendem Recht etwas anderes erforderlich ist.',
    block12:
      'Wenn Sie unbeantwortete Fragen oder Bedenken zu diesen Datenschutzrichtlinien haben, wenden Sie sich bitte an unseren Kundenservice. E-Mail:',
    block2:
      'Wir sammeln die folgenden persönlichen Informationen ("persönliche Informationen"), die mit Ihnen zu tun haben:',
    block3:
      'Informationen, die Sie zur Verfügung gestellt haben: Wenn Sie ein Konto erstellen, um unsere Dienste zu nutzen oder mit uns zu kommunizieren, sammeln wir wie folgt persönliche Informationen:',
    block4:
      'Persönliche Informationen, die automatisch aus Ihrer Nutzung von Diensten empfangen werden: Wenn Sie die Dienste zugreifen, verwenden oder interagieren, erhalten wir die folgenden Informationen zu Zugriff, Verwendung oder Interaktion ("technische Informationen"):',
    block5: 'Wir können persönliche Informationen für folgende Zwecke verwenden:',
    block6:
      'Zusammenfassung oder nicht identifizierte Informationen: Wir können persönliche Informationen aggregieren oder identifizieren, um deren Wiederverwendung zu Identifizierungszwecken zu verhindern und solche Informationen zu verwenden, um die Wirksamkeit unserer Dienste zu analysieren, Features zu verbessern und zu unseren Diensten hinzuzufügen, Forschung zu führen und für ähnliche Zwecke zu führen. Darüber hinaus können wir das allgemeine Verhalten und die Merkmale unserer Dienstnutzer analysieren und aggregierte Informationen an Dritte teilen, solche aggregierten Informationen veröffentlichen oder allgemein verfügbar machen. Wir können aggregierte Informationen über die Dienste, durch Cookies und durch andere in dieser Datenschutzrichtlinie beschriebene Mittel sammeln. Wir werden nicht identifizierte Informationen in einem anonymen oder nicht identifizierten Formular verwalten und verwenden und versuchen nicht, diese Informationen neu zu identifizieren, es sei denn, gesetzlich vorgeschrieben.',
    block7:
      'In bestimmten Fällen können wir Ihre persönlichen Daten an Dritte ohne weitere Ankündigung an Sie zur Verfügung stellen, sofern dies nicht gesetzlich vorgeschrieben ist:',
    block8:
      'Abhängig von Ihrem geografischen Standort können Einzelpersonen im europäischen Wirtschaftsgebiet, das Vereinigte Königreich und weltweit bestimmte gesetzliche Rechte in Bezug auf ihre persönlichen Daten haben. Zum Beispiel haben Sie möglicherweise das Recht auf:',
    block9:
      'Der Dienst kann Links zu anderen von Noam betriebenen oder kontrollierten Websites enthalten, einschließlich Social Media Services ("Websites von Drittanbietern"). Die Informationen, die Sie mit Websites Dritter teilen, unterliegen den spezifischen Datenschutzrichtlinien und Nutzungsbedingungen der Websites Dritter, nicht durch diese Datenschutzrichtlinie. Wir stellen diese Links aus und bedeuten nicht unsere Befürwortung oder Überprüfung dieser Websites. Bitte wenden Sie sich direkt auf Websites von Drittanbietern, um Informationen zu ihren Datenschutzrichtlinien und -bedingungen zu erhalten.',
    subBlock1:
      'Kontoinformationen: Wenn Sie ein Konto auf unserer Plattform erstellen, sammeln wir Informationen zu Ihrem Konto, einschließlich Ihres Namens, Kontaktinformationen, Kontoanmeldeinformationen, Zahlungskarteninformationen und Transaktionsverlauf ("Kontoinformationen").',
    subBlock10: 'Bereitstellung, Verwaltung, Wartung und/oder Analyse von Diensten;',
    subBlock11: 'Mit dir kommunizieren;',
    subBlock12: 'Entwicklung neuer Produkte und Dienstleistungen;',
    subBlock13:
      'Verhinderung von Betrug, kriminellen Aktivitäten oder Missbrauch unserer Dienste und des Schutzes der Sicherheit unserer IT -Systeme, Architektur und Netzwerke;',
    subBlock14: 'Geschäftsübertragungen durchführen;',
    subBlock15:
      'Einhaltung rechtlicher Verpflichtungen und Gerichtsverfahren, Schutz unserer Rechte, unserer Privatsphäre, unserer Sicherheit oder des Eigentums sowie der unserer verbundenen Unternehmen, Ihnen oder anderen Dritten.',
    subBlock16:
      'Lieferanten und Dienstleister: Um uns bei der Erfüllung der operativen Bedürfnisse und der Durchführung bestimmter Dienste und Funktionen zu unterstützen, können wir Lieferanten und Dienstanbietern persönliche Informationen zur Verfügung stellen, darunter Hosting -Dienstleister, Cloud -Service -Anbieter und andere Informationstechnologie -Dienstleister, E -Mail -Kommunikationssoftware und Webanalyse -Anbieter, die auf ihre Dienste zugreifen, verarbeiten oder speichern persönliche Informationen, oder speichern Sie persönliche Informationen, die sich nur in unserer Richtung in unserer Richtungen um die Aufgaben befinden.',
    subBlock17:
      'Geschäftstransfers: Wenn wir strategische Transaktionen, Reorganisationen, Insolvenzen, Insolvenzübernahmen oder Dienstleistungen zu einem anderen Anbieter (zusammen "Transaktionen") durchführen, können Ihre persönlichen Informationen und andere Informationen mit Transaktionen mit Transaktionen und anderen Parteien zusammen mit der Übertragung von Transaktionen und anderen Parteien zusammen mit anderen Parteien zusammenfassen.',
    subBlock18:
      'Rechtliche Anforderungen: Wir können Ihre persönlichen Daten mit Regierungsbehörden, Kollegen der Branche oder anderen Dritten teilen, einschließlich Ihrer Interaktionsinformationen mit unseren Dienstleistungen, (1) falls gesetzlich vorgeschrieben oder wenn wir der Ansicht sind, dass diese Maßnahmen erforderlich sind, um die rechtlichen Verpflichtungen nachzukommen. (2) unsere Rechte oder Eigentum zu schützen und zu verteidigen; (3) wenn wir einseitig bestimmen, dass unsere Begriffe, Richtlinien oder Gesetze verstoßen; (4) Betrug oder andere illegale Aktivitäten zu erkennen oder zu verhindern; (5) zu schützen, um unsere Produkte, Mitarbeiter oder Benutzer oder die öffentliche Sicherheit, Sicherheit, Integrität zu schützen; oder (vi) gegen rechtliche Haftung zu verteidigen.',
    subBlock19:
      'Greifen Sie auf Ihre persönlichen Informationen und Informationen darüber zu, wie sie verarbeitet werden.',
    subBlock2:
      'Benutzerinhalt: Wenn Sie unsere Dienste verwenden, sammeln wir persönliche Informationen aus Ihren Eingaben, Dateien -Uploads oder Feedback, die uns zur Verfügung gestellt werden ("Inhalt").',
    subBlock20: 'Fordern Sie die Löschung Ihrer persönlichen Daten aus unseren Unterlagen an.',
    subBlock21: 'Korrigieren oder aktualisieren Sie Ihre persönlichen Daten.',
    subBlock22: 'Übertragen Sie Ihre persönlichen Daten auf einen Dritten (Datenportabilität).',
    subBlock23: 'Beschränken Sie, wie wir Ihre persönlichen Daten verarbeiten.',
    subBlock24:
      'Ziehen Sie Ihre Einwilligung zurück - wo wir uns auf die Zustimmung als rechtliche Grundlage für die Bearbeitung verlassen, können Sie Ihre Einwilligung jederzeit zurückziehen.',
    subBlock25: 'Zeigen Sie darauf hin, wie wir Ihre persönlichen Daten verarbeiten.',
    subBlock26: 'Verbesserung unserer Dienstleistungen und Durchführung von Forschung;',
    subBlock3:
      'Kommunikationsinformationen: Wenn Sie uns Nachrichten senden, sammeln wir Ihren Namen, Ihre Kontaktinformationen und den Inhalt Ihrer Nachrichten ("Kommunikationsinformationen").',
    subBlock4:
      'Social -Media -Informationen: Wir haben Seiten auf Social -Media -Websites wie Instagram, Facebook, Medium, Twitter, YouTube und LinkedIn. Wenn Sie mit unseren Social -Media -Seiten interagieren, sammeln wir die persönlichen Informationen, die Sie uns zur Verfügung stellen, z. B. Ihre Kontaktdaten ("soziale Informationen"). Darüber hinaus können die Unternehmen, die unsere Social -Media -Seiten veranstalten, uns aggregierte Informationen und Analysen zu unseren Social -Media -Aktivitäten zur Verfügung stellen.',
    subBlock5:
      'Protokolldaten: Informationen, die bei Verwendung unserer Dienste automatisch von Ihrem Browser gesendet wurden. Die Protokolldaten umfassen Ihre IP -Adresse (Internet Protocol), den Browsertyp und die Einstellungen, das Datum und die Uhrzeit Ihrer Anfragen und die Interaktion mit unserer Website.',
    subBlock6:
      'Verwendungsdaten: Wir können automatisch Informationen über Ihre Nutzung des Dienstes sammeln, z. B. die Arten von Inhalten, mit denen Sie ansehen oder mit denen Sie interagieren, die von Ihnen verwendeten Funktionen, die von Ihnen ergriffenen Aktionen sowie Ihre Zeitzone, Ihr Land, das Datum und die Uhrzeit des Zugriffs, der Benutzeragent und die Version, der Computer- oder Mobilgeräte -Typ sowie Ihre Computerverbindung.',
    subBlock7:
      'Geräteinformationen: Dies umfasst den Namen des Geräts, das Betriebssystem, die Gerätekennung und den von Ihnen verwendeten Browser. Die gesammelten Informationen können von dem Gerätetyp abhängen, den Sie verwenden, und der Einstellungen.',
    subBlock8:
      'Cookies: Wir verwenden Cookies, um unsere Dienste zu bedienen und zu verwalten und Ihre Erfahrung zu verbessern. Ein "Cookie" sind Informationen, die über eine Website gesendet werden, die Sie in Ihrem Browser besuchen. Sie können Ihren Browser so einstellen, dass Sie alle Cookies akzeptieren, alle Cookies ablehnen oder Sie benachrichtigen, wenn ein Cookie bereitgestellt wird, damit Sie entscheiden können, ob Sie ihn jedes Mal akzeptieren können. Das Ablehnen von Cookies kann jedoch verhindern, dass Sie die Anzeige oder Funktionalität bestimmter Bereiche oder Funktionen der Website verwenden oder negativ beeinflussen. Weitere Informationen zu Cookies finden Sie in allen Cookies.',
    subBlock9:
      'Analytics: Wir können verschiedene Online -Analyseprodukte verwenden, mit denen Cookies verwendet werden, um zu analysieren, wie Benutzer unsere Dienste nutzen und Ihre Erfahrung bei der Nutzung der Dienste verbessern.',
    subTitle1: 'Persönliche Informationen, die wir sammeln',
    subTitle2: 'Wie wir persönliche Informationen verwenden',
    subTitle3: 'Offenlegung persönlicher Informationen',
    subTitle4: 'Ihre Rechte',
    subTitle5: 'Links zu anderen Websites',
    subTitle6: 'Sicherheit und Aufbewahrung von Informationen',
    subTitle7: 'Änderung der Datenschutzrichtlinie',
    subTitle8: 'So kontaktieren Sie uns',
    title: 'Datenschutzrichtlinie',
    updateTime: 'Aktualisiert: 15. März 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Akzeptiere alle',
    text: 'Um Ihre Erfahrung zu verbessern, verwenden wir Cookies, um Inhalte und Dienste zu personalisieren.',
    title: 'Diese Website verwendet Cookies',
  },
  slogen: 'Der einfachste KI -Assistent',
  stripe: {
    contact: 'Kontaktieren Sie uns unter contact@noam.tools mit Fragen!',
    fail: {
      btn: 'Wiederholen',
      text1: 'Wir konnten Ihre Zahlung nicht verarbeiten.',
      text2: 'Überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
      title: 'Zahlung fehlgeschlagen',
    },
    succ: {
      btn: 'Lass uns gehen',
      text1: 'Willkommen bei Noam,',
      text2: 'Fangen wir an!',
      title: 'Glückwunsch',
    },
  },
  terms: {
    block1: 'Danke, dass du Noam benutzt hast!',
    block10:
      '(a) Gebühren und Abrechnung. Sie zahlen alle Gebühren ("Gebühren") entsprechend den Preisen und Bedingungen auf der geltenden Preisseite oder gemäß den schriftlichen Verabschiedung zwischen uns. Wir behalten uns das Recht vor, Preisfehler oder Fehler zu korrigieren, selbst wenn wir Rechnungen ausgestellt oder Zahlungen erhalten haben. Sie geben vollständige und genaue Abrechnungsinformationen an, einschließlich einer gültigen und autorisierten Zahlungsmethode. Wir berechnen Ihre Zahlungsmethode regelmäßig wie vereinbart, können das Abrechnungsdatum vernünftigerweise ändern. Sie genehmigen Noam, seine Partner und Zahlungsverarbeiter von Drittanbietern, Ihre Zahlungsmethode zu berechnen. Wenn Ihre Zahlung fehlschlägt, geben wir Ihnen eine schriftliche Mitteilung und können den Zugang zum Service aussetzen, bis die Zahlung eingegangen ist. Ausgenommen, wie in dieser Vereinbarung auf andere Weise angegeben, sind Zahlungen nicht erstattungsfähig.',
    block11:
      '(b) Steuern. Sofern nicht anders angegeben, enthalten Gebühren keine Bundes-, Landes-, lokalen und ausländischen Steuern, Pflichten und ähnlichen Bewertungen ("Steuern"). Sie sind für alle mit Ihren Einkäufen verbundenen Steuern verantwortlich, ohne Steuern basierend auf unserem Nettoeinkommen, für das wir Sie in Rechnung stellen. Sie erklären sich damit einverstanden, solche Steuern umgehend zu zahlen und uns gesetzlich gültige Datenschutzangaben zu geben und die erforderlichen Zustimmungen für die Behandlung solcher Daten einzuholen.',
    block12:
      '(c) Preisänderungen. Wir können unsere Preise ändern, indem wir Sie über Ihr Konto und/oder unsere Website informieren. Die Preiserhöhungen werden sofort nach der Veröffentlichung wirksam. Alle Preisänderungen gelten für Gebühren für Ihr Konto nach dem Datum des Inkrafttretens der Änderung.',
    block13:
      '(d) Streitigkeiten und überfällige Zahlungen. Wenn Sie Gebühren oder Steuern bestreiten möchten, wenden Sie sich bitte innerhalb von dreißig (30) Tagen nach dem Datum an contact@noam.tools an.',
    block14:
      '(e) freie Verwendung. Sie dürfen nicht mehrere Konten erstellen, um kostenlose Credits zu erhalten. Wenn wir feststellen, dass Sie die kostenlosen Credits nicht in gutem Glauben verwenden, können wir Ihnen Standardgebühren erheben oder den Zugriff auf den Service einstellen.',
    block15:
      '(a) Vertraulichkeit. Möglicherweise haben Sie Zugriff auf vertrauliche Informationen von Noam. Sie dürfen nur vertrauliche Informationen verwenden, wie dies unter diesen Bedingungen für den Zweck der Nutzung des Dienstes zulässig ist. Sie dürfen vertrauliche Informationen an Dritte nicht weitergeben, und Sie schützen vertrauliche Informationen auf eine Weise nicht weniger schützend als Sie Ihre eigenen ähnlichen vertraulichen Informationen zumindest mit angemessener Betreuung schützen. Vertrauliche Informationen sind nicht öffentliche Informationen, die von Noam als vertraulich bezeichnet werden oder dies unter den Umständen, einschließlich Software, Spezifikationen und anderen nicht öffentlichen Geschäftsinformationen, als vertraulich behandelt werden sollte. Vertrauliche Informationen enthalten keine Informationen, die: (1) der Öffentlichkeit im Allgemeinen ohne Verschulden von Ihrer Seite zur Verfügung stehen. (2) Sie hatten in Ihrem Besitz ohne Verpflichtung zur Vertraulichkeit, bevor Sie sie unter diesen Bedingungen erhalten hatten. (3) Ihnen von Dritten ohne Vertraulichkeitsverpflichtungen rechtmäßig weitergegeben wird; oder (4) unabhängig von Ihnen entwickelt, ohne die vertraulichen Informationen zu verwenden. Sie können vertrauliche Informationen offenlegen, wenn dies gesetzlich, Gericht oder andere staatliche Anordnung erforderlich ist. Sie müssen Noam jedoch vorherige schriftliche Mitteilung auf angemessene Weise vorlegen und nach möglichen Ausdehnung angemessene Anstrengungen zur Begrenzung des Offenlegungsumfangs unternehmen, einschließlich der Unterstützung von uns bei der entgegengesetzten Offenlegungsanfragen.',
    block16:
      '(b) Sicherheit. Sie müssen angemessene und geeignete Maßnahmen durchführen, um Ihren Zugang und die Nutzung des Dienstes zu schützen. Wenn Sie Schwachstellen oder Verstöße im Zusammenhang mit Ihrer Nutzung des Dienstes entdecken, müssen Sie sich umgehend mit Noam wenden und Einzelheiten zur Sicherheitsanfälligkeit oder der Verletzung angeben.',
    block17:
      '((c) Verarbeitung personenbezogener Daten. Wenn Sie den Dienst verwenden, um personenbezogene Daten zu verarbeiten, müssen Sie rechtlich ausreichende Datenschutzangaben angeben und die erforderlichen Einwilligungen für die Verarbeitung solcher Daten einholen, und Sie rechtfertigen uns, dass Sie diese Daten gemäß den geltenden Recht verarbeiten.',
    block18:
      '(a) Kündigung; Suspension. Diese Begriffe sind von Ihrer ersten Nutzung des Dienstes wirksam und bleiben bis zur Beendigung in Kraft. Sie können diese Bedingungen jederzeit aus irgendeinem Grund beenden, indem Sie die Nutzung des Dienstes und des Inhalts einstellen. Wir können diese Bedingungen aus irgendeinem Grund mit vorheriger Ankündigung kündigen. Wir können diese Bedingungen sofort kündigen, indem wir Sie benachrichtigen, wenn Sie Abschnitt 2 (Nutzungsanforderungen), Abschnitt 5 (Vertraulichkeit, Sicherheit und Datenschutz), Abschnitt 8 (Streitbeilegung) oder Abschnitt 9 (allgemeine Bedingungen) oder wenn unsere Beziehung zu einem Drittanbieter über unsere Kontrolländerungen oder für die Einhaltung von Rechts- oder Regierungsanfragen über unsere Kontrolländerungen hinausgeht. Wir können Ihren Zugriff auf den Service aussetzen, wenn Sie diese Bedingungen nicht einhalten oder wenn Ihre Verwendung ein Sicherheitsrisiko für uns oder Dritte darstellt oder wenn wir vermuten, dass Ihre Verwendung betrügerisch ist oder uns oder Dritte einer Haftung aussetzen kann.',
    block19:
      '(b) Auswirkungen der Beendigung. Bei der Kündigung werden Sie die Verwendung des Dienstes einstellen und vertrauliche Informationen, wie sie von uns angewiesen wurden, umgehend zurücksenden oder zerstören. Die Bestimmungen dieser Begriffe, die nach ihrer Natur die Kündigung oder Ablauf überleben sollten, einschließlich, aber nicht beschränkt auf die Abschnitte 3 und 5-9, müssen überleben.',
    block2:
      'Diese Nutzungsbedingungen gelten für die Nutzung der NOAM -Dienste, einschließlich unserer Interfaces, Software, Tools, Entwicklerdienste, Daten, Dokumentation und Website (gemeinsam als "Dienste"). Durch die Nutzung unserer Dienste erklären Sie sich damit einverstanden, diese Bedingungen einzuhalten. Unsere Datenschutzrichtlinie erklärt, wie wir persönliche Informationen sammeln und verwenden.',
    block20:
      '(a) Entschädigung. Sie werden uns, unsere Partner und unser Personal von und gegen Ansprüche, Verluste und Kosten (einschließlich Anwaltsgebühren), die sich aus oder im Zusammenhang mit Ihrer Nutzung des Dienstes, einschließlich Ihres Inhalts, Ihrer Nutzung von Produkten oder Dienstleistungen im Zusammenhang mit dem Dienst und Ihrer Verletzung dieser Bedingungen, und Ihrer Verstöße gegen diese Bedingungen, und Ihre Verstöße dieser Bedingungen, die sich auf Ihre Nutzung, und Ihre Verstöße dieser Bedingungen ergeben, und Ihre Verstöße gegen diese Bedingungen oder anwendbares Gesetz, die sich aus und im Zusammenhang mit Ihrer Nutzung des Dienstes oder Ihrer geltenden Gesetze ergeben.',
    block21:
      '(b) Haftungsausschlüsse. Der Service wird "wie sie sind" erbracht. In dem gesetzlich zulässigen Umfang machen wir und unsere verbundenen Unternehmen und Lizenzgeber keine Zusicherungen oder Gewährleistungen in Bezug auf den Service und stellen keine Gewährleistungen vor, einschließlich, aber nicht beschränkt auf Gewährleistung der Handelsfähigkeit, Eignung für einen bestimmten Zweck, zufriedenstellende Qualität, Nichtverletzung und ruhiger Genuss sowie jegliche Gewährleistungen, die sich aus der Hand von Handel oder Nutzung des Handels oder der Nutzung des Handels ergeben. Wir garantieren nicht, dass der Dienst ununterbrochen, genau oder fehlerfrei ist oder dass Inhalte sicher, nicht verloren oder nicht geändert werden.',
    block22:
      '(c) Haftungsbeschränkung. Weder wir, unsere verbundenen Unternehmen noch unsere Lizenzgeber haften für indirekte, zufällige, besondere, konsequente oder strafende Schäden, einschließlich Gewinne, Verlust des Reputation, Verwendungsverlust, Datenverlust oder anderen immateriellen Verlusten, selbst wenn wir über die Möglichkeit einer solchen Schäden hingewiesen wurden. Nach diesen Bedingungen wird unsere Gesamtverpflichtung die Gebühren, die Sie an den Dienst in den zwölf (12) Monaten vor dem Anspruch oder einhundert US -Dollar (100 US -Dollar), je nachdem, was immer größer ist, an den Dienst gezahlt haben. Die Einschränkungen in diesem Abschnitt gelten in maximalem Umfang, das nach geltendem Recht zulässig ist.',
    block23:
      'Die Bedingungen und Ihre Nutzung der Dienste unterliegen den Gesetzen der Vereinigten Staaten, mit Ausnahme der Regeln der US -Gesetze in den USA. Ihre Verwendung des Antrags kann auch anderen lokalen, staatlichen, nationalen oder internationalen Gesetzen unterliegen. Jegliche rechtliche Schritte oder Verfahren in Bezug auf diese Bedingungen werden ausschließlich vor Gericht der Vereinigten Staaten eingereicht, und Sie erklären sich damit einverstanden, sich der persönlichen Zuständigkeit dieser Gerichte zu unterwerfen.',
    block24:
      '(a) Beziehung der Parteien. Noam und Sie sind unabhängige Auftragnehmer, und keine Partei hat die Befugnis, die andere Partei zu vertreten oder zu binden oder Verpflichtungen für die andere Partei ohne vorherige schriftliche Zustimmung der anderen Partei zu übernehmen.',
    block25:
      '(b) Markennutzung. Sie dürfen ohne unsere vorherige schriftliche Zustimmung nicht den Namen, Logos oder Marken von Noam oder seiner verbundenen Unternehmen verwenden.',
    block26:
      'Wenn eine Bestimmung dieser Bedingungen als ungültig, illegal oder nicht durchsetzbar ermittelt wird, bleiben die verbleibenden Bestimmungen in voller Kraft und Wirkung.',
    block3:
      'Wenn Sie unter 18 Jahre alt sind, müssen Sie die Erlaubnis von Ihren Eltern oder Erziehungsberechtigten einholen, den Dienst zu nutzen. Wenn Sie den Dienst im Namen einer anderen Person oder eines Unternehmens verwenden, müssen Sie befugt sein, diese Bedingungen in ihrem Namen zu akzeptieren. Sie müssen genaue und vollständige Informationen zur Registrierung für ein Konto bereitstellen. Sie dürfen Ihre Zugriffsanmeldeinformationen oder Ihr Konto für Personen außerhalb Ihrer Organisation möglicherweise nicht zur Verfügung stellen, und Sie sind für alle Aktivitäten verantwortlich, die mit Ihren Anmeldeinformationen durchgeführt werden.',
    block4:
      '(a) Nutzung des Dienstes. Sie können unter diesen Bedingungen auf den Service zugreifen, und wir gewähren Ihnen ein nicht exklusives Recht, den Service zu nutzen. Bei der Nutzung des Dienstes entsprechen Sie diese Bedingungen und alle anwendbaren Gesetze. Wir und unsere Partner besitzen alle Rechte, Titel und Interessen am Dienst.',
    block5:
      '(b) Feedback. Wir begrüßen Feedback, Kommentare, Ideen, Vorschläge und Verbesserungen. Wenn Sie solche Inhalte angeben, können wir ihn ohne Einschränkungen und ohne Entschädigung für Sie verwenden.',
    block6:
      '(c) Einschränkungen. Sie können nicht: (1) den Dienst in einer Weise nutzen, die gegen andere Rechte anderer verletzt, missbraucht oder verstößt; (2) Reverse Engineer, Decompile, Disassemble, Übersetzung oder andere Versuch, den Quellcode oder die zugrunde liegenden Komponenten der Servicemodelle, Algorithmen und Systeme zu ermitteln (es sei denn, solche Beschränkungen sind nach geltendem Recht verboten); (3) die Ausgabe des Dienstes verwenden, um Modelle zu entwickeln, die mit Noam konkurrieren. (4) Daten oder Ausgabe aus dem Dienst mit automatisierten oder Programmiermethoden extrahieren, sofern dies nicht über die API zulässig ist, einschließlich Schablonen, Webernte oder Webdatenextraktion; (5) die Ausgabe des Dienstes darstellen, wie künstlich erzeugt, wenn dies nicht der Fall ist, oder auf andere Weise gegen unsere Nutzungsrichtlinien verstoßen; (6) API -Schlüssel ohne unsere vorherige Zustimmung kaufen, verkaufen oder übertragen; oder (7) uns persönliche Informationen von Kindern unter 13 Jahren oder anwendbarem Alter der digitalen Zustimmung zur Verfügung stellen. Sie werden in unserer Dokumentation die Ratengrenzen und andere Anforderungen einhalten.',
    block7:
      '(d) Dienste von Drittanbietern. Jegliche Software, Dienstleistungen oder andere mit dem Service verbundene Produkte von Drittanbietern unterliegen ihren eigenen Bedingungen, und wir sind nicht für Produkte von Drittanbietern verantwortlich.',
    block8:
      '(a) Ihr Inhalt. Sie können Eingänge ("Eingabe") für den Dienst angeben und die Ausgabe ("Ausgabe") erzeugt und zurückgegeben, die auf der Eingabe (gemeinsam als "Inhalt" bezeichnet) erzeugt und zurückgegeben werden). Zwischen den Parteien und in dem Maße, in dem Sie gesetzlich zulässig sind, besitzen Sie alle Eingaben. Noam kann Inhalte verwenden, um den Service bereitzustellen und zu pflegen, die anwendbaren Gesetze einzuhalten und unsere Richtlinien durchzusetzen. Sie sind für den Inhalt verantwortlich, einschließlich der Gewährleistung, dass sie keine anwendbaren Gesetze oder diese Bedingungen verstoßen.',
    block9:
      '(b) Genauigkeit. Künstliche Intelligenz und maschinelles Lernen entwickeln sich rasch entwickelnde Forschungsfelder. Wir bemühen uns kontinuierlich, unseren Service zu verbessern, um sie genauer, zuverlässig, sicherer und vorteilhafter zu gestalten. Angesichts der probabilistischen Natur des maschinellen Lernens kann die Verwendung unseres Dienstes in bestimmten Fällen zu falschen Ausgängen führen, die möglicherweise nicht genau reale Personen, Orte oder Tatsachen widerspiegeln. Sie sollten die Genauigkeit jeder Ausgabe anhand Ihres Anwendungsfalls angemessen bewerten, einschließlich der manuellen Überprüfung der Ausgaben.',
    subTitle1: 'Registrierung und Zugang',
    subTitle10: 'Trennbarkeit',
    subTitle2: 'Nutzungsanforderungen',
    subTitle3: 'Inhalt',
    subTitle4: 'Gebühren und Zahlung',
    subTitle5: 'Vertraulichkeit, Sicherheit und Datenschutz',
    subTitle6: 'Begriff und Kündigung',
    subTitle7: 'Entschädigung; Haftungsausschlüsse; Haftungsbeschränkung',
    subTitle8: 'Regierungsrecht und Gerichtsbarkeit',
    subTitle9: 'Allgemeine Begriffe',
    title: 'Nutzungsbedingungen',
    updateTime: 'Aktualisiert: 15. März 2024',
  },
  trialModal: {
    btn: 'Starten Sie jetzt （nur $ 0,99）',
    chat: 'CHATPDF',
    desc1: 'Nach Ihrer Testversion wird Ihnen eine jährliche Gebühr von 180 US -Dollar berechnet',
    desc2: 'Jederzeit abbrechen',
    focus: 'Fokussierung',
    hover: 'Schwebende Übersetzung',
    image: 'Bildübersetzung',
    installBtn: 'Installieren Sie jetzt, um Ihr Geschenk zu erhalten',
    memo: 'Favoritenmanagement',
    pdf: 'PDF Parallele Übersetzung',
    realtime: 'Sofortige Übersetzung',
    select: 'Wortauswahlübersetzung',
    title: 'Willkommensgeschenk',
    trialText: '7-tägige Testversion',
    video: 'Videoübersetzung',
    videoSummary: 'Videoübersicht',
    web: 'Parallele Übersetzung',
    webSummary: 'Webzusammenfassung',
  },
  upgradeModal: {
    btn: 'Upgrade jetzt',
    chat: {
      free: 'Sie haben das Chatpdf -Grenze erreicht',
      pro: 'Sie haben das ChatPDF -Limit für diesen Monat erreicht',
    },
    contactStr:
      'Bitte warten Sie auf den nächsten Reset oder kontaktieren Sie uns unter contact@noam.tools.',
    limitAlertStr: 'Sie haben Ihr kostenloses Planlimit erreicht. Upgrade, um fortzufahren.',
    more: 'Pläne anzeigen',
    pageLimit: { free: 'Sie haben die PDF -Seitenlimit erreicht' },
    title: 'Erinnerung',
    upgradeAlertStr: 'Sie haben Ihr kostenloses Planlimit erreicht. Upgrade, um fortzufahren.',
    upload: {
      free: 'Sie haben Ihre PDF -Übersetzungsgrenze erreicht',
      pro: 'Sie haben Ihr PDF -Übersetzungslimit für diesen Monat erreicht',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Mach jetzt mit',
      label: 'Neue Benutzervorteile:',
      remain: 'Nur 30 links',
      text: 'Nur 100 Plätze verfügbar! Schnapp dir jetzt deine!',
    },
  },
}

export default TRANSLATION
