const TRANSLATION = {
  buyModal: {
    afterFree: 'Sau khi bạn dùng thử miễn phí',
    btn: 'Hãy thử nó miễn phí',
    freeTrial: 'Thử nghiệm miễn phí 3 ngày',
    monthLabel: 'tháng',
    selectLabel: 'Chọn một kế hoạch',
    title: 'Kế hoạch đăng ký',
    yearLabel: 'năm',
  },
  contact: {
    block1:
      'Để tùy chỉnh, câu hỏi hoặc đề xuất, xin vui lòng liên hệ với chúng tôi tại contact@noam.tools. Chúng tôi sẽ trả lời kịp thời.',
    block2:
      'Hỗ trợ chúng tôi bằng cách đánh giá chúng tôi trên cửa hàng mở rộng ứng dụng Google tại ★★★★★★★.',
    block3: 'Giúp người khác bằng cách chia sẻ phần mở rộng này:',
    title: 'Liên hệ với chúng tôi',
  },
  help: {
    AquickOverviewOfNoam: 'ERROR: [A Quick Overview of Noam]',
    ChatPDF: 'Trò chuyện pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Quản lý yêu thích',
    Fixedplugin: 'Đã sửa lỗi plugin',
    Focusing: 'Tập trung',
    Free: 'Miễn phí',
    FreeTrialBenefits: 'Lợi ích dùng thử miễn phí',
    GoWithWikipedia: 'Đi với Wikipedia',
    GoWithYouTube: 'Đi với YouTube',
    HoverTranslation: 'Di chuột dịch',
    Howtouse: 'Cách sử dụng',
    ImageTranslation: 'Dịch hình ảnh',
    InstantTranslation: 'Dịch ngay',
    Membership: 'Tư cách thành viên',
    NoamHelp: 'Noam giúp bạn dịch và tóm tắt',
    PDFparallelTranslation: 'PDF Dịch song song',
    ParallelTranslation: 'Bản dịch song song',
    SkipAllSteps: 'Bỏ qua tất cả các bước',
    Summary: 'Bản tóm tắt',
    Translation: 'Dịch',
    TryItForFree: 'Hãy thử nó miễn phí',
    Video: 'Băng hình',
    VideoSummary: 'Tóm tắt video',
    VideoTranslation: 'Video dịch',
    WebSummary: 'Tóm tắt web',
    WordSelectionTranslation: 'Lựa chọn từ dịch',
    benefits: 'Chúng tôi có những lợi ích đang chờ bạn!',
    dayFree: 'Thử nghiệm miễn phí 3 ngày',
    done: 'Xong',
    next: 'Kế tiếp',
    pin: 'Pin noam để truy cập dễ dàng hơn ～～',
    planIsNotExist: 'Kế hoạch không có sẵn',
    startWiki: 'Hãy bắt đầu với Wikimedia!',
    startYoutube: 'Hãy bắt đầu với YouTube!',
    try: 'Thử nó ra',
    tryNow: 'Hãy thử ngay bây giờ!',
  },
  home: {
    PDFTranslate: {
      desc: 'Dịch song ngữ + Chat PDF',
      sub1: {
        desc: 'Đọc song ngữ cho phép bạn so sánh văn bản gốc và bản dịch cạnh nhau, cải thiện hiệu quả đọc.',
        title: 'Đọc song ngữ',
      },
      sub2: {
        desc: 'AI phân tích và tóm tắt, và có thể trả lời câu hỏi của bạn dựa trên nội dung PDF.',
        title: 'Trò chuyện pdf',
      },
      sub3: {
        desc: 'Bản dịch PDF chạy bằng AI cho độ chính xác và hiệu quả cao hơn.',
        title: 'Độ chính xác và hiệu quả',
      },
      title: 'Pdf dịch',
    },
    aiAssistant: {
      desc: 'Làm cho dịch web thông minh hơn và thuận tiện hơn',
      panel1: {
        desc: 'Các bản dịch tham khảo song ngữ giúp bạn so sánh văn bản gốc với bản dịch để bạn có thể hiểu rõ hơn và cải thiện hiệu quả đọc của bạn.',
        title: 'Dịch song ngữ',
      },
      panel2: {
        left: {
          desc: 'Khi duyệt một trang web, di chuột qua nội dung bạn cần dịch để xem bản dịch.',
          title: 'Di chuột dịch',
        },
        right: {
          desc: 'Khi duyệt một trang web, chọn văn bản bạn muốn dịch để xem bản dịch.',
          title: 'Lựa chọn văn bản dịch',
        },
      },
      panel3: {
        one: {
          desc: 'Dịch và tóm tắt các trang web và nội dung video mọi lúc, mọi nơi, để có trải nghiệm mượt mà hơn!',
          title: 'Điểm và nhấp',
        },
        three: {
          desc: 'Mô hình ngôn ngữ lớn do AI điều khiển đảm bảo độ chính xác và tính nhất quán dịch thuật cho trải nghiệm đọc nâng cao.',
          title: 'AI điều khiển',
        },
        two: {
          desc: 'Phạm vi bảo hiểm đầy đủ nhiều bằng ngôn ngữ, đọc không có hàng rào song ngữ, dịch một nhấp chuột-Trải nghiệm sự khác biệt!',
          title: 'Toàn diện và thuận tiện hơn',
        },
      },
      panel4: {
        tab1: {
          desc: 'Chuyên gia hiệu quả của bạn - Sử dụng Chatgpt, Noam dịch và tóm tắt nội dung video YouTube để giúp bạn tiết kiệm thời gian!',
          key: 'Băng hình',
          title: 'Bản dịch video & Tóm tắt',
        },
        tab2: {
          desc: 'Bấm để dịch hình ảnh trong khi duyệt web.',
          key: 'Hình ảnh',
          title: 'Hình ảnh dịch',
        },
        tab3: {
          desc: 'Trích xuất ngay lập tức thông tin chính từ các trang web và cung cấp một bản tóm tắt, nâng cao hiệu quả đọc của bạn.',
          key: 'Web',
          title: 'Web Dịch & Tóm tắt',
        },
        tag1: 'Đơn giản',
        tag2: 'Có hiệu quả',
        tag3: 'Dễ hiểu',
      },
      title: 'Trợ lý AI',
    },
    getStart: 'Bắt đầu',
    getStartedForFree: 'Bắt đầu miễn phí',
    howItWork: {
      desc: 'NOAM tích hợp các mô hình AI để cung cấp cho người dùng trải nghiệm thông minh cho bản dịch và tóm tắt trang web ngôn ngữ chéo, pdf và dịch hình ảnh, dịch video và tóm tắt',
      title: 'Cách nó hoạt động',
    },
    lastBlock: { desc: 'Bản dịch AI tốt hơn cho trải nghiệm đọc mượt mà hơn' },
    top: {
      desc: 'Noam là tiện ích mở rộng trình duyệt AI miễn phí giúp bạn dịch và tóm tắt các trang web, video và nội dung pdf. \\ N Tham gia NOAM và trải nghiệm phép thuật!',
      title1: 'Một phép thuật',
      title2: 'Một cú nhấp chuột',
    },
    userReviews: {
      desc: 'Đánh giá năm sao trên cửa hàng trang web Chrome',
      title: 'Đánh giá của người dùng',
    },
  },
  installTips: {
    btn: 'Hãy thử nó ngay bây giờ',
    text: 'Cài đặt "Plugin trình duyệt Noam" để mở khóa nhiều tính năng hơn! Tham chiếu chéo web, tóm tắt video web, và nhiều hơn nữa!',
  },
  locale: {
    af: 'Người Afrika',
    am: 'Amharic',
    ar: 'Tiếng Ả Rập',
    as: 'Assamese',
    auto: 'Tự động phát hiện',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    bg: 'Bulgaria',
    bn: 'Bangla',
    bo: 'Tây Tạng',
    bs: 'Bosnian',
    ca: 'Catalan',
    cs: 'Séc',
    cy: 'Xứ Wales',
    da: 'Đan Mạch',
    de: 'Đức',
    dsb: 'Lower Sorbian',
    dv: 'Divehi',
    el: 'Hy Lạp',
    en: 'Tiếng Anh',
    es: 'Tiếng Tây Ban Nha',
    et: 'Estonia',
    eu: 'Basque',
    fa: 'Ba Tư',
    fi: 'Tiếng Phần Lan',
    fil: 'Philippines',
    fj: 'Fijian',
    fr: 'Tiếng Pháp',
    frCA: 'Pháp (Canada)',
    ga: 'Ailen',
    gl: 'Galician',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Tiếng Do Thái',
    hi: 'Tiếng Hindi',
    hr: 'Croatia',
    hsb: 'Thượng Sorbian',
    ht: 'Haiti Creole',
    hu: 'Hungary',
    hy: 'Armenia',
    id: 'Indonesia',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'Iceland',
    it: 'Ý',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (tiếng Latin)',
    ja: 'Nhật Bản',
    ka: 'Gruzia',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Người Kurd (miền Bắc)',
    kn: 'Kannada',
    ko: 'Hàn Quốc',
    ku: 'Người Kurd (Trung tâm)',
    ky: 'Kyrgyz',
    ln: 'Lingala',
    lo: 'Lào',
    lt: 'Litva',
    lug: 'Ganda',
    lv: 'Latvia',
    lzh: 'Trung Quốc (văn học)',
    mai: 'Maithili',
    mg: 'Malagasy',
    mi: 'Māori',
    mk: 'Macedonia',
    ml: 'Malayalam',
    mnCyrl: 'Người Mông Cổ (Cyrillic)',
    mnMong: 'Mông Cổ (truyền thống)',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Người Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Miến Điện)',
    nb: 'Na Uy',
    ne: 'Nepal',
    nl: 'Hà Lan',
    noSearchLang: 'Ngôn ngữ không được hỗ trợ',
    nso: 'Sesotho sa Leboa',
    nya: 'Nyanja',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Đánh bóng',
    placeholder: 'Tìm kiếm',
    prs: 'Dari',
    ps: 'Tiếng trống',
    pt: 'Bồ Đào Nha (Brazil)',
    ptPT: 'Bồ Đào Nha (Bồ Đào Nha)',
    ro: 'Rumani',
    ru: 'Tiếng Nga',
    run: 'RUNDI',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovak',
    sl: 'Người Slovenia',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somali',
    sq: 'Albania',
    srCyrl: 'Serbia (Cyrillic)',
    srLatn: 'Serbia (Latin)',
    st: 'Sesotho',
    sv: 'Thụy Điển',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thái',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tlhLatn: 'Klingon (Latin)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tongan',
    tr: 'Thổ Nhĩ Kỳ',
    tt: 'Tatar',
    ty: 'Tahiti',
    ug: 'Uyghur',
    uk: 'Ukraine',
    ur: 'Tiếng Urdu',
    uz: 'Uzbek (Latin)',
    vi: 'Việt Nam',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Quảng Đông (truyền thống)',
    zh: 'Trung Quốc',
    zhHans: 'Trung Quốc đơn giản hóa',
    zhHant: 'Trung Quốc truyền thống',
    zu: 'Zulu',
  },
  login: {
    and: 'Và',
    btn: 'Đăng nhập bằng Google',
    btn1: 'Đăng nhập ngay bây giờ',
    desc: 'Đăng nhập để mở khóa thêm kinh nghiệm',
    policy: 'Chính sách bảo mật',
    terms: 'Điều khoản sử dụng',
    tips: 'Bằng cách đăng ký, tôi đồng ý với',
    title: 'Chào mừng đến với Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Theo', twitter: 'Twitter' },
    legal: { policy: 'Chính sách bảo mật', terms: 'Điều khoản dịch vụ', title: 'Hợp pháp' },
    product: {
      image: 'Dịch hình ảnh',
      pdf: 'Bản dịch PDF',
      title: 'Các sản phẩm',
      video: 'Bản dịch video & Tóm tắt',
      web: 'Dịch thuật & Tóm tắt Web',
    },
    resources: { title: 'Tài nguyên' },
  },
  pageHeader: {
    contact: 'Liên hệ',
    help: 'Giúp đỡ',
    logout: 'Đăng xuất',
    logoutSucc: 'Đăng xuất.',
    pricing: 'Giá cả',
    settings: 'Cài đặt',
    signUp: 'Đăng nhập',
  },
  pdf: {
    autoDetect: 'Tự động phát hiện',
    cancelBtn: 'Hủy bỏ',
    cancelUpload: 'Hủy bỏ',
    chatPdf: {
      summaryError: 'Tóm tắt thất bại, vui lòng thử lại sau',
      chatError: 'Trò chuyện không thành công, vui lòng thử lại sau',
      Search: 'Tìm kiếm',
      cancel: 'Hủy bỏ',
      chatTitle: 'Chào mừng đến với Noam! Đây là bản tóm tắt về bản PDF của bạn:',
      clear: 'Thông thoáng',
      clearContent:
        'Điều này sẽ bắt đầu một cuộc trò chuyện mới. Cuộc trò chuyện hiện tại sẽ không được lưu. Bạn có chắc không?',
      clearDialogTitle: 'Vui lòng xác nhận',
      clearSucc: 'Đã xóa',
      copy: 'Sao chép',
      copySucc: 'Đã sao chép',
      delete: 'Xóa bỏ',
      deleteCancel: 'Hủy bỏ',
      deleteContent: 'PDF này không thể được phục hồi sau khi bị xóa. Bạn có chắc không?',
      deleteOk: 'Xóa bỏ',
      deleteSuccess: 'Đã xóa',
      deleteTitle: 'Xác nhận xóa',
      expand: 'Sụp đổ',
      historyTitle: 'Lịch sử',
      listError: 'Không tải danh sách đọc. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
      loading: 'Đang tải...',
      noDataDesc: 'Chưa có lịch sử.',
      noMore: 'Không còn nội dung nữa.',
      placeholder: 'Hỏi bất cứ điều gì về bản PDF này',
      summaryTitle: 'Bạn có thể quan tâm đến những câu hỏi này:',
    },
    collectionEmpty: 'Bộ sưu tập của bạn trống rỗng.',
    collectionTab: 'Ghi chú',
    dataLoading: 'Đang tải...',
    dialogContent: 'Bạn có chắc bạn muốn xóa cái này không?',
    dialogTitle: 'Vui lòng xác nhận',
    download: {
      both: 'Song ngữ',
      btn: 'Cứu',
      cancel: 'Đóng cửa sổ này sẽ hủy tải xuống PDF. Bạn có chắc là bạn muốn hủy bỏ?',
      desc: 'Lưu vào thiết bị: Tải xuống bản dịch dưới dạng hình ảnh; Lưu & In: Tải xuống các bản dịch ở định dạng gốc, với hỗ trợ sao chép.',
      download: 'Tải xuống',
      downloadMsg1: 'Tiến trình dịch pdf:',
      downloadMsg2: 'Thời gian ước tính còn lại:',
      downloadSucc: 'PDF đã sẵn sàng! Bấm để lưu.',
      downloadTip: 'Tải xuống PDF. Đừng đóng cửa sổ này để tránh tải xuống lỗi.',
      error: 'Không thể tiết kiệm. Hãy thử in thay thế.',
      fileName: 'Tên tập tin:',
      fileType: 'Loại tệp:',
      loading: 'Tiết kiệm ...',
      loading2: 'Đang tải...',
      minute: 'Phút...',
      msg1: 'Một số bản dịch trong tài liệu này dài hơn văn bản gốc và có thể trùng lặp trong tệp đã tải xuống. Chúng tôi khuyên bạn nên đọc trên trang web.',
      msg2: '1. Bản dịch vẫn chưa hoàn thành. Vui lòng tải xuống sau khi bản dịch kết thúc.',
      msg3: '2. Một số bản dịch quá dài. Đọc trên trang web để tránh chồng chéo văn bản.',
      msg4: 'Nhiệm vụ dịch thuật đang được tiến hành. Vui lòng thử lại sau khi hoàn thành.',
      progress:
        'Dịch pdf. Tiến trình: {{tiến trình}}, thời gian ước tính còn lại: {{phút}} phút ...',
      save: 'Lưu vào thiết bị',
      savePrint: 'Lưu & In',
      success: 'Tải xuống',
      trans: 'Chỉ dịch',
      transContinue: 'Tiếp tục dịch',
    },
    downloadBtn: 'Tải xuống',
    dragTipModal: {
      black: 'không gian',
      leftKey: 'nút trái',
      ok: 'Hiểu rồi',
      press: 'Nhấn',
      tips: 'để kéo và xem.',
    },
    entry: 'PDFS',
    fileErrMsg: 'Đọc tập tin không thành công. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    fileMsg: 'Kích thước tệp hiện tại:',
    fileMsg2: 'Vui lòng tải lên một tập tin nhỏ hơn 300MB.',
    freeBannerBtn: 'Nâng cấp',
    freeBannerTips: 'Giới hạn bản dịch pdf miễn phí đạt được',
    guide: {
      placeholder: 'Dán liên kết PDF ở đây',
      start: 'Bắt đầu dịch',
      text1: 'Đọc song ngữ',
      text2: 'Đọc Bán thức để so sánh văn bản gốc và dịch bên cạnh, cải thiện hiệu quả của bạn.',
      text3: 'Trò chuyện với PDF của bạn',
      text4:
        'Phân tích và tóm tắt hỗ trợ AI. Đặt câu hỏi về PDF của bạn và nhận được câu trả lời ngay lập tức.',
      text5: 'Chính xác và hiệu quả',
      text6: 'Bản dịch PDF chạy bằng AI cho độ chính xác và hiệu quả cao hơn.',
      title1: 'Dịch song ngữ',
      title2: 'Trò chuyện với PDF của bạn',
      toast: 'Vui lòng nhập kết thúc liên kết PDF trực tuyến hợp lệ trong .pdf',
    },
    hasTransTask: 'Một nhiệm vụ dịch đang được tiến hành. Vui lòng chờ.',
    hoverTrans: 'Các đoạn văn bằng di chuột',
    initial: 'Dịch pdf',
    installText: 'Giải pháp một cửa của bạn cho các bản dịch hàng ngày.',
    installText2: 'Vui lòng cài đặt tiện ích mở rộng trước',
    installTips:
      'Cài đặt tiện ích mở rộng cho các bản dịch PDF thậm chí nhiều hơn và bản dịch trang web mượt mà hơn:',
    kw: 'Staple',
    link: 'liên kết',
    listError: 'Không tải PDFS. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    memoTotalCount: 'Tổng số 1 mặt hàng',
    new: 'Tệp mới',
    noId: 'ID tập tin không tìm thấy.',
    notPdf: 'Chỉ các tệp PDF.',
    okBtn: 'Xóa bỏ',
    okBtn2: 'Hiểu rồi',
    original: 'Nguyên bản',
    parallel: 'Song song',
    parseErrDialogContent:
      'Liên kết PDF này không thể được dịch trực tiếp. Vui lòng tải xuống PDF và tải lên để dịch.',
    parseErrTips: 'Không thể phân tích được tệp',
    parseFileToast: 'Không thể phân tích được tệp. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    pdfLoading: 'Phân tích cú pháp ...',
    searchPlaceholder: 'Tìm kiếm',
    selectTrans: 'Chọn văn bản để dịch',
    shareBtn: 'Chia sẻ',
    shareBtnText1: 'Tạo liên kết chia sẻ',
    shareBtnText2: 'Cập nhật nội dung chia sẻ',
    shareModal: {
      cancel: 'Hủy bỏ',
      copy: 'Sao chép',
      copySucc: 'Liên kết sao chép',
      custom: 'Tùy chỉnh:',
      errMsg: 'Nội dung được chọn chưa được dịch đầy đủ. Vui lòng dịch trước khi chia sẻ.',
      gen: 'Chia sẻ',
      modify: 'Biên tập',
      notSupport: 'Ngôn ngữ này hiện không được hỗ trợ.',
      page: 'Trang',
      pageNuErr: 'Vui lòng nhập số trang hợp lệ.',
      range: 'Phạm vi:',
      remain: 'còn lại',
      remain1: 'Tổng thời gian ước tính:',
      search: 'Tìm kiếm',
      settings: 'Cài đặt chia sẻ:',
      shareSetting: 'Cài đặt chia sẻ',
      shareText: 'Chia sẻ',
      shareTips: 'Chia sẻ liên kết PDF được dịch với bạn bè của bạn:',
      sourceLang: 'Từ:',
      target: 'ĐẾN:',
      text1: 'Cài đặt chia sẻ',
      text2: 'Tạo liên kết chia sẻ',
      totalPage: 'Tổng cộng 1 trang',
      trans: 'Dịch',
      translateTips: 'Mẹo: Nếu bản dịch mất một lúc, bạn có thể đóng cửa sổ này và chia sẻ sau.',
      translating: 'Dịch pdf ...',
      update: 'Cập nhật liên kết chia sẻ',
    },
    shareMsg1: 'Chia sẻ liên kết được tạo ra.',
    shareMsg2: 'Cập nhật nội dung chia sẻ.',
    shareMsg3: 'Không thể tạo liên kết chia sẻ. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    shareMsg4: 'Không cập nhật nội dung chia sẻ. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    shareTips: 'Bản dịch pdf của bạn đã sẵn sàng! Chia sẻ nó với bạn bè của bạn.',
    start: 'Bắt đầu dùng thử miễn phí của bạn',
    thumbnail: 'Mục lục',
    toast1: 'Tải PDF. Vui lòng thử lại sau.',
    toast2: 'Nội dung PDF quá lớn. Hộp thoại hiện không được hỗ trợ.',
    toast3: 'Tải xuống. Vui lòng thử lại sau khi tải xuống kết thúc.',
    toolbar: { Adjust: 'Điều chỉnh', autoFit: 'Tự động phù hợp' },
    trans: 'Dịch',
    transSucc: 'Dịch thành công!',
    unLoginToast: 'Vui lòng đăng nhập để sử dụng tính năng này.',
    uploadBtn: 'Tải lên',
    uploadErr: 'Tải lên tệp không thành công. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    uploadErrSignUrl: 'Không nhận được URL tải lên. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    uploadMsg1: 'Kéo và thả tập tin của bạn ở đây.',
    uploadMsg2: 'Các loại tệp được hỗ trợ: PDF |  Kích thước tệp tối đa: 100MB',
    uploadMsg3: 'Bấm để chọn hoặc kéo và thả tệp ở đây.',
    uploading: 'Tải lên',
    uploadingMsg: 'Tải lên. Vui lòng chờ.',
    uploadingMsg1: 'Tệp đang tải lên. Vui lòng thử lại sau.',
    uploadingMsg2: 'Một tập tin hiện đang được chuyển đổi. Vui lòng thử lại sau.',
    uploadingMsg3:
      'Yêu cầu tiến độ chuyển đổi không thành công. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    uploadingMsg4: 'Noam không thể truy cập các tệp cục bộ. Vui lòng tải lên thủ công PDF để dịch.',
    uploadingMsg5: 'Không thể truy xuất URL tệp. Vui lòng thử lại sau hoặc liên hệ với hỗ trợ.',
    video: 'băng hình',
    vipBannerBtn: 'Nâng cấp',
    vipBannerTips: 'Bạn đã đạt được giới hạn dịch pdf của mình trong tháng này.',
    webTrans: 'Tham chiếu chéo đầy đủ',
  },
  pricing: {
    FAQ: 'Câu hỏi thường gặp',
    FQAObj: {
      a1: 'Có, Noam cung cấp một kế hoạch miễn phí bạn có thể sử dụng ngay lập tức.',
      a2: 'Chúng tôi cung cấp ba kế hoạch thành viên: Pro, Pro+và Enterprise. Bạn có thể chọn thanh toán hàng tháng hoặc hàng năm. Thanh toán hàng năm cung cấp tiết kiệm đáng kể.',
      a3: 'Tất cả các tính năng có sẵn trên cả hai kế hoạch. Sự khác biệt chính là chi phí: đăng ký hàng năm chi phí ít hơn. Đăng ký hàng tháng được lập hóa đơn mỗi tháng, trong khi các kế hoạch hàng năm được lập hóa đơn một lần mỗi năm.',
      a4: 'Bạn có thể hủy đăng ký bất cứ lúc nào trong cài đặt tài khoản của mình.',
      q1: 'Có kế hoạch miễn phí không?',
      q2: 'Những kế hoạch giá và tùy chọn thanh toán có sẵn?',
      q3: 'Sự khác biệt giữa việc đăng ký hàng tháng so với hàng năm là gì?',
      q4: 'Làm cách nào để hủy đăng ký của tôi?',
    },
    around: 'Xung quanh',
    billedMonthly: 'Hóa đơn hàng tháng',
    billedYearly: 'Hóa đơn hàng năm',
    day: 'ngày',
    desc: 'So sánh các kế hoạch và chọn tốt nhất',
    detailedCompare: 'So sánh kế hoạch',
    discount: 'Giảm 20%',
    enterprise: {
      benefit1: 'Hỗ trợ khách hàng ưu tiên',
      benefit2: 'Nhiều người dùng',
      benefit3: 'Giải pháp phù hợp',
      benefit5: 'Quản lý tài khoản chuyên dụng',
      btn: 'Liên hệ với chúng tôi',
      recommendText: 'Các kế hoạch tùy chỉnh （≥3 人）',
      talkToUs: 'Hãy liên lạc',
    },
    free: {
      benefit1: 'Dịch ngay 100 lần/ngày',
      benefit2: 'Bản dịch & Tóm tắt trang web',
      benefit3: '30 bản dịch hình ảnh',
      benefit4: 'Bản dịch video & Tóm tắt',
      benefit5: '10 bản dịch PDF',
      benefit6: '5.000 mã thông báo',
      btn: 'Hãy thử nó miễn phí',
      recommendText: 'Dùng thử miễn phí',
    },
    imageTranslation: 'Dịch hình ảnh',
    month: 'tháng',
    monthly: 'Hàng tháng',
    pageTitle: 'Giá cả',
    pdf: {
      chat: 'Truy vấn trò chuyện PDF',
      maxPage: 'Tối đa. Trang mỗi pdf',
      translation: 'Bản dịch PDF',
    },
    preMonth: 'Mỗi tháng',
    pro: {
      benefit1: 'Hỗ trợ khách hàng ưu tiên',
      benefit2: '1.000.000 mã thông báo/tháng',
      benefit3: 'Bản dịch & Tóm tắt trang web',
      benefit4: '200 bản dịch hình ảnh',
      benefit5: 'Bản dịch video & Tóm tắt',
      benefit6: '200 bản dịch PDF',
      btn: 'Bắt đầu',
      recommendText: 'Phổ biến nhất',
    },
    proPlus: {
      benefit1: 'Hỗ trợ khách hàng ưu tiên',
      benefit2: '3.000.000 mã thông báo/tháng',
      benefit3: 'Bản dịch & Tóm tắt trang web',
      benefit4: '500 bản dịch hình ảnh',
      benefit5: 'Bản dịch video & Tóm tắt',
      benefit6: '500 bản dịch PDF',
      recommendText: 'Giá trị tốt nhất',
    },
    video: { summary: 'Tóm tắt video', translation: 'Video dịch' },
    web: {
      contrastiveTranslation: 'Dịch song ngữ',
      dictionaryTranslation: 'Lựa chọn dịch',
      hoverTranslation: 'Di chuột dịch',
      instantTranslation: 'Dịch ngay',
      summary: 'Tóm tắt trang web',
    },
    yearly: 'Hàng năm',
  },
  privacy: {
    block1:
      'Noam ("Noam", "Chúng tôi", "của chúng tôi" hoặc "chúng tôi") tôn trọng quyền riêng tư của bạn và cam kết bảo vệ bất kỳ thông tin nào chúng tôi có được từ bạn hoặc về bạn. Chính sách bảo mật này mô tả các thực tiễn của chúng tôi về việc thu thập hoặc sử dụng thông tin cá nhân từ việc bạn sử dụng trang web, ứng dụng và dịch vụ của chúng tôi (gọi chung là "dịch vụ"). Chính sách bảo mật này không áp dụng cho nội dung chúng tôi xử lý thay mặt cho khách hàng sản phẩm kinh doanh của chúng tôi, chẳng hạn như API của chúng tôi. Việc sử dụng dữ liệu này phải tuân theo thỏa thuận khách hàng của chúng tôi, bao gồm quyền truy cập và sử dụng các sản phẩm này.',
    block10:
      'Chúng tôi thực hiện các biện pháp kỹ thuật, hành chính và tổ chức hợp lý về mặt thương mại để bảo vệ thông tin cá nhân trực tuyến và ngoại tuyến khỏi mất mát, lạm dụng và truy cập trái phép, tiết lộ, thay đổi hoặc hành vi thù địch. Tuy nhiên, không có internet hoặc truyền email có thể đảm bảo bảo mật hoặc chính xác hoàn toàn. Cụ thể, các email được gửi cho chúng tôi hoặc từ chúng tôi có thể không an toàn. Vì vậy, bạn nên đặc biệt cẩn thận khi quyết định thông tin nào sẽ gửi cho chúng tôi thông qua dịch vụ hoặc email. Ngoài ra, chúng tôi không chịu trách nhiệm cho bất kỳ sự cản trở nào của các cài đặt quyền riêng tư hoặc các biện pháp bảo mật trong cài đặt bảo mật hoặc các biện pháp bảo mật của trang web của bên thứ ba hoặc bên thứ ba. Chúng tôi sẽ giữ lại thông tin cá nhân của bạn miễn là cần thiết để chúng tôi cung cấp cho bạn các dịch vụ hoặc cho các mục đích kinh doanh hợp pháp khác, chẳng hạn như giải quyết tranh chấp, lý do an ninh và an toàn hoặc tuân thủ các nghĩa vụ pháp lý của chúng tôi. Khoảng thời gian chúng tôi giữ lại thông tin cá nhân sẽ phụ thuộc vào nhiều yếu tố, chẳng hạn như số lượng, tính chất, độ nhạy của thông tin, các rủi ro tiềm ẩn của việc sử dụng hoặc tiết lộ trái phép, mục đích chúng tôi xử lý thông tin và xem xét liên kết về mặt pháp lý khác.',
    block11:
      'Chúng tôi có thể cập nhật chính sách bảo mật này theo thời gian. Khi chúng tôi làm như vậy, chúng tôi sẽ đăng một phiên bản cập nhật trên trang này, trừ khi theo yêu cầu khác của luật hiện hành.',
    block12:
      'Nếu bạn có bất kỳ câu hỏi hoặc mối quan tâm chưa được trả lời nào về chính sách quyền riêng tư này, vui lòng liên hệ với dịch vụ khách hàng của chúng tôi. E-mail:',
    block2: 'Chúng tôi thu thập các thông tin cá nhân sau ("thông tin cá nhân") liên quan đến bạn:',
    block3:
      'Thông tin do bạn cung cấp: Khi bạn tạo một tài khoản để sử dụng dịch vụ của chúng tôi hoặc liên lạc với chúng tôi, chúng tôi thu thập thông tin cá nhân như sau:',
    block4:
      'Thông tin cá nhân tự động nhận được từ việc bạn sử dụng Dịch vụ: Khi bạn truy cập, sử dụng hoặc tương tác với Dịch vụ, chúng tôi nhận được thông tin sau về quyền truy cập, sử dụng hoặc tương tác của bạn ("Thông tin kỹ thuật"):',
    block5: 'Chúng tôi có thể sử dụng thông tin cá nhân cho các mục đích sau:',
    block6:
      'Tóm tắt hoặc thông tin được xác định: Chúng tôi có thể tổng hợp hoặc xác định thông tin cá nhân để ngăn chặn việc sử dụng lại cho mục đích nhận dạng và sử dụng thông tin đó để phân tích tính hiệu quả của các dịch vụ của chúng tôi, cải thiện và thêm các tính năng vào dịch vụ của chúng tôi, tiến hành nghiên cứu và cho các mục đích tương tự. Ngoài ra, chúng tôi có thể phân tích hành vi và đặc điểm chung của người dùng dịch vụ của chúng tôi và chia sẻ thông tin tổng hợp với các bên thứ ba, xuất bản thông tin tổng hợp đó hoặc nói chung. Chúng tôi có thể thu thập thông tin tổng hợp thông qua các dịch vụ, thông qua cookie và thông qua các phương tiện khác được mô tả trong chính sách bảo mật này. Chúng tôi sẽ duy trì và sử dụng thông tin không xác định trong một hình thức ẩn danh hoặc không xác định và chúng tôi sẽ không cố gắng xác định lại thông tin đó trừ khi luật pháp theo yêu cầu.',
    block7:
      'Trong một số trường hợp nhất định, chúng tôi có thể cung cấp thông tin cá nhân của bạn cho các bên thứ ba mà không cần thông báo thêm cho bạn, trừ khi luật pháp theo yêu cầu:',
    block8:
      'Tùy thuộc vào vị trí địa lý của bạn, các cá nhân trong khu vực kinh tế châu Âu, Vương quốc Anh và trên toàn cầu có thể có một số quyền theo luật định nhất định về thông tin cá nhân của họ. Ví dụ: bạn có thể có quyền:',
    block9:
      'Dịch vụ có thể chứa các liên kết đến các trang web khác không được vận hành hoặc kiểm soát bởi NOAM, bao gồm các dịch vụ truyền thông xã hội ("trang web của bên thứ ba"). Thông tin bạn chia sẻ với các trang web của bên thứ ba sẽ được điều chỉnh bởi các chính sách bảo mật cụ thể và các điều khoản dịch vụ của các trang web của bên thứ ba, không phải bởi chính sách quyền riêng tư này. Chúng tôi cung cấp các liên kết này để thuận tiện và không ngụ ý sự chứng thực của chúng tôi hoặc xem xét các trang web này. Vui lòng liên hệ trực tiếp với các trang web của bên thứ ba để biết thông tin về các chính sách và điều khoản về quyền riêng tư của họ.',
    subBlock1:
      'Thông tin tài khoản: Khi bạn tạo một tài khoản trên nền tảng của chúng tôi, chúng tôi thu thập thông tin liên quan đến tài khoản của bạn, bao gồm tên, thông tin liên hệ, thông tin tài khoản, thông tin thẻ thanh toán và lịch sử giao dịch ("thông tin tài khoản").',
    subBlock10: 'Cung cấp, quản lý, duy trì và/hoặc phân tích các dịch vụ;',
    subBlock11: 'Giao tiếp với bạn;',
    subBlock12: 'Phát triển các sản phẩm và dịch vụ mới;',
    subBlock13:
      'Ngăn chặn gian lận, hoạt động tội phạm hoặc lạm dụng các dịch vụ của chúng tôi và bảo vệ an ninh của các hệ thống CNTT, kiến ​​trúc và mạng lưới của chúng tôi;',
    subBlock14: 'Tiến hành chuyển nhượng kinh doanh;',
    subBlock15:
      'Tuân thủ nghĩa vụ pháp lý và thủ tục tố tụng, bảo vệ quyền, quyền riêng tư, bảo mật hoặc tài sản của chúng tôi, cũng như các chi nhánh của chúng tôi, bạn hoặc các bên thứ ba khác.',
    subBlock16:
      'Nhà cung cấp và nhà cung cấp dịch vụ: Để hỗ trợ chúng tôi đáp ứng nhu cầu hoạt động của doanh nghiệp và thực hiện một số dịch vụ và chức năng nhất định, chúng tôi có thể cung cấp thông tin cá nhân cho các nhà cung cấp và nhà cung cấp dịch vụ, bao gồm các nhà cung cấp dịch vụ lưu trữ, nhà cung cấp dịch vụ đám mây và các nhà cung cấp dịch vụ công nghệ thông tin khác, phần mềm truyền thông qua email của chúng tôi.',
    subBlock17:
      'Chuyển giao kinh doanh: Nếu chúng tôi tham gia vào các giao dịch chiến lược, tổ chức lại, phá sản, tiếp quản phá sản hoặc chuyển đổi dịch vụ sang một nhà cung cấp khác (gọi chung là "giao dịch"), thông tin cá nhân của bạn và thông tin khác có thể được chia sẻ với các đối tác giao dịch và các bên khác.',
    subBlock18:
      'Yêu cầu pháp lý: Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các cơ quan chính phủ, đồng nghiệp trong ngành hoặc các bên thứ ba khác, bao gồm thông tin tương tác của bạn với các dịch vụ của chúng tôi, (1) nếu pháp luật yêu cầu hoặc nếu chúng tôi tin rằng hành động đó là cần thiết để tuân thủ nghĩa vụ pháp lý; (2) để bảo vệ và bảo vệ các quyền hoặc tài sản của chúng tôi; (3) nếu chúng ta đơn phương xác định rằng có vi phạm các điều khoản, chính sách hoặc luật pháp của chúng ta; (4) phát hiện hoặc ngăn chặn gian lận hoặc các hoạt động bất hợp pháp khác; (5) để bảo vệ sản phẩm, nhân viên hoặc người dùng của chúng tôi hoặc an toàn công cộng, bảo mật, toàn vẹn; hoặc (vi) để bảo vệ chống lại trách nhiệm pháp lý.',
    subBlock19: 'Truy cập thông tin cá nhân của bạn và thông tin về cách xử lý.',
    subBlock2:
      'Nội dung người dùng: Khi bạn sử dụng dịch vụ của chúng tôi, chúng tôi thu thập thông tin cá nhân từ đầu vào, tải lên tệp hoặc phản hồi được cung cấp cho chúng tôi ("nội dung").',
    subBlock20: 'Yêu cầu xóa thông tin cá nhân của bạn khỏi hồ sơ của chúng tôi.',
    subBlock21: 'Sửa hoặc cập nhật thông tin cá nhân của bạn.',
    subBlock22: 'Chuyển thông tin cá nhân của bạn sang bên thứ ba (tính di động dữ liệu).',
    subBlock23: 'Giới hạn cách chúng tôi xử lý thông tin cá nhân của bạn.',
    subBlock24:
      'Rút được sự đồng ý của bạn, nơi chúng tôi dựa vào sự đồng ý làm cơ sở pháp lý để xử lý, bạn có thể rút lại sự đồng ý của mình bất cứ lúc nào.',
    subBlock25: 'Phản đối cách chúng tôi xử lý thông tin cá nhân của bạn.',
    subBlock26: 'Cải thiện dịch vụ của chúng tôi và tiến hành nghiên cứu;',
    subBlock3:
      'Thông tin liên lạc: Nếu bạn gửi tin nhắn cho chúng tôi, chúng tôi sẽ thu thập tên, thông tin liên hệ của bạn và nội dung tin nhắn của bạn ("thông tin liên lạc").',
    subBlock4:
      'Thông tin về phương tiện truyền thông xã hội: Chúng tôi có các trang trên các trang web truyền thông xã hội như Instagram, Facebook, Medium, Twitter, YouTube và LinkedIn. Khi bạn tương tác với các trang truyền thông xã hội của chúng tôi, chúng tôi thu thập thông tin cá nhân bạn chọn để cung cấp cho chúng tôi, chẳng hạn như chi tiết liên hệ của bạn ("thông tin xã hội"). Ngoài ra, các công ty lưu trữ các trang truyền thông xã hội của chúng tôi có thể cung cấp cho chúng tôi thông tin và phân tích tổng hợp về các hoạt động truyền thông xã hội của chúng tôi.',
    subBlock5:
      'Dữ liệu nhật ký: Thông tin tự động được gửi bởi trình duyệt của bạn khi sử dụng Dịch vụ của chúng tôi. Dữ liệu nhật ký bao gồm địa chỉ giao thức Internet (IP) của bạn, loại trình duyệt và cài đặt, ngày và thời gian yêu cầu của bạn và cách bạn tương tác với trang web của chúng tôi.',
    subBlock6:
      'Dữ liệu sử dụng: Chúng tôi có thể tự động thu thập thông tin về việc bạn sử dụng dịch vụ, chẳng hạn như các loại nội dung bạn xem hoặc tương tác, các tính năng bạn sử dụng, hành động bạn thực hiện, cũng như múi giờ, quốc gia, ngày và thời gian truy cập, tác nhân người dùng và phiên bản, loại máy tính hoặc thiết bị di động của bạn.',
    subBlock7:
      'Thông tin về thiết bị: Điều này bao gồm tên của thiết bị, hệ điều hành, định danh thiết bị và trình duyệt bạn đang sử dụng. Thông tin được thu thập có thể phụ thuộc vào loại thiết bị bạn đang sử dụng và cài đặt của nó.',
    subBlock8:
      'Cookies: Chúng tôi sử dụng cookie để vận hành và quản lý dịch vụ của chúng tôi và cải thiện trải nghiệm của bạn. Một "cookie" là thông tin được gửi bởi một trang web bạn truy cập vào trình duyệt của bạn. Bạn có thể đặt trình duyệt của mình chấp nhận tất cả các cookie, từ chối tất cả các cookie hoặc thông báo cho bạn khi một cookie được cung cấp để bạn có thể quyết định có chấp nhận mỗi lần hay không. Tuy nhiên, việc từ chối cookie có thể ngăn bạn sử dụng hoặc ảnh hưởng tiêu cực đến màn hình hoặc chức năng của một số khu vực hoặc tính năng nhất định của trang web. Để biết thêm thông tin về cookie, vui lòng truy cập tất cả về cookie.',
    subBlock9:
      'Phân tích: Chúng tôi có thể sử dụng các sản phẩm phân tích trực tuyến khác nhau sử dụng cookie để giúp chúng tôi phân tích cách người dùng sử dụng dịch vụ của chúng tôi và cải thiện trải nghiệm của bạn khi sử dụng Dịch vụ.',
    subTitle1: 'Thông tin cá nhân chúng tôi thu thập',
    subTitle2: 'Cách chúng tôi sử dụng thông tin cá nhân',
    subTitle3: 'Tiết lộ thông tin cá nhân',
    subTitle4: 'Quyền của bạn',
    subTitle5: 'Liên kết đến các trang web khác',
    subTitle6: 'Bảo mật và lưu giữ thông tin',
    subTitle7: 'Sửa đổi Chính sách bảo mật',
    subTitle8: 'Cách liên hệ với chúng tôi',
    title: 'Chính sách bảo mật',
    updateTime: 'Cập nhật: ngày 15 tháng 3 năm 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Chấp nhận tất cả',
    text: 'Để cải thiện trải nghiệm của bạn, chúng tôi sử dụng cookie để cá nhân hóa nội dung và dịch vụ.',
    title: 'Trang web này sử dụng cookie',
  },
  slogen: 'Trợ lý AI dễ nhất',
  stripe: {
    contact: 'Liên lạc với chúng tôi tại contact@noam.tools với bất kỳ câu hỏi!',
    fail: {
      btn: 'Thử lại',
      text1: 'Chúng tôi không thể xử lý thanh toán của bạn.',
      text2: 'Kiểm tra kết nối của bạn và thử lại.',
      title: 'Thanh toán không thành công',
    },
    succ: {
      btn: 'Hãy đi',
      text1: 'Chào mừng đến với Noam,',
      text2: 'Hãy bắt đầu!',
      title: 'Chúc mừng',
    },
  },
  terms: {
    block1: 'Cảm ơn bạn đã sử dụng Noam!',
    block10:
      '(a) Phí và thanh toán. Bạn sẽ trả tất cả các khoản phí ("phí") theo giá và điều khoản trên trang giá áp dụng hoặc theo thỏa thuận bằng văn bản giữa chúng tôi. Chúng tôi có quyền sửa lỗi hoặc lỗi giá cả ngay cả khi chúng tôi đã cấp hóa đơn hoặc nhận thanh toán. Bạn sẽ cung cấp thông tin thanh toán đầy đủ và chính xác, bao gồm một phương thức thanh toán hợp lệ và được ủy quyền. Chúng tôi sẽ tính phí phương thức thanh toán của bạn trên cơ sở định kỳ theo thỏa thuận, nhưng chúng tôi có thể thay đổi hợp lý ngày thanh toán. Bạn cho phép NOAM, các chi nhánh của nó và bộ xử lý thanh toán của bên thứ ba để tính phí phương thức thanh toán của bạn. Nếu thanh toán của bạn không thành công, chúng tôi sẽ cung cấp cho bạn thông báo bằng văn bản và có thể tạm dừng quyền truy cập vào dịch vụ cho đến khi nhận được thanh toán. Trừ khi có quy định khác trong Thỏa thuận này, các khoản thanh toán không được hoàn lại.',
    block11:
      '(b) Thuế. Trừ khi có quy định khác, các khoản phí không bao gồm bất kỳ thuế liên bang, tiểu bang, địa phương và nước ngoài, nhiệm vụ và các đánh giá tương tự ("thuế"). Bạn chịu trách nhiệm cho tất cả các loại thuế liên quan đến việc mua hàng của bạn, không bao gồm thuế dựa trên thu nhập ròng của chúng tôi mà chúng tôi sẽ lập hóa đơn cho bạn. Bạn đồng ý trả kịp thời các khoản thuế đó và cung cấp cho chúng tôi các báo cáo bảo mật hợp pháp về mặt pháp lý và nhận được sự đồng ý cần thiết để xử lý dữ liệu đó và bạn bảo đảm cho chúng tôi rằng bạn xử lý dữ liệu đó theo luật hiện hành.',
    block12:
      '(c) Thay đổi giá. Chúng tôi có thể thay đổi giá của chúng tôi bằng cách thông báo cho bạn thông qua tài khoản của bạn và/hoặc trang web của chúng tôi. Tăng giá sẽ có hiệu lực ngay lập tức khi đăng. Bất kỳ thay đổi giá nào sẽ áp dụng cho phí cho tài khoản của bạn sau ngày thay đổi có hiệu lực.',
    block13:
      '(d) Tranh chấp và thanh toán quá hạn. Nếu bạn muốn tranh chấp bất kỳ khoản phí hoặc thuế nào, vui lòng liên hệ với contact@noam.tools trong vòng ba mươi (30) ngày kể từ ngày hóa đơn.',
    block14:
      '(e) Sử dụng miễn phí. Bạn không được tạo nhiều tài khoản để có được tín dụng miễn phí. Nếu chúng tôi xác định rằng bạn không sử dụng các khoản tín dụng miễn phí với thiện chí, chúng tôi có thể tính phí tiêu chuẩn hoặc ngừng cung cấp quyền truy cập vào dịch vụ.',
    block15:
      '(a) Bảo mật. Bạn có thể có quyền truy cập vào thông tin bí mật của Noam. Bạn chỉ có thể sử dụng thông tin bí mật như được cho phép theo các điều khoản này cho mục đích sử dụng dịch vụ. Bạn không được tiết lộ thông tin bí mật cho bất kỳ bên thứ ba nào và bạn sẽ bảo vệ thông tin bí mật theo cách không ít bảo vệ hơn bạn bảo vệ thông tin bí mật tương tự của chính mình, ít nhất là với sự chăm sóc hợp lý. Thông tin bí mật là thông tin không công khai được chỉ định là bí mật của NOAM hoặc điều đó nên được coi là bí mật trong các trường hợp, bao gồm phần mềm, thông số kỹ thuật và thông tin kinh doanh không công khai khác. Thông tin bí mật không bao gồm thông tin rằng: (1) thường có sẵn cho công chúng mà không có lỗi về phía bạn; (2) Bạn đã sở hữu mà không có nghĩa vụ bảo mật trước khi nhận được theo các điều khoản này; (3) được tiết lộ một cách hợp pháp cho bạn bởi một bên thứ ba mà không có bất kỳ nghĩa vụ bảo mật nào; hoặc (4) được bạn phát triển độc lập mà không sử dụng thông tin bí mật. Bạn có thể tiết lộ thông tin bí mật nếu luật pháp, tòa án hoặc lệnh của chính phủ khác, nhưng bạn phải cung cấp thông báo trước bằng văn bản cho NOAM một cách hợp lý và, trong phạm vi có thể, nỗ lực hợp lý để hạn chế phạm vi tiết lộ, bao gồm hỗ trợ chúng tôi trong việc phản đối các yêu cầu tiết lộ.',
    block16:
      '(b) Bảo mật. Bạn phải thực hiện các biện pháp hợp lý và phù hợp để giúp bảo vệ quyền truy cập và sử dụng dịch vụ của bạn. Nếu bạn khám phá bất kỳ lỗ hổng hoặc vi phạm nào liên quan đến việc bạn sử dụng dịch vụ, bạn phải ngay lập tức liên hệ với NOAM và cung cấp chi tiết về lỗ hổng hoặc vi phạm.',
    block17: '.',
    block18:
      '(a) chấm dứt; Đình chỉ. Các điều khoản này sẽ có hiệu lực từ việc sử dụng dịch vụ đầu tiên của bạn và sẽ vẫn có hiệu lực cho đến khi chấm dứt. Bạn có thể chấm dứt các Điều khoản này bất cứ lúc nào vì bất kỳ lý do nào bằng cách ngừng sử dụng Dịch vụ và Nội dung. Chúng tôi có thể chấm dứt các điều khoản này với thông báo trước vì bất kỳ lý do nào. Chúng tôi có thể ngay lập tức chấm dứt các Điều khoản này bằng cách thông báo cho bạn nếu bạn vi phạm nghiêm trọng Phần 2 (Yêu cầu sử dụng), Phần 5 (Bảo mật, Bảo mật và Bảo vệ Dữ liệu), Mục 8 (Giải quyết tranh chấp) hoặc Phần 9 (Điều khoản chung) hoặc nếu mối quan hệ của chúng tôi với bất kỳ nhà cung cấp công nghệ nào của bên thứ ba ngoài các thay đổi kiểm soát của chúng tôi hoặc để tuân thủ luật pháp hoặc chính phủ. Chúng tôi có thể đình chỉ quyền truy cập của bạn vào Dịch vụ nếu bạn không tuân thủ các Điều khoản này hoặc nếu việc bạn sử dụng có nguy cơ bảo mật cho chúng tôi hoặc bất kỳ bên thứ ba nào hoặc nếu chúng tôi nghi ngờ việc sử dụng của bạn là gian lận hoặc có thể khiến chúng tôi hoặc bất kỳ bên thứ ba nào phải chịu trách nhiệm pháp lý.',
    block19:
      '(b) Ảnh hưởng của việc chấm dứt. Sau khi chấm dứt, bạn sẽ ngừng sử dụng dịch vụ và nhanh chóng trả lại hoặc phá hủy bất kỳ thông tin bí mật nào theo chỉ dẫn của chúng tôi. Các quy định của các thuật ngữ này mà bản chất của chúng nên tồn tại trong việc chấm dứt hoặc hết hạn, bao gồm nhưng không giới hạn ở phần 3 và 5-9, sẽ tồn tại.',
    block2:
      'Các Điều khoản sử dụng này áp dụng cho việc bạn sử dụng các dịch vụ của NOAM, bao gồm giao diện lập trình ứng dụng, phần mềm, công cụ, dịch vụ nhà phát triển, dữ liệu, tài liệu và trang web của chúng tôi (gọi chung là "Dịch vụ"). Bằng cách sử dụng các dịch vụ của chúng tôi, bạn đồng ý tuân thủ các điều khoản này. Chính sách bảo mật của chúng tôi giải thích cách chúng tôi thu thập và sử dụng thông tin cá nhân.',
    block20:
      '(a) Bồi thường. Bạn sẽ bảo vệ, bồi thường và giữ chúng tôi, các chi nhánh của chúng tôi và nhân viên của chúng tôi vô hại từ và chống lại bất kỳ khiếu nại, tổn thất và chi phí nào (bao gồm cả phí luật sư) phát sinh từ hoặc liên quan đến việc bạn sử dụng dịch vụ, bao gồm cả nội dung, việc sử dụng các sản phẩm hoặc dịch vụ của bạn liên quan đến dịch vụ và vi phạm các điều khoản này hoặc luật áp dụng.',
    block21:
      '(b) Tuyên bố miễn trừ trách nhiệm. Dịch vụ được cung cấp "như hiện tại." Trong phạm vi được pháp luật cho phép, chúng tôi và các chi nhánh và người cấp phép của chúng tôi không đưa ra các đại diện hoặc bảo đảm dưới bất kỳ hình thức nào liên quan đến dịch vụ và không bảo đảm, bao gồm nhưng không giới hạn bảo đảm về tính thương mại, thể lực cho một mục đích cụ thể, chất lượng thỏa đáng, không bị ràng buộc và tận hưởng yên tĩnh. Chúng tôi không đảm bảo rằng dịch vụ sẽ không bị gián đoạn, chính xác hoặc không có lỗi hoặc bất kỳ nội dung nào sẽ được bảo mật, không bị mất hoặc không bị thay đổi.',
    block22:
      '(c) Giới hạn trách nhiệm pháp lý. Cả chúng tôi, các chi nhánh của chúng tôi, cũng không phải là người cấp phép của chúng tôi sẽ chịu trách nhiệm cho bất kỳ thiệt hại gián tiếp, ngẫu nhiên, đặc biệt, hậu quả hoặc trừng phạt, bao gồm mất lợi nhuận, mất danh tiếng, mất sử dụng, mất dữ liệu hoặc tổn thất vô hình khác, ngay cả khi chúng tôi đã được khuyến cáo về khả năng thiệt hại như vậy. Theo các điều khoản này, tổng trách nhiệm của chúng tôi sẽ không vượt quá các khoản phí bạn đã trả cho dịch vụ trong mười hai (12) tháng trước khi yêu cầu hoặc một trăm đô la Mỹ (100 đô la), tùy theo mức nào lớn hơn. Những hạn chế trong phần này áp dụng cho mức độ tối đa được cho phép bởi luật hiện hành.',
    block23:
      'Các điều khoản và việc sử dụng các dịch vụ của bạn được điều chỉnh bởi luật pháp của Hoa Kỳ, ngoại trừ xung đột của các quy tắc luật pháp Hoa Kỳ. Việc bạn sử dụng ứng dụng cũng có thể phải tuân theo luật pháp địa phương, tiểu bang, quốc gia hoặc quốc tế khác. Bất kỳ hành động pháp lý hoặc thủ tục tố tụng nào liên quan đến các Điều khoản này sẽ được đưa ra độc quyền tại các tòa án của Hoa Kỳ và bạn đồng ý đệ trình lên thẩm quyền cá nhân của các tòa án đó.',
    block24:
      '(a) Mối quan hệ của các bên. Noam và bạn là nhà thầu độc lập, và không bên nào có quyền đại diện hoặc ràng buộc bên kia hoặc đảm nhận nghĩa vụ cho bên kia mà không có sự đồng ý trước của bên kia.',
    block25:
      '(b) Sử dụng thương hiệu. Bạn không được sử dụng tên, logo hoặc nhãn hiệu của NOAM hoặc bất kỳ chi nhánh nào của nó mà không có sự đồng ý trước bằng văn bản của chúng tôi.',
    block26:
      'Nếu bất kỳ điều khoản nào của các Điều khoản này được xác định là không hợp lệ, bất hợp pháp hoặc không thể thực thi, các điều khoản còn lại sẽ vẫn có hiệu lực đầy đủ.',
    block3:
      'Nếu bạn dưới 18 tuổi, bạn phải xin phép cha mẹ hoặc người giám hộ hợp pháp để sử dụng dịch vụ. Nếu bạn đang sử dụng dịch vụ thay mặt cho người khác hoặc thực thể, bạn phải được ủy quyền để chấp nhận các điều khoản này thay mặt họ. Bạn phải cung cấp thông tin chính xác và đầy đủ để đăng ký tài khoản. Bạn không được cung cấp thông tin đăng nhập hoặc tài khoản của mình cho bất kỳ ai bên ngoài tổ chức của bạn và bạn chịu trách nhiệm cho tất cả các hoạt động được thực hiện bằng thông tin đăng nhập của bạn.',
    block4:
      '(a) Sử dụng dịch vụ. Bạn có thể truy cập dịch vụ theo các điều khoản này và chúng tôi cấp cho bạn quyền sử dụng dịch vụ không độc quyền. Khi sử dụng dịch vụ, bạn sẽ tuân thủ các Điều khoản này và tất cả các luật hiện hành. Chúng tôi và các chi nhánh của chúng tôi sở hữu tất cả các quyền, tiêu đề và lợi ích trong dịch vụ.',
    block5:
      '(b) Phản hồi. Chúng tôi hoan nghênh phản hồi, nhận xét, ý tưởng, đề xuất và cải tiến. Nếu bạn cung cấp bất kỳ nội dung nào như vậy, chúng tôi có thể sử dụng nó mà không có bất kỳ hạn chế nào và không có bồi thường cho bạn.',
    block6:
      '(c) Hạn chế. Bạn không thể: (1) sử dụng dịch vụ theo cách xâm phạm, chiếm đoạt hoặc vi phạm bất kỳ quyền nào của người khác; . (3) sử dụng đầu ra của dịch vụ để phát triển các mô hình cạnh tranh với NOAM; . (5) đại diện cho đầu ra của dịch vụ được tạo ra một cách giả tạo khi nó không, hoặc vi phạm các chính sách sử dụng của chúng tôi; (6) mua, bán hoặc chuyển khóa API mà không có sự đồng ý trước của chúng tôi; hoặc (7) cung cấp cho chúng tôi bất kỳ thông tin cá nhân nào của trẻ em dưới 13 tuổi hoặc độ tuổi áp dụng của sự đồng ý kỹ thuật số. Bạn sẽ tuân thủ mọi giới hạn tỷ lệ và các yêu cầu khác trong tài liệu của chúng tôi.',
    block7:
      '(d) Dịch vụ của bên thứ ba. Bất kỳ phần mềm, dịch vụ nào của bên thứ ba hoặc các sản phẩm khác được liên kết với dịch vụ đều được điều chỉnh bởi các điều khoản riêng của họ và chúng tôi không chịu trách nhiệm về các sản phẩm của bên thứ ba.',
    block8:
      '(a) Nội dung của bạn. Bạn có thể cung cấp đầu vào ("đầu vào") cho dịch vụ và nhận đầu ra ("đầu ra") được tạo và trả về dựa trên đầu vào (gọi chung là "nội dung"). Giữa các bên và trong phạm vi được cho phép bởi luật hiện hành, bạn sở hữu tất cả các đầu vào. NOAM có thể sử dụng nội dung để cung cấp và duy trì dịch vụ, tuân thủ các luật hiện hành và thực thi các chính sách của chúng tôi. Bạn chịu trách nhiệm về nội dung, bao gồm đảm bảo rằng nó không vi phạm bất kỳ luật hiện hành hoặc các điều khoản này.',
    block9:
      '(b) Độ chính xác. Trí tuệ nhân tạo và học máy đang phát triển nhanh chóng các lĩnh vực nghiên cứu. Chúng tôi liên tục cố gắng cải thiện dịch vụ của mình để làm cho nó chính xác hơn, đáng tin cậy, an toàn và có lợi hơn. Với tính chất xác suất của học máy, sử dụng dịch vụ của chúng tôi có thể dẫn đến đầu ra không chính xác trong một số trường hợp nhất định, điều này có thể không phản ánh chính xác các cá nhân, địa điểm hoặc sự kiện thực sự. Bạn nên đánh giá một cách thích hợp tính chính xác của bất kỳ đầu ra nào dựa trên trường hợp sử dụng của bạn, bao gồm cả bằng cách xem xét thủ công các đầu ra.',
    subTitle1: 'Đăng ký và truy cập',
    subTitle10: 'Độ nghiêm trọng',
    subTitle2: 'Yêu cầu sử dụng',
    subTitle3: 'Nội dung',
    subTitle4: 'Lệ phí và thanh toán',
    subTitle5: 'Bảo mật, bảo mật và bảo vệ dữ liệu',
    subTitle6: 'Thời hạn và chấm dứt',
    subTitle7: 'Bồi thường; Từ chối trách nhiệm; Giới hạn trách nhiệm pháp lý',
    subTitle8: 'Luật điều chỉnh và quyền tài phán',
    subTitle9: 'Điều khoản chung',
    title: 'Điều khoản dịch vụ',
    updateTime: 'Cập nhật: ngày 15 tháng 3 năm 2024',
  },
  trialModal: {
    btn: 'Bắt đầu ngay bây giờ （chỉ $ 0,99',
    chat: 'Chatpdf',
    desc1: 'Sau khi dùng thử, bạn sẽ bị tính phí hàng năm là $ 180',
    desc2: 'Hủy bất cứ lúc nào',
    focus: 'Tập trung',
    hover: 'Di chuột dịch',
    image: 'Dịch hình ảnh',
    installBtn: 'Cài đặt ngay bây giờ để nhận món quà của bạn',
    memo: 'Quản lý yêu thích',
    pdf: 'PDF Dịch song song',
    realtime: 'Dịch ngay',
    select: 'Lựa chọn từ dịch',
    title: 'Món quà chào mừng',
    trialText: 'Thử nghiệm 7 ngày',
    video: 'Video dịch',
    videoSummary: 'Tóm tắt video',
    web: 'Bản dịch song song',
    webSummary: 'Tóm tắt web',
  },
  upgradeModal: {
    btn: 'Nâng cấp ngay bây giờ',
    chat: {
      free: 'Bạn đã đạt đến giới hạn chatpdf',
      pro: 'Bạn đã đạt đến giới hạn chatpdf trong tháng này',
    },
    contactStr:
      'Vui lòng đợi thiết lập lại tiếp theo, hoặc liên hệ với chúng tôi tại contact@noam.tools.',
    limitAlertStr: 'Bạn đã đạt đến giới hạn kế hoạch miễn phí của mình. Nâng cấp để tiếp tục.',
    more: 'Xem kế hoạch',
    pageLimit: { free: 'Bạn đã đạt đến giới hạn trang pdf' },
    title: 'Lời nhắc nhở',
    upgradeAlertStr: 'Bạn đã đạt đến giới hạn kế hoạch miễn phí của mình. Nâng cấp để tiếp tục.',
    upload: {
      free: 'Bạn đã đạt đến giới hạn dịch pdf của mình',
      pro: 'Bạn đã đạt đến giới hạn bản dịch pdf của mình trong tháng này',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Tham gia ngay bây giờ',
      label: 'Lợi ích người dùng mới:',
      remain: 'Chỉ còn lại 30',
      text: 'Chỉ có 100 điểm có sẵn! Lấy của bạn ngay bây giờ!',
    },
  },
}

export default TRANSLATION
