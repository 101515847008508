const TRANSLATION = {
  buyModal: {
    afterFree: 'Setelah uji coba gratis Anda',
    btn: 'Cobalah gratis',
    freeTrial: 'Uji coba gratis 3 hari',
    monthLabel: 'bulan',
    selectLabel: 'Pilih rencana',
    title: 'Rencana Langganan',
    yearLabel: 'tahun',
  },
  contact: {
    block1:
      'Untuk penyesuaian, pertanyaan, atau saran, silakan hubungi kami di contact@noam.tools. Kami akan segera merespons.',
    block2:
      'Dukung kami dengan memberi peringkat kepada kami di toko Google Apps Extension di ★★★★★.',
    block3: 'Membantu orang lain dengan berbagi ekstensi ini:',
    title: 'Hubungi kami',
  },
  help: {
    AquickOverviewOfNoam: 'Tinjauan cepat Noam',
    ChatPDF: 'Obrolan pdf',
    ChatPDF2: 'Chatpdf',
    FavoritesManagement: 'Manajemen favorit',
    Fixedplugin: 'Plugin Memperbaiki',
    Focusing: 'Fokus',
    Free: 'Bebas',
    FreeTrialBenefits: 'Manfaat uji coba gratis',
    GoWithWikipedia: 'Pergi dengan Wikipedia',
    GoWithYouTube: 'Pergi dengan youtube',
    HoverTranslation: 'Lempar Terjemahan',
    Howtouse: 'Cara menggunakan',
    ImageTranslation: 'Terjemahan gambar',
    InstantTranslation: 'Terjemahan instan',
    Membership: 'Keanggotaan',
    NoamHelp: 'Noam membantu Anda menerjemahkan dan meringkas',
    PDFparallelTranslation: 'Terjemahan paralel PDF',
    ParallelTranslation: 'Terjemahan paralel',
    SkipAllSteps: 'Lewati semua langkah',
    Summary: 'Ringkasan',
    Translation: 'Terjemahan',
    TryItForFree: 'Cobalah secara gratis',
    Video: 'Video',
    VideoSummary: 'Ringkasan Video',
    VideoTranslation: 'Terjemahan video',
    WebSummary: 'Ringkasan web',
    WordSelectionTranslation: 'Terjemahan Pilihan Kata',
    benefits: 'Kami memiliki manfaat menunggu Anda!',
    dayFree: 'Uji coba gratis 3 hari',
    done: 'Selesai',
    next: 'Berikutnya',
    pin: 'Pin Noam untuk akses yang lebih mudah ～～',
    planIsNotExist: 'Rencana tidak tersedia',
    startWiki: 'Mari kita mulai dengan Wikimedia!',
    startYoutube: 'Mari kita mulai dengan YouTube!',
    try: 'Cobalah',
    tryNow: 'Cobalah sekarang!',
  },
  home: {
    PDFTranslate: {
      desc: 'Terjemahan Bilingual + Obrolan PDF',
      sub1: {
        desc: 'Membaca bilingual memungkinkan Anda membandingkan teks asli dan terjemahan berdampingan, meningkatkan efisiensi membaca.',
        title: 'Bacaan Bilingual',
      },
      sub2: {
        desc: 'AI menganalisis dan merangkum, dan dapat menjawab pertanyaan Anda berdasarkan konten PDF.',
        title: 'Obrolan pdf',
      },
      sub3: {
        desc: 'Terjemahan PDF bertenaga AI untuk akurasi dan efisiensi yang lebih besar.',
        title: 'Akurasi dan efisiensi',
      },
      title: 'PDF Terjemahan',
    },
    aiAssistant: {
      desc: 'Membuat terjemahan web lebih pintar dan lebih nyaman',
      panel1: {
        desc: 'Terjemahan referensi silang bilingual membantu Anda membandingkan teks asli dengan terjemahan sehingga Anda dapat memahami lebih baik dan meningkatkan efisiensi membaca Anda.',
        title: 'Terjemahan dwibahasa',
      },
      panel2: {
        left: {
          desc: 'Saat menjelajahi halaman web, arahkan kursor ke konten yang perlu Anda terjemahkan untuk melihat terjemahannya.',
          title: 'Lempar Terjemahan',
        },
        right: {
          desc: 'Saat menjelajahi halaman web, pilih teks yang ingin Anda terjemahkan untuk melihat terjemahannya.',
          title: 'Terjemahan pemilihan teks',
        },
      },
      panel3: {
        one: {
          desc: 'Terjemahkan dan rangkum halaman web dan konten video kapan saja, di mana saja, untuk pengalaman yang lebih halus!',
          title: 'Tunjuk dan klik',
        },
        three: {
          desc: 'Model bahasa besar yang digerakkan oleh AI memastikan akurasi terjemahan dan konsistensi untuk pengalaman membaca yang ditingkatkan.',
          title: 'AI-driven',
        },
        two: {
          desc: 'Cakupan penuh multi-bahasa, bacaan bebas hambatan dwibahasa, terjemahan satu klik-alami perbedaannya!',
          title: 'Lebih komprehensif dan nyaman',
        },
      },
      panel4: {
        tab1: {
          desc: 'Pakar efisiensi Anda - Menggunakan chatgpt, noam menerjemahkan dan merangkum konten video youtube untuk menghemat waktu Anda!',
          key: 'Video',
          title: 'Terjemahan & Ringkasan Video',
        },
        tab2: {
          desc: 'Klik untuk menerjemahkan gambar saat menjelajahi web.',
          key: 'Gambar',
          title: 'Gambar terjemahan',
        },
        tab3: {
          desc: 'Secara instan mengekstrak informasi utama dari halaman web dan memberikan ringkasan, meningkatkan efisiensi membaca Anda.',
          key: 'Web',
          title: 'Web Terjemahan & Ringkasan',
        },
        tag1: 'Sederhana',
        tag2: 'Efisien',
        tag3: 'Mudah dimengerti',
      },
      title: 'Asisten ai',
    },
    getStart: 'Mulai',
    getStartedForFree: 'Mulailah secara gratis',
    howItWork: {
      desc: 'Noam mengintegrasikan model AI untuk memberi pengguna pengalaman cerdas untuk terjemahan dan peringkasan halaman web lintas bahasa, PDF dan terjemahan gambar, terjemahan video dan peringkasan',
      title: 'Cara kerjanya',
    },
    lastBlock: { desc: 'Terjemahan AI yang lebih baik untuk pengalaman membaca yang lebih halus' },
    top: {
      desc: 'Noam adalah ekstensi browser AI gratis yang membantu Anda menerjemahkan dan merangkum halaman web, video, dan konten PDF. \\ N Bergabunglah dan alami keajaiban!',
      title1: 'Satu sihir',
      title2: 'Satu klik',
    },
    userReviews: { desc: 'Ulasan bintang lima di toko web chrome', title: 'Ulasan Pengguna' },
  },
  installTips: {
    btn: 'Cobalah sekarang',
    text: 'Instal "Noam Browser Plugin" untuk membuka kunci lebih banyak fitur! Referensi silang web, ringkasan video web, dan banyak lagi!',
  },
  locale: {
    af: 'Afrikanas',
    am: 'Amharik',
    ar: 'Arab',
    as: 'Orang Assam',
    auto: 'Deteksi Otomatis',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    bg: 'Bulgaria',
    bn: 'Bangla',
    bo: 'Tibet',
    bs: 'Bosnia',
    ca: 'Catalan',
    cs: 'Ceko',
    cy: 'Welsh',
    da: 'Denmark',
    de: 'Jerman',
    dsb: 'Sorbian Bawah',
    dv: 'Divehi',
    el: 'Orang yunani',
    en: 'Bahasa inggris',
    es: 'Spanyol',
    et: 'Estonia',
    eu: 'Basque',
    fa: 'Persia',
    fi: 'Finlandia',
    fil: 'Orang Filipina',
    fj: 'Fiji',
    fr: 'Perancis',
    frCA: 'Prancis (Kanada)',
    ga: 'Irlandia',
    gl: 'Galicia',
    gom: 'Konkani',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Ibrani',
    hi: 'Hindi',
    hr: 'Kroasia',
    hsb: 'Sorbian Atas',
    ht: 'Haitian Creole',
    hu: 'Hongaria',
    hy: 'Armenia',
    id: 'Indonesia',
    ig: 'Igbo',
    ikt: 'Inuinnaqtun',
    is: 'Islandia',
    it: 'Italia',
    iu: 'Inuktitut',
    iuLatn: 'Inuktitut (Latin)',
    ja: 'Jepang',
    ka: 'Georgia',
    kk: 'Kazakh',
    km: 'Khmer',
    kmr: 'Kurdi (utara)',
    kn: 'Kannada',
    ko: 'Korea',
    ku: 'Kurdi (Tengah)',
    ky: 'Kyrgyz',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuania',
    lug: 'Ganda',
    lv: 'Latvia',
    lzh: 'Cina (sastra)',
    mai: 'Maithili',
    mg: 'Malagasi',
    mi: 'Māori',
    mk: 'Makedonia',
    ml: 'Malayalam',
    mnCyrl: 'Mongolian (Cyrillic)',
    mnMong: 'Mongolia (tradisional)',
    mr: 'Marathi',
    ms: 'Melayu',
    mt: 'Malta',
    mww: 'Hmong Daw',
    my: 'Myanmar (Burma)',
    nb: 'Norwegia',
    ne: 'Nepal',
    nl: 'Belanda',
    noSearchLang: 'Bahasa tidak didukung',
    nso: 'Sesotho SA LEBOA',
    nya: 'ERROR: [Nyanja]',
    or: 'Odia',
    otq: 'Querétaro Otomi',
    pa: 'Punjabi',
    pl: 'Polandia',
    placeholder: 'Mencari',
    prs: 'Dari',
    ps: 'Pashto',
    pt: 'Portugis (Brasil)',
    ptPT: 'Portugis (Portugal)',
    ro: 'Rumania',
    ru: 'Rusia',
    run: 'Rundi',
    rw: 'Kinyarwanda',
    sd: 'Sindhi',
    si: 'Sinhala',
    sk: 'Slovakia',
    sl: 'Slovenia',
    sm: 'Samoa',
    sn: 'Shona',
    so: 'Somalia',
    sq: 'bahasa Albania',
    srCyrl: 'Serbia (Cyrillic)',
    srLatn: 'Serbia (Latin)',
    st: 'Sesotho',
    sv: 'Swedia',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tlhLatn: 'Klingon (Latin)',
    tlhPiqd: 'Klingon (Piqad)',
    tn: 'Setswana',
    to: 'Tonga',
    tr: 'Turki',
    tt: 'Tatar',
    ty: 'Tahitian',
    ug: 'Uyghur',
    uk: 'Ukraina',
    ur: 'Urdu',
    uz: 'Uzbek (Latin)',
    vi: 'Vietnam',
    xh: 'Xhosa',
    yo: 'Yoruba',
    yua: 'Yucatec Maya',
    yue: 'Kanton (tradisional)',
    zh: 'Cina',
    zhHans: 'Cina disederhanakan',
    zhHant: 'Tradisional Cina',
    zu: 'Zulu',
  },
  login: {
    and: 'Dan',
    btn: 'Masuk dengan Google',
    btn1: 'Masuk sekarang',
    desc: 'Masuk untuk membuka lebih banyak pengalaman',
    policy: 'Kebijakan Privasi',
    terms: 'Ketentuan Penggunaan',
    tips: 'Dengan mendaftar, saya setuju dengan',
    title: 'Selamat datang di Noam',
  },
  pageFooter: {
    follow: { facebook: 'Facebook', title: 'Mengikuti', twitter: 'Twitter' },
    legal: { policy: 'Kebijakan Privasi', terms: 'Ketentuan Layanan', title: 'Legal' },
    product: {
      image: 'Terjemahan gambar',
      pdf: 'Terjemahan PDF',
      title: 'Produk',
      video: 'Terjemahan & Ringkasan Video',
      web: 'Terjemahan & Ringkasan Web',
    },
    resources: { title: 'Sumber daya' },
  },
  pageHeader: {
    contact: 'Kontak',
    help: 'Membantu',
    logout: 'Keluar',
    logoutSucc: 'Keluar.',
    pricing: 'Harga',
    settings: 'Pengaturan',
    signUp: 'Masuk',
  },
  pdf: {
    autoDetect: 'Deteksi otomatis',
    cancelBtn: 'Membatalkan',
    cancelUpload: 'Membatalkan',
    chatPdf: {
      summaryError: 'Ringkasan gagal, coba lagi nanti',
      chatError: 'Obrolan gagal, coba lagi nanti',
      Search: 'Mencari',
      cancel: 'Membatalkan',
      chatTitle: 'Selamat datang di Noam! Berikut ringkasan PDF Anda:',
      clear: 'Jernih',
      clearContent:
        'Ini akan memulai percakapan baru. Percakapan saat ini tidak akan diselamatkan. Apa kamu yakin?',
      clearDialogTitle: 'Harap konfirmasi',
      clearSucc: 'Dibersihkan',
      copy: 'Menyalin',
      copySucc: 'Disalin',
      delete: 'Menghapus',
      deleteCancel: 'Membatalkan',
      deleteContent: 'PDF ini tidak dapat dipulihkan setelah dihapus. Apa kamu yakin?',
      deleteOk: 'Menghapus',
      deleteSuccess: 'Dihapus',
      deleteTitle: 'Konfirmasi hapus',
      expand: 'Runtuh',
      historyTitle: 'Sejarah',
      listError: 'Gagal memuat daftar bacaan. Silakan coba lagi nanti, atau hubungi dukungan.',
      loading: 'Memuat...',
      noDataDesc: 'Belum ada sejarah.',
      noMore: 'Tidak ada lagi konten.',
      placeholder: 'Tanyakan apapun tentang PDF ini',
      summaryTitle: 'Anda mungkin tertarik dengan pertanyaan ini:',
    },
    collectionEmpty: 'Koleksi Anda kosong.',
    collectionTab: 'Catatan',
    dataLoading: 'Memuat...',
    dialogContent: 'Apakah Anda yakin ingin menghapus ini?',
    dialogTitle: 'Harap konfirmasi',
    download: {
      both: 'Dwibahasa',
      btn: 'Menyimpan',
      cancel:
        'Menutup jendela ini akan membatalkan unduhan PDF. Apakah Anda yakin ingin membatalkan?',
      desc: 'Simpan ke perangkat: Unduh terjemahan sebagai gambar; Save & Print: Unduh terjemahan dalam format asli, dengan dukungan salin.',
      download: 'Unduh',
      downloadMsg1: 'Kemajuan terjemahan PDF:',
      downloadMsg2: 'Perkiraan waktu tersisa:',
      downloadSucc: 'PDF sudah siap! Klik untuk menyimpan.',
      downloadTip: 'Mengunduh pdf. Jangan tutup jendela ini untuk menghindari kesalahan unduhan.',
      error: 'Gagal menyimpan. Coba cetak sebagai gantinya.',
      fileName: 'Nama file:',
      fileType: 'Jenis file:',
      loading: 'Penghematan...',
      loading2: 'Memuat...',
      minute: 'Menit ...',
      msg1: 'Beberapa terjemahan dalam dokumen ini lebih panjang dari teks asli, dan mungkin tumpang tindih dalam file yang diunduh. Kami merekomendasikan membaca di halaman web.',
      msg2: '1. Terjemahan belum lengkap. Silakan unduh setelah terjemahan selesai.',
      msg3: '2. Beberapa terjemahan terlalu panjang. Baca di halaman web untuk menghindari tumpang tindih teks.',
      msg4: 'Tugas terjemahan sedang berlangsung. Silakan coba lagi setelah selesai.',
      progress:
        'Menerjemahkan pdf. Progress: {{progress}}, perkiraan waktu tersisa: {{menit}} menit ...',
      save: 'Simpan ke perangkat',
      savePrint: 'Simpan & Cetak',
      success: 'Diunduh',
      trans: 'Terjemahan saja',
      transContinue: 'Lanjutkan menerjemahkan',
    },
    downloadBtn: 'Unduh',
    dragTipModal: {
      black: 'ruang angkasa',
      leftKey: 'tombol kiri',
      ok: 'Mengerti',
      press: 'Tekan',
      tips: 'untuk menyeret dan melihat.',
    },
    entry: 'PDFS',
    fileErrMsg: 'Membaca file gagal. Silakan coba lagi nanti, atau hubungi dukungan.',
    fileMsg: 'Ukuran file saat ini:',
    fileMsg2: 'Harap unggah file yang lebih kecil dari 300MB.',
    freeBannerBtn: 'Meningkatkan',
    freeBannerTips: 'Batas terjemahan PDF gratis tercapai',
    guide: {
      placeholder: 'Tempel tautan PDF di sini',
      start: 'Mulailah menerjemahkan',
      text1: 'Bacaan Bilingual',
      text2:
        'Baca bilingtual untuk membandingkan teks asli dan terjemahan berdampingan, meningkatkan efisiensi Anda.',
      text3: 'Mengobrol dengan pdf Anda',
      text4:
        'Analisis dan peringkasan bertenaga AI. Ajukan pertanyaan tentang PDF Anda, dan dapatkan jawaban instan.',
      text5: 'Akurat & efisien',
      text6: 'Terjemahan PDF bertenaga AI untuk akurasi dan efisiensi yang lebih besar.',
      title1: 'Terjemahan dwibahasa',
      title2: 'Mengobrol dengan pdf Anda',
      toast: 'Harap masukkan tautan PDF online yang valid berakhir di .pdf',
    },
    hasTransTask: 'Tugas terjemahan sedang berlangsung. Harap tunggu.',
    hoverTrans: 'Paragraf melayang ke translasi',
    initial: 'Menerjemahkan pdf',
    installText: 'Solusi satu atap Anda untuk terjemahan sehari-hari.',
    installText2: 'Harap instal ekstensi terlebih dahulu',
    installTips:
      'Instal ekstensi untuk lebih banyak terjemahan PDF, dan terjemahan situs web yang lebih halus:',
    kw: 'pokok',
    link: 'link',
    listError: 'Gagal memuat PDF. Silakan coba lagi nanti, atau hubungi dukungan.',
    memoTotalCount: '1 total item',
    new: 'file baru',
    noId: 'ID file tidak ditemukan.',
    notPdf: 'Hanya file pdf.',
    okBtn: 'Menghapus',
    okBtn2: 'Mengerti',
    original: 'Asli',
    parallel: 'Paralel',
    parseErrDialogContent:
      'Tautan PDF ini tidak dapat diterjemahkan secara langsung. Silakan unduh pdf dan unggah untuk terjemahan.',
    parseErrTips: 'Gagal Mengurai File',
    parseFileToast: 'Gagal Mengurai File. Silakan coba lagi nanti, atau hubungi dukungan.',
    pdfLoading: 'Parsing ...',
    searchPlaceholder: 'Mencari',
    selectTrans: 'Pilih teks untuk diterjemahkan',
    shareBtn: 'Membagikan',
    shareBtnText1: 'Menghasilkan tautan berbagi',
    shareBtnText2: 'Perbarui Konten Bersama',
    shareModal: {
      cancel: 'Membatalkan',
      copy: 'Menyalin',
      copySucc: 'Tautan disalin',
      custom: 'Kustomisasi:',
      errMsg:
        'Konten yang dipilih belum sepenuhnya diterjemahkan. Harap terjemahkan sebelum berbagi.',
      gen: 'Membagikan',
      modify: 'Edit',
      notSupport: 'Bahasa ini saat ini tidak didukung.',
      page: 'Halaman',
      pageNuErr: 'Harap masukkan nomor halaman yang valid.',
      range: 'Cakupan:',
      remain: 'tersisa',
      remain1: 'Total perkiraan waktu:',
      search: 'Mencari',
      settings: 'Bagikan Pengaturan:',
      shareSetting: 'Bagikan Pengaturan',
      shareText: 'Membagikan',
      shareTips: 'Bagikan tautan PDF yang diterjemahkan dengan teman Anda:',
      sourceLang: 'Dari:',
      target: 'Ke:',
      text1: 'Bagikan Pengaturan',
      text2: 'Menghasilkan tautan berbagi',
      totalPage: 'Total 1 halaman',
      trans: 'Menerjemahkan',
      translateTips:
        'Kiat: Jika terjemahannya memakan waktu, Anda dapat menutup jendela ini dan berbagi nanti.',
      translating: 'Menerjemahkan pdf ...',
      update: 'Perbarui tautan berbagi',
    },
    shareMsg1: 'Bagikan tautan yang dihasilkan.',
    shareMsg2: 'Konten dibagikan diperbarui.',
    shareMsg3: 'Gagal menghasilkan tautan berbagi. Silakan coba lagi nanti, atau hubungi dukungan.',
    shareMsg4: 'Gagal memperbarui konten bersama. Silakan coba lagi nanti, atau hubungi dukungan.',
    shareTips: 'Terjemahan PDF Anda sudah siap! Bagikan dengan teman Anda.',
    start: 'Mulailah uji coba gratis Anda',
    thumbnail: 'Daftar isi',
    toast1: 'Memuat pdf. Silakan coba lagi nanti.',
    toast2: 'Konten PDF terlalu besar. Dialog saat ini tidak didukung.',
    toast3: 'Mengunduh. Silakan coba lagi setelah unduhan selesai.',
    toolbar: { Adjust: 'Menyesuaikan', autoFit: 'Fit otomatis' },
    trans: 'Terjemahan',
    transSucc: 'Terjemahan sukses!',
    unLoginToast: 'Harap masuk untuk menggunakan fitur ini.',
    uploadBtn: 'Mengunggah',
    uploadErr: 'Unggahan file gagal. Silakan coba lagi nanti, atau hubungi dukungan.',
    uploadErrSignUrl: 'Gagal mengunggah URL. Silakan coba lagi nanti, atau hubungi dukungan.',
    uploadMsg1: 'Seret & letakkan file Anda di sini.',
    uploadMsg2: 'Jenis File yang Didukung: PDF |  Ukuran file maks: 100MB',
    uploadMsg3: 'Klik untuk memilih atau menyeret & menjatuhkan file di sini.',
    uploading: 'Mengunggah',
    uploadingMsg: 'Mengunggah. Harap tunggu.',
    uploadingMsg1: 'File mengunggah. Silakan coba lagi nanti.',
    uploadingMsg2: 'File saat ini sedang dikonversi. Silakan coba lagi nanti.',
    uploadingMsg3:
      'Permintaan kemajuan konversi gagal. Silakan coba lagi nanti, atau hubungi dukungan.',
    uploadingMsg4:
      'Noam tidak dapat mengakses file lokal. Tolong unggah PDF secara manual untuk terjemahan.',
    uploadingMsg5: 'Gagal mengambil URL file. Silakan coba lagi nanti, atau hubungi dukungan.',
    video: 'video',
    vipBannerBtn: 'Meningkatkan',
    vipBannerTips: 'Anda telah mencapai batas terjemahan PDF Anda untuk bulan ini.',
    webTrans: 'Referensi silang penuh',
  },
  pricing: {
    FAQ: 'FAQ',
    FQAObj: {
      a1: 'Ya, Noam menawarkan rencana gratis yang dapat Anda gunakan segera.',
      a2: 'Kami menawarkan tiga rencana keanggotaan: Pro, Pro+, dan Enterprise. Anda dapat memilih untuk membayar setiap bulan atau setiap tahun. Penagihan tahunan menawarkan penghematan yang signifikan.',
      a3: 'Semua fitur tersedia di kedua paket. Perbedaan utamanya adalah biaya: Langganan tahunan lebih murah secara keseluruhan. Langganan bulanan ditagih setiap bulan, sedangkan rencana tahunan ditagih setahun sekali.',
      a4: 'Anda dapat membatalkan langganan kapan saja di pengaturan akun Anda.',
      q1: 'Apakah ada rencana gratis?',
      q2: 'Paket harga dan opsi pembayaran apa yang tersedia?',
      q3: 'Apa perbedaan antara berlangganan bulanan vs setiap tahun?',
      q4: 'Bagaimana cara membatalkan langganan saya?',
    },
    around: 'Sekitar',
    billedMonthly: 'Ditagih setiap bulan',
    billedYearly: 'Ditagih setiap tahun',
    day: 'hari',
    desc: 'Bandingkan rencana & pilih yang terbaik',
    detailedCompare: 'Bandingkan rencana',
    discount: 'Diskon 20%',
    enterprise: {
      benefit1: 'Dukungan Pelanggan Prioritas',
      benefit2: 'Banyak pengguna',
      benefit3: 'Solusi yang disesuaikan',
      benefit5: 'Manajer Akun Dedikasi',
      btn: 'Hubungi kami',
      recommendText: 'Paket Kustom （≥3 人）',
      talkToUs: 'Hubungi',
    },
    free: {
      benefit1: 'Terjemahan instan 100 kali/hari',
      benefit2: 'Terjemahan & Ringkasan Situs Web',
      benefit3: '30 terjemahan gambar',
      benefit4: 'Terjemahan & Ringkasan Video',
      benefit5: '10 terjemahan PDF',
      benefit6: '5.000 token',
      btn: 'Cobalah gratis',
      recommendText: 'Uji coba gratis',
    },
    imageTranslation: 'Terjemahan gambar',
    month: 'bulan',
    monthly: 'Bulanan',
    pageTitle: 'Harga',
    pdf: {
      chat: 'Permintaan obrolan pdf',
      maxPage: 'Max. Halaman per pdf',
      translation: 'Terjemahan PDF',
    },
    preMonth: 'Per bulan',
    pro: {
      benefit1: 'Dukungan Pelanggan Prioritas',
      benefit2: '1.000.000 token/bulan',
      benefit3: 'Terjemahan & Ringkasan Situs Web',
      benefit4: '200 terjemahan gambar',
      benefit5: 'Terjemahan & Ringkasan Video',
      benefit6: '200 terjemahan PDF',
      btn: 'Mulai',
      recommendText: 'Paling Populer',
    },
    proPlus: {
      benefit1: 'Dukungan Pelanggan Prioritas',
      benefit2: '3.000.000 token/bulan',
      benefit3: 'Terjemahan & Ringkasan Situs Web',
      benefit4: '500 terjemahan gambar',
      benefit5: 'Terjemahan & Ringkasan Video',
      benefit6: '500 terjemahan PDF',
      recommendText: 'Nilai terbaik',
    },
    video: { summary: 'Ringkasan Video', translation: 'Terjemahan video' },
    web: {
      contrastiveTranslation: 'Terjemahan dwibahasa',
      dictionaryTranslation: 'Terjemahan seleksi',
      hoverTranslation: 'Lempar Terjemahan',
      instantTranslation: 'Terjemahan instan',
      summary: 'Ringkasan Halaman Web',
    },
    yearly: 'Tahunan',
  },
  privacy: {
    block1:
      'Noam ("noam," "kami," "kami," atau "kami") menghormati privasi Anda dan berkomitmen untuk melindungi informasi apa pun yang kami peroleh dari Anda atau tentang Anda. Kebijakan privasi ini menjelaskan praktik kami mengenai pengumpulan atau penggunaan informasi pribadi dari penggunaan Anda atas situs web, aplikasi, dan layanan kami (secara kolektif, "Layanan"). Kebijakan privasi ini tidak berlaku untuk konten yang kami tangani atas nama pelanggan produk bisnis kami, seperti API kami. Penggunaan data ini tunduk pada perjanjian pelanggan kami, yang mencakup akses dan penggunaan produk ini.',
    block10:
      'Kami menerapkan langkah -langkah teknis, administrasi, dan organisasi yang wajar secara komersial untuk melindungi informasi pribadi online dan offline dari kehilangan, penyalahgunaan, dan akses yang tidak sah, pengungkapan, perubahan, atau tindakan bermusuhan. Namun, tidak ada transmisi internet atau email yang dapat menjamin keamanan atau akurasi lengkap. Secara khusus, email yang dikirimkan kepada kami atau dari kami mungkin tidak aman. Jadi, Anda harus sangat berhati -hati ketika memutuskan informasi apa yang akan dikirimkan kepada kami melalui layanan atau email. Selain itu, kami tidak bertanggung jawab atas penghalang pengaturan privasi atau langkah -langkah keamanan di Layanan atau pengaturan keamanan situs web pihak ketiga atau langkah -langkah keamanan. Kami akan menyimpan informasi pribadi Anda selama yang diperlukan bagi kami untuk memberi Anda layanan atau untuk tujuan bisnis yang sah lainnya, seperti menyelesaikan perselisihan, alasan keamanan dan keamanan, atau mematuhi kewajiban hukum kami. Lamanya waktu kami menyimpan informasi pribadi akan tergantung pada banyak faktor, seperti jumlah, sifat, sensitivitas informasi, potensi risiko penggunaan atau pengungkapan yang tidak sah, tujuan yang kami proses informasi, dan pertimbangan yang mengikat secara hukum lainnya.',
    block11:
      'Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Ketika kami melakukannya, kami akan memposting versi yang diperbarui di halaman ini, kecuali jika dituntut oleh hukum yang berlaku.',
    block12:
      'Jika Anda memiliki pertanyaan atau masalah yang belum terjawab tentang kebijakan privasi ini, silakan hubungi layanan pelanggan kami. E-mail:',
    block2:
      'Kami mengumpulkan informasi pribadi berikut ("Informasi Pribadi") yang terkait dengan Anda:',
    block3:
      'Informasi yang disediakan oleh Anda: Saat Anda membuat akun untuk menggunakan layanan kami atau berkomunikasi dengan kami, kami mengumpulkan informasi pribadi sebagai berikut:',
    block4:
      'Informasi pribadi secara otomatis diterima dari penggunaan layanan Anda: Saat Anda mengakses, menggunakan, atau berinteraksi dengan Layanan, kami menerima informasi berikut tentang akses, penggunaan, atau interaksi Anda ("Informasi Teknis"):',
    block5: 'Kami dapat menggunakan informasi pribadi untuk tujuan berikut:',
    block6:
      'Ringkasan atau informasi yang tidak diidentifikasi: Kami dapat menggabungkan atau menghilangkan informasi pribadi untuk mencegah penggunaannya kembali untuk tujuan identifikasi dan menggunakan informasi tersebut untuk menganalisis efektivitas layanan kami, meningkatkan dan menambahkan fitur ke layanan kami, melakukan penelitian, dan untuk tujuan yang sama. Selain itu, kami dapat menganalisis perilaku dan karakteristik umum dari pengguna layanan kami dan berbagi informasi agregat dengan pihak ketiga, mempublikasikan informasi agregat tersebut, atau membuatnya tersedia secara umum. Kami dapat mengumpulkan informasi agregat melalui layanan, melalui cookie, dan melalui cara lain yang dijelaskan dalam Kebijakan Privasi ini. Kami akan memelihara dan menggunakan informasi yang tidak diidentifikasi dalam bentuk anonim atau tidak diidentifikasi, dan kami tidak akan berusaha untuk mengidentifikasi kembali informasi tersebut kecuali jika disyaratkan oleh hukum.',
    block7:
      'Dalam kasus -kasus tertentu, kami dapat memberikan informasi pribadi Anda kepada pihak ketiga tanpa pemberitahuan lebih lanjut kepada Anda, kecuali diharuskan oleh hukum:',
    block8:
      'Bergantung pada lokasi geografis Anda, individu di wilayah ekonomi Eropa, Inggris, dan secara global mungkin memiliki hak hukum tertentu mengenai informasi pribadi mereka. Misalnya, Anda mungkin memiliki hak untuk:',
    block9:
      'Layanan ini dapat berisi tautan ke situs web lain yang tidak dioperasikan atau dikendalikan oleh Noam, termasuk layanan media sosial ("situs web pihak ketiga"). Informasi yang Anda bagikan dengan situs web pihak ketiga akan diatur oleh kebijakan privasi spesifik dan ketentuan layanan situs web pihak ketiga, bukan oleh kebijakan privasi ini. Kami menyediakan tautan ini untuk kenyamanan dan tidak menyiratkan dukungan atau ulasan kami terhadap situs web ini. Silakan hubungi situs web pihak ketiga secara langsung untuk informasi tentang kebijakan dan ketentuan privasi mereka.',
    subBlock1:
      'Informasi Akun: Saat Anda membuat akun di platform kami, kami mengumpulkan informasi yang terkait dengan akun Anda, termasuk nama Anda, informasi kontak, kredensial akun, informasi kartu pembayaran, dan riwayat transaksi ("Informasi Akun").',
    subBlock10: 'Menyediakan, mengelola, memelihara, dan/atau menganalisis layanan;',
    subBlock11: 'Berkomunikasi dengan Anda;',
    subBlock12: 'Mengembangkan produk dan layanan baru;',
    subBlock13:
      'Mencegah penipuan, aktivitas kriminal, atau penyalahgunaan layanan kami, dan melindungi keamanan sistem TI, arsitektur, dan jaringan kami;',
    subBlock14: 'Melakukan transfer bisnis;',
    subBlock15:
      'Memenuhi kewajiban hukum dan proses hukum, melindungi hak -hak kami, privasi, keamanan, atau properti, serta afiliasi kami, Anda, atau pihak ketiga lainnya.',
    subBlock16:
      'Pemasok dan Penyedia Layanan: Untuk membantu kami dalam memenuhi kebutuhan operasional bisnis dan melakukan layanan dan fungsi tertentu, kami dapat memberikan informasi pribadi kepada pemasok dan penyedia layanan, termasuk penyedia layanan hosting, penyedia layanan cloud, dan penyedia layanan teknologi informasi lainnya, perangkat lunak komunikasi email, dan penyedia layanan analitik kami, dll. Mitra ini akan mengakses, memproses, atau hanya informasi yang disimpan di kami.',
    subBlock17:
      'Transfer Bisnis: Jika kita terlibat dalam transaksi strategis, reorganisasi, kebangkrutan, pengambilalihan kebangkrutan, atau transisi layanan ke penyedia lain (secara kolektif, "transaksi"), informasi pribadi Anda dan informasi lain dapat dibagikan dengan rekan transaksi dan pihak lain yang membantu dalam transaksi selama penilaian uji coba dan transfer sebagai bagian dari transaksi bersama dengan transaksi.',
    subBlock18:
      'Persyaratan Hukum: Kami dapat membagikan informasi pribadi Anda dengan otoritas pemerintah, rekan industri, atau pihak ketiga lainnya, termasuk informasi interaksi Anda dengan layanan kami, (1) jika diharuskan oleh hukum atau jika kami yakin tindakan tersebut diperlukan untuk mematuhi kewajiban hukum; (2) untuk melindungi dan mempertahankan hak atau properti kami; (3) Jika kita secara sepihak menentukan bahwa ada pelanggaran terhadap persyaratan, kebijakan, atau hukum kita; (4) untuk mendeteksi atau mencegah penipuan atau kegiatan ilegal lainnya; (5) untuk melindungi produk, karyawan, atau pengguna kami, atau keselamatan publik, keamanan, integritas; atau (vi) untuk membela terhadap tanggung jawab hukum.',
    subBlock19: 'Akses informasi pribadi dan informasi Anda tentang bagaimana itu diproses.',
    subBlock2:
      'Konten Pengguna: Saat Anda menggunakan Layanan kami, kami mengumpulkan informasi pribadi dari input Anda, mengunggah file, atau umpan balik yang diberikan kepada kami ("Konten").',
    subBlock20: 'Minta penghapusan informasi pribadi Anda dari catatan kami.',
    subBlock21: 'Memperbaiki atau memperbarui informasi pribadi Anda.',
    subBlock22: 'Transfer informasi pribadi Anda ke pihak ketiga (portabilitas data).',
    subBlock23: 'Batasi cara kami memproses informasi pribadi Anda.',
    subBlock24:
      'Tarik persetujuan Anda - di mana kami mengandalkan persetujuan sebagai dasar hukum untuk diproses, Anda dapat menarik persetujuan Anda kapan saja.',
    subBlock25: 'Keberatan dengan bagaimana kami memproses informasi pribadi Anda.',
    subBlock26: 'Meningkatkan layanan kami dan melakukan penelitian;',
    subBlock3:
      'Informasi Komunikasi: Jika Anda mengirim pesan kepada kami, kami akan mengumpulkan nama Anda, informasi kontak, dan konten pesan Anda ("Informasi Komunikasi").',
    subBlock4:
      'Informasi Media Sosial: Kami memiliki halaman di situs web media sosial seperti Instagram, Facebook, Medium, Twitter, YouTube, dan LinkedIn. Ketika Anda berinteraksi dengan halaman media sosial kami, kami mengumpulkan informasi pribadi yang Anda pilih untuk kami berikan kepada kami, seperti detail kontak Anda ("Informasi Sosial"). Selain itu, perusahaan yang menjadi tuan rumah halaman media sosial kami dapat memberi kami informasi dan analisis agregat tentang kegiatan media sosial kami.',
    subBlock5:
      'Data log: Informasi yang secara otomatis dikirim oleh browser Anda saat menggunakan Layanan kami. Data log termasuk alamat Internet Protocol (IP) Anda, jenis dan pengaturan browser, tanggal dan waktu permintaan Anda, dan bagaimana Anda berinteraksi dengan situs web kami.',
    subBlock6:
      'Data Penggunaan: Kami dapat secara otomatis mengumpulkan informasi tentang penggunaan layanan Anda, seperti jenis konten yang Anda lihat atau berinteraksi dengan, fitur yang Anda gunakan, tindakan yang Anda ambil, serta zona waktu, negara, tanggal dan waktu akses, agen pengguna dan versi, jenis komputer atau perangkat seluler, dan koneksi komputer Anda.',
    subBlock7:
      'Informasi Perangkat: Ini termasuk nama perangkat, sistem operasi, pengidentifikasi perangkat, dan browser yang Anda gunakan. Informasi yang dikumpulkan mungkin tergantung pada jenis perangkat yang Anda gunakan dan pengaturannya.',
    subBlock8:
      'Cookie: Kami menggunakan cookie untuk mengoperasikan dan mengelola layanan kami dan meningkatkan pengalaman Anda. "Cookie" adalah informasi yang dikirim oleh situs web yang Anda kunjungi ke browser Anda. Anda dapat mengatur browser Anda untuk menerima semua cookie, menolak semua cookie, atau memberi tahu Anda saat cookie disediakan sehingga Anda dapat memutuskan apakah akan menerimanya setiap kali. Namun, menolak cookie dapat mencegah Anda menggunakan atau mempengaruhi secara negatif tampilan atau fungsionalitas area tertentu atau fitur situs web. Untuk informasi lebih lanjut tentang cookie, silakan kunjungi semua tentang cookie.',
    subBlock9:
      'Analytics: Kami dapat menggunakan berbagai produk analitik online yang menggunakan cookie untuk membantu kami menganalisis bagaimana pengguna menggunakan layanan kami dan meningkatkan pengalaman Anda saat menggunakan layanan.',
    subTitle1: 'Informasi Pribadi Kami Kumpulkan',
    subTitle2: 'Bagaimana kami menggunakan informasi pribadi',
    subTitle3: 'Pengungkapan informasi pribadi',
    subTitle4: 'Hak Anda',
    subTitle5: 'Tautan ke situs web lain',
    subTitle6: 'Keamanan dan retensi informasi',
    subTitle7: 'Modifikasi Kebijakan Privasi',
    subTitle8: 'Bagaimana Menghubungi Kami',
    title: 'Kebijakan Privasi',
    updateTime: 'Diperbarui: 15 Maret 2024',
  },
  privacyCollectionPanel: {
    acceptBtn: 'Terima semua',
    text: 'Untuk meningkatkan pengalaman Anda, kami menggunakan cookie untuk mempersonalisasikan konten dan layanan.',
    title: 'Situs web ini menggunakan cookie',
  },
  slogen: 'Asisten AI termudah',
  stripe: {
    contact: 'Hubungi kami di contact@noam.tools dengan pertanyaan!',
    fail: {
      btn: 'Mencoba kembali',
      text1: 'Kami tidak dapat memproses pembayaran Anda.',
      text2: 'Periksa koneksi Anda dan coba lagi.',
      title: 'Pembayaran gagal',
    },
    succ: {
      btn: 'Ayo pergi',
      text1: 'Selamat datang di Noam,',
      text2: 'Mari kita mulai!',
      title: 'Selamat',
    },
  },
  terms: {
    block1: 'Terima kasih telah menggunakan Noam!',
    block10:
      '(a) Biaya dan penagihan. Anda akan membayar semua biaya ("biaya") sesuai dengan harga dan persyaratan pada halaman penetapan harga yang berlaku atau seperti yang disepakati secara tertulis di antara kami. Kami berhak untuk memperbaiki kesalahan harga atau kesalahan bahkan jika kami telah mengeluarkan faktur atau menerima pembayaran. Anda akan memberikan informasi penagihan yang lengkap dan akurat, termasuk metode pembayaran yang valid dan resmi. Kami akan menagih metode pembayaran Anda secara berkala seperti yang disepakati, tetapi kami dapat mengubah tanggal penagihan secara wajar. Anda mengesahkan Noam, afiliasinya, dan pemroses pembayaran pihak ketiga untuk menagih metode pembayaran Anda. Jika pembayaran Anda gagal, kami akan memberi Anda pemberitahuan tertulis dan dapat menangguhkan akses ke Layanan sampai pembayaran diterima. Kecuali sebagaimana ditentukan dalam Perjanjian ini, pembayaran tidak dapat dikembalikan.',
    block11:
      '(B) Pajak. Kecuali dinyatakan lain, biaya tidak termasuk pajak federal, negara bagian, lokal, dan asing, bea, dan penilaian serupa ("pajak"). Anda bertanggung jawab atas semua pajak yang terkait dengan pembelian Anda, tidak termasuk pajak berdasarkan laba bersih kami yang akan kami tagakan. Anda setuju untuk segera membayar pajak tersebut dan memberi kami pernyataan privasi yang valid secara hukum dan mendapatkan persetujuan yang diperlukan untuk menangani data tersebut, dan Anda menjamin kepada kami agar Anda menangani data tersebut sesuai dengan hukum yang berlaku.',
    block12:
      '(c) Perubahan harga. Kami dapat mengubah harga kami dengan memberi tahu Anda melalui akun Anda dan/atau situs web kami. Kenaikan harga akan efektif segera setelah posting. Perubahan harga apa pun akan berlaku untuk biaya untuk akun Anda setelah tanggal efektif perubahan.',
    block13:
      '(d) Sengketa dan pembayaran yang sudah lewat. Jika Anda ingin membantah biaya atau pajak, silakan hubungi contact@noam.tools dalam waktu tiga puluh (30) hari dari tanggal faktur.',
    block14:
      '(e) Penggunaan gratis. Anda tidak boleh membuat banyak akun untuk mendapatkan kredit gratis. Jika kami menentukan bahwa Anda tidak menggunakan kredit gratis dengan itikad baik, kami dapat membebankan biaya standar atau berhenti menyediakan akses ke layanan.',
    block15:
      '(a) Kerahasiaan. Anda mungkin memiliki akses ke informasi rahasia Noam. Anda hanya dapat menggunakan informasi rahasia sebagaimana diizinkan berdasarkan Ketentuan ini untuk tujuan menggunakan Layanan. Anda tidak boleh mengungkapkan informasi rahasia kepada pihak ketiga mana pun, dan Anda akan melindungi informasi rahasia dengan cara yang tidak kurang protektif daripada Anda melindungi informasi rahasia Anda sendiri, setidaknya dengan perawatan yang wajar. Informasi rahasia adalah informasi non-publik yang ditetapkan sebagai rahasia oleh Noam atau yang harus diperlakukan secara wajar sebagai rahasia dalam keadaan tersebut, termasuk perangkat lunak, spesifikasi, dan informasi bisnis non-publik lainnya. Informasi rahasia tidak termasuk informasi bahwa: (1) secara umum tersedia untuk umum tanpa kesalahan di pihak Anda; (2) Anda memiliki kepemilikan tanpa kewajiban kerahasiaan sebelum menerimanya berdasarkan Ketentuan ini; (3) secara sah diungkapkan kepada Anda oleh pihak ketiga tanpa kewajiban kerahasiaan; atau (4) dikembangkan secara mandiri oleh Anda tanpa menggunakan informasi rahasia. Anda dapat mengungkapkan informasi rahasia jika diharuskan oleh hukum, pengadilan, atau perintah pemerintah lainnya, tetapi Anda harus memberikan pemberitahuan tertulis sebelumnya kepada Noam dengan cara yang wajar dan, sejauh mungkin, melakukan upaya yang wajar untuk membatasi ruang lingkup pengungkapan, termasuk membantu kami dalam permintaan pengungkapan yang berlawanan.',
    block16:
      '(B) Keamanan. Anda harus menerapkan langkah -langkah yang masuk akal dan tepat untuk membantu melindungi akses Anda ke dan penggunaan Layanan. Jika Anda menemukan kerentanan atau pelanggaran yang terkait dengan penggunaan layanan Anda, Anda harus segera menghubungi Noam dan memberikan rincian kerentanan atau pelanggaran.',
    block17:
      '((c) Pemrosesan data pribadi. Jika Anda menggunakan Layanan untuk memproses data pribadi, Anda harus memberikan pernyataan privasi yang cukup secara hukum dan mendapatkan persetujuan yang diperlukan untuk memproses data tersebut, dan Anda menjamin kepada kami bahwa Anda menangani data tersebut sesuai dengan hukum yang berlaku.',
    block18:
      '(a) penghentian; Penangguhan. Ketentuan ini akan efektif dari penggunaan layanan pertama Anda dan akan tetap berlaku sampai diakhiri. Anda dapat mengakhiri Ketentuan ini kapan saja dengan alasan apa pun dengan menghentikan penggunaan Layanan dan Konten. Kami dapat mengakhiri Ketentuan ini dengan pemberitahuan sebelumnya dengan alasan apa pun. Kami dapat segera mengakhiri Ketentuan ini dengan memberi tahu Anda jika Anda secara material melanggar Bagian 2 (Persyaratan Penggunaan), Bagian 5 (Kerahasiaan, Keamanan, dan Perlindungan Data), Bagian 8 (Penyelesaian Sengketa), atau Bagian 9 (Ketentuan Umum), atau jika hubungan kami dengan penyedia teknologi pihak ketiga mana pun di luar perubahan kendali kami, atau untuk kepatuhan terhadap undang-undang atau permintaan pemerintah. Kami dapat menangguhkan akses Anda ke Layanan jika Anda gagal mematuhi ketentuan -ketentuan ini, atau jika penggunaan Anda menimbulkan risiko keamanan kepada kami atau pihak ketiga mana pun, atau jika kami mencurigai penggunaan Anda curang atau dapat membuat kami atau pihak ketiga mana pun untuk bertanggung jawab.',
    block19:
      '(B) Efek penghentian. Setelah penghentian, Anda akan berhenti menggunakan Layanan dan segera mengembalikan atau menghancurkan informasi rahasia seperti yang diarahkan oleh kami. Ketentuan-ketentuan dari Ketentuan ini bahwa pada dasarnya harus bertahan hidup dari penghentian atau kedaluwarsa, termasuk tetapi tidak terbatas pada bagian 3 dan 5-9, akan bertahan.',
    block2:
      'Ketentuan penggunaan ini berlaku untuk penggunaan layanan Noam Anda, termasuk antarmuka pemrograman aplikasi kami, perangkat lunak, alat, layanan pengembang, data, dokumentasi, dan situs web (secara kolektif disebut sebagai "Layanan"). Dengan menggunakan Layanan kami, Anda setuju untuk mematuhi Ketentuan ini. Kebijakan privasi kami menjelaskan bagaimana kami mengumpulkan dan menggunakan informasi pribadi.',
    block20:
      '(a) Ganti Rugi. Anda akan membela, mengganti rugi, dan menahan kami, afiliasi kami, dan personel kami tidak berbahaya dari dan terhadap klaim, kerugian, dan pengeluaran apa pun (termasuk biaya pengacara) yang timbul dari atau terkait dengan penggunaan layanan Anda, termasuk konten Anda, penggunaan produk atau layanan Anda yang terkait dengan layanan, dan pelanggaran Anda terhadap persyaratan ini atau hukum yang berlaku.',
    block21:
      '(B) Penafian. Layanan disediakan "sebagaimana adanya." Sejauh diizinkan oleh hukum, kami dan afiliasi dan pemberi lisensi kami tidak membuat perwakilan atau jaminan apa pun mengenai layanan tersebut dan tidak membuat jaminan, termasuk tetapi tidak terbatas pada jaminan dapat diperjualbelikan, kebugaran untuk tujuan tertentu, kualitas yang memuaskan, non-pelanggaran, dan kenikmatan yang tenang, dan jaminan apa pun yang timbul di luar perjalanan atau penggunaan perdagangan. Kami tidak menjamin bahwa Layanan akan tidak terputus, akurat, atau bebas dari kesalahan, atau bahwa konten apa pun akan aman, tidak hilang, atau tidak diubah.',
    block22:
      '(c) Keterbatasan tanggung jawab. Baik kami, afiliasi kami, maupun pemberi lisensi kami tidak akan bertanggung jawab atas kerusakan tidak langsung, insidental, khusus, konsekuensial, atau hukuman, termasuk hilangnya keuntungan, kehilangan reputasi, hilangnya penggunaan, kehilangan data, atau kerugian tidak berwujud lainnya, bahkan jika kami telah disarankan tentang kemungkinan ganti rugi tersebut. Di bawah ketentuan ini, total tanggung jawab kami tidak akan melebihi biaya yang Anda bayar ke layanan dalam dua belas (12) bulan sebelum klaim atau seratus dolar AS ($ 100), mana yang lebih besar. Batasan dalam bagian ini berlaku sampai batas maksimum yang diizinkan oleh hukum yang berlaku.',
    block23:
      'Ketentuan dan penggunaan layanan Anda diatur oleh undang -undang Amerika Serikat, kecuali untuk aturan konflik hukum A.S. Penggunaan aplikasi Anda juga dapat dikenakan hukum lokal, negara bagian, nasional atau internasional lainnya. Setiap tindakan hukum atau proses yang berkaitan dengan ketentuan ini akan dibawa secara eksklusif di pengadilan Amerika Serikat, dan Anda setuju untuk menyerahkan ke yurisdiksi pribadi pengadilan tersebut.',
    block24:
      '(a) Hubungan para pihak. Noam dan Anda adalah kontraktor independen, dan tidak ada pihak yang memiliki kekuatan untuk mewakili atau mengikat pihak lain atau memikul kewajiban untuk pihak lain tanpa persetujuan tertulis sebelumnya dari pihak lain.',
    block25:
      '(B) Penggunaan merek. Anda tidak boleh menggunakan nama, logo, atau merek dagang Noam atau afiliasinya tanpa persetujuan tertulis sebelumnya.',
    block26:
      'Jika ada ketentuan dari ketentuan ini yang ditentukan tidak valid, ilegal, atau tidak dapat dilaksanakan, ketentuan yang tersisa akan tetap berlaku penuh dan efek.',
    block3:
      'Jika Anda berusia di bawah 18 tahun, Anda harus mendapatkan izin dari orang tua atau wali sah Anda untuk menggunakan layanan ini. Jika Anda menggunakan Layanan atas nama orang atau entitas lain, Anda harus berwenang untuk menerima Ketentuan ini atas nama mereka. Anda harus memberikan informasi yang akurat dan lengkap untuk mendaftar untuk suatu akun. Anda tidak boleh memberikan kredensial akses atau akun kepada siapa pun di luar organisasi Anda, dan Anda bertanggung jawab atas semua kegiatan yang dilakukan dengan menggunakan kredensial Anda.',
    block4:
      '(a) Penggunaan layanan. Anda dapat mengakses Layanan berdasarkan Ketentuan ini, dan kami memberi Anda hak non-eksklusif untuk menggunakan Layanan. Saat menggunakan Layanan, Anda akan mematuhi Ketentuan ini dan semua hukum yang berlaku. Kami dan afiliasi kami memiliki semua hak, judul, dan kepentingan dalam Layanan.',
    block5:
      '(B) Umpan balik. Kami menyambut umpan balik, komentar, ide, saran, dan perbaikan. Jika Anda memberikan konten semacam itu, kami dapat menggunakannya tanpa batasan dan tanpa kompensasi kepada Anda.',
    block6:
      '(c) Pembatasan. Anda tidak boleh: (1) menggunakan layanan dengan cara yang melanggar, menyalahgunakan, atau melanggar hak orang lain; (2) Reverse Engineer, Dekompilasi, Membongkar, Menerjemahkan, atau Mencoba Menemukan Kode Sumber atau Komponen Yang Mendasari dari Model Layanan, Algoritma, dan Sistem (kecuali pembatasan tersebut dilarang oleh hukum yang berlaku); (3) Gunakan output layanan untuk mengembangkan model yang bersaing dengan Noam; (4) mengekstrak data atau output dari layanan menggunakan metode otomatis atau pemrograman apa pun kecuali diizinkan melalui API, termasuk pengikisan, pemanenan web, atau ekstraksi data web; (5) mewakili output dari layanan sebagaimana dihasilkan secara artifisial ketika tidak, atau melanggar kebijakan penggunaan kami; (6) membeli, menjual, atau mentransfer kunci API tanpa persetujuan kami sebelumnya; atau (7) memberi kami informasi pribadi anak -anak di bawah 13 tahun atau usia persetujuan digital yang berlaku. Anda akan mematuhi batasan laju dan persyaratan lain dalam dokumentasi kami.',
    block7:
      '(D) Layanan Pihak Ketiga. Perangkat lunak pihak ketiga, layanan, atau produk lain yang terkait dengan layanan diatur oleh persyaratan mereka sendiri, dan kami tidak bertanggung jawab atas produk pihak ketiga.',
    block8:
      '(a) Konten Anda. Anda dapat memberikan input ("input") ke layanan dan menerima output ("output") yang dihasilkan dan dikembalikan berdasarkan input (secara kolektif disebut sebagai "konten"). Antara para pihak, dan sejauh diizinkan oleh hukum yang berlaku, Anda memiliki semua input. Noam dapat menggunakan konten untuk menyediakan dan memelihara layanan, mematuhi hukum yang berlaku, dan menegakkan kebijakan kami. Anda bertanggung jawab atas konten tersebut, termasuk memastikan bahwa ia tidak melanggar hukum yang berlaku atau persyaratan ini.',
    block9:
      '(B) Akurasi. Kecerdasan buatan dan pembelajaran mesin adalah bidang penelitian yang berkembang pesat. Kami terus berusaha untuk meningkatkan layanan kami untuk membuatnya lebih akurat, andal, aman, dan bermanfaat. Mengingat sifat probabilistik dari pembelajaran mesin, menggunakan layanan kami dapat menghasilkan output yang salah dalam kasus -kasus tertentu, yang mungkin tidak secara akurat mencerminkan individu, tempat, atau fakta yang nyata. Anda harus menilai keakuratan output apa pun berdasarkan kasus penggunaan Anda, termasuk dengan meninjau secara manual output.',
    subTitle1: 'Pendaftaran dan akses',
    subTitle10: 'Keparahan',
    subTitle2: 'Persyaratan penggunaan',
    subTitle3: 'Isi',
    subTitle4: 'Biaya dan pembayaran',
    subTitle5: 'Kerahasiaan, keamanan, dan perlindungan data',
    subTitle6: 'Istilah dan penghentian',
    subTitle7: 'Ganti rugi; Penafian; Batasan tanggung jawab',
    subTitle8: 'Mengatur hukum dan yurisdiksi',
    subTitle9: 'Istilah umum',
    title: 'Ketentuan Layanan',
    updateTime: 'Diperbarui: 15 Maret 2024',
  },
  trialModal: {
    btn: 'Mulai sekarang （hanya $ 0,99）',
    chat: 'Chatpdf',
    desc1: 'Setelah persidangan Anda, Anda akan dikenakan biaya tahunan sebesar $ 180',
    desc2: 'Batalkan kapan saja',
    focus: 'Fokus',
    hover: 'Lempar Terjemahan',
    image: 'Terjemahan gambar',
    installBtn: 'Instal sekarang untuk menerima hadiah Anda',
    memo: 'Manajemen favorit',
    pdf: 'Terjemahan paralel PDF',
    realtime: 'Terjemahan instan',
    select: 'Terjemahan Pilihan Kata',
    title: 'Hadiah Selamat Datang',
    trialText: 'Uji coba 7 hari',
    video: 'Terjemahan video',
    videoSummary: 'Ringkasan Video',
    web: 'Terjemahan paralel',
    webSummary: 'Ringkasan web',
  },
  upgradeModal: {
    btn: 'Tingkatkan sekarang',
    chat: {
      free: 'Anda telah mencapai batas chatpdf',
      pro: 'Anda telah mencapai batas chatpdf untuk bulan ini',
    },
    contactStr: 'Harap tunggu reset berikutnya, atau hubungi kami di contact@noam.tools.',
    limitAlertStr: 'Anda telah mencapai batas rencana gratis Anda. Tingkatkan untuk melanjutkan.',
    more: 'Lihat rencana',
    pageLimit: { free: 'Anda telah mencapai batas halaman PDF' },
    title: 'Pengingat',
    upgradeAlertStr: 'Anda telah mencapai batas rencana gratis Anda. Tingkatkan untuk melanjutkan.',
    upload: {
      free: 'Anda telah mencapai batas terjemahan PDF Anda',
      pro: 'Anda telah mencapai batas terjemahan PDF Anda untuk bulan ini',
    },
  },
  welfare: {
    newUser: {
      joinBtn: 'Bergabunglah sekarang',
      label: 'Manfaat Pengguna Baru:',
      remain: 'Hanya 30 tersisa',
      text: 'Hanya 100 tempat yang tersedia! Ambil milikmu sekarang!',
    },
  },
}

export default TRANSLATION
